import React, { FC } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Controller } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CommonInputProps, MaskedInputProps } from "./types";

export const Input: FC<CommonInputProps> = ({
  name,
  control,
  label,
  type = "text",
  isPassword = false,
  multiline = false,
  error,
  defaultValue = "",
  startIcon, // For left-side icon
  endIcon,
  size,
  placeHolder,
  sx, // For right-side icon
  disabled
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          {...field}
          variant="outlined"
          fullWidth
          size={size}
          placeholder={placeHolder}
          multiline={multiline}
          margin="normal"
          type={isPassword && !showPassword ? "password" : type}
          error={!!error}
          helperText={error}
          sx={{
            marginTop: "0px",
            "& [class*='-MuiInputBase-root-MuiOutlinedInput-root'] .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #98A5B5",
              borderRadius: "5px",
            },
            ...sx,
          }}
          InputProps={{
            startAdornment: startIcon && (
              <InputAdornment position="start">{startIcon}</InputAdornment>
            ),
            endAdornment: isPassword ? (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ) : (
              endIcon && (
                <InputAdornment position="end">{endIcon}</InputAdornment>
              )
            ),
          }}
          disabled={disabled}
        />
      )}
    />
  );
};


export const MaskedInput: FC<MaskedInputProps> = ({
  name,
  control,
  type = "text",
  isPassword = false,
  multiline = false,
  error,
  defaultValue = "",
  startIcon,
  endIcon,
  size,
  placeHolder,
  sx,
  formatStyle = "space",
  disabled
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const formatInput = (value: string) => {
    const sanitizedValue = value.replace(/\D/g, "");
    if (formatStyle === "space") {
      return sanitizedValue.replace(/(.{4})/g, "$1 ").trim();
    } else if (formatStyle === "dash") {
      return sanitizedValue.replace(/(.{2})/g, "$1-").replace(/-$/, "").trim();
    }
    return sanitizedValue;
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          {...field}
          value={field.value || ""}
          onChange={(e) => {
            const formattedValue = formatInput(e.target.value);
            field.onChange(formattedValue);
          }}
          variant="outlined"
          fullWidth
          size={size}
          placeholder={placeHolder}
          multiline={multiline}
          margin="normal"
          type={isPassword && !showPassword ? "password" : type}
          error={!!error}
          helperText={error}
          sx={{
            marginTop: "0px",
            "& [class*='-MuiInputBase-root-MuiOutlinedInput-root'] .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #98A5B5",
              borderRadius: "5px",
            },
            ...sx,
          }}
          InputProps={{
            startAdornment: startIcon && (
              <InputAdornment position="start">{startIcon}</InputAdornment>
            ),
            endAdornment: isPassword ? (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ) : (
              endIcon && <InputAdornment position="end">{endIcon}</InputAdornment>
            ),
          }}
          disabled={disabled}
        />
      )}
    />
  );
};
