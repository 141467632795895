import { type Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Stepper(theme: Theme) {
  return {
    MuiStepLabel: {
      styleOverrides: {
        root: {
          alignItems: 'flex-start',
          padding: 0,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: theme.palette.success.main,
          },
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          borderColor: `${theme.palette.divider} !important`,
          minHeight: '5em',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: `${theme.palette.divider} !important`,
        },
      },
    },
  };
}
