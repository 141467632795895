import React, { useState, Suspense } from "react";
import { Box, Card, Stack, Tab, Tabs, Typography } from "@mui/material";

// Lazy load the components
const AdminUsers = React.lazy(() => import("./AdminUsers"));
const AdminPermission = React.lazy(() => import("./AdminPermission"));

// TabPanel component
const TabPanel = ({
  children,
  value,
  index,
}: {
  children: React.ReactNode;
  value: number;
  index: number;
}) => {
  return (
    <Box role="tabpanel" hidden={value !== index} sx={{ p: 2 }}>
      {value === index && children}
    </Box>
  );
};

export const AdminSetting = () => {
  const [tabIndex, setTabIndex] = useState(0);

  // Tab change handler
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Stack sx={{ marginTop: "10px", padding: "20px" }}>
      <Card>
        <Stack sx={{ px: 4, py: 2 }}>
          <Typography sx={{ fontSize: "17px", fontWeight: 500 }}>
            Admin Settings
          </Typography>
        </Stack>
        <Box sx={{ borderTop: "1px solid #F1F4F7" }}>
          {/* Tabs Navigation */}
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="Admin Settings Tabs"
            sx={{
              "& .MuiTabs-indicator": { backgroundColor: "#0177FB", height: "3px" },
              "& .MuiTab-root": {
                textTransform: "none",
                fontWeight: 500,
                color: "gray",
                minWidth: 150,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "12px 16px",
              },
              "& .Mui-selected": { color: "#0177FB" },
              borderBottom: "1px solid #F1F4F7",
            }}
          >
            <Tab label="Users" sx={{ borderRight: "1px solid #F1F4F7" }} />
            <Tab label="Roles" sx={{ borderRight: "1px solid #F1F4F7" }} />
          </Tabs>

         
          <TabPanel value={tabIndex} index={0}>
            <Suspense fallback={<Typography>Loading Users...</Typography>}>
              <AdminUsers />
            </Suspense>
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <Suspense fallback={<Typography>Loading Roles...</Typography>}>
              <AdminPermission />
            </Suspense>
          </TabPanel>
        </Box>
      </Card>
    </Stack>
  );
};

export default AdminSetting;