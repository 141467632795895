import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { request } from "@app/services";
import * as endpoints from "./endpoints";
import {
  changePassword,
  changePasswordFailed,
  changePasswordSuccess,
  changeProfilePicture,
  changeProfilePictureFailed,
  changeProfilePictureSuccess,
  createCompanyDocuments,
  createCompanyDocumentsFailed,
  createCompanyDocumentsSuccess,
  createProjectDocuments,
  createProjectDocumentsFailed,
  createProjectDocumentsSuccess,
  getCompanyDocuments,
  getCompanyDocumentsFailed,
  getCompanyDocumentsSuccess,
  getProjectDocuments,
  getProjectDocumentsFailed,
  getProjectDocumentsSuccess,
  // getCompanyUsersFailed,
  // getCompanyUsersSuccess,
  getGeneralSettings,
  getGeneralSettingsFailed,
  getGeneralSettingsSuccess,
  getUser,
  getUserFailed,
  getUserSuccess,
  updateCompanyProfile,
  updateGeneralSettings,
  updateGeneralSettingsFailed,
  updateGeneralSettingsSuccess,
  updateProfile,
  updateProfileFailed,
  updateProfileSuccess,
  getProjectUsersSuccess,
  getProjectUsersFailed,
  getProjectUsers,
  getOnboardingQuestionnaire,
  updateOnboardingQuestionnaireSuccess,
  updateOnboardingQuestionnaireFailed,
  updateOnboardingQuestionnaire,
  getGeneralList,
  getGeneralListSuccess,
  getGeneralListFailed,
  postGeneralList,
  postGeneralListSuccess,
  postGeneralListFailed,
  deleteGeneralList,
  deleteGeneralListSuccess,
  deleteGeneralListFailed,
  getOnboardingQuestionnaireSuccess,
  getOnboardingQuestionnaireFailed,
  getCompanyUsers,
  getCompanyUsersSuccess,
  getCompanyUsersFailed,
  postCompanyUser,
  postCompanyUserSuccess,
  postCompanyUserFailed,
  deleteCompanyUser,
  deleteCompanyUserSuccess,
  deleteCompanyUserFailed,
  updateCompanyUser,
  updateCompanyUserSuccess,
  updateCompanyUserFailed,

    getSystemUsers,
    getSystemUsersSuccess,
    getSystemUsersFailed,
    deleteSystemUser,
    deleteSystemUserSuccess,
    deleteSystemUserFailed,
} from "./slice";
import { enqueueSnackbar } from "notistack";

import {
  companyDocuments,
  selectGeneralSettings,
  selectprojectDocuments,
} from "./selector";

import {
  getCompanyDetailFailed,
  getCompanyDetailSuccess,
  getSelectedCompanyById,
  getSelectedCompanyByIdFailed,
  getSelectedCompanyByIdSuccess,
} from "../company/slice";
import {
  updateProjectData,
  updateProjectFailed,
  updateProjectSuccess,
} from "../project/slice";
import { update } from "lodash";
import {
  getQualificationFailedAction,
  getQualificationSuccessAction,
} from "../qualification/slice";

function* changePasswordSaga(action: ReturnType<typeof changePassword>): any {
  try {
    const { oldPassword, newPassword } = action.payload;

    const response = yield call(request.post, `${endpoints.changePassword}`, {
      oldPassword,
      newPassword,
    });

    yield put(changePasswordSuccess(response.message));
  } catch (error: any) {
    yield put(changePasswordFailed(error));
  }
}
function* changeProfilePictureSaga(
  action: ReturnType<typeof changeProfilePicture>
): any {
  try {
    const { file } = action.payload;

    const formData = new FormData();

    formData.append("file", file);

    const response = yield call(
      request.post,
      `${endpoints.changeProfilePicture}`,
      formData
    );

    yield put(changeProfilePictureSuccess(response.message));
  } catch (error: any) {
    yield put(changeProfilePictureFailed(error));
  }
}
//
function* getUserSaga(action: ReturnType<typeof getUser>): any {
  try {
    const response = yield call(request.get, `${endpoints.getUser}`);
    yield put(getUserSuccess(response));
  } catch (error: any) {
    yield put(getUserFailed(error));
  }
}

function* updateUserSaga(action: ReturnType<typeof updateProfile>): any {
  try {
    const formData = new FormData();
    for (const key in action.payload) {
      formData.append(key, action.payload[key]);
    }

    const response = yield call(request.put, `${endpoints.getUser}`, formData);
    yield put(updateProfileSuccess(response.message));

    yield call(
      enqueueSnackbar,
      response.message || "Profile updated successfully!",
      { variant: "success" }
    );
  } catch (error: any) {
    yield put(updateProfileFailed(error));

    yield call(enqueueSnackbar, error?.message || "Failed to update profile.", {
      variant: "error",
    });
  }
}

function* updateCompanySaga(
  action: ReturnType<typeof updateCompanyProfile>
): any {
  try {
    const formData = new FormData();

    for (const key in action.payload.data) {
      if (
        action.payload.data[key] !== null &&
        action.payload.data[key] !== undefined
      ) {
        if (action.payload.data[key] instanceof File) {
          formData.append(key, action.payload.data[key]); // Append file directly
        } else {
          formData.append(key, String(action.payload.data[key])); // Convert non-file values to strings
        }
      }
    }

    const response = yield call(
      request.put,
      `${endpoints.updateCompanyProfile}${action.payload.id}`,
      formData
    );
    enqueueSnackbar(
      response.message || "Company profile updated successfully!",
      { variant: "success" }
    );

    yield put(getSelectedCompanyByIdSuccess(response.data));
  } catch (error: any) {
    console.log("error", error);
    enqueueSnackbar(error?.message || "Failed to update company profile.", {
      variant: "error",
    });
    yield put(getSelectedCompanyByIdFailed(error));
  }
}

function* createCompanyDocumentsSaga(
  action: ReturnType<typeof createCompanyDocuments>
): any {
  try {
    const formData = new FormData();
    for (const key in action.payload.data) {
      formData.append(key, action.payload.data[key]);
    }
    const response = yield call(
      request.put,
      `${endpoints.createCompanyDocuments}/${action.payload.companyId}/create-documents`,
      formData
    );
    const data: any = yield select(companyDocuments);
    // const updatedData = [...data, response.data];
    const updatedData = {
      ...data,
      data: [...(data.data || []), response.data],
    };

    yield put(createCompanyDocumentsSuccess(updatedData));
    yield call(
      enqueueSnackbar,
      response.message || "Company documents created successfully!",
      { variant: "success" }
    );
  } catch (error: any) {
    yield put(createCompanyDocumentsFailed(error));
    yield call(
      enqueueSnackbar,
      error?.message || "Failed to create company documents.",
      { variant: "error" }
    );
  }
}

function* getCompanyDocumentsSaga(action: any): any {
  try {
    const { page, limit } = action.payload;
    const companyId = action.payload.companyId;
    const response = yield call(
      request.get,
      `${endpoints.createCompanyDocuments}/${companyId}/documents?page=${page}&limit=${limit}`
    );

    yield put(getCompanyDocumentsSuccess(response.data));
  } catch (error: any) {
    yield put(getCompanyDocumentsFailed(error));
  }
}

function* createProjectDocumentsSaga(
  action: ReturnType<typeof createProjectDocuments>
): any {
  try {
    const formData = new FormData();
    for (const key in action.payload.data) {
      formData.append(key, action.payload.data[key]);
    }
    const response = yield call(
      request.put,
      `${endpoints.createCompanyDocuments}/${action.payload.projectId}/create-projects-documents`,
      formData
    );
    const data: any = yield select(selectprojectDocuments);
    // const updatedData = [...data, response.data];
    const updatedData = {
      ...data,
      data: [...(data.data || []), response.data],
    };

    yield put(createProjectDocumentsSuccess(updatedData));
    yield call(
      enqueueSnackbar,
      response.message || "Project documents created successfully!",
      { variant: "success" }
    );
  } catch (error: any) {
    yield put(createProjectDocumentsFailed(error));
    yield call(
      enqueueSnackbar,
      error?.message || "Failed to create project documents.",
      { variant: "error" }
    );
  }
}

function* updateGeneralSettingsSaga(
  action: ReturnType<typeof updateGeneralSettings>
): any {
  try {
    const genId = action?.payload?.genId;

    const {
      employmentStatusItems,
      inductionStatusItems,
      employeeId,
      onboardingWaitingList,
      inductionBookings,
      contractorsId,
      qualificationId,
      generalListId,
      defaultListId,
      ...rest
    } = action?.payload?.data;

    const transformedData = {
      ...rest,
      employment_statuses: employmentStatusItems,
      induction_statuses: inductionStatusItems,
      emp_id_prefix: employeeId,
      onboarding_list_prefix: onboardingWaitingList,
      induction_booking_prefix: inductionBookings,
      contract_prefix: contractorsId,
      qualification_prefix: qualificationId,
      general_list_prefix: generalListId,
      default_list_prefix: defaultListId,
    };

    const response = yield call(
      request.put,
      `${endpoints.getGeneralSettings}${genId}`,
      transformedData
    );

    const currentSettings: any = yield select(selectGeneralSettings);
    const updatedSettings = {
      ...currentSettings,
      data: response.data,
    };

    yield put(updateGeneralSettingsSuccess(updatedSettings));
    yield call(
      enqueueSnackbar,
      response.message || "General settings updated successfully!",
      { variant: "success" }
    );
  } catch (error: any) {
    yield put(updateGeneralSettingsFailed(error));
    yield call(
      enqueueSnackbar,
      error?.message || "Failed to update general settings.",
      { variant: "error" }
    );
  }
}

function* updateProject(action: any): any {
  try {
    const formData = new FormData();
    for (const key in action.payload) {
      formData.append(key, action.payload[key]);
    }
    console.log(formData.entries());
    const response = yield call(
      request.put,
      `${endpoints.createCompanyDocuments}/update-project/${action.payload.id}`,
      formData
    );
    if (response) {
      yield put(updateProjectSuccess(response));
      yield call(
        enqueueSnackbar,
        response.message || "Project update successfully!",
        { variant: "success" }
      );
    }
    return response.data;
  } catch (error: any) {
    yield put(updateProjectFailed(error));
  }
}

function* getProejctDocumentsSaga(action: any): any {
  try {
    const { page, limit } = action.payload;
    const projectId = action.payload.projectId;
    const response = yield call(
      request.get,
      `${endpoints.createCompanyDocuments}/${projectId}/project-documents?page=${page}&limit=${limit}`
    );

    yield put(getProjectDocumentsSuccess(response.data));
  } catch (error: any) {
    yield put(getProjectDocumentsFailed(error));
  }
}

function* getGeneralSettingsDetails(action: any): any {
  try {
    const userId = action?.payload?.id;
    const response = yield call(
      request.get,
      `${endpoints.getGeneralSettings}${userId}`
    );

    yield put(getGeneralSettingsSuccess(response.data));
  } catch (error: any) {
    yield put(getGeneralSettingsFailed(error));
  }
}

function* getProjectUsersSaga(action: any) {
  try {
    const { page = 1, limit = 10 } = action.payload;
    const id = action.payload.projectId;

    if (id) {
      const { data } = yield call(
        request.get,
        `${endpoints.getProjectUsers}/${id}?&page=${page}&limit=${limit}`
      );
      yield put(getProjectUsersSuccess({ data }));
    }
  } catch (error: any) {
    yield put(getProjectUsersFailed(error));
  }
}

function* getQuestionnaireSaga(action: any): any {
  try {
    const id = action?.payload?.id;

    if (id) {
      const response = yield call(
        request.get,
        `${endpoints.getOnboardingQuestionnaire}${id}`
      );
      yield put(getOnboardingQuestionnaireSuccess(response.data));
    }
  } catch (error: any) {
    yield put(getOnboardingQuestionnaireFailed(error));
  }
}
function* updateQuestionnaireSaga(action: any): any {
  try {
    console.log("holo", action.payload);
    const id = action?.payload?.id;
    const data = action?.payload?.data;

    const response = yield call(
      request.put,
      `${endpoints.getOnboardingQuestionnaire}${id}`,
      data
    );

    yield put(updateOnboardingQuestionnaireSuccess(response.data));
    yield call(
      enqueueSnackbar,
      response.message || "Questionnaire updated successfully!",
      { variant: "success" }
    );
  } catch (error: any) {
    yield put(updateOnboardingQuestionnaireFailed(error));
    yield call(
      enqueueSnackbar,
      error.message || "Failed to update questionnaire!",
      { variant: "error" }
    );
  }
}
//sytem useres sagas is here 

function* getSystemUsersSaga(): any {
  try {
    const response = yield call(
      request.get,
      `${endpoints.getSystemUsers}`
    );
    yield put(getSystemUsersSuccess(response.data));
  } catch (error: any) {
    yield put(getSystemUsersFailed(error));
    yield call(
      enqueueSnackbar,
      error?.message || "Failed to fetch system users.",
      { variant: "error" }
    );
  }
}

function* deleteSystemUserSaga(action: ReturnType<typeof deleteSystemUser>): any {
  try {
    const userId = action.payload;
    yield call(
      request.delete,
      `${endpoints.deleteSystemUser}/${userId}`
    );
    yield put(deleteSystemUserSuccess(userId));
    yield put(getSystemUsers()); // Refresh the list after deletion
    yield call(
      enqueueSnackbar,
      "System user deleted successfully!",
      { variant: "success" }
    );
  } catch (error: any) {
    yield put(deleteSystemUserFailed(error));
    yield call(
      enqueueSnackbar,
      error?.message || "Failed to delete system user.",
      { variant: "error" }
    );
  }
}

//============ general list ===============
function* getGeneralListSaga(action: ReturnType<typeof getGeneralList>): any {
  try {
    const id = action.payload.id;
    const response = yield call(
      request.get,
      `${endpoints.getGeneralListByCompany}${id}`
    );
    yield put(getGeneralListSuccess(response.data));
  } catch (error: any) {
    yield put(getGeneralListFailed(error));
    yield call(
      enqueueSnackbar,
      error?.message || "Failed to fetch general list.",
      { variant: "error" }
    );
  }
}

function* postGeneralListSaga(action: ReturnType<typeof postGeneralList>): any {
  try {
    const formData = new FormData();
    for (const key in action.payload) {
      formData.append(key, action.payload[key]);
    }

    const response = yield call(
      request.post,
      `${endpoints.postGeneralList}`,
      formData
    );

    yield put(postGeneralListSuccess(response.data));
    yield call(
      enqueueSnackbar,
      response.message || "Item added to general list successfully!",
      { variant: "success" }
    );
  } catch (error: any) {
    yield put(postGeneralListFailed(error));
    yield call(
      enqueueSnackbar,
      error?.message || "Failed to add item to general list.",
      { variant: "error" }
    );
  }
}

function* deleteGeneralListSaga(
  action: ReturnType<typeof deleteGeneralList>
): any {
  try {
    const response = yield call(
      request.delete,
      `${endpoints.deleteGeneralListById}${action.payload}`
    );

    yield put(deleteGeneralListSuccess(response.data));

    yield call(
      enqueueSnackbar,
      response.message || "Item removed from general list successfully!",
      { variant: "success" }
    );
  } catch (error: any) {
    yield put(deleteGeneralListFailed(error));
    yield call(
      enqueueSnackbar,
      error?.message || "Failed to remove item from general list.",
      { variant: "error" }
    );
  }
}
function* getCompanyUsersSaga(action: ReturnType<typeof getCompanyUsers>): any {
  try {
    const companyId = action.payload.companyId;
    // console.log("Fetching users for companyId:", companyId);
    const response = yield call(
      request.get,
      `${endpoints.getCompanyUsers}/${companyId}/users`
    );
    // console.log(" comapny user API Response:", response);
    yield put(getCompanyUsersSuccess(response.data)); 
  } catch (error: any) {
    console.error("API Error:", error.response || error);
    yield put(getCompanyUsersFailed(error));
    yield call(enqueueSnackbar, error?.message || "Failed to fetch company users.", {
      variant: "error",
    });
  }
}


function* postCompanyUserSaga(action: ReturnType<typeof postCompanyUser>): any {
  try {
    const { email, username, userType, password, companies } = action.payload; // Destructure companies instead of companyId

    // Validate that companies array is not empty
    if (!companies || companies.length === 0) {
      throw new Error("At least one company ID is required to create a user.");
    }

    const payload = {
      email,
      username,
      userType,
      password,
      companies, // Use the companies array as-is
      emailNotification: "enabled",
      textMessages: "enabled",
      profilePicture: "https://example.com/profile/johndoe.jpg",
      permissionLevel: "editor",
      receiveEmailnotification: "YES",
      receiveTextNotification: "NO",
      frequency: "Weekly",
    };

    console.log("Sending payload to API:", payload); // Debug the final payload
    const response = yield call(request.post, endpoints.createUser, payload);
    console.log("POST Response:", response);

    // Handle different possible response structures
    const userData = response.data?.data || response.data;
    if (!userData) {
      throw new Error("No user data found in response.");
    }

    yield put(postCompanyUserSuccess(userData));
    yield put(getCompanyUsers({ companyId: companies[0] })); // Refresh with the first companyId
    yield call(enqueueSnackbar, "User added successfully!", { variant: "success" });
  } catch (error: any) {
    console.error("POST Error:", error.response || error);
    yield put(postCompanyUserFailed(error));
    yield call(enqueueSnackbar, error?.message || "Failed to add user.", {
      variant: "error",
    });
  }
}

function* deleteCompanyUserSaga(action: ReturnType<typeof deleteCompanyUser>): any {
  try {
    const { userId, companyId } = action.payload;
    
    
    const payload = {
      companyId,
      userId
    };

  
    yield call(request.delete, endpoints.deleteCompanyUser, { data: payload });
    
    yield put(deleteCompanyUserSuccess(userId));
    yield put(getCompanyUsers({ companyId:parseInt(companyId) })); // Refresh list after delete
    yield call(enqueueSnackbar, "User deleted successfully!", { variant: "success" });
  } catch (error: any) {
    console.error('Delete error:', error);
    yield put(deleteCompanyUserFailed(error));
    yield call(enqueueSnackbar, error?.message || "Failed to delete user.", { variant: "error" });
  }
}

function* updateCompanyUserSaga(action: ReturnType<typeof updateCompanyUser>): any {
  try {
    const { userId, data } = action.payload;
    const response = yield call(request.put, `${endpoints.updateCompanyUser}${userId}`, data);
    yield put(updateCompanyUserSuccess(response.data));
    yield call(enqueueSnackbar, "User updated successfully!", { variant: "success" });
  } catch (error: any) {
    yield put(updateCompanyUserFailed(error));
    yield call(enqueueSnackbar, error?.message || "Failed to update user.", { variant: "error" });
  }
  
}

function* accountInformationSaga() {
  yield all([
    takeLatest(changePassword, changePasswordSaga),
    takeLatest(changeProfilePicture, changeProfilePictureSaga),
    takeLatest(getUser, getUserSaga),
    takeLatest(updateProfile, updateUserSaga),
    takeLatest(updateCompanyProfile, updateCompanySaga),
    takeLatest(createCompanyDocuments, createCompanyDocumentsSaga),
    takeLatest(getCompanyDocuments, getCompanyDocumentsSaga),
    takeLatest(updateProjectData, updateProject),
    takeLatest(getProjectDocuments, getProejctDocumentsSaga),
    takeLatest(updateGeneralSettings, updateGeneralSettingsSaga),
    takeLatest(getGeneralSettings, getGeneralSettingsDetails),
    takeLatest(createProjectDocuments, createProjectDocumentsSaga),
    takeLatest(getProjectUsers, getProjectUsersSaga),
    takeLatest(getOnboardingQuestionnaire, getQuestionnaireSaga),
    takeLatest(updateOnboardingQuestionnaire, updateQuestionnaireSaga),
    takeLatest(getGeneralList, getGeneralListSaga),
    takeLatest(postGeneralList, postGeneralListSaga),
    takeLatest(deleteGeneralList, deleteGeneralListSaga),
    takeLatest(getCompanyUsers, getCompanyUsersSaga), 
    takeLatest(postCompanyUser, postCompanyUserSaga), 
    takeLatest(deleteCompanyUser, deleteCompanyUserSaga),
    takeLatest(updateCompanyUser, updateCompanyUserSaga),
    takeLatest(getSystemUsers, getSystemUsersSaga),
    takeLatest(deleteSystemUser, deleteSystemUserSaga),
  ]);
}

export { accountInformationSaga };