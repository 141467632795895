import React from 'react';
import {
  Box,
  Card,
  Typography,
  Chip,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Checkbox,
  Avatar,
  IconButton,
  Tabs,
  Tab,
  useTheme,
} from '@mui/material';
import { Icon } from "@iconify/react";
import { SvgColor } from '@app/components/svg-color';
import { sourceUrl } from '@app/constants/source_url';
import UsersInvoice from './UsersInvoice';

import { Stepper } from "@app/components/Stepper";
import Status from "@app/components/status/Status";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import SendIcon from '@mui/icons-material/Send';
import { PATH_DASHBOARD } from '@app/routes/paths';
import { Navigate } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
};

const UsersPayment = () => {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // Verify the icon paths
  const iconPaths = {
    left: "icon-park-outline:left",
    right: "icon-park-outline:right",
    export: sourceUrl.icons.exportInfo
  };

  // Dummy Data Objects
  const clientInfo = {
    reference: "000001",
    status: "ACTIVE",
    fullName: "Petru Lacatis",
    organization: "IP Builders Brickwork Limited",
    industry: "Construction",
    address: "3 -13 Church Street, Epsom, KT174RH",
    email: "RLacatis@ipbuilders.co.uk",
    mobile: "+44773 886 0938",
    vat: "334455345",
    joinedDate: "02 January 2023"
  };

  const subscriptionInfo = {
    subscription: "Basic Plan",
    paymentPlan: "Yearly",
    planValue: "£ 300.00 / Year per user",
    userCount: "5/5",
    subscriptionValue: "£ 1,200.00 / Year",
    renewalDate: "01 January 2024"
  };

  const revenueInfo = {
    netRevenue: "£ 2,400.00",
    vatAmount: "£ 480.00",
    grossRevenue: "£ 2,880.00"
  };

  const usersData = [
    {
      ref: '000001-001',
      status: 'Active',
      name: 'Petru Lacatis',
      permission: 'System Owner (Main Account)',
      start: '02/01/2023',
      end: '01/01/2024',
      billable: 'Yes',
      net: '300.00',
      vat: '60.00',
      amount: '360.00',
      payment: 'Paid',
      access: 'Access Account'
    },
    {
      ref: '000001-002',
      status: 'Active',
      name: 'Reimound Lacatis',
      permission: 'Super Admin',
      start: '07/02/2023',
      end: '06/02/2024',
      billable: 'Yes',
      net: '300.00',
      vat: '60.00',
      amount: '360.00',
      payment: 'Paid',
      access: 'Access Account'
    },
    {
      ref: '000001-003',
      status: 'Active',
      name: 'Ciprian Luculescu',
      permission: 'Manager',
      start: '07/02/2023',
      end: '06/02/2024',
      billable: 'Yes',
      net: '300.00',
      vat: '60.00',
      amount: '360.00',
      payment: 'Paid',
      access: 'Access Account'
    },
    {
      ref: '000001-004',
      status: 'Active',
      name: 'Alex Luculescu',
      permission: 'Administrator',
      start: '08/04/2023',
      end: '07/04/2024',
      billable: 'Yes',
      net: '300.00',
      vat: '60.00',
      amount: '360.00',
      payment: 'Paid',
      access: 'Access Account'
    },
    {
      ref: '000001-005',
      status: 'Active',
      name: 'Robert Luculescu',
      permission: 'Viewer',
      start: '01/05/2023',
      end: '31/03/2024',
      billable: 'No',
      net: '0.00',
      vat: '0.00',
      amount: '0.00',
      payment: 'N/A',
      access: 'Access Account'
    }
  ];

  const commentsData = [
    {
      author: "Sadie Sauer",
      timestamp: "27-06-2024 03:55 am",
      content: "view/preview",
      replies: [
        {
          author: "mail-disgrasav-1...",
          timestamp: "1 minute ago"
        }
      ]
    },
    {
      author: "Sadie Sauer",
      timestamp: "27-06-2024 03:55 am",
      content: "view/preview",
      replies: []
    }
  ];

  return (
    <Box sx={{
      height: '100%',
      overflow: 'auto',
      p: 2
    }}>
      <Card sx={{
        maxWidth: '100%',
        border: '1px solid #e0e0e0',
        borderRadius: 1,
        overflow: 'auto',
        position: 'relative',  
        zIndex: 1  
      }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            p: 2,  
            borderBottom: '1px solid #e0e0e0', 
      
            bgcolor: '#F1F4F7',  
          }}
        >
          <Button
            sx={{ 
              fontSize: "13px", 
              fontWeight: "500",
              m: 1, 
              color: '#616E80',
             border: '1px solid #616E80'
            }}
           startIcon={<Icon icon="icon-park-outline:left" />}
          >
            Previous
          </Button>
          <Box sx={{ display: "flex", gap: "10px", alignItems: 'center' }}>
            <Button
              sx={{ 
                fontSize: "13px", 
                fontWeight: "500",
                border: '1px solid #616E80',
              color: '#616E80',
              }}
              startIcon={<SvgColor src={iconPaths.export} />}
            >
              Export
            </Button>
            <Button
              sx={{ 
                fontSize: "13px", 
                fontWeight: "500",
                color: '#616E80',

                
             border: '1px solid #616E80'
               
              }}
              endIcon={<Icon icon="icon-park-outline:right" />}
            >
              Next
            </Button>
          </Box>
        </Box>
        {/* Header */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, bgcolor: 'transparent', borderBottom: '1px solid #e0e0e0' }}>
          <Typography variant="body1" fontWeight="medium">Members Reference # {clientInfo.reference}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="body1">Client Status:</Typography>
            <Chip label={clientInfo.status} sx={{ fontWeight: 'bold', backgroundColor: "#3BD365" }} />
          </Box>
        </Box>

        {/* Client Information Section */}
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            {/* Left column */}
            <Grid item xs={6}>
              <Typography sx={{
          color: theme.palette.grey[600],
          fontWeight: 800,
          fontStyle: "italic",
          ml: 2,
          mb: 2,
        }} >Recipient Information</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: 'medium', color: "#000000" }}>Full Name</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="primary">{clientInfo.fullName}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: 'medium', color: "#000000" }}>Organization:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="primary">{clientInfo.organization}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: 'medium', color: "#000000" }}>Industry:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="primary">{clientInfo.industry}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: 'medium', color: "#000000" }}>Registered Address:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="primary">{clientInfo.address}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: 'medium', color: "#000000" }}>Email Address:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="primary">{clientInfo.email}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: 'medium', color: "#000000" }}>Mobile Number:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="primary">{clientInfo.mobile}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: 'medium', color: "#000000" }}>VAT:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="primary">{clientInfo.vat}</Typography>
                </Grid>
              </Grid>

              <Box sx={{ mt: 3 }}>
                <Typography variant="body2" sx={{ fontWeight: 'medium', color: "#000000", mb: 1 }}>
                  Subscription Information
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ fontWeight: 'medium', color: "#000000" }}>Subscription:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" color="primary">{subscriptionInfo.subscription}</Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ fontWeight: 'medium', color: "#000000" }}>Payment Plan:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" color="primary">{subscriptionInfo.paymentPlan}</Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ fontWeight: 'medium', color: "#000000" }}>Plan Value:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" color="primary">{subscriptionInfo.planValue}</Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ fontWeight: 'medium', color: "#000000" }}>User Count:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" color="primary">{subscriptionInfo.userCount}</Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ fontWeight: 'medium', color: "#000000" }}>Subscription Value:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" color="primary">{subscriptionInfo.subscriptionValue}</Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ fontWeight: 'medium', color: "#000000" }}>Renewal Date:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" color="primary">{subscriptionInfo.renewalDate}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            {/* Right column */}
            <Grid item xs={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, mt: 3 }}>
                <Typography variant="body2" sx={{ fontWeight: 'medium', color: "#000000" }}>Client Status:</Typography>
                <Chip
                  label={clientInfo.status}
                  size="small"
                  sx={{ bgcolor: '#3BD365', color: 'white', borderRadius: 4, ml: 1, fontSize: '0.75rem' }}
                />
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={4}><Typography variant="body2" sx={{ fontWeight: 'medium', color: "#000000" }}>Joined on:</Typography></Grid>
                <Grid item xs={8}><Typography variant="body2">{clientInfo.joinedDate}</Typography></Grid>
              </Grid>

              <Typography variant="body2" color="textSecondary" sx={{ mt: 3, mb: 1,fontStyle: 'italic'  }}>Revenue To Date</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}><Typography variant="body2" sx={{ fontWeight: 'medium', color: "#000000" }}>Net Revenue:</Typography></Grid>
                <Grid item xs={8}><Typography variant="body2">{revenueInfo.netRevenue}</Typography></Grid>

                <Grid item xs={4}><Typography variant="body2" sx={{ fontWeight: 'medium', color: "#000000" }}>VAT Amount:</Typography></Grid>
                <Grid item xs={8}><Typography variant="body2">{revenueInfo.vatAmount}</Typography></Grid>

                <Grid item xs={4}><Typography variant="body2" fontWeight="bold"sx={{  color: "#000000" }}>GROSS REVENUE:</Typography></Grid>
                <Grid item xs={8}><Typography variant="body2" fontWeight="bold" sx={{color:"#357D44"}}>{revenueInfo.grossRevenue}</Typography></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {/* Tabs Section */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="client tabs">
            <Tab label="Users & Subscriptions" sx={{ textTransform: 'none' }} />
            <Tab label="Invoices" sx={{ textTransform: 'none' }} />
            <Tab label="Documents" sx={{ textTransform: 'none' }} />
            <Tab label="Notification Preferences & Marketing" sx={{ textTransform: 'none' }} />
          </Tabs>
        </Box>

        {/* Tab Panels */}
        <TabPanel value={value} index={0}>
          {/* Users Table */}
          <Box sx={{ p: 2 }}>
            <TableContainer
              component={Paper}
              elevation={0}
              sx={{ border: '1px solid #e0e0e0', maxHeight: '400px', overflow: 'auto' }}
            >
              <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                  <TableRow sx={{ bgcolor: '#f9f9f9' }}>
                    <TableCell padding="checkbox"><Checkbox color="primary" /></TableCell>
                    <TableCell>Our Ref.</TableCell>
                    <TableCell>User Status</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Permission</TableCell>
                    <TableCell>License Start</TableCell>
                    <TableCell>License End</TableCell>
                    <TableCell>Billable</TableCell>
                    <TableCell>Net Value</TableCell>
                    <TableCell>VAT</TableCell>
                    <TableCell>Bill Amount</TableCell>
                    <TableCell>Payment Status</TableCell>
                    <TableCell>Access</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersData.map((row, index) => (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell padding="checkbox"><Checkbox color="primary" /></TableCell>
                      <TableCell>{row.ref}</TableCell>
                      <TableCell>
                        <Chip
                          label={row.status}
                          size="small"
                          sx={{ bgcolor: '#4caf50', color: 'white', borderRadius: 4, fontSize: '0.75rem' }}
                        />
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'medium', color: "#000000" }} >{row.name}</TableCell>
                      <TableCell sx={{  color: "#000000" }}>{row.permission}</TableCell>
                      <TableCell>{row.start}</TableCell>
                      <TableCell>{row.end}</TableCell>
                      <TableCell sx={{ fontWeight: 'medium', color: "#000000" }}>{row.billable}</TableCell>
                      <TableCell sx={{ fontWeight: 'medium', color: "#000000" }}>£ {row.net}</TableCell>
                      <TableCell sx={{ fontWeight: 'medium', color: "#000000" }}>£ {row.vat}</TableCell>
                      <TableCell sx={{color:"#357D44"}}>£ {row.amount}</TableCell>
                      <TableCell sx={{ fontWeight: 'medium', color: "#000000" }}>{row.payment}</TableCell>
                      <TableCell sx={{ fontWeight: 'medium', color: "#000000" }}>{row.access}</TableCell>
                      <TableCell>
                        <IconButton size="small">
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <UsersInvoice />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Box p={2}>Documents Content</Box>
        </TabPanel>

        <TabPanel value={value} index={3}>
          <Box p={2}>Notification Preferences & Marketing Content</Box>
        </TabPanel>

        {/* Comments and Notes Section */}
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>Client Note (Internal)</Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                placeholder="Write your notes here..."
              />
            </Grid>

            <Grid item xs={6}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Typography variant="body2" color="textSecondary">Comments ( internal )</Typography>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  size="small"
                  sx={{ bgcolor: '#1976d2', color: 'white', textTransform: 'none', borderRadius: '4px' }}
                >
                  New Comment
                </Button>
              </Box>

              <Box sx={{ bgcolor: '#fafafa', p: 2, borderRadius: '4px', mb: 2 }}>
                {commentsData.map((comment, index) => (
                  <Box key={index} sx={{ mb: comment.replies.length ? 2 : 1 }}>
                    <Box sx={{ display: 'flex', mb: comment.replies.length ? 1 : 0 }}>
                      <Avatar
                        src="/api/placeholder/40/40"
                        alt={comment.author}
                        sx={{ width: 36, height: 36, mr: 1 }}
                      />
                      <Box sx={{ flex: 1 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="body2" fontWeight="medium">{comment.author}</Typography>
                          <Typography variant="caption" color="textSecondary">{comment.timestamp}</Typography>
                        </Box>
                        <Typography variant="caption" color="textSecondary">{comment.content}</Typography>
                      </Box>
                    </Box>
                    {comment.replies.map((reply, replyIndex) => (
                      <Box key={replyIndex} sx={{ display: 'flex', ml: 5, mt: 1 }}>
                        <Avatar
                          src="/api/placeholder/40/40"
                          sx={{ width: 24, height: 24, mr: 1 }}
                        />
                        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="caption" color="textSecondary">{reply.author}</Typography>
                          <Typography variant="caption" color="textSecondary">{reply.timestamp}</Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>

              <Divider />
              <Box sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  placeholder="Write your message here"
                  variant="outlined"
                  size="small"
                  sx={{ mb: 1 }}
                  InputProps={{
                    endAdornment: (
                      <Box sx={{ display: 'flex' }}>
                        <IconButton size="small"><AttachFileIcon fontSize="small" /></IconButton>
                        <IconButton size="small"><EmojiEmotionsIcon fontSize="small" /></IconButton>
                      </Box>
                    )
                  }}
                />
                <Button
                  variant="contained"
                  startIcon={<SendIcon />}
                  size="small"
                  sx={{ bgcolor: '#1976d2', color: 'white', textTransform: 'none', borderRadius: '4px' }}
                >
                  Send
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};

const Layout = () => {
  return (
    <Box sx={{
      height: 'calc(100vh - 64px)',
      overflow: 'auto',
      backgroundColor: '#f5f7f9'
    }}>
      <UsersPayment />
    </Box>
  );
};

export default Layout;