import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Divider,
  Stack,
  Typography,
  TextField,
  useTheme,
} from "@mui/material";
import { Search } from "@mui/icons-material";

import { PATH_DASHBOARD } from "@app/routes/paths";
import { Fragment, useEffect, useState } from "react";
import { SvgColor } from "@app/components/svg-color";
import { sourceUrl } from "@app/constants/source_url";
import KnowledgeTable from "./table/knowledgeTable";

import {
  getCategories,
  getKnowledgeBase,
  getKnowledgeBaseCategory,
} from "./slice";
import { useRequestState } from "@app/hooks/use-request-state";
import {
  selectCategoriesState,
  selectKnowledgeBaseCategoryState,
  selectKnowledgeBaseState,
} from "./selectors";
import { selectCompanyId } from "../company/selectors";

export const KnowledgeBaseScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const companyId = useSelector(selectCompanyId);

  const [selectedCategory, setSelectedCategory] = useState("all");

  useEffect(() => {
    dispatch(getCategories({ page: 1, limit: 100, companyId }));
    dispatch(getKnowledgeBase({ companyId }));
  }, [companyId, dispatch]);

  const { data } = useRequestState({
    stateSelector: selectCategoriesState,
  });

  useEffect(() => {
    if (data?.length > 0) {
      setUpdatedCategory(
        data?.length > 0 ? [{ id: "all", name: "All" }, ...data] : []
      );
    }
  }, [data]);

  const [updatedCategory, setUpdatedCategory] = useState<any[]>();

  const { data: knowledgeBase } = useRequestState({
    stateSelector: selectKnowledgeBaseState,
  });

  const { data: knowledgeBaseCategory } = useRequestState({
    stateSelector: selectKnowledgeBaseCategoryState,
  });

  const handleCategoryClick = (item: any) => {
    setSelectedCategory(item.id);
    dispatch(getKnowledgeBaseCategory({ categoryId: item.id }));
  };

  const selectedKnowledgeBase =
    selectedCategory.toLowerCase().trim() === "all"
      ? knowledgeBase
      : knowledgeBaseCategory;

  return (
    <Stack
      component={"div"}
      sx={{
        border: "1px solid #F1F4F7",
        display: "flex",
        flexDirection: "row",
        height: "100%",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          height: "100%",
          backgroundColor: theme.palette.common.white,
          marginLeft: "12px",
          width: "20%",
        }}
      >
        <Stack
          sx={{
            borderRight: "1px solid #F1F4F7",
            padding: "7px",
            backgroundColor: theme.palette.common.white,
            py: 2,
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search"
            size="small"
            onChange={(e) => {
              const value = e.target.value.toLowerCase().trim();

              setUpdatedCategory(
                value.length === 0
                  ? data?.length > 0
                    ? [{ id: "all", name: "All" }, ...data]
                    : []
                  : data?.filter(
                      (cat: any) =>
                        cat.name &&
                        cat.name.toLowerCase().trim().includes(value)
                    )
              );
            }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #F1F4F7",
                width: "100%",
              },
              "& .MuiOutlinedInput-root": {
                height: "40px",
                mx: 2,
                borderRadius: "4px",
              },
              "& .MuiInputBase-input::placeholder": {
                fontSize: "13.34px",
                color: "#99A5B5",
                fontWeight: 400,
              },
              borderRadius: "4px",
            }}
            InputProps={{
              startAdornment: (
                <Search
                  sx={{
                    marginRight: "5px",
                    color: "#99A5B5",
                    height: "20px",
                    width: "20px",
                    fontWeight: 600,
                  }}
                />
              ),
            }}
          />
        </Stack>
        <Stack
          sx={{
            maxHeight: "100%",
            overflowY: "auto",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: theme.palette.grey[400],
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: theme.palette.grey[200],
            },
          }}
        >
          {updatedCategory &&
            updatedCategory.length > 0 &&
            updatedCategory.map((item: any, index: number) => (
              <Fragment key={item.id}>
                <Stack
                  onClick={() => handleCategoryClick(item)}
                  sx={{
                    py: 1,
                    px: 3,
                    pl: 3,
                    height: "51.5px",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    "&:hover": { backgroundColor: "#E8EDF3" },
                    backgroundColor:
                      item.id === selectedCategory
                        ? "#E8EDF3"
                        : theme.palette.common.white,
                    borderRight:
                      item.id === selectedCategory
                        ? `3px solid ${theme.palette.common.black}`
                        : "none",
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.grey[600],
                      fontSize: "13.48px",
                      fontWeight: 400,
                    }}
                  >
                    {item?.name}
                  </Typography>
                </Stack>

                <Divider
                  sx={{
                    borderColor: theme.palette.grey[300],
                  }}
                />
              </Fragment>
            ))}
          <Stack></Stack>
        </Stack>
      </Stack>
      <Stack sx={{ paddingTop: "30px", paddingBottom: "20px", width: "80%" }}>
        <Stack
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: "20px",
            paddingLeft: "20px",
          }}
        >
          <Button
            variant="contained"
            startIcon={<SvgColor src={sourceUrl.icons.addIcon} />}
            onClick={() => {
              navigate(PATH_DASHBOARD.addNewArticle);
            }}
            sx={{
              borderRadius: "5px",
              height: " 34px",
              width: "155px",
              fontSize: "14px",
              fontWeight: "400",
              textWrap: "nowrap",
            }}
          >
            Add New Article
          </Button>

          <Button
            variant="outlined"
            startIcon={<SvgColor src={sourceUrl.icons.inducted} />}
            onClick={() => {
              navigate(PATH_DASHBOARD.articleCategory);
            }}
            sx={{
              color: "#616E80",
              backgroundColor: "#FFFFFF",
              borderColor: "#616E80",
              borderRadius: "5px",
              height: " 34px",
              width: "208px",
              fontSize: "14px",
              fontWeight: "400",
              textWrap: "nowrap",
            }}
          >
            Manage Article Category
          </Button>
        </Stack>
        <Stack sx={{ width: "94%", margin: "20px" }}>
          {selectedKnowledgeBase && selectedKnowledgeBase?.length > 0 && (
            <>
              <KnowledgeTable data={selectedKnowledgeBase} />
              <Divider sx={{ borderColor: "#F1F4F7" }} />
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
