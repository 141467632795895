import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  addDesignationAction,
  addDesignationSuccessAction,
  deleteDesignation,
  deleteDesignationFailed,
  deleteDesignationSuccess,
  getDesignationAction,
  getDesignationFailedAction,
  getDesignationSuccessAction,
} from "./slice";
import * as endpoint from "./endpoint";
import { selectCompanyId } from "../company/selectors";
import { request } from "@app/services";
import { selectDesignation } from "./selectors";

function* fetchDesignation(action: any) {
  try {
    const companyId: string = yield select(selectCompanyId);
    const { page, limit } = action.payload;
    if (companyId) {
      const { data } = yield call(
        request.get,
        `${endpoint.designationByCompanyId}${companyId}?page=${page}&limit=${limit}`
      );
      yield put(getDesignationSuccessAction({ data }));
    }
  } catch (error: any) {
    yield put(getDesignationFailedAction(error));
  }
}

function* postDesignation(action: any): any {
  try {
    const companyId: string = yield select(selectCompanyId);
    const { callback, id, ...rest } = action.payload;
    if (companyId) {
      if (id) {
        const response = yield call(
          request.put,
          endpoint.deleteDesignation + id,
          {
            companyId,
            ...rest,
          }
        );
        yield put(getDesignationAction({ page: 1, limit: 1000 }));
        yield put(addDesignationSuccessAction(response.message));
      } else {
        const response = yield call(request.post, `${endpoint.designation}`, {
          companyId,
          ...rest,
        });

        yield put(getDesignationAction({ page: 1, limit: 1000 }));
        yield put(addDesignationSuccessAction(response.message));
      }

      callback();
    }
  } catch (error: any) {
    yield put(getDesignationFailedAction(error));
  }
}

function* deleteDesignationSaga(
  action: ReturnType<typeof deleteDesignation>
): any {
  try {
    const { id } = action.payload;

    const result = yield call(request.delete, endpoint.deleteDesignation + id);

    const designationData = yield select(selectDesignation);

    const updatedDesignationData = designationData.data.filter(
      (designation: any) => designation?.id !== id
    );

    const updatedDesignation = {
      ...designationData,
      data: updatedDesignationData,
    };

    yield put(getDesignationSuccessAction({ data: updatedDesignation }));

    yield put(deleteDesignationSuccess(result.message));
  } catch (err: any) {
    yield put(deleteDesignationFailed(err));
  }
}

export function* designationSaga() {
  yield all([
    takeLatest(getDesignationAction, fetchDesignation),
    takeLatest(addDesignationAction, postDesignation),
    takeLatest(deleteDesignation, deleteDesignationSaga),
  ]);
}
