import { MenuItem } from "@mui/material";

interface Props {
  actions?: Array<{ label: string; callback?: () => void; disabled?: boolean }>;
  onClickAction: VoidFunction;
}

export default function RowActions({ actions, onClickAction }: Props) {
  return (
    <>
      {actions?.map((action) => (
        <MenuItem
          key={action.label}
          onClick={() => {
            onClickAction();
            action.callback?.();
          }}
          disabled={action.disabled}
        >
          {action.label}
        </MenuItem>
      ))}
    </>
  );
}
