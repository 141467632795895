import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Strings } from "../../constants/strings";
import Card from "../../components/card/Card";
import { DialogBox } from "../../components/dialog/Dialog";
import { SvgColor } from "@app/components/svg-color";
import { Input, Label } from "@app/components";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { selectProjectData } from "./selectors";
import { formSchema } from "./validator";
import { IProjectFormSchema } from "./types";
import { addProject, getProjects, selectProject } from "./slice";
import { PATH_DASHBOARD } from "@app/routes/paths";
import { LandingPageLayout } from "@app/layout";
import { selectCompanyId } from "../company/selectors";
import { Icon } from "@iconify/react";
import { sourceUrl } from "@app/constants/source_url";
import { enqueueSnackbar } from "notistack";

const SelectProjectScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyId } = useParams();
  const projects = useSelector(selectProjectData);

  const updatedProjects: any = [
    ...(projects && Array.isArray(projects) && projects.length > 1
      ? [
        {
          name: "View All Projects",
          imageUrl: sourceUrl.company.selectAllCompany,
        },
      ]
      : []),
    ...(projects && Array.isArray(projects) ? projects : []),
  ];

  const selectedCompanyId = useSelector(selectCompanyId);
  const [open, setOpen] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IProjectFormSchema>({
    resolver: yupResolver(formSchema),
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = (data: IProjectFormSchema) => {
    if (companyId) {
      const projectName = projects?.find((project: any) => project.name === data.projectName );
      if (projectName){
        enqueueSnackbar(`${data.projectName} already exist`, {
          variant: "error",
        });
        return
      } else {
        dispatch(addProject({ companyId, ...data }));
        handleClose();
      }
    } else {
      handleClose();
    }



  };

  useEffect(() => {
    console.log("selected---", selectedCompanyId);
    if (companyId != undefined) dispatch(getProjects({ companyId }));
  }, [dispatch]);
  return (
    <LandingPageLayout>
      <Stack
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          flexDirection: "column",
          paddingY: "25px",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          {Strings.selectProjectScreen.selectProject}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
            marginTop: "12px",
            flexWrap: "wrap",
          }}
        >
          {updatedProjects?.map((item: any) => (
            <Card
              key={item.name}
              headerTitle={item.name}
              imageUri={item.imageUrl}
              imageStyle={{ borderRadius: "5px" }}
              buttonText={
                item?.name?.toLowerCase().replace(/\s+/g, "") ===
                  "viewallprojects"
                  ? "Select All Projects"
                  : Strings.selectProjectScreen.selectProject
              }
              buttonStyle={{
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "24px",
                height: "48px",
              }}
              onButtonClick={() => {
                dispatch(
                  selectProject({
                    name:
                      item?.name?.toLowerCase().replace(/\s+/g, "") ===
                        "viewallproject"
                        ? "All Projects"
                        : item.name,
                    id:
                      item?.name?.toLowerCase().replace(/\s+/g, "") ===
                        "viewallproject"
                        ? ""
                        : item?.id,
                  })
                );
                navigate(PATH_DASHBOARD.employeeList);
              }}
              deleteBtn={item.name === "View All Projects" ? "" : "active"}
              cardId={item.id}
              cardType={'project'}
            />
          ))}

          <Card
            cardProps={{
              component: "label",
            }}
            headerTitle={Strings.selectProjectScreen.addNewProject}
            imageUri="/assets/images/upload_button_image.png"
            buttonText={Strings.selectProjectScreen.addNewProject}
            buttonStyle={{
              fontWeight: "700",
              fontSize: "20px",
              lineHeight: "24px",
              height: "48px",
            }}
            onButtonClick={handleOpen}
          />
        </Box>
        <DialogBox
          open={open}
          onClose={handleClose}
          onSave={handleSubmit(onSubmit)}
          title="Create New Project"
          saveButtonText="Save"
          cancelButtonText="Cancel"
          saveButtonStyle={{ fontWeight: "400", fontSize: "14px" }}
          cancelButtonStyle={{ fontWeight: "400", fontSize: "14px" }}
        >
          <Label>Project Name</Label>
          <Input
            name="projectName"
            control={control}
            label="Company Name"
            placeHolder={Strings.field.inputPlaceholder("Project Name")}
            error={errors?.projectName?.message}
          />
          <Label>Upload Picture</Label>
          <Controller
            name="imageUpload"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <>
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  style={{ display: "none" }}
                  id="uploadProjectPic"
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      field.onChange(e.target.files[0]); // Pass the file to react-hook-form
                    }
                  }}
                />
                {!field.value ? (
                  <Stack
                    component="label"
                    htmlFor="uploadProjectPic"
                    sx={{
                      width: "100%",
                      height: "120px",
                      border: "1px solid #e5e7eb",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                  >
                    <SvgColor
                      src={`/assets/icons/Upload.svg`}
                      sx={{ width: 80, height: 80, color: "#98A5B5" }}
                    />
                    <Typography variant="body2" color="textSecondary">
                      Click to upload an image
                    </Typography>
                    {errors?.imageUpload?.message && (
                      <Typography variant="body2" color="error"></Typography>
                    )}
                  </Stack>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                    }}
                  >
                    <Box
                      component="img"
                      src={URL.createObjectURL(field.value)}
                      sx={{
                        width: "100%",
                        height: "120px",
                        borderRadius: "8px",
                        display: "flex",
                        objectFit: "contain",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        cursor: "pointer",
                      }}
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: "5px",
                        right: "14px",
                      }}
                      onClick={() => {
                        field.onChange(null);
                      }}
                    >
                      <Icon
                        icon="fontisto:close"
                        width="28"
                        height="28"
                        color="#98A5B5"
                      />
                    </IconButton>
                  </Box>
                )}
              </>
            )}
          />
        </DialogBox>
      </Stack>
    </LandingPageLayout>
  );
};

export default SelectProjectScreen;
