import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { request } from "@app/services";
import * as endpoint from "./endpoints";
import {
  addDetailsOfContractFiles,
  addDetailsOfContractFilesSuccess,
  editIdentifyRightWorkAction,
  editIdentifyRightWorkFailedAction,
  editIdentifyRightWorkSuccessAction,
  editMedicalHistory,
  editMedicalHistoryFailed,
  editMedicalHistorySuccess,
  removeEmergencyContact,
  removeEmergencyContactFailed,
  removeEmergencyContactSuccess,
  updateEmergencyContactAction,
  updateEmergencyContactFailed,
  updateEmergencyContactSuccess,
} from "./slice";
import { addDetailsOfContractFailed, getEmployee, getEmployeeEmergencyContact, getEmployeesSuccess, getEmployeeSuccess } from "../add_employee/slice";
import { selectSelectedEmployee, selectSelectedEmployeeData } from "../add_employee/selector";
import { RequestStatus } from "@app/constants/request";
import { take } from "lodash";

function* editIdentifyRightWorkActionSaga(
  action: ReturnType<typeof editIdentifyRightWorkAction>
): any {
  const payload: any = action.payload;

  const formData = new FormData();

  Object.keys(payload).forEach((key) => {
    const value = payload[key];
    if (value instanceof File || value instanceof Blob) {
      formData.append(key, value);
    } else {
      formData.append(key, String(value));
    }
  });

  try {
    const result = yield call(
      request.put,
      `${endpoint.editIdentifyRightWork}${payload.employeeId}/identity-and-right-to-work`,
      formData
    );
    yield put(editIdentifyRightWorkSuccessAction(result));
  } catch (error: any) {
    yield put(editIdentifyRightWorkFailedAction);
  }
}
function* editMedicalHistorySaga(
  action: ReturnType<typeof editMedicalHistory>
): any {
  const payload: any = action.payload;

  try {
    const result = yield call(
      request.put,
      `${endpoint.editMedicalInformation}${payload.employeeId}/medical-information`,
      payload
    );

    yield put(editMedicalHistorySuccess(result));
    yield put(getEmployee({ employeeId: payload.employeeId }));
  } catch (error: any) {
    yield put(editMedicalHistoryFailed(error));
  }
}

function* deleteEmergencyContact(
  action: ReturnType<typeof removeEmergencyContact>
): any {
  const payload = action.payload
  try {
    const { id } = yield select(selectSelectedEmployee);
    if (id) {
      const result = yield call(
        request.delete,
        `${endpoint.employee}${id}${endpoint.emergencyContact}${payload}`
      )
      yield put(removeEmergencyContactSuccess(result))
      yield put(getEmployeeEmergencyContact({ page: 1, limit: 10 }))
    }
  } catch (err: any) {
    yield put(removeEmergencyContactFailed(err))
  }
}

function* updateEmergencyContact(action: ReturnType<typeof updateEmergencyContactAction>): any {
  try {
    const { id: contactId, callback, ...rest } = action.payload
    const { id } = yield select(selectSelectedEmployee);
    if (id) {
      const result = yield call(
        request.put,
        `${endpoint.employee}${id}${endpoint.emergencyContact}${contactId}`,
        action.payload,
        rest
      );
      yield put(updateEmergencyContactSuccess(result.data))
      yield put(getEmployeeEmergencyContact({ page: 1, limit: 10 }))
      callback()
    }
  } catch (err: any) {
    yield put(updateEmergencyContactFailed(err));
  }
}

function* addEmployeeContractFile(
  action: ReturnType<typeof addDetailsOfContractFiles>
): any {
  try {
    const formData = new FormData();
    const { payload, callback } = action.payload;
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        const value = payload[key];

        if (key === "imageUpload" && value) {
          formData.append("file", value);
        } else {
          if (value) formData.append(key, value);
        }
      }
    }
    // formData.append("front", action.payload.payload);
    const selectedEmployee = yield select(selectSelectedEmployeeData);
    const selectSelectedEmploye = yield select(selectSelectedEmployee);
    const result = yield call(
      request.post,
      `${endpoint.employee}${selectedEmployee.employee.id}/declaration/upload`,
      formData,
    );
    const updatedEmployee = {
      ...selectSelectedEmploye,
      employmentDeclaration: result.data, // Updating employmentDeclaration only
    };
    yield put(
      getEmployeeSuccess({
        data: { employee: updatedEmployee },
        status: RequestStatus.SUCCESS,
      })
    );
    yield put(addDetailsOfContractFilesSuccess(result));
    callback();
  } catch (error: any) {
    yield put(addDetailsOfContractFailed(error));
  }
}


export function* employeeProfileSagas() {
  yield all([takeLatest(editMedicalHistory, editMedicalHistorySaga)]);
  yield all([takeLatest(editIdentifyRightWorkAction, editIdentifyRightWorkActionSaga)]);
  yield all([takeLatest(removeEmergencyContact, deleteEmergencyContact)])
  yield all([takeLatest(updateEmergencyContactAction, updateEmergencyContact)])
  yield all([takeLatest(addDetailsOfContractFiles, addEmployeeContractFile)])
}
