import * as Yup from "yup";
import { Strings } from "../../constants/strings";

export const formSchema = Yup.object()
  .shape({
    companyName: Yup.string().required(
      Strings.validation.fieldRequired("Company Name")
    ),
    companyLogo: Yup.mixed().required(
      Strings.validation.uploadRequired("Image")
    ),
    companySmallLogo: Yup.mixed().required(
      Strings.validation.uploadRequired("Image")
    ),
  })
  .required();

export const companyDocumentFormSchema = Yup.object()
  .shape({
    name: Yup.string().required(
      Strings.validation.fieldRequired("Document Name")
    ),
    documentUrl: Yup.mixed().required(
      Strings.validation.uploadRequired("Image")
    ),
    description: Yup.mixed().required(
      Strings.validation.fieldRequired("Description")
    ),
  })
  .required();
