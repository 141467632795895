import { SvgColor } from "@app/components/svg-color";
import { Icon } from "@iconify/react";
import { Label } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addEmployeeCscsDocumentAction, addEmployeeQualificationDocumentAction } from "./slice";
import dayjs from "dayjs";
import { addDetailsOfContractFiles } from "../employee-profile/slice";
import { selectSelectedEmployee } from "./selector";
type Props = {
  isCscs?: boolean;
  employee: any;
  isFromTrainingMatrix?: boolean,
  isDetailContract?: boolean,
  employeeCertification?: any,
};
const UploadDocument = ({ isCscs, employee, isFromTrainingMatrix, isDetailContract ,employeeCertification}: Props) => {
  const dispatch = useDispatch();
  const { control, handleSubmit, formState: { errors }, setValue, reset } = useForm();

  const handleFormSubmit = (data: any) => {
    isCscs
      ? dispatch(
        addEmployeeCscsDocumentAction({
          payload: {
            employeeId: employee.id,
            ...data,
            expiryDate: data.expiryDate
              ? dayjs(data.expiryDate, "DD/MM/YYYY").toISOString()
              : null,
          },
          callback: (handleFormAfterApiStatus),
        })
      )
      : isDetailContract ?
        dispatch(
          addDetailsOfContractFiles({
            payload: {
              ...data
            },
            callback: (handleFormAfterApiStatus),
          })
        )
        : dispatch(
          addEmployeeQualificationDocumentAction({
            payload: {
              certificationId: employeeCertification?.id,
              employeeId: employee?.id,
              ...data,
              expiryDate: data.expiryDate
                ? dayjs(data.expiryDate, "DD/MM/YYYY").toISOString()
                : null,
            },
            callback: (handleFormAfterApiStatus),
          })
        );
  };

  const handleFormAfterApiStatus = () => {
    reset();
  }
  return (
    <form >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Controller
          name="front"
          control={control}
          defaultValue={null}
          rules={{
            validate: (file) => {
              if (!file) {
                return "File is required!";
              }
              const validTypes = [
                "image/jpeg",
                "image/png",
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              ];
              if (!validTypes.includes(file.type)) {
                return "Only image files (JPG, PNG), PDF, or DOC files are allowed!";
              }
              return true;
            },
          }}
          render={({ field }) => (
            <>
              <input
                type="file"
                accept="image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                style={{ display: "none" }}
                id={`${isCscs}uploadDocFile-${employeeCertification?.id}`}
                onChange={(e: any) => {
                  if (e.target.files && e.target.files[0]) {
                    field.onChange(e.target.files[0]);
                    handleSubmit(handleFormSubmit)();

                  }
                  e.target.value = "";
                }}
              />
              {!field.value ? (
                <Box
                  component="label"
                  htmlFor={`${isCscs}uploadDocFile-${employeeCertification?.id}`}
                  sx={{
                    border: `1px solid #A1A1A1`,
                    p: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                    ":hover": {
                      backgroundColor: "#ECECEC"
                    },
                    color: " #535455",
                    minWidth: "100%"
                  }}

                >
                  <SvgColor
                    src={`/assets/icons/Upload.svg`}
                    sx={{ color: "#CCCCCC", width: "50px", height: "30px" }}
                  />
                </Box>
              ) : <></>
              // (
              //   <Box
              //     sx={{
              //       display: "flex",
              //       flexDirection: "column",
              //       position: "relative",
              //       alignItems: "center",
              //     }}
              //   >
              //     <Box
              //       component="img"
              //       src={
              //         field.value.type.includes("image")
              //           ? URL.createObjectURL(field.value)
              //           : "/pdfIcon.png"
              //       }
              //       sx={{
              //         width: "100%",
              //         height: "120px",
              //         borderRadius: "8px",
              //         display: "flex",
              //         objectFit: "contain",
              //         alignItems: "center",
              //         justifyContent: "center",
              //         flexDirection: "column",
              //         cursor: "pointer",
              //       }}
              //     />
              //     {!field.value.type.includes("image") && (
              //       <Typography
              //         variant="body2"
              //         sx={{ cursor: "pointer" }}
              //       >
              //         {field.value.name}
              //       </Typography>
              //     )}
              //     <IconButton
              //       sx={{
              //         position: "absolute",
              //         top: "5px",
              //         right: "14px",
              //       }}
              //       onClick={() => {
              //         field.onChange(null);
              //       }}
              //     >
              //       <Icon
              //         icon="fontisto:close"
              //         width="24"
              //         height="24"
              //         color="#98A5B5"
              //       />
              //     </IconButton>
              //   </Box>
              // )
              }
            </>
          )}
        />
      </Box>
    </form>
  )
}

export default UploadDocument