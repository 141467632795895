import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

interface SalesData {
  month: string;
  earnings: string;
}

const salesData: SalesData[] = [
  { month: "January", earnings: "$10,000" },
  { month: "February", earnings: "$12,500" },
  { month: "March", earnings: "$9,800" },
  { month: "April", earnings: "$11,200" },
  { month: "May", earnings: "$15,300" },
  { month: "June", earnings: "$14,000" },
  { month: "July", earnings: "$13,500" },
  { month: "August", earnings: "$16,100" },
  { month: "September", earnings: "$12,400" },
  { month: "October", earnings: "$17,600" },
  { month: "November", earnings: "$14,900" },
  { month: "December", earnings: "$18,200" },
];

export const MonthlySalesTable: React.FC = () => {
  return (
    <Paper elevation={3} className="p-4 rounded-xl w-[50vw]">
      {/* Title */}
      <Typography variant="h6" fontWeight="bold" className="mb-3">
        Monthly Sales Earnings
      </Typography>

      {/* Scrollable Table Container */}
      <TableContainer
        component={Paper}
        style={{ maxHeight: "350px", overflowY: "auto" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow style={{ backgroundColor: "#f8fafc" }}>
              <TableCell style={{ fontWeight: "bold" }}>Month</TableCell>
              <TableCell align="right" style={{ fontWeight: "bold" }}>
                Earnings ($)
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {salesData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.month}</TableCell>
                <TableCell align="right">{row.earnings}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
