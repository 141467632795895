import { Header } from '@app/components'
import { LANDING_PAGE_PATH } from '@app/routes/paths';
import { Icon } from '@iconify/react';
import { Button, Stack } from '@mui/material';
import React, { FC, ReactElement, ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface Props {
    children: ReactElement;
}
export const LandingPageLayout: FC<Props> = ({ children }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate()
    return (
        <>
            <Header isAuthHeader={true} />
            <Stack component={"main"}
                sx={{
                    marginTop: "64px", position: "relative",
                    '&::-webkit-scrollbar': {
                        width: '10px',
                        padding: "1px"
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#171F22',
                        borderRadius: '4px',
                        width: "8px",
                        height: "4px"
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#fff',
                        display: "none"
                    },
                    overflowY: "scroll",
                }}
            >
                {
                    pathname != LANDING_PAGE_PATH.company && <Button
                        startIcon={<Icon icon="ion:arrow-back-outline" />}
                        sx={{
                            position: "absolute"
                        }}
                        onClick={() => { navigate(LANDING_PAGE_PATH.company) }}
                    >
                        Back
                    </Button>
                }

                {children}
            </Stack>
        </>
    )
}
