import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RequestStatus } from "@app/constants/request";
import { IRequestFailed } from "@app/types";
import { IEmployeeProfileState } from "./types";

const initialState: IEmployeeProfileState = {
  editEmploymentProfile: {},
  editMedicalHistory: {},
  emergencyContact: {},
  detailOfContractFiles:{},
  
};
const employeeProfile = createSlice({
  initialState,
  name: "employeeProfile",
  reducers: {
    editIdentifyRightWorkAction(state, action: PayloadAction<any>) {
      state.editEmploymentProfile.status = RequestStatus.RUNNING;
    },
    editIdentifyRightWorkSuccessAction(state, action: PayloadAction<any>) {
      state.editEmploymentProfile.status = RequestStatus.SUCCESS;
      state.editEmploymentProfile.data = action.payload;
    },
    editIdentifyRightWorkFailedAction(
      state,
      action: PayloadAction<IRequestFailed>
    ) {
      state.editEmploymentProfile.status = RequestStatus.ERROR;
      state.editEmploymentProfile.error = action.payload;
    },
    editMedicalHistory(state, action: PayloadAction<any>) {
      state.editMedicalHistory.status = RequestStatus.RUNNING;
    },
    editMedicalHistorySuccess(state, action: PayloadAction<any>) {
      state.editMedicalHistory.status = RequestStatus.SUCCESS;
      state.editMedicalHistory.data = action.payload;
    },
    editMedicalHistoryFailed(state, action: PayloadAction<IRequestFailed>) {
      state.editMedicalHistory.status = RequestStatus.ERROR;
      state.editMedicalHistory.error = action.payload;
    },
    removeEmergencyContact(state, action: PayloadAction<any>) {
      state.emergencyContact.status = RequestStatus.RUNNING;
    },
    removeEmergencyContactSuccess(state, action: PayloadAction<any>) {
      state.emergencyContact.status = RequestStatus.SUCCESS;
      state.emergencyContact.data = action.payload
    },
    removeEmergencyContactFailed(state, action: PayloadAction<IRequestFailed>) {
      state.emergencyContact.status = RequestStatus.ERROR;
      state.emergencyContact.error = action.payload
    },
    updateEmergencyContactAction(state, action: PayloadAction<any>) {
      state.emergencyContact.status = RequestStatus.RUNNING;
    },
    updateEmergencyContactSuccess(state, action: PayloadAction<any>) {
      state.emergencyContact.status = RequestStatus.SUCCESS;
      state.emergencyContact.data = action.payload
    },
    updateEmergencyContactFailed(state, action: PayloadAction<IRequestFailed>) {
      state.emergencyContact.status = RequestStatus.ERROR;
      state.emergencyContact.error = action.payload
    },
    addDetailsOfContractFiles(state, action) {
      state.detailOfContractFiles.status = RequestStatus.RUNNING;
    },
    addDetailsOfContractFilesSuccess(state, action: PayloadAction<any>) {
      state.detailOfContractFiles.status = RequestStatus.SUCCESS;
      state.detailOfContractFiles.data = action.payload
    },
    addDetailsOfContractFilesFailed(state, action: PayloadAction<IRequestFailed>) {
      state.detailOfContractFiles.status = RequestStatus.ERROR;
      state.detailOfContractFiles.error = action.payload
    }
  },
});

export const employeeProfileReducer = employeeProfile.reducer;

export const {
  editIdentifyRightWorkAction,
  editIdentifyRightWorkSuccessAction,
  editIdentifyRightWorkFailedAction,
  editMedicalHistory,
  editMedicalHistorySuccess,
  editMedicalHistoryFailed,
  removeEmergencyContact,
  removeEmergencyContactFailed,
  removeEmergencyContactSuccess,
  updateEmergencyContactAction,
  updateEmergencyContactFailed,
  updateEmergencyContactSuccess,
  addDetailsOfContractFiles,
  addDetailsOfContractFilesFailed,
  addDetailsOfContractFilesSuccess,
} = employeeProfile.actions;
