import { RequestStatus } from "@app/constants/request";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRequestFailed, IRequestState } from "@app/types";
import { IContractorState } from "./types";

const initialState: IContractorState = {
  contractor: {},
  addContractor: {},
  deleteContractor: {},
};

const ContractorSlice = createSlice({
  initialState,
  name: "contractor",
  reducers: {
    getContractor(state, action: PayloadAction<{ page: any; limit: any }>) {
      state.contractor.status = RequestStatus.RUNNING;
    },
    getContractorSuccess(state, action: PayloadAction<IRequestState>) {
      state.contractor.data = action.payload.data;
      state.contractor.status = RequestStatus.SUCCESS;
    },
    getContractorFailed(state, action: PayloadAction<IRequestFailed>) {
      state.contractor.data = action.payload;
      state.contractor.status = RequestStatus.ERROR;
    },
    addContractor(state, action) {
      state.addContractor.status = RequestStatus.RUNNING;
    },
    addContractorFailed(state, action) {
      state.addContractor.status = RequestStatus.ERROR;
      state.addContractor.error = action.payload;
    },
    addContractorSuccess(state, action) {
      state.addContractor.status = RequestStatus.SUCCESS;
      state.addContractor.data = action.payload;
    },
    deleteContractor(state, action: PayloadAction<any>) {
      state.deleteContractor.status = RequestStatus.RUNNING;
    },
    deleteContractorSuccess(state, action) {
      state.deleteContractor.status = RequestStatus.SUCCESS;
      state.deleteContractor.data = action.payload.data;
    },
    deleteContractorFailed(state, action: PayloadAction<IRequestFailed>) {
      state.deleteContractor.status = RequestStatus.ERROR;
      state.deleteContractor.error = action.payload;
    },
  },
});

export const contractorReducer = ContractorSlice.reducer;
export const {
  getContractor,
  getContractorFailed,
  getContractorSuccess,
  addContractor,
  addContractorFailed,
  addContractorSuccess,
  deleteContractor,
  deleteContractorFailed,
  deleteContractorSuccess
} = ContractorSlice.actions;
