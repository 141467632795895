import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { request } from "@app/services";
import * as endpoint from "./endpoints";
import {
  addEmployementTypeAction,
  addEmployementTypeFailedAction,
  addEmployementTypeSuccessAction,
  deleteEmploymentTypeAction,
  deleteEmploymentTypeFailedAction,
  deleteEmploymentTypeSuccessAction,
  getEmployementType,
  getEmployementTypeFailed,
  getEmployementTypeSuccess,
} from "./slice";
import { selectCompanyId } from "../company/selectors";
import { selectEmployementType } from "./selector";

function* fetchEmployementType(
  action: ReturnType<typeof getEmployementType>
): any {
  try {
    const companyId: string = yield select(selectCompanyId);
    const { page, limit } = action.payload;

    if (companyId) {
      const { data } = yield call(
        request.get,
        `${endpoint.employementType}${companyId}?page=${page}&limit=${limit}`
      );
      yield put(getEmployementTypeSuccess({ data }));
    }
  } catch (error: any) {
    yield put(getEmployementTypeFailed(error));
  }
}

function* addEmployementType(
  action: ReturnType<typeof addEmployementTypeAction>
): any {
  try {
    const { callback, name, id } = action.payload;
    const companyId: string = yield select(selectCompanyId);

    if (companyId) {
      if (id) {
        const { data, status, message } = yield call(
          request.put,
          `${endpoint.editEmploymentType}${id}`,
          {
            companyId,
            name: name,
          }
        );
        const existingEmploymentType = yield select(selectEmployementType);

        const updatedData = {
          ...existingEmploymentType,
          data: existingEmploymentType?.data?.map((exist: any) => {
            if (exist.id === data?.id) {
              return data;
            } else {
              return exist;
            }
          }),
        };

        yield put(getEmployementTypeSuccess({ data: updatedData }));
        yield put(addEmployementTypeSuccessAction(message));
        if (status === true) {
          callback();
        }
      } else {
        const { data, status, message } = yield call(
          request.post,
          endpoint.addemployementType,
          {
            companyId,
            name: name,
          }
        );

        const existingEmploymentType = yield select(selectEmployementType);
        let newData;

        if (existingEmploymentType?.data?.length > 0) {
          newData = [...existingEmploymentType.data, data];
        } else {
          newData = [data];
        }

        const updatedData = { ...existingEmploymentType, data: newData };

        yield put(getEmployementTypeSuccess({ data: updatedData }));
        yield put(addEmployementTypeSuccessAction(message));
        if (status === true) {
          callback();
        }
      }
    }
  } catch (error: any) {
    yield put(addEmployementTypeFailedAction(error));
  }
}
function* deleteEmploymentTypeSaga(
  action: ReturnType<typeof deleteEmploymentTypeAction>
): any {
  try {
    const { id } = action.payload;

    const result = yield call(
      request.delete,
      `${endpoint.editEmploymentType}${id}`
    );
    const existingEmploymentType = yield select(selectEmployementType);

    const updatedData = {
      ...existingEmploymentType,
      data: existingEmploymentType?.data?.filter(
        (exist: any) => exist?.id !== result?.data?.id
      ),
    };

    yield put(deleteEmploymentTypeSuccessAction(result.message));

    yield put(getEmployementTypeSuccess({ data: updatedData }));
  } catch (error: any) {
    yield put(deleteEmploymentTypeFailedAction(error));
  }
}

export function* employementTypeSagas() {
  yield all([
    takeLatest(getEmployementType, fetchEmployementType),
    takeLatest(addEmployementTypeAction, addEmployementType),
    takeLatest(deleteEmploymentTypeAction, deleteEmploymentTypeSaga),
  ]);
}
