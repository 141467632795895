import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Divider,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { get } from "lodash";

import { addQualificationAction, getQualificationAction } from "../slice";
import { selectAddQualification, selectQualification } from "../selector";
import { Input } from "@app/components";
import { useRequestState } from "@app/hooks/use-request-state";
import { LoadingButton } from "@mui/lab";
import { Strings } from "@app/constants/strings";
import { ICreateQualificationPayload } from "./types";

const schema = yup.object().shape({
  name: yup.string().required("Qualification name is required"),
  isMandatory: yup.boolean().required("Mandatory status is required"),
  totalHoldingEmp: yup
    .number()
    .typeError("Employees holding must be a number")
    .integer("Employees holding must be an integer")
    .min(0, "Employees holding cannot be negative")
    .required("Employees holding count is required"),
});

export const AddNewQualification = ({ open, onClose, data }: any) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ICreateQualificationPayload>({
    resolver: yupResolver(schema),
    defaultValues: {
      id: get(data, "id", ""),
      name: get(data, "name", ""),
      totalHoldingEmp: get(data, "totalHoldingEmp", 0),
      isMandatory: get(data, "isMandatory", false),
    },
  });

  const dispatch = useDispatch();

  const handleSaveQualification = (data: any) => {
    dispatch(
      addQualificationAction({ ...data, callback: handleAfterSaveSuccess })
    );
  };

  const handleAfterSaveSuccess = () => {
    reset();
    onClose();
  };

  const { loading } = useRequestState({
    stateSelector: selectAddQualification,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  const qualifications = useSelector(selectQualification);

  useEffect(() => {
    dispatch(getQualificationAction({ page: 1, limit: 1000 }));
  }, [dispatch]);

  return (
    <Dialog open={open}>
      <Stack
        sx={{
          height: "53px",
          width: "100%",
          boxShadow: "0px 4px 4px #F1F4F7",
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: 400, padding: "5px" }}>
          Create Qualification
        </Typography>
      </Stack>
      <Divider sx={{ color: "#F1F4F7" }} />
      <Card sx={{ padding: "0px" }}>
        <CardContent
          sx={{
            padding: "0px",
            ":last-child": {
              paddingBottom: "0px",
            },
          }}
          component={"form"}
          onSubmit={handleSubmit(handleSaveQualification)}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              paddingX: "20px",
              paddingY: "12px",
            }}
          >
            <Box sx={{ width: "100%", minWidth: "400px" }}>
              <Typography>Qualification Name</Typography>
              <Input
                name="name"
                control={control}
                placeHolder={Strings.field.inputPlaceholder(
                  "Qualification Name"
                )}
                error={errors.name?.message}
              />
            </Box>

            <Box sx={{ width: "100%", minWidth: "400px" }}>
              <Typography>Employees Holding This Qualification</Typography>
              <Input
                name="totalHoldingEmp"
                type="number"
                control={control}
                placeHolder="Number of Employees"
                error={errors.totalHoldingEmp?.message}
              />
            </Box>

            <Box sx={{ width: "100%", minWidth: "400px", marginTop: "10px" }}>
              <FormControlLabel
                control={
                  <Controller
                    name="isMandatory"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label="Is this Qualification Mandatory?"
              />
              {errors.isMandatory && (
                <Typography color="error">
                  {errors.isMandatory.message}
                </Typography>
              )}
            </Box>
          </Box>

          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "22px",
              paddingY: "10px",
            }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={onClose}
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                backgroundColor: "#FE6B6B",
              }}
            >
              Cancel
            </Button>
            <Box sx={{ display: "flex", gap: 2 }}>
              <LoadingButton
                loading={loading}
                variant="contained"
                type="submit"
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  backgroundColor: "#0177FB",
                }}
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Dialog>
  );
};
