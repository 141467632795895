import { createSlice, createAction } from '@reduxjs/toolkit';

import { type ISliceState } from './types';

const initialState: ISliceState = {
  ready: false,
};

const slice = createSlice({
  name: 'bootstrap',
  initialState,
  reducers: {
    markAppAsReady(state) {
      state.ready = true;
    },
  },
});

// Reducer )-------------------------------------
export default slice.reducer;

// Actions )-------------------------------------
export const { markAppAsReady } = slice.actions;

export const bootstrapApp = createAction('APP_INIT/INIT_APP');
