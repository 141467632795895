import { TableCell, Typography, Box } from '@mui/material';
import { AppTableHeadCellProps } from '../types';

const AppTableHeadCell = ({
  typographyProps,
  cellProps,
  icon,
  IconPosition = "right",
  title,
  renderComponent,
  width="120px"
}: AppTableHeadCellProps) => {
  return (
    <TableCell
      sx={{
        textAlign: "left",
        backgroundColor:"#fff",
        width:width,
        
        "&.MuiTableCell-root":{
          padding:"0px 12px"
        },
        ...cellProps?.sx,
      }}
      {...cellProps}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent={IconPosition === "right" ? "space-between" : "flex-start"}
        gap={1}
      >
        {IconPosition === "left" && icon}
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#616E80",
            ...typographyProps?.sx, // Allow external customization of styles
          }}
          {...typographyProps}
        >
          {title}
        </Typography>
        {renderComponent && renderComponent}
        {IconPosition === "right" && icon}
      </Box>
      
    </TableCell>
  );
};

export default AppTableHeadCell;
