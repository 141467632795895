import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import { IRequestFailed, IRequestState } from "@app/types";
import { RootState } from "@app/redux/root-reducer";
import { useEffectExceptOnMount } from "./use-effect-except-on-mounts";
import { RequestStatus } from "@app/constants/request";
import { Strings } from "@app/constants/strings";

// ----------------------------------------------------------------------

interface UseReturnType<T = any> extends IRequestState<T> {
  loading: boolean;
  isSuccess: boolean;
  isFailed: boolean;
}

export interface UseRequestStateProps<
  S extends (state: RootState) => IRequestState<any>
> {
  // Request state selector
  stateSelector: S;

  errorShown?: boolean;
  successMessageShown?: boolean;

  errorMessage?: string;

  successMessage?: string;

  // Call on request success
  onSuccess?: (data: ReturnType<S>["data"]) => void;

  // Call on request failed
  onFailed?: (error?: IRequestFailed) => void;
}

export function useRequestState<
  S extends (state: RootState) => IRequestState<any>
>(props: UseRequestStateProps<S>): UseReturnType<ReturnType<S>["data"]> {
  const {
    stateSelector,
    successMessageShown,
    successMessage,
    errorShown,
    errorMessage,
    onSuccess,
    onFailed,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { status, error, data } = useSelector(stateSelector) || {};

  console.log("Status____________", status);
  console.log("SucessMessage__________", successMessageShown);

  useEffectExceptOnMount(() => {
    if (status === RequestStatus.SUCCESS && successMessageShown) {
      enqueueSnackbar(
        `${Strings.common.success} ${
          successMessage ?? typeof data?.message === "string"
            ? (data.message as string)
            : ""
        }`,
        {
          variant: "success",
        }
      );
      onSuccess?.(data);
    }

    if (status === RequestStatus.ERROR && errorShown) {
      enqueueSnackbar(
        `${Strings.common.failed} ${errorMessage ?? error?.message ?? ""}`,
        {
          variant: "error",
        }
      );
      onFailed?.(error);
    }
  }, [
    status,
    successMessageShown,
    error,
    data,
    errorMessage,
    successMessage,
    errorShown,
    onSuccess,
    onFailed,
  ]);

  return {
    loading: status === RequestStatus.RUNNING,
    status,
    data,
    error,
    isFailed: status === RequestStatus.ERROR,
    isSuccess: status === RequestStatus.SUCCESS,
  };
}
