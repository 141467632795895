import { RootState } from "@app/redux/root-reducer";

export const selectChangePassword = (state: RootState) =>
  state.accountInformationReducer.changePassword;

export const selectChangePicture = (state: RootState) =>
  state.accountInformationReducer.changeProfile;

export const selectGetUser = (state: RootState) =>
  state.accountInformationReducer.user;

export const selectUpdateCompanyProfile = (state: RootState) =>
  state.accountInformationReducer.changeCompanyProfile;

export const companyDocuments = (state: RootState) =>
  state.accountInformationReducer.companyDocuments.data;

export const selectprojectDocuments = (state: RootState) =>
  state.accountInformationReducer.projectDocuments.data;
// export const companyUsers = (state: RootState) =>
//   state.accountInformationReducer.companyUsers.data;

export const selectGeneralSettings = (state: RootState) =>
  state.accountInformationReducer.generalSettings.data;

export const selectProjectUsers = (state: RootState) =>
  state.accountInformationReducer.projectUsers.data;

// New selector for full generalList object
export const selectGeneralList = (state: RootState) =>
  state.accountInformationReducer.generalList.data;

export const selectOnboardingQuestionnaire = (state: RootState) =>
  state.accountInformationReducer.onboardingQuestionnaire.data;
export const selectCompanyUsers = (state: RootState) =>
  state.accountInformationReducer.companyUsers.data || [];
