import { TextFieldProps } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useFormContext } from "react-hook-form";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";

import { SvgColor } from "../svg-color";

type DatePickerProps = {
  name: string;
  label: string;
  minDate?: any;
  maxDate?: any;
  onChange?: (date: any) => void;
  defaultValue?: Dayjs | null;
  disabled?: boolean;
  control: any;
  setValue: any;
  disablePast?: boolean;
  trigger?: any;
} & TextFieldProps;

function RHFDatePicker({
  name,
  label,
  sx,
  minDate,
  maxDate,
  onChange,
  required,
  defaultValue,
  disabled,
  setValue,
  control,
  trigger,
  disablePast = false,
}: DatePickerProps) {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    defaultValue ? defaultValue : null
  );

  const handleDateSelection = async (date: Dayjs | null) => {
    console.log("datepicker date", date);

    if (date) {
      setSelectedDate(date);
      setValue(name, date.format("DD/MM/YYYY"));
      if (onChange) {
        onChange(date);
      }
      await trigger(name);
    }
  };

  const icon = () => (
    <SvgColor
      src={`/assets/icons/calendar_icon.svg`}
      sx={{
        width: 22,
        height: 22,
        color: "black",
        fill: "black",
      }}
    />
  );

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          if (
            field.value &&
            !dayjs(field.value, "DD/MM/YYYY").isSame(selectedDate)
          ) {
            setSelectedDate(dayjs(field.value, "DD/MM/YYYY"));
          }
          if (!field.value && selectedDate !== null) {
            setSelectedDate(null);
          }
          return (
            <>
              <DatePicker
                {...field}
                minDate={minDate ?? undefined}
                maxDate={maxDate ?? undefined}
                sx={{
                  // "& .css-1qe1x6a-MuiInputBase-root-MuiOutlinedInput-root": {
                  //   border: !error ? "1px solid #98A5B5":"none",
                  //   borderRadius: "5px",
                  // },
                  ...sx,
                }}
                value={selectedDate}
                disabled={disabled}
                disablePast={disablePast}
                onChange={handleDateSelection}
                label={label}
                format="DD/MM/YYYY"
                slotProps={{
                  textField: () => ({
                    error: !!error,
                    focused: false,
                    helperText: error ? error.message : null,
                    required,
                  }),
                  inputAdornment: {
                    position: "start",
                  },
                }}
                slots={{ openPickerIcon: icon }}
              />
            </>
          );
        }}
      />
    </>
  );
}

export { RHFDatePicker };
