/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormProvider as Form, type UseFormReturn } from "react-hook-form";

// ----------------------------------------------------------------------

interface Props {
  children: React.ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
  flex?: number;
}

export function FormProvider({ children, flex, onSubmit, methods }: Props) {
  return (
    <Form {...methods}>
      <form style={{ flex }} onSubmit={onSubmit}>
        {children}
      </form>
    </Form>
  );
}
