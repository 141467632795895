import { SvgColor } from "@app/components/svg-color";
import { sourceUrl } from "@app/constants/source_url";
import { useDialog } from "@app/hooks/use-dialog";
import {
  Box,
  Card,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { AddContractFiles } from "./AddContarctFiles";
import { SignedDeclarationContract } from "./SignedDeclarationContract";
import { request } from "@app/services";
import * as endpoint from "../add_employee/endpoints";
import { useState } from "react";
import FilePreviewDialog from "@app/components/file-preview/FilePreview";
import { Height } from "@mui/icons-material";
import UploadDocument from "../add_employee/UploadDocument";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedEmployee,
  selectSelectedEmployeeData,
} from "../add_employee/selector";
import { deleteEmployeeDocumentOfContractDetails } from "../ApiServices/employeeApis";
import { getEmployeeSuccess } from "../add_employee/slice";
import { RequestStatus } from "@app/constants/request";

const DetailOfContract = ({ edit = false, employee }: any) => {
  const theme = useTheme();

  const addContractFilePopup = useDialog();
  const [pdfurl, setPdfUrl] = useState("");
  const [selectedPreviewFile, setSelectedPreviewFile] = useState<any>(null);
  const employeeData = useSelector(selectSelectedEmployee);
  const dispatch = useDispatch();
  const trfainingMatrix = [
    {
      heading: "Mandatory Qualifications Schemes ( MQS )",
      data: [
        {
          certification: "CSCS - Blue Card",
          registration: "N/A",
          expiryDate: "12 January 2026",
          documentIdFront: "Proof Front.pdf",
          proofIdBack: "Proof Back.pdf",
        },
      ],
    },
    {
      heading: "Other Qualifications",
      data: [
        {
          certification: "SMSTS - Site Management Safety Training Scheme",
          registration: "N/A",
          expiryDate: "12 January 2026",
          proofIdFront: "Proof Front.pdf",
          proofIdBack: null,
        },
        {
          certification: "First Aid",
          registration: "12345678",
          expiryDate: "12 January 2026",
          proofIdFront: "Proof Front.pdf",
          proofIdBack: null,
        },
        {
          certification: "Fire Marshall Certificate",
          registration: "12345678",
          expiryDate: "12 January 2026",
          proofIdFront: "Proof Front.pdf",
          proofIdBack: null,
        },
        {
          certification: "PASMA Certificate",
          registration: "12345678",
          expiryDate: "12 January 2026",
          proofIdFront: "Proof Front.pdf",
          proofIdBack: null,
        },
        {
          certification: "IPAF Certificate",
          registration: "12345678",
          expiryDate: "12 January 2026",
          proofIdFront: "Proof Front.pdf",
          proofIdBack: null,
        },
        {
          certification: "Harness Training Certificate",
          registration: "12345678",
          expiryDate: "12 January 2026",
          proofIdFront: "Proof Front.pdf",
          proofIdBack: null,
        },
      ],
    },
  ];

  const pdfPreview = useDialog();

  const pdfDownload = async (pdfData: any) => {
    try {
      const response = await request.post(
        `${endpoint.employee}/pdf-generate`,
        pdfData
      );
      const pdfUrl: any = response;
      if (pdfUrl) {
        setPdfUrl(pdfUrl);
        pdfPreview.show();
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const handleDeleteDocument = async (document: any) => {
    try {
      const response: any = await deleteEmployeeDocumentOfContractDetails(
        employee.id,
        employee.employmentDeclaration.id,
        document.fileUrl
      );
      if (response.status === true) {
        const updatedEmployee = {
          ...employee,
          employmentDeclaration: response.data,
        };
        dispatch(
          getEmployeeSuccess({
            data: { employee: updatedEmployee },
            status: RequestStatus.SUCCESS,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Card
        sx={{
          flex: 1,
          padding: 2,
          gap: 1.5,
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Stack sx={{ alignItems: "center", width: "70%" }}>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#616E80",
                fontWeight: 600,
                fontStyle: "italic",
              }}
            >
              Our Agreement
            </Typography>
            <Stack
              sx={{
                border: `1px solid  ${theme.palette.grey[400]}`,
                borderRadius: "10px",
                width: "100%",
                mt: 2,
                mb: 2,
                p: "0px 15px",
                paddingBottom: "10px",
              }}
            >
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    {/* Header Row */}
                    <TableRow>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                          fontSize: "10px",
                          width: "45%",
                          fontWeight: 400,
                        }}
                      >
                        Linked Document Name
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                          width: "15%",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "10px",
                            color: theme.palette.grey[600],
                            fontWeight: 400,
                          }}
                        >
                          Revision Number
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                          width: "40%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "10px",
                            color: theme.palette.grey[600],
                            fontWeight: 400,
                          }}
                        >
                          Document Description
                        </Typography>
                      </TableCell>
                    </TableRow>

                    {/* Data Row with Full Border */}
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        sx={{ border: "none", padding: 0 }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "45% 15% 40%",
                            border: `1.6px solid ${theme.palette.grey[400]}`, // Apply border to wrap all items
                            borderRadius: "8px", // Curved border
                            padding: "4px",
                            ":hover": {
                              backgroundColor: "#ECECEC",
                            },
                            cursor: "pointer",
                            color: " #535455", // Inner spacing
                          }}
                          onClick={() => {
                            pdfDownload(employee);
                          }}
                        >
                          {/* Document Name */}
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "#535455",
                              fontStyle: "italic",
                              paddingLeft: 1,
                              display: "flex",
                            }}
                          >
                            <SvgColor
                              src="/assets/icons/pdfIcon.svg"
                              style={{ marginRight: "10px" }}
                              color="red"
                            />
                            Signed Declaration Form.pdf
                          </Typography>

                          {/* Revision Number */}
                          <Stack
                            sx={{
                              border: `1.6px solid ${theme.palette.grey[400]}`,
                              paddingX: 2,
                              borderRadius: "4px",
                              textAlign: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "15px",
                                color: theme.palette.grey[700],
                                fontWeight: 400,
                                fontStyle: "italic",
                              }}
                            >
                              001
                            </Typography>
                          </Stack>

                          {/* Signed Copy of Declaration */}
                          <Stack
                            sx={{
                              border: `1.6px solid ${theme.palette.grey[400]}`,
                              paddingX: 2,
                              borderRadius: "4px",
                              textAlign: "left",
                            }}
                            onClick={() => {
                              pdfPreview.show();
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: theme.palette.grey[700],
                                fontWeight: 400,
                                fontStyle: "italic",
                              }}
                            >
                              Signed copy of Declaration
                            </Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            <Typography
              sx={{
                fontSize: "14px",
                color: theme.palette.grey[600],
                fontWeight: 500,
                fontStyle: "italic",
                textAlign: "center",
              }}
            >
              Other related documents specific or non specific forming our
              agreement that are not mentioned in the declaration form or any
              other related documents.
            </Typography>
            <Stack
              sx={{
                border: employeeData.employmentDeclaration?.documents.length
                  ? `1px solid ${theme.palette.grey[400]}`
                  : "none",
                borderRadius: "10px",
                width: "100%",
                mt: 2,
                p: "0px 15px",
                paddingBottom: "10px",
                paddingTop: "10px",
              }}
            >
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    {/* Header Row */}
                    {/* <TableRow>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                          fontSize: "10px",
                          width: "45%",
                          fontWeight: 400,
                        }}
                      >
                        Linked Document Name
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                          width: "15%",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "10px",
                            color: theme.palette.grey[600],
                            fontWeight: 400,
                          }}
                        >
                          Revision Number
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                          width: "40%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "10px",
                            color: theme.palette.grey[600],
                            fontWeight: 400,
                          }}
                        >
                          Document Description
                        </Typography>
                      </TableCell>
                    </TableRow> */}

                    {/* Data Row with Full Border */}
                    {/* <TableRow>
                      <TableCell colSpan={3} sx={{ border: "none", padding: 0 }}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "45% 15% 40%",
                            border: `1.6px solid ${theme.palette.grey[400]}`,  // Apply border to wrap all items
                            borderRadius: "8px",  // Curved border
                            padding: "4px",
                            ":hover": {
                              backgroundColor: "#ECECEC"
                            },
                            cursor: "pointer",
                            color: " #535455", // Inner spacing
                          }}
                        onClick={() => {
                          pdfPreview.show();
                        }}
                        >

                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "#535455",
                              fontStyle: "italic",
                              paddingLeft: 1,
                              display: "flex"
                            }}
                          >
                            <SvgColor
                              src="/assets/icons/pdfIcon.svg"
                              style={{ marginRight: "10px" }}
                              color="red"
                            />Contact.pdf
                          </Typography>

                          <Stack
                            sx={{
                              border: `1.6px solid ${theme.palette.grey[400]}`,
                              paddingX: 2,
                              borderRadius: "4px",
                              textAlign: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "15px",
                                color: theme.palette.grey[700],
                                fontWeight: 400,
                                fontStyle: "italic",
                              }}
                            >
                              001
                            </Typography>
                          </Stack>
                          <Stack
                            sx={{
                              border: `1.6px solid ${theme.palette.grey[400]}`,
                              paddingX: 2,
                              borderRadius: "4px",
                              textAlign: "left",
                            }}
                          // onClick={() => {
                          //   pdfPreview.show();
                          // }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: theme.palette.grey[700],
                                fontWeight: 400,
                                fontStyle: "italic",
                              }}
                            >
                              A Copy of employee contact
                            </Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                    </TableRow> */}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer>
                {employeeData.employmentDeclaration?.documents.map(
                  (document: any, index: any) => {
                    return (
                      <Stack
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          padding: 0,
                          paddingBottom: 1,
                          // margin: 1,
                          gap: 1,
                          // border: `1.6px solid ${theme.palette.grey[400]}`,
                          justifyContent: "center",
                          borderRadius: 1,
                        }}
                      >
                        <Stack
                          sx={{
                            width: "100%",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        ></Stack>
                        <Stack
                        // sx={{
                        //   width: "100%",
                        //   border: `1px solid ${theme.palette.grey[400]}`,
                        //   padding: 1,
                        //   borderRadius: 1,
                        // }}
                        >
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              fontStyle: "italic",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <Stack
                              sx={{
                                border: `1px solid #A1A1A1`,
                                p: 1,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderRadius: "10px",
                                ":hover": {
                                  backgroundColor: "#ECECEC",
                                },
                                color: " #535455",
                                minWidth: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "90%",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setPdfUrl(document.fileUrl);
                                  pdfPreview.show();
                                }}
                              >
                                {document?.fileUrl &&
                                  (document?.fileUrl?.endsWith(".jpg") ||
                                  document?.fileUrl?.endsWith(".jpeg") ||
                                  document?.fileUrl?.endsWith(".webp") ? (
                                    <Icon
                                      icon="line-md:image-twotone"
                                      height={"25px"}
                                      width={"25px"}
                                      style={{
                                        marginRight: "10px",
                                        color: "blue",
                                      }}
                                    />
                                  ) : document?.fileUrl?.endsWith(".png") ? (
                                    <SvgColor
                                      src="/assets/icons/file-png.svg"
                                      style={{ marginRight: "10px" }}
                                      color="blue"
                                    />
                                  ) : document?.fileUrl?.endsWith(".pdf") ? (
                                    <SvgColor
                                      src="/assets/icons/pdfIcon.svg"
                                      style={{ marginRight: "10px" }}
                                      color="red"
                                    />
                                  ) : (
                                    <Icon
                                      icon="bxs:file"
                                      height={"25px"}
                                      width={"25px"}
                                      style={{
                                        marginRight: "10px",
                                        color: "gray",
                                      }}
                                    />
                                  ))}

                                <div>
                                  {document.fileName.length > 55
                                    ? document.fileName.substring(0, 55) + "..."
                                    : document.fileName}
                                </div>
                              </div>
                              {document?.fileName && edit && (
                                <SvgColor
                                  src="/assets/icons/remove.svg"
                                  height={"40px"}
                                  width={"30px"}
                                  onClick={() => handleDeleteDocument(document)}
                                  style={{
                                    marginLeft: "10px",
                                  }}
                                  color="535455"
                                />
                              )}
                            </Stack>
                          </Typography>
                        </Stack>
                      </Stack>
                    );
                  }
                )}
              </TableContainer>
              {edit && (
                <UploadDocument isDetailContract={true} employee={employee} />
              )}
            </Stack>
          </Stack>
        </Stack>
        {/* {addContractFilePopup.visible && (
          <UploadDocument  isDetailContract={addContractFilePopup.visible} employee={employee} />
        )} */}
        {pdfurl && pdfPreview.visible && (
          <FilePreviewDialog
            open
            onClose={() => {
              pdfPreview.hide();
            }}
            fileUrl={pdfurl && pdfurl}
            fileName="Contract.pdf"
            minHeight="90dvh"
          />
          // <SignedDeclarationContract
          //   employee={employee}
          //   open
          //   onClose={pdfPreview.hide}
          // />
        )}
      </Card>
    </>
  );
};

export { DetailOfContract };
