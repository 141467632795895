import { RootState } from "@app/redux/root-reducer";

export const selectAddKnowledgeBase = (store: RootState) =>
  store.knowledgeReducer.addKnowledgeBase;
export const selectAddNewCategoryState = (store: RootState) =>
  store.knowledgeReducer.addNewCategory;
export const selectCategoriesState = (store: RootState) =>
  store.knowledgeReducer.categories;
export const selectKnowledgeBaseState = (store: RootState) =>
  store.knowledgeReducer.knowledgeBase;
export const selectKnowledgeBaseCategoryState = (store: RootState) =>
  store.knowledgeReducer.knowledgeBaseCategory;
export const selectDeleteCategoryState = (store: RootState) =>
  store.knowledgeReducer.deleteCategory;
export const selectDeleteKnowledgeBase= (store: RootState) =>
  store.knowledgeReducer.deleteKnowledgeBase;
