import * as Yup from "yup";
import { Strings } from "../../constants/strings";

export const addEmergencyContactSchema = Yup.object()
  .shape({
    fullName: Yup.string().required(
      Strings.validation.fieldRequired("Full Name")
    ),
    relationship: Yup.string().required(
      Strings.validation.fieldRequired("Relationship with your contact")
    ),
    mobileNumber: Yup.string().required(
      Strings.validation.fieldRequired("Mobile Number")
    ),
    address: Yup.string().required(Strings.validation.fieldRequired("Address")),
    city: Yup.string().required(Strings.validation.fieldRequired("City")),
    postCode: Yup.string().required(
      Strings.validation.fieldRequired("Post Code")
    ),
  })
  .required();

export const addBookInductionSchema = Yup.object()
  .shape({
    project: Yup.mixed().required(
      Strings.validation.selectRequired("Project")
    ),
    inductionDate: Yup.string().required(
      Strings.validation.selectRequired("Induction Date")
    ),
  })
  .required();

export const uploadAdditionalInformationSchema = Yup.object().shape({
  // documentName: Yup.string().required(
  //   Strings.validation.inputRequired("Description")
  // ),
  file: Yup.string().required(
    Strings.validation.uploadRequired("Document File")
  ),
}).required()
