import { useState } from "react";
import { useDispatch } from "react-redux";
import { Icon } from "@iconify/react";
import dayjs from "dayjs";
import { Visibility } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";

import { SvgColor } from "@app/components/svg-color";
import { sourceUrl } from "@app/constants/source_url";
import FilePreviewDialog from "@app/components/file-preview/FilePreview";
import { useDialog } from "@app/hooks/use-dialog";
import { useRequestState } from "@app/hooks/use-request-state";
import {
  deleteCscsAction,
  deleteCscsSingleImageAction,
  deleteQualificationAction,
  deleteQualificationActionSingle,
} from "./slice";
import { selectDeleteCscsRecord, selectDeleteQualification } from "./selector";
import UploadDocument from "./UploadDocument";

type Props = {
  employeeCertification: any;
  isCscs: boolean;
  isPreview: boolean;
  employee: any;
};
export const DocumentCard = ({
  employeeCertification,
  isCscs,
  isPreview = false,
  employee,
}: Props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  useRequestState({
    stateSelector: selectDeleteCscsRecord,
    successMessageShown: true,
    errorShown: true,
  });

  useRequestState({
    stateSelector: selectDeleteQualification,
    successMessageShown: true,
    errorShown: true,
  });

  const filePreviewDialog = useDialog();
  const [selectedPreviewFile, setSelectedPreviewFile] = useState<any>();
  return (
    <Stack
      sx={{
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
      }}
    >
      <Box
        sx={{
          border: "1px solid #98A5B5",
          padding: "8px",
          width: "100%",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
        }}
      >
        {/* Red Cross Icon - Added at top right corner */}
        {isPreview && (
          <Box
            sx={{
              position: "absolute",
              top: "-12px",
              right: "-12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              backgroundColor: "white",
              width: "24px",
              height: "24px",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "bold",
              zIndex: 1,
            }}
          >
            <Icon
              icon="mdi:close-circle"
              height="30px"
              width="30px"
              style={{ cursor: "pointer", color: "red" }}
              onClick={() => {
                isCscs
                  ? dispatch(
                      deleteCscsAction({ documentId: employeeCertification.id })
                    )
                  : dispatch(
                      deleteQualificationAction({
                        documentId: employeeCertification.id,
                      })
                    );
              }}
            />
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            width: "39%",
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
            Certification :{" "}
          </Typography>
          <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
            Registration Number :{" "}
          </Typography>
          <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
            Expiry Date :{" "}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            width: "100%",
          }}
        >
          <Typography
            color="primary"
            sx={{ fontSize: "14px", fontWeight: "500" }}
          >
            {employeeCertification.certificationName}
          </Typography>
          <Typography
            color="primary"
            sx={{ fontSize: "16px", fontWeight: "500" }}
          >
            {employeeCertification.registrationNumber ?? "N/A"}
          </Typography>
          <Typography
            color="primary"
            sx={{ fontSize: "16px", fontWeight: "500" }}
          >
            {employeeCertification.expiryDate
              ? dayjs(employeeCertification.expiryDate, "YYYY-MM-DD").format(
                  "DD MMMM YYYY"
                )
              : "N/A"}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "89%",
          }}
        >
          <Typography
            color="primary"
            sx={{ fontSize: "16px", fontWeight: "300", fontStyle: "italic" }}
          >
            Uploaded Document
          </Typography>

          {employeeCertification?.documents?.map((document: any) => (
            document?.fileUrl &&(
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                fontStyle: "italic",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <Stack
                sx={{
                  border: `1px solid #A1A1A1`,
                  p: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "10px",
                  ":hover": {
                    backgroundColor: "#ECECEC",
                  },
                  color: " #535455",
                  minWidth: "100%",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    setSelectedPreviewFile({
                      name: document?.fileName,
                      url: document?.fileUrl,
                    });
                    filePreviewDialog.show();
                  }}
                >
                  {document?.fileUrl &&
                    (document?.fileUrl?.endsWith(".jpg") ||
                    document?.fileUrl?.endsWith(".jpeg") ||
                    document?.fileUrl?.endsWith(".webp") ? (
                      <Icon
                        icon="line-md:image-twotone"
                        height={"25px"}
                        width={"25px"}
                        style={{ marginRight: "10px", color: "blue" }}
                      />
                    ) : document?.fileUrl?.endsWith(".png") ? (
                      <SvgColor
                        src="/assets/icons/file-png.svg"
                        style={{ marginRight: "10px" }}
                        color="blue"
                      />
                    ) : document?.fileUrl?.endsWith(".pdf") ? (
                      <SvgColor
                        src="/assets/icons/pdfIcon.svg"
                        style={{ marginRight: "10px" }}
                        color="red"
                      />
                    ) : (
                      <Icon
                        icon="bxs:file"
                        height={"25px"}
                        width={"25px"}
                        style={{ marginRight: "10px", color: "gray" }}
                      />
                    ))}

                  <div>
                    {document?.fileName?.length > 50
                      ? document?.fileName?.substring(0, 50) + "..."
                      : document?.fileName}
                  </div>
                </div>
                {document?.fileUrl && isPreview && (
                  <SvgColor
                    onClick={() => {
                      isCscs
                        ? dispatch(
                            deleteCscsSingleImageAction({
                              documentId: employeeCertification?.id,
                              fileUrl: document?.fileUrl,
                            })
                          )
                        : dispatch(
                            deleteQualificationActionSingle({
                              documentId: employeeCertification?.id,
                              fileUrl: document?.fileUrl,
                            })
                          );
                    }}
                    src="/assets/icons/remove.svg"
                    height={"40px"}
                    width={"30px"}
                    style={{
                      marginLeft: "10px",
                    }}
                    color="535455"
                  />
                )}
              </Stack>
            </Typography>)
          ))}

          {isPreview && <UploadDocument isCscs={isCscs} employee={employee} employeeCertification={employeeCertification} />}
        </Box>
      </Box>

      {filePreviewDialog.visible && (
        <FilePreviewDialog
          open
          onClose={() => {
            filePreviewDialog.hide();
          }}
          fileUrl={selectedPreviewFile && selectedPreviewFile.url}
          fileName={selectedPreviewFile && selectedPreviewFile.name}
        />
      )}
    </Stack>
  );
};
