import { Header } from '@app/components'
import { Stack } from '@mui/material'
import { Outlet } from 'react-router-dom'

export const AuthLayout = () => {
    return (
        <>
            <Header isAuthHeader={true} />
            <Stack component={"main"} sx={{marginTop:"64px",
                '&::-webkit-scrollbar': {
                        width: '10px', 
                        padding:"1px"
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#171F22',
                        borderRadius: '4px',
                        width:"8px",
                        height:"4px"
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555', 
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#fff', 
                    },
                    overflowY:"scroll",
            }}>
                <Outlet />
            </Stack>
        </>
    )
}