import { Header, Sidebar } from "@app/components";
import SmallSidebar from "@app/components/sidebar/SmallSidebar";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";

export const DashboardLayout = () => {
  const [isSmallSideBar, setIsSmallSidebar] = useState<boolean>(false);

  const handleSidebarToggle = () => {
    setIsSmallSidebar((prev) => !prev);
  };

  return (
    <>
      <Header isSmallSideBar={isSmallSideBar} />
      {isSmallSideBar ? (
        <SmallSidebar onHideSideBarButtonClick={handleSidebarToggle} />
      ) : (
        <Sidebar onHideSideBarButtonClick={handleSidebarToggle} />
      )}
      <Stack
        component={"main"}
        sx={{
          marginLeft: isSmallSideBar ? "50px" : "260px",
          marginTop: "60px",
          height: "100vh",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: "10px",
            padding: "1px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#171F22",
            borderRadius: "4px",
            width: "8px",
            height: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#fff",
          },
        }}
      >
        <Outlet />
      </Stack>
    </>
  );
};
