import { Input, Label } from "@app/components";
import { SvgColor } from "@app/components/svg-color";
import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ICertificationFormUpload } from "./types";
import { useDispatch, useSelector } from "react-redux";
import {
  addEmployeeCscsDocumentAction,
  addEmployeeQualificationDocumentAction,
} from "./slice";
import { RHFDatePicker } from "@app/components/hook-form";
import { Strings } from "@app/constants/strings";
import { LoadingButton } from "@mui/lab";
import { useRequestState } from "@app/hooks/use-request-state";
import {
  selectAddEmployeeCscs,
  selectAddEmployeeQualification,
  selectEmplooyeeDocument,
} from "./selector";
import { selectQualification } from "../qualification/selector";
import { getQualificationAction } from "../qualification/slice";

type Props = {
  isCscsUploadDialog?: boolean;
  isModalOpen: boolean;
  onModalCloseClick: () => void;
  employee: any;
  isFromTrainingMatrix: boolean;
};

const certificationOptions = {
  certificationAndQualificationSelects: [
    "SMSTS - Site Management Safety Training Scheme",
    "First Aid",
    "Fire Marshall Certificate",
    "PASMA Certificate",
    "IPAF Certificate",
    "Harness Training Certificate",
    "Supervisor",
  ],
  cscs: [
    "CSCS - RED - TRAINEE",
    "CSCS - RED - GRADUATE",
    "CSCS - RED - EXPERIENCED TECHNICAL SUPERVISOR OR MANAGER",
    "CSCS - RED - EXPERIENCED WORKER (TEMPORARY)",
    "CSCS - GREEN - CONSTRUCTION SITE OPERATIVE",
    "CSCS - BLUE - SKILLED WORKER",
  ],
};

export const AddAdditionalDocumentDialog = ({
  isCscsUploadDialog,
  isModalOpen,
  onModalCloseClick,
  employee,
  isFromTrainingMatrix = false,
}: Props) => {
  const dispatch = useDispatch();
  const qualifications = useSelector(selectQualification);
  const [pageValue, setPageValue] = useState(1);
  const [pageLimitValue, setPageLimitValue] = useState<number | string>(10);

  useEffect(() => {
    dispatch(
      getQualificationAction({
        page: pageValue,
        limit: pageLimitValue,
      })
    );
  }, [dispatch, pageValue, pageLimitValue]);
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    setValue,
    trigger,
    reset,
    watch,
  } = useForm<ICertificationFormUpload>();

  console.log(qualifications, "qualifications");

  useEffect(() => {
    if (!isModalOpen) {
      reset();
    }
  }, [isModalOpen]);

  const handleFormAfterApiStatus = () => {
    reset();
    onModalCloseClick();
  };

  const { loading } = useRequestState({
    stateSelector: isCscsUploadDialog
      ? selectAddEmployeeCscs
      : selectAddEmployeeQualification,
    successMessageShown: true,
    errorShown: true,
  });

  const handleFormSubmit = (data: ICertificationFormUpload) => {
    isCscsUploadDialog
      ? dispatch(
          addEmployeeCscsDocumentAction({
            payload: {
              employeeId: employee.id,
              ...data,
              expiryDate: data.expiryDate
                ? dayjs(data.expiryDate, "DD/MM/YYYY").toISOString()
                : null,
            },
            callback: handleFormAfterApiStatus,
          })
        )
      : dispatch(
          addEmployeeQualificationDocumentAction({
            payload: {
              employeeId: employee?.id,
              ...data,
              expiryDate: data.expiryDate
                ? dayjs(data.expiryDate, "DD/MM/YYYY").toISOString()
                : null,
            },
            callback: handleFormAfterApiStatus,
          })
        );
  };

  return (
    <Dialog
      open={isModalOpen}
      component={"form"}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Stack sx={{ width: "100%" }}>
        <Typography
          sx={{ fontSize: "24px", fontWeight: "500", padding: "5px" }}
        >
          {isCscsUploadDialog
            ? "Mandatory Qualifications Schemes ( MQS )"
            : "Add Additional Qualification"}
        </Typography>
        <Divider sx={{ color: "#F1F4F7" }} />
      </Stack>
      <DialogContent
        sx={{
          width: "600px",
          display: "flex",
          justifyContent: "center",
          padding: "5px",
        }}
      >
        <Card
          sx={{
            width: "500px",
            borderRadius: "8px",
            height: "100%",
          }}
        >
          <CardContent
            sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Box
              sx={{
                minWidth: "200px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Label>Select Certification Scheme</Label>
              <Controller
                name={"certificationName"}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    name="certificationName"
                    error={!!errors.certificationName?.message}
                  >
                    {qualifications && qualifications.length > 0 ? (
                      isCscsUploadDialog ? (
                        qualifications
                          .filter((item: any) => item.isMandatory)
                          .map((item: any) => (
                            <MenuItem key={item.id} value={item.name}>
                              {item.name}
                            </MenuItem>
                          ))
                      ) : (
                        qualifications
                          .filter((item: any) => !item.isMandatory)
                          .map((item: any) => (
                            <MenuItem key={item.id} value={item.name}>
                              {item.name}
                            </MenuItem>
                          ))
                      )
                    ) : (
                      <MenuItem disabled>No qualifications available</MenuItem>
                    )}
                  </Select>
                )}
              />
            </Box>
            <Box>
              <Label>Registration Number</Label>
              <Input
                name="registrationNumber"
                control={control}
                placeHolder={Strings.field.inputPlaceholder(
                  "registration number"
                )}
                error={errors?.registrationNumber?.message}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Label>Expiry Date</Label>
              <RHFDatePicker
                name="expiryDate"
                control={control}
                setValue={setValue}
                trigger={trigger}
                label={""}
              />
            </Box>
            {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Label>Upload Document</Label>
              <Controller
                name="front"
                control={control}
                defaultValue={null}
                rules={{
                  validate: (file) => {
                    if (!file) {
                      return "File is required!";
                    }
                    const validTypes = [
                      "image/jpeg",
                      "image/png",
                      "application/pdf",
                      "application/msword",
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    ];
                    if (!validTypes.includes(file.type)) {
                      return "Only image files (JPG, PNG), PDF, or DOC files are allowed!";
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <>
                    <input
                      type="file"
                      accept="image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      style={{ display: "none" }}
                      id="uploadFrontFile"
                      onChange={(e: any) => {
                        if (e.target.files && e.target.files[0]) {
                          field.onChange(e.target.files[0]);
                        }
                        e.target.value = "";
                      }}
                    />
                    {!field.value ? (
                      <Stack
                        component="label"
                        htmlFor="uploadFrontFile"
                        sx={{
                          width: "100%",
                          height: "120px",
                          border: "1px solid #e5e7eb",
                          borderRadius: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          cursor: "pointer",
                        }}
                      >
                        <SvgColor
                          src={`/assets/icons/Upload.svg`}
                          sx={{ width: 80, height: 80, color: "#98A5B5" }}
                        />
                        <Typography variant="body2" color="textSecondary">
                          Click to upload an image/pdf
                        </Typography>
                        {errors?.front?.message && (
                          <Typography variant="body2" color="error">
                            {errors?.front?.message && ""}
                          </Typography>
                        )}
                      </Stack>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          position: "relative",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          component="img"
                          src={
                            field.value.type.includes("image")
                              ? URL.createObjectURL(field.value)
                              : "/pdfIcon.png"
                          }
                          sx={{
                            width: "100%",
                            height: "120px",
                            borderRadius: "8px",
                            display: "flex",
                            objectFit: "contain",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            cursor: "pointer",
                          }}
                        />
                        {!field.value.type.includes("image") && (
                          <Typography
                            variant="body2"
                            sx={{ cursor: "pointer" }}
                          >
                            {field.value.name}
                          </Typography>
                        )}
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: "5px",
                            right: "14px",
                          }}
                          onClick={() => {
                            field.onChange(null);
                          }}
                        >
                          <Icon
                            icon="fontisto:close"
                            width="24"
                            height="24"
                            color="#98A5B5"
                          />
                        </IconButton>
                      </Box>
                    )}
                  </>
                )}
              />
            </Box> */}
          </CardContent>
        </Card>
      </DialogContent>
      <Divider sx={{ color: "#F1F4F7" }} />
      <Stack
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          paddingY: "8px",
          paddingX: "12px",
        }}
      >
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            reset();
            onModalCloseClick();
          }}
        >
          Cancel
        </Button>
        <LoadingButton loading={loading} variant="contained" type="submit">
          {isFromTrainingMatrix ? "Save Qualification" : "Add Qualification"}
        </LoadingButton>
      </Stack>
    </Dialog>
  );
};
