import { Box, Typography, BoxProps } from '@mui/material';
import { Icon } from '@iconify/react';

interface Props extends BoxProps {
    status: "active" | "Inactive" | "Awaiting Review" | "Retired" | "On Leave" | "Suspended" | "Terminated" | "Booked For Induction" | "Not Inducted" | "Inducted";
    title: string;
    isEmployeeInductionStatus?: boolean;
}

const Status = ({ status, title, sx,isEmployeeInductionStatus, ...rest }: Props) => {
    const getBackgroundColor = () => {
        switch (status) {
            case "active":
                return "#3BD365"; // Green
            case "Awaiting Review":
                return "#BEC1C3"; // Grey
            case "Retired":
                return "#BEC1C3"; // Grey
            case "On Leave":
                return "#FFC201"; // Yellow
            case "Suspended":
                return "#F35454"; // Light Red
            case "Terminated":
                return "#F35454"; // Darker Red
            case "Inactive":
                return "#BEC1C3";// Grey
            case "Not Inducted":
                return "#FFE48E";
            case "Inducted":
                return "#E0FCDC"; // Green
            default:
                return "#BEC1C3"; // Default fallback color
        }
    };

   console.log(isEmployeeInductionStatus,status,"123");
   

    return (
        <Box
            sx={{
                backgroundColor: getBackgroundColor(),
                padding: "4px ",
                borderRadius: "18px",
                width: "max-content",
                display: "flex",
                alignItems: "center",
                gap: "4px",
                ...sx,
            }}
            {...rest}
        >
            {
                !isEmployeeInductionStatus && status == "Not Inducted" ? <Icon icon="si:warning-fill" width="24" height="24" color='#fcb438' style={{ background: "#fff", borderRadius: "50%" }} /> :
                !isEmployeeInductionStatus && status == "Inducted" && <Icon icon="icon-park-solid:check-one" width="24" height="24" color='#3BD365' />
            }
            <Typography
                variant="body2"
                sx={{
                    fontWeight: "500",
                    color: "#000000",
                }}
            >
                {title}
            </Typography>
        </Box>
    );
};

export default Status;
