import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Card,
  CardContent,
  Divider,
  IconButton,
} from "@mui/material";
import { SvgColor } from "@app/components/svg-color";
import { sourceUrl } from "@app/constants/source_url";
import { useRequestState } from "@app/hooks/use-request-state";
import { PATH_DASHBOARD } from "@app/routes/paths";
import { selectCompanyId } from "@app/modules/company/selectors";
import { deleteKnowledgeBase } from "../slice";
import { selectDeleteKnowledgeBase } from "../selectors";
import { useDialog } from "@app/hooks/use-dialog";
import { ArticlePreview } from "../articlePreview";
import { Visibility } from "@mui/icons-material";
import { useState } from "react";
import { useDeleteAlert } from "@app/hooks/useAlertDialogue";

interface Props {
  data: any;
}

const KnowledgeTable = ({ data }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const previewDialog = useDialog();
  const companyId = useSelector(selectCompanyId);
  const [knowledgeData, setKnowledgeData] = useState();
  const { showDeleteAlert } = useDeleteAlert();

  useRequestState({
    stateSelector: selectDeleteKnowledgeBase,
    successMessageShown: true,
    errorShown: true,
  });

  return (
    <Card sx={{ padding: 0, minHeight: "400px", maxHeight: "80vh" }}>
      <CardContent sx={{ padding: 0, height: "100%" }}>
        <Typography variant="h6" sx={{ padding: "5px 25px" }} gutterBottom>
          Knowledge Base
        </Typography>
        <Divider sx={{ borderColor: "#F1F4F7" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            p: 2,
            // Add padding bottom for space
            overflowY: "auto",
            maxHeight: "calc(80vh - 50px)", // Adjust height accounting for header
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <TableContainer
            sx={{
              flexGrow: 1,
              mb: 2, // Add margin bottom
            }}
          >
            <Table sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  {[
                    "#",
                    "Article Heading",
                    "Article Category",
                    "Revision Number",
                    "Revision Date",
                    "To",
                    "Action",
                  ].map((title, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        fontSize: "12.39px",
                        fontWeight: 500,
                        color: "#99A5B5",
                        width: `${100 / 7}%`,
                        textAlign: "center", // Center the text
                      }}
                    >
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((items: any) => (
                  <TableRow key={items.id}>
                    <TableCell sx={{ textAlign: "center", width: "5%" }}>
                      {items.id}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", width: "20%" }}>
                      {items.heading}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", width: "15%" }}>
                      {items?.category?.name}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", width: "15%" }}>
                      {items?.revisionNumber}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", width: "15%" }}>
                      {items?.date}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", width: "10%" }}>
                      All
                    </TableCell>
                    {/* Fixing Action Column Alignment */}
                    <TableCell sx={{ width: "20%", textAlign: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: 1,
                        }}
                      >
                        <IconButton
                          onClick={() =>
                            navigate(PATH_DASHBOARD.addNewArticle, {
                              state: { items },
                            })
                          }
                        >
                          <SvgColor
                            src={sourceUrl.icons.edit}
                            sx={{ width: 20, height: 20 }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setKnowledgeData(items);
                            previewDialog.show();
                          }}
                        >
                          <Visibility />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            showDeleteAlert({
                              description: `Are you sure you want to delete ${items?.heading}? This action cannot be undone.`,
                              onConfirm: () =>
                                dispatch(
                                  deleteKnowledgeBase({
                                    id: items.id,
                                    companyId,
                                  })
                                ),
                            })
                          }
                        >
                          <SvgColor
                            src={sourceUrl.icons.delete}
                            sx={{ width: 20, height: 20 }}
                          />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {previewDialog.visible && (
          <ArticlePreview
            open
            onClose={previewDialog.hide}
            knowledgeData={knowledgeData}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default KnowledgeTable;
