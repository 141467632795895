import { forwardRef, useImperativeHandle, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  Card,
  Divider,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";

import { useDialog } from "@app/hooks/use-dialog";
import { Input } from "@app/components";
import { FormProvider } from "@app/components/hook-form";
import { AddAdditionalDocumentDialog } from "../add_employee/AddAdditionalDocumentDialog";
import FilePreviewDialog from "@app/components/file-preview/FilePreview";
import { DocumentCard } from "../add_employee/DocumentCard";
import { updateEmployeeAction } from "../add_employee/slice";
import { useDispatch } from "react-redux";

const TrainingMatrix = forwardRef(({ edit = false, employee }: any, ref) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const addAdditionalQualification = useDialog();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      employeeRole: employee.employeeRole || "",
      yearExperience: employee.tradeWorkTime || "",
      qualificationAndCertification:
        employee.qualificationAndCertification || [],
      certification: employee.employeeCSCS?.certificationName || "",
      registration: employee.employeeCSCS?.registrationNumber || "",
      expiryDate: employee.employeeCSCS?.expiryDate || "",
    },
  });

  const onSubmit = (data: any) => {
    dispatch(
      updateEmployeeAction({
        employeeId: employee.id,
        employeeRole: data?.employeeRole,
        tradeWorkTime: data?.yearExperience,
        callback: () => {},
      })
    );
  };

  useImperativeHandle(ref, () => ({
    submitForm: handleSubmit(onSubmit),
  }));

  const trainingMatrix = [
    {
      heading: "Construction Skills Certification Scheme ( CSCS )",
      data: [
        {
          certification: "CSCS - Blue Card",
          registration: "N/A",
          expiryDate: "12 January 2026",
          proofIdFront: "Proof Front.pdf",
          proofIdBack: "Proof Back.pdf",
        },
      ],
    },
    {
      heading: "Other Qualifications",
      data: [
        {
          certification: "SMSTS - Site Management Safety Training Scheme",
          registration: "N/A",
          expiryDate: "12 January 2026",
          proofIdFront: "Proof Front.pdf",
          proofIdBack: null,
        },
        {
          certification: "First Aid",
          registration: "12345678",
          expiryDate: "12 January 2026",
          proofIdFront: "Proof Front.pdf",
          proofIdBack: null,
        },
        {
          certification: "Fire Marshall Certificate",
          registration: "12345678",
          expiryDate: "12 January 2026",
          proofIdFront: "Proof Front.pdf",
          proofIdBack: null,
        },
        {
          certification: "PASMA Certificate",
          registration: "12345678",
          expiryDate: "12 January 2026",
          proofIdFront: "Proof Front.pdf",
          proofIdBack: null,
        },
        {
          certification: "IPAF Certificate",
          registration: "12345678",
          expiryDate: "12 January 2026",
          proofIdFront: "Proof Front.pdf",
          proofIdBack: null,
        },
        {
          certification: "Harness Training Certificate",
          registration: "12345678",
          expiryDate: "12 January 2026",
          proofIdFront: "Proof Front.pdf",
          proofIdBack: null,
        },
      ],
    },
  ];

  const employeeRoles = [
    { role: "BRICK_LAYER", name: "Brick Layer" },
    { role: "LABOURER", name: "Labourer" },
    { role: "INSULATION_FIXER", name: "Insulation Fixer" },
    { role: "METAL_FIXER", name: "Metal Fixer" },
    { role: "SCAFFOLDER", name: "Scaffolder" },
    { role: "MANAGER", name: "Manager" },
    { role: "SUPERVISOR", name: "Supervisor" },
  ];

  const certificationOptions = {
    certificationAndQualificationSelects: [
      "SMSTS - Site Management Safety Training Scheme",
      "First Aid",
      "Fire Marshall Certificate",
      "PASMA Certificate",
      "IPAF Certificate",
      "Harness Training Certificate",
      "Supervisor",
    ],
    cscs: [
      "CSCS - RED - TRAINEE",
      "CSCS - RED - GRADUATE",
      "CSCS - RED - EXPERIENCED TECHNICAL SUPERVISOR OR MANAGER",
      "CSCS - RED - EXPERIENCED WORKER (TEMPORARY)",
      "CSCS - GREEN - CONSTRUCTION SITE OPERATIVE",
      "CSCS - BLUE - SKILLED WORKER",
    ],
  };

  const [selectedPreviewFile, setSelectedPreviewFile] = useState<any>();
  const [isCSCSDocumentUploadDialog, setIsCSCSDocumentUploadDialog] =
    useState(false);

  const filePreviewDialog = useDialog();

  return (
    <>
      <Card
        sx={{
          flex: 1,
          padding: 2,
          gap: 1.5,
        }}
        component={"form"}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack>
          <Stack sx={{ flex: 1, width: "45%" }}>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <Stack sx={{ pl: 4 }}>
                      <Typography
                        sx={{
                          color: theme.palette.grey[600],
                          fontWeight: 600,
                          fontStyle: "italic",
                          fontSize: "16px",
                        }}
                      >
                        Trade Information
                      </Typography>
                    </Stack>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        fontWeight: 500,
                        fontSize: "16px",
                      }}
                    >
                      Employee Role
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        color: theme.palette.primary.main,
                      }}
                    >
                      {edit ? (
                        <Controller
                          name={"employeeRole"}
                          control={control}
                          render={({ field }) => (
                            <Select
                              sx={{ width: "100%" }}
                              {...field}
                              displayEmpty
                              renderValue={(selected: any) => {
                                if (!selected) {
                                  return (
                                    <Typography
                                      sx={{
                                        color: theme.palette.grey[500],
                                      }}
                                    >
                                      {"Employee Role"}
                                    </Typography>
                                  );
                                }
                                return selected;
                              }}
                              name={field.name}
                              size="small"
                            >
                              {employeeRoles?.map((item: any) => (
                                <MenuItem value={item?.role}>
                                  {item?.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          color="primary"
                          sx={{ fontWeight: 500, fontSize: "16px" }}
                        >
                          {employee.employeeRole
                            ? employee.employeeRole
                            : "N/A"}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        fontWeight: 500,
                        fontSize: "16px",
                      }}
                    >
                      Years Experience in Field at date of employment:
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        color: theme.palette.primary.main,
                      }}
                    >
                      {edit ? (
                        <Input
                          name="yearExperience"
                          control={control}
                          size={"small"}
                          placeHolder={"Year Experience"}
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          color="primary"
                          sx={{ fontWeight: 500, fontSize: "16px" }}
                        >
                          {employee.tradeWorkTime
                            ? employee.tradeWorkTime
                            : "N/A"}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
          {employee?.employeeCSCS ? (
            <>
              <Stack>
                <Typography
                  sx={{
                    color: theme.palette.grey[600],
                    fontWeight: 600,
                    fontStyle: "italic",
                    fontSize: "16px",
                  }}
                >
                  Mandatory Qualifications Schemes ( MQS )
                </Typography>
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 2,
                  px: 6,
                }}
              >
                <DocumentCard
                  employeeCertification={employee?.employeeCSCS}
                  isCscs={true}
                  isPreview={edit}
                  employee={employee}
                />
              </Stack>
            </>
          ) : (
            <Stack sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <Stack>
                <Typography
                  sx={{
                    color: theme.palette.grey[600],
                    fontWeight: 600,
                    fontStyle: "italic",
                    fontSize: "16px",
                  }}
                >
                  Mandatory Qualifications Schemes ( MQS )
                </Typography>
              </Stack>
              <Button
                variant="contained"
                onClick={() => {
                  setIsCSCSDocumentUploadDialog(true);
                  addAdditionalQualification.show();
                }}
              >
                Add MQS
              </Button>
            </Stack>
          )}
          <Divider sx={{ color: "#F1F4F7", marginTop: "8px" }} />
          {employee?.qualificationAndCertification ? (
            <Stack
              sx={{
                display: "flex",
                flexDirection:
                  employee?.qualificationAndCertification?.length > 0
                    ? "column"
                    : "row",
                gap: 2,
                mt: 2,
              }}
            >
              <Stack sx={{ marginTop: "8px" }}>
                <Typography
                  sx={{
                    color: theme.palette.grey[600],
                    fontWeight: 600,
                    fontStyle: "italic",
                  }}
                >
                  Other Qualifications
                </Typography>
              </Stack>
              {employee?.qualificationAndCertification &&
              employee?.qualificationAndCertification?.length > 0
                ? employee.qualificationAndCertification.map(
                    (qualification: any, index: number) => (
                      <Stack sx={{ px: 6 }}>
                        <DocumentCard
                          employeeCertification={qualification}
                          isCscs={false}
                          isPreview={edit}
                          employee={employee}
                        />
                      </Stack>
                    )
                  )
                : null}
              {edit &&
                (employee.qualificationAndCertification.length > 0 ? (
                  <Box
                    sx={{
                      cursor: "pointer",
                      border: "1px solid #98A5B5",
                      marginBlockEnd: "10px",
                      borderRadius: "8px",
                      display: "flex",
                      mx: 6,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      icon="ei:plus"
                      width="50"
                      height="50"
                      style={{ color: "#98A5B5" }}
                      onClick={() => {
                        setIsCSCSDocumentUploadDialog(false);
                        addAdditionalQualification.show();
                      }}
                    />
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setIsCSCSDocumentUploadDialog(false);
                      addAdditionalQualification.show();
                    }}
                  >
                    Add Additional Qualification
                  </Button>
                ))}
            </Stack>
          ) : null}
        </Stack>
        {addAdditionalQualification.visible && (
          <AddAdditionalDocumentDialog
            isModalOpen={true}
            isCscsUploadDialog={isCSCSDocumentUploadDialog}
            onModalCloseClick={() => {
              addAdditionalQualification.hide();
            }}
            employee={employee}
            isFromTrainingMatrix={true}
          />
        )}
        {filePreviewDialog.visible && (
          <FilePreviewDialog
            open
            onClose={() => {
              filePreviewDialog.hide();
            }}
            fileUrl={selectedPreviewFile && selectedPreviewFile.url}
            fileName={selectedPreviewFile && selectedPreviewFile.name}
          />
        )}
      </Card>
    </>
  );
});

export { TrainingMatrix };
