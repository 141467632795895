import { RootState } from "@app/redux/root-reducer";


export const selectDashboardData = (state: RootState) => 
  state.adminReducer.dashboardData.data;

export const selectSystemUsersData = (state: RootState) =>
  state.adminReducer.systemUsersData.data;

export const selectSystemPermissionsData = (state: RootState) =>
  state.adminReducer.systemPermissionsData.data;


