import { request } from "@app/services";
import { all, call, put, takeLatest } from "redux-saga/effects";
import * as endpoints from "./endpoints";
import {
  addSubscription,
  addSubscriptionFailed,
  addSubscriptionSuccess,
  getSubscriptionDetail,
  getSubscriptionDetailFailed,
  getSubscriptionDetailSuccess,
} from "./slice";

function* getSubscriptionSaga(
  action: ReturnType<typeof getSubscriptionDetail>
): any {
  try {
    const response = yield call(
      request.get,
      `${endpoints.getSubscriptionDetail}`
    );
    yield put(getSubscriptionDetailSuccess(response));
  } catch (error: any) {
    yield put(getSubscriptionDetailFailed(error));
  }
}
function* addSubscriptionSaga(action: ReturnType<typeof addSubscription>): any {
  try {
    const { id, userId } = action.payload;
    const response = yield call(request.post, `${endpoints.addSubscription}`, {
      subscriptionId: id,
      userId,
    });

    yield put(addSubscriptionSuccess(response));
  } catch (error: any) {
    yield put(addSubscriptionFailed(error));
  }
}

function* subscriptionSaga() {
  yield all([takeLatest(getSubscriptionDetail, getSubscriptionSaga)]);
  yield all([takeLatest(addSubscription, addSubscriptionSaga)]);
}

export { subscriptionSaga };
