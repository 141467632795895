import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IRequestFailed } from '@app/types';
import { IAuthState, IRegisterUserSchema, type ILoginSchema, } from './types';
import { RequestStatus } from '@app/constants/request';

// ------------------------------------------------------

const initialState: IAuthState = {
  login: {},
  register: {}
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<ILoginSchema>) {
      state.login.status = RequestStatus.RUNNING;
    },
    loginSuccess(state, action) {
      state.login.status = RequestStatus.SUCCESS;
    },
    loginFailed(state, action: PayloadAction<IRequestFailed>) {
      state.login.status = RequestStatus.ERROR;
      state.login.error = action.payload;
    },
    register(state, action: PayloadAction<IRegisterUserSchema>) {
      state.register.status = RequestStatus.RUNNING;
    },
    registerSuccess(state, action) {
      state.register.status = RequestStatus.SUCCESS
      state.register.data=action.payload
    },
    registerFailed(state,action:PayloadAction<IRequestFailed>){
      state.register.status = RequestStatus.ERROR;
      state.register.error = action.payload;
    },
    logout() {
    },
  },
});

export const authReducer = slice.reducer;

export const {
  login,
  loginSuccess,
  loginFailed,
  logout,
  register,
  registerFailed,
  registerSuccess
} = slice.actions;
