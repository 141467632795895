import { PATH_DASHBOARD } from "@app/routes/paths";
import { SvgColor } from "../svg-color";
import { NavListItem } from "./types";
import { Icon } from "@iconify/react";

export const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/sidebar/${name}.svg`}
    sx={{ width: 18, height: 18, color: "#98A5B5" }}
  />
);
export const ICONS = {
  dashboard: icon("dashboard"),
  reports: icon("reports"),
  management: icon("management"),
  attendance: icon("attendance"),
  employees: icon("employees"),
  expenses: icon("expenses"),
  knowledge_base: icon("knowledge_base"),
  messages: icon("messages"),
  payroll: icon("payroll"),
  recruitment: icon("recruitment"),
  tasks: icon("tasks"),
  tickets: icon("tickets"),
  admin: icon("admin"),
  project: icon("project"),
  speed: icon("speed"),
};
export const items: NavListItem[] = [
  {
    id: "1",
    title: "All Projects",
    hasChild: false,
  },
  {
    id: "3",
    title: "Reports",
    hasChild: true,
    nestedChild: [
      { id: "3-1", title: "Sub-Report 1" },
      { id: "3-2", title: "Sub-Report 2" },
    ],
    icon: ICONS.reports,
  },
  {
    id: "4",
    title: "Management",
    hasChild: true,
    nestedChild: [
      { id: "4-1", title: "Sub-Management 1" },
      { id: "4-2", title: "Sub-Management 2" },
    ],
    icon: ICONS.management,
  },
  {
    id: "5",
    title: "Employees",
    hasChild: true,
    nestedChild: [
      { id: "5-1", title: "Employee List", path: PATH_DASHBOARD.employeeList },
      {
        id: "5-2",
        title: "Add New Employees",
        path: PATH_DASHBOARD.checkForDuplicates,
      },
      { id: "5-3", title: "Departments" },
      { id: "5-4", title: "Contracter" },
      { id: "5-5", title: "Employment Type" },
      { id: "5-6", title: "Designation" },
      { id: "5-7", title: "Rewards & Deductions" },
    ],
    icon: ICONS.employees,
  },
  {
    id: "6",
    title: "Attendance",
    hasChild: false,
    icon: ICONS.attendance,
  },
  {
    id: "7",
    title: "Expenses",
    hasChild: false,
    icon: ICONS.expenses,
  },
  {
    id: "8",
    title: "Payroll",
    hasChild: false,
    icon: ICONS.payroll,
  },
  {
    id: "9",
    title: "Recruitment",
    hasChild: false,
    icon: ICONS.recruitment,
  },
  {
    id: "10",
    title: "Messages",
    hasChild: false,
    icon: ICONS.messages,
  },
  {
    id: "11",
    title: "Tasks",
    hasChild: false,
    icon: ICONS.tasks,
  },
  {
    id: "12",
    title: "Tickets",
    hasChild: false,
    icon: ICONS.tickets,
  },
  {
    id: "13",
    title: "Knowledge Base",
    hasChild: false,
    icon: ICONS.knowledge_base,
  },
];
