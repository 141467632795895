import { type Theme } from '@mui/material/styles';

//
import { InputSelectIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function Select(theme: Theme) {
  return {
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius:"5px",
        },
      },
      defaultProps: {
        IconComponent: InputSelectIcon,
      },
    },
  };
}
