import { AddNewQualification } from "./qualificationsTable/AddNewQualification";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  addQualificationAction,
  addQualificationSuccessAction,
  deleteQualificationAction,
  deleteQualificationFailedAction,
  deleteQualificationSuccessAction,
  getQualificationAction,
  getQualificationFailedAction,
  getQualificationSuccessAction,
} from "./slice";
import * as endpoint from "./endpoints";
import { selectCompanyId } from "../company/selectors";
import { request } from "@app/services";
import { selectQualification } from "./selector";

function* fetchQualification(action: any) {
  try {
    const companyId: string = yield select(selectCompanyId);
    const { page, limit } = action.payload;
    if (companyId) {
      const { data } = yield call(
        request.get,
        `${endpoint.qualificationByCompanyId}${companyId}?page=${page}&limit=${limit}`
      );
      yield put(getQualificationSuccessAction({ data }));
    }
  } catch (error: any) {
    yield put(getQualificationFailedAction(error));
  }
}

function* postQualification(action: any): any {
  try {
    const companyId: string = yield select(selectCompanyId);
    const { callback, id, ...rest } = action.payload;

    if (companyId) {
      let response;

      if (id) {
        // Use PUT request when updating an existing qualification
        response = yield call(
          request.put,
          `${endpoint.updateQualification}${id}`,
          {
            companyId,
            ...rest,
          }
        );
      } else {
        // Use POST request when creating a new qualification
        response = yield call(request.post, `${endpoint.qualification}`, {
          companyId,
          ...rest,
        });
      }

      // Fetch updated qualifications list after successful request
      yield put(getQualificationAction({ page: 1, limit: 1000 }));
      yield put(addQualificationSuccessAction(response.message));

      if (callback) {
        callback();
      }
    }
  } catch (error: any) {
    yield put(getQualificationFailedAction(error));
  }
}

function* deleteQualificationSaga(
  action: ReturnType<typeof deleteQualificationAction>
): any {
  try {
    const { id } = action.payload || {};

    const result = yield call(
      request.delete,
      endpoint.deleteQualification + id
    );

    const qualificationData = yield select(selectQualification);

    const updatedQualification = qualificationData.filter(
      (qualification: any) => qualification?.id !== id
    );

    yield put(getQualificationSuccessAction({ data: updatedQualification }));

    yield put(deleteQualificationSuccessAction(result.message));
  } catch (err: any) {
    yield put(deleteQualificationFailedAction(err));
  }
}

export function* qualificationSaga() {
  yield all([
    takeLatest(getQualificationAction, fetchQualification),
    takeLatest(addQualificationAction, postQualification),
    takeLatest(deleteQualificationAction, deleteQualificationSaga),
  ]);
}
