import ProjectInformationScreen from "@app/modules/account-information/company-information/project-settings/ProjectSetitngScreen";
import { Label } from "../components/label/label";
import AdminSetting from "@app/modules/admin/settings/AdminSetting";
// ----------------------------------------------------------------------

function path(root: string, subLink: string) {
  return `${root}${subLink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: path(ROOTS_AUTH, "/login"),
  forgotPassword: path(ROOTS_AUTH, "/forgot-password"),
  setPassword: path(ROOTS_AUTH, "/set-password"),
  register: path(ROOTS_AUTH, "/register"),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  app: path(ROOTS_DASHBOARD, "/"),
  addEmployee: path(ROOTS_DASHBOARD, "/add-employee"),
  employeeList: path(ROOTS_DASHBOARD, "/employee-list"),
  employeeRole: path(ROOTS_DASHBOARD, "/employee-role"),
  checkForDuplicates: path(ROOTS_DASHBOARD, "/check-for-duplicate"),
  employee_profile: path(ROOTS_DASHBOARD, "/employee-profile"),
  edit_employee_profile: path(ROOTS_DASHBOARD, "/edit_employee"),
  knowledge_base: path(ROOTS_DASHBOARD, "/knowledge-base"),
  accountInformation: path(ROOTS_DASHBOARD, "/account-information"),
  department: path(ROOTS_DASHBOARD, "/department"),
  contracter: path(ROOTS_DASHBOARD, "/contracter"),
  invitation: path(ROOTS_DASHBOARD, "/invitation"),
  knowledge: path(ROOTS_DASHBOARD, "/knowledge-base"),
  articleCategory: path(ROOTS_DASHBOARD, "/article-category"),
  addNewArticle: path(ROOTS_DASHBOARD, "/add-new-article"),
  designation: path(ROOTS_DASHBOARD, "/designation"),
  employeeType: path(ROOTS_DASHBOARD, "/employee-type"),
  onBoardingEmpList: path(ROOTS_DASHBOARD, "/employee-onboarding"),
  adminDashboard: path(ROOTS_DASHBOARD, "/admin/dashboard"),
  adminUsers: path(ROOTS_DASHBOARD, "/admin/users"),
  usersPayment: path(ROOTS_DASHBOARD, "/admin/users-payment"),
  adminSales: path(ROOTS_DASHBOARD, "/admin/sales"),
  qualifications: path(ROOTS_DASHBOARD, "/qualifications"),
  adminSetting: path(ROOTS_DASHBOARD, "/admin/admin-Setting"),
  companyInformationScreen: path(ROOTS_DASHBOARD, "/company-information"),
  projectInformationScreen: path(ROOTS_DASHBOARD, "/project-information"),
} as const;

export const PATH_INVITATION = {
  root: "/invitation/onboarding",
  onboarding: "",
  addEmployee: "add-employee",
};

export const LANDING_PAGE_PATH = {
  company: "/company",
};
