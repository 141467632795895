import {
  Box,
  Button,
  IconButton,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Strings } from "../../constants/strings";
import Card from "../../components/card/Card";
import { DialogBox } from "../../components/dialog";
import { useForm, Controller } from "react-hook-form";
import { ICompanyFormSchema } from "./types";
import { Input, Label } from "../../components";
import { Icon } from "@iconify/react";
import { SvgColor } from "@app/components/svg-color";
import { yupResolver } from "@hookform/resolvers/yup";
import { formSchema } from "./validator";
import { useDispatch, useSelector } from "react-redux";
import { addCompany, getCompanies, selectCompany } from "./slice";
import { selectCompanyData } from "./selectors";
import { useNavigate } from "react-router-dom";
import { LandingPageLayout } from "@app/layout";
import { sourceUrl } from "@app/constants/source_url";
import { enqueueSnackbar } from "notistack";

export const SelectCompanyScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companies = useSelector(selectCompanyData);
  // console.log("Companies DATA IS HERE:", companies); 

  const [open, setOpen] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ICompanyFormSchema>({
    resolver: yupResolver(formSchema),
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = (data: ICompanyFormSchema) => {
    const companyName = companies?.find(
      (company: any) => company.name === data.companyName
    );
    if (companyName) {
      enqueueSnackbar(`${data.companyName} already exist`, {
        variant: "error",
      });
      return;
    } else {
      dispatch(addCompany(data));
      handleClose();
    }
  };

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch]);
  return (
    <LandingPageLayout>
      <Stack
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          flexDirection: "column",
          paddingY: "25px",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            lineHeight: "24px",
            marginBottom: "10px",
          }}
        >
          {Strings.selectCompanyScreen.selectYourCompany}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
            marginTop: "12px",
            flexWrap: "wrap",
          }}
        >
          {companies?.map((item: any) => (
            <Card
              key={item.name} // Ensures each card has a unique key
              headerTitle={item.name}
              imageUri={item.companyLogo}
              imageStyle={{ borderRadius: "5px", border: "1px solid #98A5B5" }}
              buttonText={Strings.selectCompanyScreen.selectCompany}
              buttonStyle={{
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "24px",
                height: "48px",
              }}
              onButtonClick={() => {
                dispatch(selectCompany(item));
                navigate(`/company/${item.id}/projects`);
              }}
              deleteBtn={"active"}
              cardId={item.id}
              cardType={"company"}
            />
          ))}

          <Card
            cardProps={{
              component: "label",
            }}
            headerTitle={Strings.selectCompanyScreen.addNewCompany}
            imageUri={sourceUrl.company.add_image}
            buttonText={Strings.selectCompanyScreen.addNewCompany}
            buttonStyle={{
              fontWeight: "700",
              fontSize: "20px",
              lineHeight: "24px",
              height: "48px",
            }}
            onButtonClick={handleOpen}
          />
        </Box>
        <DialogBox
          open={open}
          onClose={handleClose}
          onSave={handleSubmit(onSubmit)} // Handle form submission
          title="Create New Company"
          saveButtonText="Save"
          cancelButtonText="Cancel"
          saveButtonStyle={{ fontWeight: "400", fontSize: "14px" }}
          cancelButtonStyle={{ fontWeight: "400", fontSize: "14px" }}
        >
          <Stack>
            <Label>Company Name</Label>
            <Input
              name="companyName"
              control={control}
              label="Company Name"
              placeHolder={Strings.field.inputPlaceholder("Company Name")}
              error={errors?.companyName?.message}
            />
          </Stack>
          <Stack mb={"15px"}>
            <Label>Upload Company Logo</Label>
            <Controller
              name="companyLogo"
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <>
                  <input
                    type="file"
                    accept="image/jpeg, image/png"
                    style={{ display: "none" }}
                    id="uploadCompanyPic"
                    onChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        field.onChange(e.target.files[0]); // Pass the file to react-hook-form
                      }
                    }}
                  />
                  {!field.value ? (
                    <Stack
                      component="label"
                      htmlFor="uploadCompanyPic"
                      sx={{
                        width: "100%",
                        height: "200px",
                        border: "1px solid #98A5B5",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        cursor: "pointer",
                      }}
                    >
                      <SvgColor
                        src={`/assets/icons/Upload.svg`}
                        sx={{ width: 80, height: 80, color: "#CCCCCC" }}
                      />
                      <Typography variant="body2" color="#CCCCCC">
                        Click to upload an image
                      </Typography>
                      {errors?.companyLogo?.message && (
                        <Typography variant="body2" color="error">
                          {String(errors.companyLogo.message)}
                        </Typography>
                      )}
                    </Stack>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                      }}
                    >
                      <Box
                        component="img"
                        src={URL.createObjectURL(field.value)}
                        sx={{
                          width: "100%",
                          height: "200px",
                          borderRadius: "5px",
                          display: "flex",
                          objectFit: "contain",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          cursor: "pointer",
                        }}
                      />
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "5px",
                          right: "14px",
                        }}
                        onClick={() => {
                          field.onChange(null);
                        }}
                      >
                        <Icon
                          icon="fontisto:close"
                          width="24"
                          height="24"
                          color="#98A5B5"
                        />
                      </IconButton>
                    </Box>
                  )}
                </>
              )}
            />
          </Stack>
          <Stack mb={"15px"}>
            <Label>Upload Small Company Logo</Label>
            <Controller
              name="companySmallLogo"
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <>
                  <input
                    type="file"
                    accept="image/jpeg, image/png"
                    style={{ display: "none" }}
                    id="uploadSmallCompanyLogo"
                    onChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        field.onChange(e.target.files[0]); // Pass the file to react-hook-form
                      }
                    }}
                  />
                  {!field.value ? (
                    <Stack
                      component="label"
                      htmlFor="uploadSmallCompanyLogo"
                      sx={{
                        width: "100%",
                        height: "200px",
                        border: "1px solid #98A5B5",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        cursor: "pointer",
                      }}
                    >
                      <SvgColor
                        src={`/assets/icons/Upload.svg`}
                        sx={{ width: 80, height: 80, color: "#CCCCCC" }}
                      />
                      <Typography variant="body2" color="#CCCCCC">
                        Click to upload an image
                      </Typography>
                      {errors?.companySmallLogo?.message && (
                        <Typography variant="body2" color="error">
                          {String(errors.companySmallLogo.message)}
                        </Typography>
                      )}
                    </Stack>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                      }}
                    >
                      <Box
                        component="img"
                        src={URL.createObjectURL(field.value)}
                        sx={{
                          width: "100%",
                          height: "120px",
                          borderRadius: "8px",
                          display: "flex",
                          objectFit: "contain",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          cursor: "pointer",
                        }}
                      />
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "5px",
                          right: "14px",
                        }}
                        onClick={() => {
                          field.onChange(null);
                        }}
                      >
                        <Icon
                          icon="fontisto:close"
                          width="24"
                          height="24"
                          color="#98A5B5"
                        />
                      </IconButton>
                    </Box>
                  )}
                </>
              )}
            />
          </Stack>
        </DialogBox>
      </Stack>
    </LandingPageLayout>
  );
};
