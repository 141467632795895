import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  addCompany,
  addCompanyFailed,
  addCompanySuccess,
  deleteSelectedCompany,
  getCompanies,
  getCompaniesFailed,
  getCompaniesSuccess,
  getCompanyDetail,
  getCompanyDetailFailed,
  getCompanyDetailSuccess,
  getSelectedCompanyById,
  getSelectedCompanyByIdFailed,
  getSelectedCompanyByIdSuccess,
} from "./slice";
import { request } from "@app/services";
import * as endpoints from "./endpoints";
import { selectCompanyData } from "./selectors";

function* createCompany(action: ReturnType<typeof addCompany>): any {
  try {
    const { companyName, companyLogo, companySmallLogo } = action.payload;
    const companyData = yield select(selectCompanyData);
    const formData = new FormData();
    formData.append("name", companyName);
    formData.append("companyLogo", companyLogo);
    formData.append("companySmallLogo", companySmallLogo);
    const { data } = yield call(request.post, endpoints.company, formData);

    const newCompanyData = [...companyData, data];
    yield put(getCompaniesSuccess({ data: newCompanyData }));
  } catch (error: any) {
    yield put(addCompanyFailed(error));
  }
}

function* fetchCompanies() {
  try {
    const { data } = yield call(request.get, endpoints.company);
    yield put(getCompaniesSuccess({ data }));
  } catch (error: any) {
    yield put(getCompaniesFailed(error));
  }
}

function* deleteCompany(action: any): any {
  try {
    const data = yield call(
      request.delete,
      `${endpoints.companyDelete}/${action.payload}`
    );
    const companies = yield select(selectCompanyData);

    if (data.status === true) {
      // Filter out the deleted company
      const updatedCompanies = companies.filter(
        (company: any) => company.id !== action.payload
      );
      // Dispatch updated company list
      yield put(getCompaniesSuccess({ data: updatedCompanies }));
    }

    // yield put(getCompaniesSuccess({ data: data }))
  } catch (error) {
    console.log(error);
  }
}
function* fetchCompanyDetail(action: any): any {
  try {
    const response = yield call(
      request.get,
      `${endpoints.companyDetail}/${action?.payload?.id}`
    );

    yield put(getCompanyDetailSuccess(response));
  } catch (error: any) {
    yield put(getCompanyDetailFailed(error.response || error.message));
  }
}
function* getCompanyDetailsByIdSaga(action: any): any {
  try {
    const companyId = action?.payload.id;
    const response = yield call(
      request.get,
      `${endpoints.getCompanyDetailById}${companyId}`
    );

    yield put(getSelectedCompanyByIdSuccess(response.data));
  } catch (error: any) {
    yield put(getSelectedCompanyByIdFailed(error));
  }
}

function* companySagas() {
  yield all([
    takeLatest(addCompany, createCompany),
    takeLatest(getCompanies, fetchCompanies),
    // takeLatest(deleteSelectedCompany, deleteCompanies),
    takeLatest(deleteSelectedCompany, deleteCompany),
    takeLatest(getCompanyDetail, fetchCompanyDetail),
    takeLatest(getSelectedCompanyById, getCompanyDetailsByIdSaga),
  ]);
}

export { companySagas };
