import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AppTableHeadCell from "./AppTableHeadCell";
import { RowActions } from "./row-action";

interface Props {
  data: any;
}

const QualificationTable = ({ data }: Props) => {
  const [selectedRows, setSelectedRows] = useState<readonly string[]>([]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data?.data
        ? data?.data?.length > 0
          ? data.data.map((n: any) => n.id)
          : []
        : [];
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
  };

  const handleSelectSingleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string | number
  ) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, id.toString()]);
      return;
    }
    setSelectedRows((prev) => prev.filter((item) => item !== id));
  };

  return (
    <TableContainer
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "5px",
        minHeight: "600px",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      <Table sx={{ minWidth: 500 }}>
        {/* Table Header */}
        <TableHead sx={{ borderBottom: "1px solid #ddd" }}>
          <TableRow>
            <TableCell
              sx={{
                width: "50px",
                textAlign: "center",
                backgroundColor: "#fff",
              }}
            >
              {/* Make sure the checkbox is always visible */}
              <Checkbox
                size="small"
                sx={{ color: "#616E80", backgroundColor: "#fff" }}
                checked={
                  data?.data?.length > 0 &&
                  selectedRows.length === data?.data?.length
                }
                onChange={handleSelectAllClick}
                // Set indeterminate state if some but not all rows are selected
                indeterminate={
                  selectedRows.length > 0 &&
                  data?.data?.length > 0 &&
                  selectedRows.length < data?.data?.length
                }
                // Disable if there are no rows
                disabled={!data?.data?.length}
              />
            </TableCell>

            <AppTableHeadCell
              cellProps={{
                sx: {
                  minWidth: "150px",
                  padding: "8px 12px",
                  background: "#fff",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#616E80",
                  textAlign: "left",
                },
              }}
              title="Qualification ID"
            />
            <AppTableHeadCell
              cellProps={{
                sx: {
                  minWidth: "200px",
                  padding: "8px 12px",
                  background: "#fff",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#616E80",
                  textAlign: "left",
                },
              }}
              title="Qualification Name"
            />
            <AppTableHeadCell
              cellProps={{
                sx: {
                  minWidth: "150px",
                  padding: "8px 12px",
                  background: "#fff",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#616E80",
                  textAlign: "left",
                },
              }}
              title="Is this Mandatory"
            />
            <AppTableHeadCell
              cellProps={{
                sx: {
                  minWidth: "180px",
                  padding: "8px 12px",
                  background: "#fff",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#616E80",
                  textAlign: "left",
                },
              }}
              title="Employees Holding This Qualification"
            />
            <AppTableHeadCell
              cellProps={{
                sx: {
                  minWidth: "100px",
                  padding: "8px 12px",
                  background: "#fff",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#616E80",
                  textAlign: "center", // Changed from "right" to "center"
                },
              }}
              title="Actions"
            />
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          {data && data.length > 0 ? (
            data.map((item: any) => (
              <TableRow key={item.id}>
                <TableCell width={50} sx={{ textAlign: "center" }}>
                  <Checkbox
                    checked={selectedRows.includes(item.id)}
                    size="small"
                    sx={{ color: "#616E80" }}
                    inputProps={{ "aria-label": "select qualification" }}
                    onChange={(e) => handleSelectSingleCheck(e, item.id)}
                  />
                </TableCell>

                <TableCell
                  width={80}
                  sx={{ padding: "8px", textAlign: "left" }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                    {item.id}
                  </Typography>
                </TableCell>

                <TableCell sx={{ padding: "8px", textAlign: "left" }}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                    {item.name}
                  </Typography>
                </TableCell>

                <TableCell sx={{ padding: "8px 12px", textAlign: "left" }}>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 500, color: "#000" }}
                  >
                    {item.isMandatory ? "YES" : "NO"}
                  </Typography>
                </TableCell>

                <TableCell sx={{ padding: "8px 12px", textAlign: "left" }}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                    {item.totalHoldingEmp ?? "N/A"}
                  </Typography>
                </TableCell>

                <TableCell
                  width={50}
                  sx={{ padding: "8px", textAlign: "left" }}
                >
                  <RowActions data={item} />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={6}
                sx={{ textAlign: "center", padding: "16px" }}
              >
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 500, color: "#999" }}
                >
                  No Qualification Found
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default QualificationTable;
