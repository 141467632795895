import React, { createContext, useContext, useState, ReactNode } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

type DeleteAlertProps = {
  title?: string;
  description: string;
  onConfirm: () => void;
};

type DeleteAlertContextType = {
  showDeleteAlert: (props: DeleteAlertProps) => void;
};

const DeleteAlertContext = createContext<DeleteAlertContextType | undefined>(
  undefined
);

export const useDeleteAlert = () => {
  const context = useContext(DeleteAlertContext);
  if (!context) {
    throw new Error("useDeleteAlert must be used within a DeleteAlertProvider");
  }
  return context;
};

export const DeleteAlertProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [alertProps, setAlertProps] = useState<DeleteAlertProps | null>(null);

  const showDeleteAlert = (props: DeleteAlertProps) => {
    setAlertProps(props);
    setOpen(true);
  };

  const handleClose = (confirm: boolean) => {
    setOpen(false);
    if (confirm && alertProps?.onConfirm) {
      alertProps.onConfirm();
    }
  };

  return (
    <DeleteAlertContext.Provider value={{ showDeleteAlert }}>
      {children}

      {alertProps && (
        <Dialog
          open={open}
          onClose={() => handleClose(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>{alertProps.title || "Confirm Deletion"}</DialogTitle>
          <DialogContent>
            <DialogContentText>{alertProps.description}</DialogContentText>
          </DialogContent>
          <DialogActions sx={{ padding: "16px" }}>
            <Button
              onClick={() => handleClose(false)}
              sx={{
                backgroundColor: "#007bff",
                color: "#fff",
                "&:hover": { backgroundColor: "#0056b3" },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleClose(true)}
              sx={{
                backgroundColor: "#dc3545",
                color: "#fff",
                "&:hover": { backgroundColor: "#c82333" },
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </DeleteAlertContext.Provider>
  );
};
