import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ContractCards } from "./UserCard";
import Navbar from "./UserNavbar";
import { useDispatch, useSelector } from "react-redux";
import { selectDashboardData } from "../selectors";
import { getDashboardData } from "../slice";
import { selectCompanyId } from "../../company/selectors";
import { useNavigate, Outlet } from "react-router-dom";
import { PATH_DASHBOARD } from "@app/routes/paths";

type ContractStatus = "Active" | "Inactive" | "Cancelled";
interface Project {
  id: string;
  name: string;
  isActive: boolean;
  client_name: string | null;
}
const AdminUsers: React.FC = () => {
  const dispatch = useDispatch();
  const dashboardData = useSelector(selectDashboardData);
  const companyId = useSelector(selectCompanyId);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getDashboardData({ id: companyId }));
  }, [dispatch, companyId]);

  console.log("Dashboard Data:", dashboardData);

  const statusColor = (status: ContractStatus): string => {
    switch (status) {
      case "Active":
        return "#3BD365";
      case "Inactive":
        return "#FFB300";
      case "Cancelled":
        return "#F44336";
      default:
        return "#9e9e9e";
    }
  };
  const handleNameClick = () => {
    navigate(PATH_DASHBOARD.usersPayment);
  };

  return (
    <>
      <Navbar />
      <Box sx={{ p: 3, bgcolor: "#f5f7f9", minHeight: "100vh" }}>
        <ContractCards />

        {/* Projects Table */}
        <Paper sx={{ p: 2, mb: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <input type="checkbox" />
                  </TableCell>
                  <TableCell>Member Ref</TableCell>
                  <TableCell>Client Status</TableCell>
                  <TableCell>Members Full Name</TableCell>
                  <TableCell>Subscription</TableCell>
                  <TableCell>Payment Plan</TableCell>
                  <TableCell>Users</TableCell>
                  <TableCell>Subscription Value</TableCell>
                  <TableCell align="right">Amount Paid To Date</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dashboardData?.projects?.map((project: Project) => (
                  <TableRow key={project.id}>
                    <TableCell padding="checkbox">
                      <input type="checkbox" />
                    </TableCell>
                    <TableCell>{project.id}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          bgcolor: statusColor(
                            project.isActive ? "Active" : "Inactive"
                          ),
                          color: "black",
                          py: 0.5,
                          px: 1,
                          borderRadius: 1,
                          display: "inline-block",
                          fontSize: "0.75rem",
                        }}
                      >
                        {project.isActive ? "Active" : "Inactive"}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                        onClick={handleNameClick}
                        sx={{
                          fontWeight: 700,
                          cursor: "pointer",
                          "&:hover": {
                            color: "#1976d2",
                            textDecoration: "underline",
                          },
                        }}
                      >
                        John Doe
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {/* Placeholder since API doesn't provide this */}
                      Basic Plan
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontWeight: 700 }}>
                        {/* Placeholder since API doesn't provide this */}
                        Monthly
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography sx={{ fontWeight: 700 }}>
                        {dashboardData?.totalEmployees || 0}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {/* Placeholder since API doesn't provide this */}
                      £25.00 / Month
                    </TableCell>
                    <TableCell align="right">
                      {(dashboardData?.totalRevnue || 0).toFixed(2)}
                    </TableCell>
                    <TableCell align="right">...</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography variant="body2" color="text.secondary">
              Showing 1 to {dashboardData?.projects?.length || 0} of{" "}
              {dashboardData?.totalProjects || 0} items
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                Previous
              </Typography>
              <Box
                sx={{
                  bgcolor: "#1976d2",
                  color: "white",
                  width: 24,
                  height: 24,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 0.5,
                  mr: 0.5,
                }}
              >
                1
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                Next
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default AdminUsers;
