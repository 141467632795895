import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  Divider,
  Box,
} from "@mui/material";

interface NotificationProps {
  title: string;
  message: string;
  timestamp: string;
  avatar?: string;
  onMarkAsRead?: () => void;
  onShowAll?: () => void;
}

export const NotificationCard: React.FC<NotificationProps> = ({
  title,
  message,
  timestamp,
  avatar,
  onMarkAsRead,
  onShowAll,
}) => {
  return (
    <Card elevation={2} sx={{ maxWidth: "md", width: "100%" }}>
      <CardContent sx={{ padding: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Typography variant="subtitle1" fontWeight="medium">
            New notifications
          </Typography>
          <Box display="flex" gap={1}>
            <Button
              color="primary"
              size="small"
              onClick={onMarkAsRead}
              sx={{ textTransform: "none" }}
            >
              Mark as Read
            </Button>
            <Typography color="text.secondary">|</Typography>
            <Button
              color="primary"
              size="small"
              onClick={onShowAll}
              sx={{ textTransform: "none" }}
            >
              Show All
            </Button>
          </Box>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Box
          display="flex"
          sx={{
            mt: 1,
            py: 1,
            px: 1,
            borderRadius: 1,
            "&:hover": { bgcolor: "action.hover" },
          }}
        >
          {avatar && (
            <Avatar
              src={avatar}
              alt="Profile avatar"
              sx={{ mr: 2, width: 40, height: 40 }}
            />
          )}
          <Box>
            <Typography variant="body1" fontWeight="medium">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {message}
            </Typography>
            <Typography
              variant="caption"
              color="text.disabled"
              sx={{ mt: 0.5, display: "block" }}
            >
              {timestamp}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
