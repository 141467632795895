import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Divider,
} from "@mui/material";

interface DialogProps {
  open: boolean;
  onClose: () => void;
  onSave?: () => void;
  title?: string;
  children?: React.ReactNode;
  cancelButtonText?: string;
  saveButtonText?: string;
  disableSave?: boolean;
  saveButtonStyle?:React.CSSProperties;
  cancelButtonStyle?:React.CSSProperties;
  sx? :any;
}

export const DialogBox = ({
  open,
  onClose,
  onSave,
  title = "Dialog Title",
  children,
  cancelButtonText = "Cancel",
  saveButtonText = "Save",
  disableSave = false,
  saveButtonStyle,
  cancelButtonStyle,
}: DialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {title && (
        <DialogTitle>
          <Typography variant="h6">{title}</Typography>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      <Divider sx={{ pt: 2 }} />
      <DialogActions sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
        <Button onClick={onClose} sx={{
            backgroundColor:"#FE6B6B",
            color:"#fff",
            ...cancelButtonStyle,
             
        }}>
          {cancelButtonText}
        </Button>
        {onSave && (
          <Button
            onClick={onSave}
            color="primary"
            variant="contained"
            disabled={disableSave}
            sx={{
              height:"34px",
              width:"53px",
              ...saveButtonStyle, 
            }}
          >
            {saveButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};