import { FC } from "react";
import {
  Box,
  FormLabel,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Controller } from "react-hook-form";

import { SvgColor } from "../svg-color";
import { sourceUrl } from "@app/constants/source_url";
import { Icon } from "@iconify/react";

type FilePickerFieldProps = {
  name: string;
  label: string;
  multiple?: boolean;
  filePickerName?: string;
  control: any;
  onUploadFile?: any;
  isArticle?: boolean;
};

export const RHFFilePickerField: FC<FilePickerFieldProps> = ({
  name,
  label,
  multiple,
  filePickerName = "Choose a file",
  control,
  onUploadFile,
  isArticle,
}) => {
  const theme = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const fileName = field.value?.name || filePickerName; // Safely handle field.value

        return (
          <FormLabel
            htmlFor={`${name}-fileInput`}
            sx={{
              display: "flex",
              flexGrow: 1,
              cursor: "pointer",
            }}
          >
            <Stack
              sx={{
                pointerEvents: "none",
                cursor: "pointer",
                border: `1px solid #98A5B5`,
                borderRadius: "4px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                py: 1,
              }}
            >
              {field.value?.name ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    component="img"
                    src={
                      field.value.type.includes("image")
                        ? URL.createObjectURL(field.value)
                        : "/pdfIcon.png"
                    }
                    sx={{
                      width: "100%",
                      height: "120px",
                      borderRadius: "8px",
                      display: "flex",
                      objectFit: "contain",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                  />
                  {!field.value.type.includes("image") && (
                    <Typography
                      variant="body2"
                      sx={{ cursor: "pointer", textAlign: "center" }}
                    >
                      {field.value.name}
                    </Typography>
                  )}
                </Box>
              ) : (
                <>
                  <SvgColor
                    src={sourceUrl.icons.upload}
                    sx={{
                      backgroundColor: theme.palette.grey[500],
                      width: 50,
                      height: 50,
                    }}
                  />
                  <Typography sx={{ fontSize: "13px" }}>
                    {filePickerName}
                  </Typography>
                </>
              )}
            </Stack>

            <input
              type="file"
              id={`${name}-fileInput`}
              style={{ display: "none" }}
              {...(multiple && { multiple: true })}
              onChange={(event) => {
                 if(isArticle){
                  const files = event?.target?.files;
                  if (files && files.length > 0) {
                    const file = files[0];
                    const newFile = Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    });
                    onUploadFile(newFile);
                  }
                }else{
                  const files = event?.target?.files;
                  if (files && files.length > 0) {
                    const file = files[0];
                    const newFile = Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    });
                    field.onChange(newFile);
                  }
                }
              }}
            />
          </FormLabel>
        );
      }}
    />
  );
};
