import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getDashboardData,
  getDashboardDataSuccess,
  getDashboardDataFailed,
  
  getPermissionsSettingData,
  getPermissionsSettingDataSuccess,
  getPermissionsSettingDataFailed,
 
  getUserSettingData,
  getUserSettingDataSuccess,
  getUserSettingDataFailed,
  deleteUser, // New action
  deleteUserSuccess, // New action
  deleteUserFailed, // New action
  updateUser, // New action
  updateUserSuccess, // New action
  updateUserFailed, // New action
} from "./slice";
import { request } from "@app/services";
import * as endpoints from "./endpoints";

// saga for fetching dashboard data
function* fetchEmployees(action: any): any {
  try {
    console.log("Fetching employees:", action.payload);
    const id = action.payload.id;
    const response = yield call(request.get, `${endpoints.employees}/${id}`);

    console.log("API Response:", response.data);

    yield put(getDashboardDataSuccess(response.data));
  } catch (error: any) {
    console.error("Error:", error);

    yield put(getDashboardDataFailed(error.response || error.message));
  }
}

// saga for fetching PermissionsSettingData
function* fetchPermissionsSettingData(action: any): any {
  try {
    console.log("Fetching permissions setting data:", action.payload);
    const id = action.payload.id;
    // const response = yield call(request.get, `${endpoints.permissionsSetting}/${id}`);

    // console.log("API Response:", response.data);

    // yield put(getPermissionsSettingDataSuccess(response.data));
  } catch (error: any) {
    console.error("Error:", error);

    yield put(getPermissionsSettingDataFailed(error.response || error.message));
  }
}

// Saga for fetching UserSettingData
function* fetchUserSettingData(action: any): any {
  try {
    console.log("Fetching user setting data:", action.payload);
    const id = action.payload.id;
    const response = yield call(request.get, `${endpoints.getUserSettings}`);

    console.log("get user setting :", response.data);

    yield put(getUserSettingDataSuccess(response.data));
  } catch (error: any) {
    console.error("Error:", error);
    yield put(getUserSettingDataFailed(error.response || error.message));
  }
}

// New saga for deleting a user
function* deleteUserSaga(action: any): any {
  try {
    console.log("Deleting user:", action.payload);
    const {id, query} = action.payload;
    yield call(request.delete, `${endpoints.DeleteUserSetting}/${id}?${query}`);
    yield put(deleteUserSuccess({ id }));
  } catch (error: any) {
    console.error("Error deleting user:", error);
    yield put(deleteUserFailed(error.response || error.message));
  }
}
// New saga for updating a user
function* updateUserSaga(action: any): any {
  try {
    console.log("Updating user:", action.payload);
    const { id, data } = action.payload;
    const response = yield call(request.put, `${endpoints.updateUserSettings}/${id}`, data);
    yield put(updateUserSuccess(response.data));
  } catch (error: any) {
    console.error("Error updating user:", error);
    yield put(updateUserFailed(error.response || error.message));
  }
}

// Update the adminSagas watcher to include the new actions
function* adminSagas() {
  yield all([
    takeLatest(getDashboardData, fetchEmployees),
    takeLatest(getPermissionsSettingData, fetchPermissionsSettingData),
    takeLatest(getUserSettingData, fetchUserSettingData),
    takeLatest(deleteUser, deleteUserSaga), // New saga for deleting a user
    takeLatest(updateUser, updateUserSaga),
  ]);
}


export { adminSagas };