import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Company, ICompanyFormSchema, ICompanyState } from "./types";
import { RequestStatus } from "@app/constants/request";
import { IRequestFailed, IRequestState } from "@app/types";

const initialState: ICompanyState = {
  company: {
    status: RequestStatus.IDLE,
  },
  selectedCompanyId: null,
  selectedCompany: null,
  deleteSelectedCompany: null,
  selectedCompanyDetailById: {},
};

const companySlice = createSlice({
  initialState,
  name: "company",
  reducers: {
    addCompany: (state, action: PayloadAction<ICompanyFormSchema>) => {
      state.company.status = RequestStatus.RUNNING;
    },
    addCompanyFailed: (state, action: PayloadAction<IRequestFailed>) => {
      state.company.status = RequestStatus.ERROR;
      state.company.error = action.payload;
    },
    addCompanySuccess(state) {
      state.company.status = RequestStatus.SUCCESS;
    },
    getCompanies(state) {
      state.company.status = RequestStatus.RUNNING;
    },
    getCompaniesSuccess(state, action: PayloadAction<IRequestState>) {
      state.company.data = action.payload.data;
      state.company.status = RequestStatus.SUCCESS;
    },
    getCompaniesFailed(state, action: PayloadAction<IRequestFailed>) {
      state.company.error = action.payload;
      state.company.status = RequestStatus.ERROR;
    },
    selectCompany(state, action: PayloadAction<any>) {
      state.selectedCompany = action.payload;
      state.selectedCompanyId = action.payload.id;
    },
    deleteSelectedCompany(state, action: PayloadAction<Company>) {
      state.selectedCompanyId = action.payload;
    },
    getCompanyDetail(state, action: PayloadAction<{ id: string }>) {
      state.company.status = RequestStatus.RUNNING;
    },
    getCompanyDetailSuccess(state, action: PayloadAction<IRequestState>) {
      state.selectedCompanyDetailById = action.payload.data;
      state.company.status = RequestStatus.SUCCESS;
    },
    getCompanyDetailFailed(state, action: PayloadAction<IRequestFailed>) {
      state.company.error = action.payload;
      state.company.status = RequestStatus.ERROR;
    },
    getSelectedCompanyById(state, action: PayloadAction<{ id: any }>) {
      state.selectedCompanyDetailById.status = RequestStatus.RUNNING;
    },

    getSelectedCompanyByIdSuccess(state, action: PayloadAction<any>) {
      console.log("actionn.payload", action.payload);
      state.selectedCompanyDetailById.data = action.payload;
      state.selectedCompany = action.payload;
      state.selectedCompanyId = action.payload.id;
      state.selectedCompanyDetailById.status = RequestStatus.SUCCESS;
    },
    getSelectedCompanyByIdFailed(state, action: PayloadAction<IRequestFailed>) {
      state.selectedCompanyDetailById.error = action.payload;
      state.selectedCompanyDetailById.status = RequestStatus.ERROR;
    },
  },
});

export const companyReducer = companySlice.reducer;

export const {
  addCompany,
  addCompanyFailed,
  addCompanySuccess,
  getCompanies,
  getCompaniesFailed,
  getCompaniesSuccess,
  selectCompany,
  deleteSelectedCompany,
  getCompanyDetail,
  getCompanyDetailSuccess,
  getCompanyDetailFailed,
  getSelectedCompanyById,
  getSelectedCompanyByIdSuccess,
  getSelectedCompanyByIdFailed,
} = companySlice.actions;
