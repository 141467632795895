import React from "react";

interface Props {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
  horizontal?: number;
  vertical?: number;
  height?: number;
}

/**
 * Function to scale the size dynamically based on screen width.
 * Adjusts values to maintain proportional spacing across different screen sizes.
 */
const moderateScale = (size: number): number => {
  const baseWidth = 375;
  const screenWidth = window.innerWidth;
  return (size * screenWidth) / baseWidth;
};

const Spacing: React.FC<Props> = ({
  left = 0,
  right = 0,
  top = 0,
  bottom = 0,
  horizontal = 0,
  vertical = 0,
  height = 0,
}) => {
  const spacingStyle: React.CSSProperties = {
    marginLeft: horizontal ? moderateScale(horizontal) : moderateScale(left),
    marginRight: horizontal ? moderateScale(horizontal) : moderateScale(right),
    marginTop: vertical ? moderateScale(vertical) : moderateScale(top),
    marginBottom: vertical ? moderateScale(vertical) : moderateScale(bottom),
    height: height ? moderateScale(height) : undefined,
  };

  return <div style={spacingStyle} />;
};

export { Spacing };
