import React, { useEffect } from 'react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Box, Paper, Typography, Grid } from '@mui/material';
import NewMembersList from './new-member-list';
import { useDispatch, useSelector } from 'react-redux';
import { selectDashboardData } from './selectors';
import { getDashboardData } from './slice';
import { selectCompanyId } from '../company/selectors';

export const AdminDashboard = () => {
  const dispatch = useDispatch();
  const dashboardData = useSelector(selectDashboardData);
    const companyId= useSelector(selectCompanyId);
  

  useEffect(() => {
    dispatch(getDashboardData({id:companyId}));
  
  }, [dispatch]);
  console.log('Dashboard Data:', dashboardData);


  // Revenue data for line chart
  const revenueData = [
    { month: 'Jan', '2019': 10000, '2020': 20000, '2021': 30000 },
    { month: 'Mar', '2019': 15000, '2020': 25000, '2021': 35000 },
    { month: 'May', '2019': 20000, '2020': 30000, '2021': 40000 },
    { month: 'Jul', '2019': 25000, '2020': 35000, '2021': 45000 },
    { month: 'Sep', '2019': 23000, '2020': 33000, '2021': 43000 },
    { month: 'Nov', '2019': 28000, '2020': 38000, '2021': 48000 },
    { month: 'Dec', '2019': 30000, '2020': 40000, '2021': 50000 },
  ];

  // Members per system data for bar chart
  const membersPerSystemData = [
    { name: 'Hr-System', activeMembers: 25, allTimeMembers: 35 },
    { name: 'Finance Manager', activeMembers: 35, allTimeMembers: 55 },
    { name: 'Procurment', activeMembers: 15, allTimeMembers: 20 },
    { name: 'Corporate Manager', activeMembers: 22, allTimeMembers: 35 },
    { name: 'Project Manager', activeMembers: 10, allTimeMembers: 15 },
    { name: 'Health & Safety', activeMembers: 8, allTimeMembers: 10 },
    { name: 'Company Manager', activeMembers: 12, allTimeMembers: 18 },
  ];

  // Data for member distribution pie chart
  const memberCountryData = [
    { name: 'United Kingdom', value: 40, color: '#2563EB' },
    { name: 'France', value: 15, color: '#60A5FA' },
    { name: 'United States', value: 25, color: '#93C5FD' },
    { name: 'Germany', value: 10, color: '#BFDBFE' },
    { name: 'Spain', value: 7, color: '#DBEAFE' },
    { name: 'Other', value: 3, color: '#EFF6FF' },
  ];

  // Data for revenue distribution pie chart (similar proportions but might differ slightly)
  const revenueCountryData = [
    { name: 'United Kingdom', value: 45, color: '#2563EB' },
    { name: 'France', value: 10, color: '#60A5FA' },
    { name: 'United States', value: 30, color: '#93C5FD' },
    { name: 'Germany', value: 8, color: '#BFDBFE' },
    { name: 'Spain', value: 5, color: '#DBEAFE' },
    { name: 'Other', value: 2, color: '#EFF6FF' },
  ];

  return (
    <Box sx={{ p: 3, bgcolor: '#F8FAFC' }}>
      {/* Stats Cards Row */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={4}>
          <Paper elevation={0} sx={{ p: 3, borderRadius: 2, boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '-0.01em',
                    color: '#000000'
                  }}
                >
                  Revenue
                </Typography>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '-0.01em',
                   
                    display: 'inline-block',
                    
                    borderRadius: '4px',
                    color:'#4084EF'
                  
                  }}
                >
                 
                  £ {dashboardData?.totalRevnue || 0} - Since Launch
                </Typography>
              </Box>
              <Box sx={{ color: '#718096', fontSize: '24px' }}>
                <img 
                  src="/assets/icons/Vector.png" 
                  alt="Revenue icon" 
                  width={24} 
                  height={24} 
                />
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={0} sx={{ p: 3, borderRadius: 2, boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '-0.01em',
                    color: '#000000'
                  }}
                >
                  Member Base
                </Typography>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '-0.01em',
                    display: 'inline-block',
                  
                    borderRadius: '4px',
                    color: '#4084EF'
                  }}
                >
                {dashboardData?.totalEmployees || 0}
                </Typography>
              </Box>
              <Box sx={{ color: '#718096', fontSize: '24px' }}>
                <img 
                  src="/assets/icons/Vector.png" 
                  alt="Member Base icon" 
                  width={24} 
                  height={24} 
                />
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={0} sx={{ p: 3, borderRadius: 2, boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '-0.01em',
                    color: '#000000'
                  }}
                >
                  Billable Users
                </Typography>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '-0.01em',
                    display: 'inline-block',
                  
                    borderRadius: '4px',
                    color: '#4084EF'
                  }}
                >
                  {dashboardData?.billableUsers || 0}
                </Typography>
              </Box>
              <Box sx={{ color: '#718096', fontSize: '24px' }}>
                <img 
                  src="/assets/icons/Group.png" 
                  alt="Billable Users icon" 
                  width={24} 
                  height={24} 
                />
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Charts Row */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        {/* New members list - Using the new component */}
        <Grid item xs={12} md={6}>
          <NewMembersList />
        </Grid>

        {/* Members vs Revenue pie charts */}
        <Grid item xs={12} md={6}>
          <Paper elevation={0} sx={{ p: 3, borderRadius: 2, boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)', height: '100%' }}>
            <Typography variant="h6" fontWeight="medium" sx={{ mb: 2 }}>Members vs Revenue</Typography>
            <Box sx={{ display: 'flex', gap: 1, mb: 2, justifyContent: 'center' }}>
              {memberCountryData.map((entry, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: entry.color }}></Box>
                  <Typography variant="caption">{entry.name}</Typography>
                </Box>
              ))}
            </Box>
            <Box sx={{ display: 'flex', height: 200 }}>
              <Box sx={{ width: '50%', height: '100%' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={memberCountryData}
                      cx="50%"
                      cy="50%"
                      innerRadius={40}
                      outerRadius={80}
                      paddingAngle={0}
                      dataKey="value"
                    >
                      {memberCountryData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <Typography variant="body2" textAlign="center">Member Per Country</Typography>
              </Box>
              <Box sx={{ width: '50%', height: '100%' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={revenueCountryData}
                      cx="50%"
                      cy="50%"
                      innerRadius={40}
                      outerRadius={80}
                      paddingAngle={0}
                      dataKey="value"
                    >
                      {revenueCountryData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <Typography variant="body2" textAlign="center">Revenue per Country</Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Charts Row */}
      <Grid container spacing={3}>
        {/* Revenue Chart */}
        <Grid item xs={12} md={6}>
          <Paper elevation={0} sx={{ p: 3, borderRadius: 2, boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)' }}>
            <Typography variant="h6" fontWeight="medium" sx={{ mb: 2 }}>Revenue Chart</Typography>
            <Box sx={{ display: 'flex', gap: 2, mb: 2, justifyContent: 'flex-end' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: '#3B82F6' }}></Box>
                <Typography variant="caption">2021</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: '#60A5FA' }}></Box>
                <Typography variant="caption">2020</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: '#93C5FD' }}></Box>
                <Typography variant="caption">2019</Typography>
              </Box>
            </Box>
            <ResponsiveContainer width="100%" height={250}>
              <LineChart data={revenueData}>
                <CartesianGrid vertical={false} stroke="#E2E8F0" />
                <XAxis dataKey="month" axisLine={false} tickLine={false} />
                <YAxis domain={[0, 60000]} axisLine={false} tickLine={false} tickFormatter={(value) => `£${value/1000}k`} />
                <Tooltip />
                <Line type="monotone" dataKey="2021" stroke="#3B82F6" strokeWidth={2} dot={false} />
                <Line type="monotone" dataKey="2020" stroke="#60A5FA" strokeWidth={2} dot={false} />
                <Line type="monotone" dataKey="2019" stroke="#93C5FD" strokeWidth={2} dot={false} />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Members per system */}
        <Grid item xs={12} md={6}>
          <Paper elevation={0} sx={{ p: 3, borderRadius: 2, boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)' }}>
            <Typography variant="h6" fontWeight="medium" sx={{ mb: 2 }}>Members per system</Typography>
            <Box sx={{ display: 'flex', gap: 2, mb: 2, justifyContent: 'flex-end' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: '#3B82F6' }}></Box>
                <Typography variant="caption">Active Members</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: '#93C5FD' }}></Box>
                <Typography variant="caption">All Time Members</Typography>
              </Box>
            </Box>
            <ResponsiveContainer width="100%" height={250}>
              <BarChart data={membersPerSystemData} barSize={20}>
                <CartesianGrid vertical={false} stroke="#E2E8F0" />
                <XAxis 
                  dataKey="name" 
                  axisLine={false} 
                  tickLine={false}
                  tick={{ fontSize: 12, fill: '#64748B' }}
                  angle={-45}
                  textAnchor="end"
                  height={50}
                />
                <YAxis domain={[0, 60]} axisLine={false} tickLine={false} />
                <Tooltip />
                <Bar dataKey="allTimeMembers" fill="#93C5FD" />
                <Bar dataKey="activeMembers" fill="#3B82F6" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminDashboard;