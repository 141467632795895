import { useForm, Controller, useWatch } from "react-hook-form";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import dayjs, { Dayjs } from "dayjs";
import { get } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SignaturePad from "react-signature-pad-wrapper";

import { SvgColor } from "@app/components/svg-color";
import { PATH_DASHBOARD } from "@app/routes/paths";
import { Input, Label, MaskedInput } from "../../components";
import data from "./formsections.json";
import { iUpdateProfileFormSchema } from "./types";
import {
  addEmployeeContact,
  addEmployeeDeclaration,
  addEmployeeInductor,
  addIdentifyToRight,
  addMedicalInformation,
  addPaymentDetails,
  deleteProofOfIdentity,
  deleteProofOfWork,
  getEmployee,
  setVideComplete,
  storeSectionDataInLocal,
  updateEmployeeAction,
  updatePersonalDetails,
} from "./slice";
import {
  FormProvider,
  RHFDatePicker,
  RHFSelect,
} from "@app/components/hook-form";
import { useRequestState } from "@app/hooks/use-request-state";
import { sourceUrl } from "@app/constants/source_url";
import { useDialog } from "@app/hooks/use-dialog";
import { AddAdditionalDocumentDialog } from "./AddAdditionalDocumentDialog";
import { DocumentCard } from "./DocumentCard";
import { UploadAdditionalInformation } from "../employee-profile/UploadAdditionalInformation";
import {
  selectAddDeclaration,
  selectAddEmployeeContact,
  selectAddIdentifyRightToSuccess,
  selectAddMedicalInformation,
  selectAddPaymentDetailsSuccess,
  selectIsSectionVideoComplete,
  selectSelectedEmployee,
  selectStoreSectionDataInLocal,
  selectUpdateEmployeeAction,
  selectUpdatePersonalDetails,
} from "./selector";
import { getEmployementType } from "../EmployeeType/slice";
import { selectEmployementType } from "../EmployeeType/selector";
import { getDepartment } from "../department/slice";
import { selectDepartment } from "../department/selector";
import {
  contactDetailsSchema,
  emergencyContactDetailsSchema,
  haveBeenWorkingSchema,
  identifyRightToWorkSchema,
  personalDetailsSchema,
  section10Schema,
  section11Schema,
  section8Schema,
  section9Schema,
} from "./validators";
import { getContractor } from "../Contractor/slice";
import { selectContractor } from "../Contractor/selector";
import { AddNewContract } from "../Contractor";
import { AddNewDepartment } from "../department/departmentTable/AddNewDepartment";
import { AddNewEmployeeType } from "../EmployeeType/EmployeeTable/AddNewEmployee";
import { Strings } from "@app/constants/strings";
import { Close, Visibility } from "@mui/icons-material";
import FilePreviewDialog from "@app/components/file-preview/FilePreview";
import { RowActionsIdentity } from "./RowActionsIdentity";
import { RowActionsWork } from "./RowActionRightToWork";
import { selectEmployeeRole } from "../employee-roles/selector";
import { getEmployeeRole } from "../employee-roles/slice";
import { roleSagas } from "../employee-roles/sagas";

type Props = {
  employee?: any;
  changeSection: (section: number) => void;
  onBoardingReview?: boolean;
};

export const Section1 = ({ changeSection }: Props) => {
  const { control } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSectionVideComplete = useSelector(selectIsSectionVideoComplete);
  const handleCancel = () => {
    navigate(-1);
  };
  return (
    <Stack sx={{ width: "100%", backgroundColor: "#fff", borderRadius: "5px" }}>
      <Card sx={{ padding: "0px" }}>
        <CardContent
          sx={{
            padding: "0px",
            ":last-child": {
              paddingBottom: "0px",
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              paddingTop: "5px",
              paddingLeft: "20px",
              fontSize: "20px !important",
              fontWeight: 400,
            }}
            gutterBottom
          >
            Section 1 - Company Introduction Video
          </Typography>
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              paddingY: "12px",
            }}
          >
            <Box sx={{ width: "48%", minWidth: "400px" }}>
              <CardMedia
                component={"video"}
                src="https://www.w3schools.com/tags/mov_bbb.mp4"
                controls
                onEnded={() => {
                  dispatch(setVideComplete());
                }}
              />
            </Box>
          </Box>
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "40px",
              paddingY: "5px",
              height: "73px",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                navigate(PATH_DASHBOARD.employeeList);
              }}
              sx={{
                backgroundColor: "#FE6B6B",
                height: "34px",
                width: "66px",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              Cancel
            </Button>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Typography
                variant="body1"
                sx={{ fontWeight: 400, fontSize: "14px", color: "black" }}
              >
                You can go to the next section when the video is over.
              </Typography>
              <Button
                variant="outlined"
                disabled
                onClick={handleCancel}
                sx={{ height: "34px", width: "55px" }}
              >
                Back
              </Button>
              <Button
                disabled={!isSectionVideComplete}
                variant="contained"
                onClick={() => {
                  changeSection(2);
                }}
                sx={{
                  height: "34px",
                  width: "55px",
                  backgroundColor: "#0177FB",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                Next
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};

export const Section2 = ({ changeSection }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: localStorage } = useSelector(selectStoreSectionDataInLocal);

  const { control, watch } = useForm({
    defaultValues: localStorage?.[0],
  });

  return (
    <Stack sx={{ width: "100%", backgroundColor: "#fff", borderRadius: "5px" }}>
      <Card sx={{ padding: "0px" }}>
        <CardContent
          sx={{
            padding: "0px",
            ":last-child": {
              paddingBottom: "0px",
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              paddingTop: "5px",
              paddingLeft: "20px",
              fontSize: "20px !important",
              fontWeight: 400,
            }}
            gutterBottom
          >
            Section 2 - Induction Questionaire
          </Typography>
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "center",
              paddingX: "20px",
              alignItems: "center",
              paddingY: "12px",
            }}
          >
            <Box sx={{ width: "48%", minWidth: "400px" }}>
              <Label>Question1</Label>
              <Input name="question1" control={control} />
            </Box>
            <Box sx={{ width: "48%", minWidth: "400px" }}>
              <Label>Question2</Label>
              <Input name="question2" control={control} />
            </Box>
            <Box sx={{ width: "48%", minWidth: "400px" }}>
              <Label>Question3</Label>
              <Input name="question3" control={control} />
            </Box>
          </Box>
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "22px",
              paddingY: "10px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#FE6B6B",
                fontSize: "14px",
                fontWeight: 400,
              }}
              onClick={() => {
                navigate(PATH_DASHBOARD.employeeList);
              }}
            >
              Cancel
            </Button>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 400, fontSize: "14px", color: "black" }}
              >
                You must answer all questions to go to the next section.
              </Typography>
              <Button
                variant="outlined"
                onClick={() => {
                  const watchData = watch();
                  let updated;
                  if (localStorage && localStorage?.length > 0) {
                    updated = localStorage?.map((data: any, index: any) => {
                      if (index === 0) {
                        return watchData;
                      } else {
                        return data;
                      }
                    });
                  } else {
                    updated = [watchData];
                  }
                  dispatch(storeSectionDataInLocal(updated));
                  changeSection(1);
                }}
              >
                Back
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#0177FB",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
                onClick={() => {
                  const watchData = watch();
                  let updated;
                  if (localStorage?.length < 1) {
                    updated = [...localStorage, watchData];
                  } else {
                    updated =
                      localStorage && localStorage?.length > 0
                        ? localStorage?.map((data: any, index: any) => {
                            if (index === 0) {
                              return watchData;
                            } else {
                              return data;
                            }
                          })
                        : [watchData];
                  }
                  dispatch(storeSectionDataInLocal(updated));
                  changeSection(3);
                }}
              >
                Next
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};

export const Section3 = ({
  changeSection,
  employee,
  onBoardingReview,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [changeImage, setChangeImage] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { data: contractorData } = useRequestState({
    stateSelector: selectContractor,
  });

  useEffect(() => {
    dispatch(getContractor({ page: 1, limit: 1000 }));
  }, [dispatch]);

  useEffect(() => {
    if (employee?.id) {
      dispatch(getEmployee({ employeeId: employee.id }));
    }
  }, [dispatch, employee?.id]);

  const employeeDetail = useSelector(selectSelectedEmployee);

  const { data: fetchLocal } = useSelector(selectStoreSectionDataInLocal);

  const localStorage = fetchLocal?.[1];

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
    watch,
    setError,
    // reset,
  } = useForm<any>({
    resolver: yupResolver(personalDetailsSchema),
    defaultValues: localStorage,
  });

  useEffect(() => {
    if (!employeeDetail) return;

    setValue("firstName", employeeDetail.firstName ?? localStorage?.firstName);
    setValue("lastName", employeeDetail.lastName ?? localStorage?.lastName);
    setValue(
      "gender",
      employeeDetail.gender !== "Unknown"
        ? employeeDetail.gender
        : localStorage?.gender
    );
    setValue(
      "dateOfBirth",
      employeeDetail.dateOfBirth ?? localStorage?.dateOfBirth
    );
    setValue(
      "contractorName",
      employeeDetail.contractor?.id ?? localStorage?.contractorName
    );
  }, [employeeDetail, localStorage, setValue]);

  const onSubmit = (data: iUpdateProfileFormSchema) => {
    if (data.imageUpload || employeeDetail.profileUrl) {
      dispatch(
        updatePersonalDetails({
          employeeId: employee.id,
          ...data,
          dateOfBirth: data?.dateOfBirth ? data?.dateOfBirth : null,
          callback: () => changeSection(4),
        })
      );
    } else {
      setError("imageUpload", {
        type: "manual",
        message: "Profile picture is required",
      });
    }
    const watchData = watch();
    let updated;
    if (fetchLocal?.length < 2) {
      updated = [...fetchLocal, watchData];
    } else {
      // updated = Array.isArray(fetchLocal)
      //   ? fetchLocal?.map((data: any, index: any) =>
      //       index === 2 ? watchData : data
      //     )
      //   : [];
      // updated = fetchLocal?.map((data: any, index: any) => {
      //   if (index === 1) {
      //     return watchData;
      //   } else {
      //     return data;
      //   }
      // });
    }
    dispatch(storeSectionDataInLocal(updated));
  };

  const addContractorDialog = useDialog();
  const { loading } = useRequestState({
    stateSelector: selectUpdatePersonalDetails,
    successMessageShown: true,
    errorShown: true,
  });

  return (
    <Stack sx={{ width: "100%", backgroundColor: "#fff", borderRadius: "5px" }}>
      <Card sx={{ padding: "0px" }}>
        <CardContent
          sx={{
            padding: "0px",
            ":last-child": {
              paddingBottom: "0px",
            },
          }}
          component={"form"}
          onSubmit={handleSubmit(onSubmit)}
        >
          {onBoardingReview ? (
            <Box
              sx={{
                padding: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "20px !important",
                  fontWeight: 400,
                  paddingLeft: "20px",
                }}
                gutterBottom
              >
                Section 3 - Personal Details
              </Typography>

              <Button
                variant="contained"
                onClick={() => setIsEdit(!isEdit)}
                sx={{
                  backgroundColor: "#FE6B6B",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                Information Incorrect - Update or Revise
              </Button>
            </Box>
          ) : (
            <Typography
              variant="h6"
              sx={{
                paddingTop: "5px",
                paddingLeft: "20px",
                fontSize: "20px !important",
                fontWeight: 400,
              }}
              gutterBottom
            >
              Section 3 - Personal Details
            </Typography>
          )}

          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              paddingX: "13%",
              paddingY: "2%",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "400px",
                  gap: "5px",
                }}
              >
                <Label>First Name</Label>
                <Input
                  name="firstName"
                  defaultValue={employeeDetail ? employeeDetail.firstName : ""}
                  control={control}
                  placeHolder={Strings.field.inputPlaceholder("First Name")}
                  disabled={onBoardingReview && !isEdit}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "400px",
                  gap: "5px",
                }}
              >
                <Label>Last Name</Label>
                <Input
                  name="lastName"
                  control={control}
                  placeHolder={Strings.field.inputPlaceholder("Last Name")}
                  defaultValue={
                    employeeDetail
                      ? employeeDetail.lastName
                      : localStorage
                      ? localStorage?.lastName
                      : ""
                  }
                  disabled={onBoardingReview && !isEdit}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "400px",
                  gap: "5px",
                }}
              >
                <Label>Contractor Name</Label>
                <RHFSelect
                  name={"contractorName"}
                  control={control}
                  errors={errors}
                  placeholder={"Contractor"}
                  menuItemsData={contractorData?.data ?? []}
                  addButtonName={"Add New Contractor"}
                  onAddOption={() => {
                    addContractorDialog.show();
                  }}
                  disabled={onBoardingReview && !isEdit}
                  returnId={true}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "400px",
                  gap: "5px",
                }}
              >
                <Label>Date of Birth</Label>
                <RHFDatePicker
                  name="dateOfBirth"
                  control={control}
                  setValue={setValue}
                  trigger={trigger}
                  defaultValue={
                    employeeDetail.dateOfBirth
                      ? dayjs(employeeDetail.dateOfBirth, "DD/MM/YYYY")
                      : localStorage
                      ? dayjs(employeeDetail.dateOfBirth, "DD/MM/YYYY")
                      : null
                  }
                  disabled={onBoardingReview && !isEdit}
                  label={""}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "400px",
                  gap: "5px",
                }}
              >
                <Label>Gender</Label>
                <RHFSelect
                  name={"gender"}
                  control={control}
                  errors={errors}
                  placeholder={"Gender"}
                  menuItemsData={[
                    { id: "male", name: "Male" },
                    { id: "female", name: "Female" },
                    { id: "other", name: "Other" },
                  ]}
                  returnId={false}
                  disabled={onBoardingReview && !isEdit}
                />
              </Box>
            </Stack>
            <Stack
              sx={{
                height: "inherit",
                width: "100%",
                display: "flex",
                alignItems: "center",
                paddingX: "5%",
                paddingY: "2%",
              }}
            >
              <Controller
                name="imageUpload"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <>
                    <input
                      type="file"
                      accept="image/jpeg, image/png"
                      style={{ display: "none" }}
                      id="uploadProfilePics"
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          field.onChange(e.target.files[0]);
                        }
                        setChangeImage(true);
                      }}
                    />
                    {field.value || employeeDetail.profileUrl ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          position: "relative",
                          height: "100%",
                        }}
                      >
                        <Box
                          component="img"
                          src={
                            field.value
                              ? URL.createObjectURL(field.value)
                              : employeeDetail.profileUrl
                          }
                          sx={{
                            width: "400px",
                            height: "350px",
                            borderRadius: "8px",
                            display: "flex",
                            objectFit: "cover",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    ) : (
                      <>
                        <Stack
                          component="label"
                          htmlFor="uploadProfilePics"
                          sx={{
                            width: "200px",
                            height: "180px",
                            border: errors?.imageUpload?.message
                              ? "1px solid #FF0000"
                              : "1px solid #e5e7eb",
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            cursor: "pointer",
                          }}
                        >
                          <SvgColor
                            src={`/assets/icons/Upload.svg`}
                            sx={{
                              width: 80,
                              height: 80,
                              color: errors?.imageUpload?.message
                                ? "#FF0000"
                                : "#98A5B5",
                            }}
                          />
                          <Typography
                            variant="body2"
                            color={
                              errors?.imageUpload?.message
                                ? "1px solid #FF0000"
                                : "1px solid #e5e7eb"
                            }
                          >
                            Click to upload an image
                          </Typography>
                        </Stack>
                        <Stack>
                          <Typography variant="body2" color="error">
                            {errors?.imageUpload?.message &&
                              String(errors.imageUpload.message)}
                          </Typography>
                        </Stack>
                      </>
                    )}
                  </>
                )}
              />
              <Button
                component={"label"}
                htmlFor="uploadProfilePics"
                variant="contained"
                sx={{
                  width: `${changeImage ? "400px" : "200px"} `,
                  marginTop: "10px",
                  // background: "#0177FB",
                  // color: "#fff",
                  // fontWeight: "700",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "6px 16px",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                disabled={onBoardingReview && !isEdit}
              >
                {changeImage ? "Change Picture" : "Upload Picture"}
              </Button>
            </Stack>
          </Box>
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingX: "22px",
              paddingY: "10px",
              gap: 2, // Adds spacing between elements
              flexWrap: "nowrap", // Prevents breaking on smaller screens
            }}
          >
            {/* Cancel Button */}
            <Button
              variant="contained"
              onClick={() => {
                navigate(
                  onBoardingReview
                    ? PATH_DASHBOARD.onBoardingEmpList
                    : PATH_DASHBOARD.employeeList
                );
              }}
              sx={{
                backgroundColor: "#FE6B6B",
                fontSize: "14px",
                fontWeight: 400,
                minWidth: "100px",
                height: "40px",
              }}
            >
              Cancel
            </Button>

            {/* Confirmation Text - Expands to Fill Space */}
            <Typography
              variant="body1"
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: onBoardingReview ? "red" : "black",
                textAlign: "center",
                flexGrow: 1, // Makes it expand to fill space
                maxWidth: "70%", // Prevents it from getting too wide
              }}
            >
              {onBoardingReview
                ? "By Clicking “Confirm Button” You confirm that the information provided is correct to the best of your knowledge and no further amendments must be made."
                : "You must answer all questions to go to the next section."}
            </Typography>

            {/* Back Button plus Next Button */}
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                onClick={() => {
                  navigate(PATH_DASHBOARD.onBoardingEmpList);
                }}
                sx={{
                  backgroundColor: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#616E80",
                  minWidth: "100px",
                  height: "40px",
                  border: "1px solid #F1F4F7",
                }}
              >
                Back
              </Button>

              {/* Confirm Information Button */}
              <LoadingButton
                loading={loading}
                sx={{
                  backgroundColor: "#0177FB",
                  fontSize: "14px",
                  fontWeight: 400,
                  minWidth: onBoardingReview ? "180px" : "100px", // Increased width to prevent text wrapping
                  height: "40px",
                  whiteSpace: "nowrap", // Prevents text from breaking into two lines
                  paddingX: "16px", // Adds horizontal padding for better spacing
                }}
                variant="contained"
                type="submit"
              >
                {onBoardingReview ? "Confirm Information" : "Next"}
              </LoadingButton>
            </Box>
          </Box>

          {addContractorDialog.visible && (
            <AddNewContract open onClose={addContractorDialog.hide} />
          )}
        </CardContent>
      </Card>
    </Stack>
  );
};

export const Section4 = ({
  changeSection,
  employee,
  onBoardingReview,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: fetchLocal } = useSelector(selectStoreSectionDataInLocal);

  const [isEdit, setIsEdit] = useState(false);

  const localStorage = fetchLocal?.[2];
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    watch,
  } = useForm<any>({
    defaultValues: localStorage,
    resolver: yupResolver(contactDetailsSchema),
  });

  useEffect(() => {
    if (employee) {
      dispatch(getEmployee({ employeeId: employee.id }));
    }
  }, [dispatch]);

  // useEffect(() => {
  //   reset(localStorage);
  // }, [localStorage, reset]);

  const employeeDetail = useSelector(selectSelectedEmployee);
  useEffect(() => {
    if (employeeDetail) {
      const contact = employee.contactDetails;
      if (contact) {
        setValue("address", contact.address ?? "");
        setValue("city", contact.city ?? "");
        setValue("postCode", contact.postCode ?? "");
        setValue("mobileNumber", contact.mobileNumber ?? "");
        setValue("emailAddress", contact.emailAddress ?? "");
        setValue("country", contact.country ?? "");
      }
    } else if (localStorage) {
      setValue("address", localStorage.address ?? "");
      setValue("city", localStorage.city ?? "");
      setValue("postCode", localStorage.postCode ?? "");
      setValue("mobileNumber", localStorage.mobileNumber ?? "");
      setValue("emailAddress", localStorage.emailAddress ?? "");
      setValue("country", localStorage.country ?? "");
    }
  }, [employee, employeeDetail, localStorage]);

  const theme = useTheme();
  // const countryOptions = [
  //   { id: "United States of America", name: "United States of America" },
  //   { id: "Canada", name: "Canada" },
  //   { id: "India", name: "India" },
  //   { id: "United Kingdom", name: "United Kingdom" },
  //   { id: "Australia", name: "Australia" },
  //   { id: "Germany", name: "Germany" },
  //   { id: "France", name: "France" },
  //   { id: "Brazil", name: "Brazil" },
  //   { id: "Japan", name: "Japan" },
  //   { id: "China", name: "China" },
  //   { id: "South Korea", name: "South Korea" },
  //   { id: "Mexico", name: "Mexico" },
  //   { id: "South Africa", name: "South Africa" },
  //   { id: "Netherlands", name: "Netherlands" },
  //   { id: "Italy", name: "Italy" },
  //   { id: "Spain", name: "Spain" },
  //   { id: "Russia", name: "Russia" },
  //   { id: "Sweden", name: "Sweden" },
  //   { id: "Switzerland", name: "Switzerland" },
  //   { id: "Argentina", name: "Argentina" },
  //   { id: "Egypt", name: "Egypt" },
  //   { id: "New Zealand", name: "New Zealand" },
  //   { id: "Belgium", name: "Belgium" },
  //   { id: "Poland", name: "Poland" },
  //   { id: "United Arab Emirates", name: "United Arab Emirates" },
  //   { id: "Indonesia", name: "Indonesia" },
  //   { id: "Turkey", name: "Turkey" },
  //   { id: "Thailand", name: "Thailand" },
  //   { id: "Singapore", name: "Singapore" },
  //   { id: "Malaysia", name: "Malaysia" },
  //   { id: "Philippines", name: "Philippines" },
  //   { id: "Vietnam", name: "Vietnam" },
  // ];

  const { loading } = useRequestState({
    stateSelector: selectAddEmployeeContact,
    successMessageShown: true,
    errorShown: true,
  });

  const onSubmit = (data: any) => {
    const watchData = watch();
    let updated;
    if (fetchLocal?.length < 3) {
      updated = [...fetchLocal, watchData];
    } else {
      updated = Array.isArray(fetchLocal)
        ? fetchLocal.map((data: any, index: any) =>
            index === 2 ? watchData : data
          )
        : [];
      // updated = fetchLocal?.map((data: any, index: any) => {
      //   if (index === 2) {
      //     return watchData;
      //   } else {
      //     return data;
      //   }
      // });
    }
    dispatch(storeSectionDataInLocal(updated));
    if (onBoardingReview) {
      dispatch(
        addEmployeeContact({
          ...data,
          employeeId: employee?.id,
          contactDetaiId: employee?.contactDetails?.id,
          callback: () => changeSection(5),
          section: "contactDetails",
        })
      );
    } else {
      dispatch(
        addEmployeeContact({
          ...data,
          employeeId: employee?.id,
          callback: () => changeSection(5),
          section: "contactDetails",
        })
      );
    }
  };
  return (
    <Stack sx={{ width: "100%", backgroundColor: "#fff", borderRadius: "5px" }}>
      <Card sx={{ padding: "0px" }}>
        <CardContent
          sx={{
            padding: "0px",
            ":last-child": {
              paddingBottom: "0px",
            },
          }}
          component={"form"}
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* <Typography
            variant="h6"
            sx={{
              paddingTop: "5px",
              paddingLeft: "20px",
              fontSize: "20px !important",
              fontWeight: 400,
            }}
            gutterBottom
          >
            Section 4 - Contact Details
          </Typography> */}

          {onBoardingReview ? (
            <Box
              sx={{
                padding: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "20px !important",
                  fontWeight: 400,
                  paddingLeft: "20px",
                }}
                gutterBottom
              >
                Section 4 - Contact Details
              </Typography>

              <Button
                variant="contained"
                onClick={() => setIsEdit(!isEdit)}
                sx={{
                  backgroundColor: "#FE6B6B",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                Information Incorrect - Update or Revise
              </Button>
            </Box>
          ) : (
            <Typography
              variant="h6"
              sx={{
                paddingTop: "5px",
                paddingLeft: "20px",
                fontSize: "20px !important",
                fontWeight: 400,
              }}
              gutterBottom
            >
              Section 4 - Contact Details
            </Typography>
          )}

          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              paddingX: "13%",
              paddingY: "1%",
              gap: "10px",
            }}
          >
            {data.sections[0].fields.map((field) => (
              <Box
                sx={{
                  width: "48%",
                  minWidth: "400px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "3px",
                }}
              >
                <Label>{field.label}</Label>
                {field.name !== "country" && (
                  <Input
                    name={field.name}
                    control={control}
                    placeHolder={Strings.field.inputPlaceholder(
                      field.placeholder
                    )}
                    error={errors?.[field.name]?.message}
                    startIcon={
                      field.type === "date" ? (
                        <Icon
                          icon="radix-icons:calendar"
                          style={{ color: "#A9A9A9" }}
                        />
                      ) : null
                    }
                    disabled={onBoardingReview && !isEdit}
                  />
                )}
              </Box>
            ))}
          </Box>
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingX: "22px",
              paddingY: "10px",
              gap: 2, // Adds spacing between elements
              flexWrap: "nowrap", // Prevents breaking on smaller screens
            }}
          >
            {/* Cancel Button */}
            <Button
              variant="contained"
              onClick={() => {
                navigate(
                  onBoardingReview
                    ? PATH_DASHBOARD.onBoardingEmpList
                    : PATH_DASHBOARD.employeeList
                );
              }}
              sx={{
                backgroundColor: "#FE6B6B",
                fontSize: "14px",
                fontWeight: 400,
                minWidth: "100px",
                height: "40px",
              }}
            >
              Cancel
            </Button>

            {/* Confirmation Text - Expands to Fill Space */}
            <Typography
              variant="body1"
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: onBoardingReview ? "red" : "black",
                textAlign: "center",
                flexGrow: 1, // Makes it expand to fill space
                maxWidth: "70%", // Prevents it from getting too wide
              }}
            >
              {onBoardingReview
                ? "By Clicking “Confirm Button” You confirm that the information provided is correct to the best of your knowledge and no further amendments must be made."
                : "You must answer all questions to go to the next section."}
            </Typography>

            {/* Back Button */}
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#616E80",
                  minWidth: "100px",
                  height: "40px",
                  border: "1px solid #F1F4F7",
                }}
                onClick={() => {
                  const watchData = watch();
                  let updated;
                  if (fetchLocal && fetchLocal?.length < 3) {
                    updated = [...fetchLocal, watchData];
                  } else {
                    updated = Array.isArray(fetchLocal)
                      ? fetchLocal.map((data: any, index: any) =>
                          index === 2 ? watchData : data
                        )
                      : [];
                    // updated = fetchLocal?.map((data: any, index: any) => {
                    //   if (index === 2) {
                    //     return watchData;
                    //   } else {
                    //     return data;
                    //   }
                    // });
                  }

                  dispatch(storeSectionDataInLocal(updated));
                  changeSection(3);
                }}
              >
                Back
              </Button>

              {/* Confirm Information Button */}
              <LoadingButton
                loading={loading}
                sx={{
                  backgroundColor: "#0177FB",
                  fontSize: "14px",
                  fontWeight: 400,
                  minWidth: onBoardingReview ? "180px" : "100px", // Increased width to prevent text wrapping
                  height: "40px",
                  whiteSpace: "nowrap", // Prevents text from breaking into two lines
                  paddingX: "16px", // Adds horizontal padding for better spacing
                }}
                variant="contained"
                type="submit"
              >
                {onBoardingReview ? "Confirm Information" : "Next"}
              </LoadingButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};

export const Section5 = ({
  changeSection,
  employee,
  onBoardingReview,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const countryOptions = [
    { label: "United States of America", value: "USA" },
    { label: "Canada", value: "CANADA" },
    { label: "India", value: "INDIA" },
    { label: "United Kingdom", value: "UK" },
    { label: "Australia", value: "AUSTRALIA" },
    { label: "Germany", value: "GERMANY" },
    { label: "France", value: "FRANCE" },
    { label: "Brazil", value: "BRAZIL" },
    { label: "Japan", value: "JAPAN" },
    { label: "China", value: "CHINA" },
    { label: "South Korea", value: "SOUTH_KOREA" },
    { label: "Mexico", value: "MEXICO" },
    { label: "South Africa", value: "SOUTH_AFRICA" },
    { label: "Netherlands", value: "NETHERLANDS" },
    { label: "Italy", value: "ITALY" },
    { label: "Spain", value: "SPAIN" },
    { label: "Russia", value: "RUSSIA" },
    { label: "Sweden", value: "SWEDEN" },
    { label: "Switzerland", value: "SWITZERLAND" },
    { label: "Argentina", value: "ARGENTINA" },
    { label: "Egypt", value: "EGYPT" },
    { label: "New Zealand", value: "NEW_ZEALAND" },
    { label: "Belgium", value: "BELGIUM" },
    { label: "Poland", value: "POLAND" },
    { label: "United Arab Emirates", value: "UAE" },
    { label: "Indonesia", value: "INDONESIA" },
    { label: "Turkey", value: "TURKEY" },
    { label: "Thailand", value: "THAILAND" },
    { label: "Singapore", value: "SINGAPORE" },
    { label: "Malaysia", value: "MALAYSIA" },
    { label: "Philippines", value: "PHILIPPINES" },
    { label: "Vietnam", value: "VIETNAM" },
  ];
  const { data: fetchLocal } = useSelector(selectStoreSectionDataInLocal);

  const [isEdit, setIsEdit] = useState(false);

  const storeLocally = fetchLocal?.[3];

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    watch,
  } = useForm<any>({
    defaultValues: storeLocally,
    resolver: yupResolver(emergencyContactDetailsSchema),
  });

  const theme = useTheme();

  const onSubmit = (data: any) => {
    const watchData = watch();
    let updated;
    if (fetchLocal?.length < 4) {
      updated = [...fetchLocal, watchData];
    } else {
      updated = fetchLocal?.map((data: any, index: any) => {
        if (index === 3) {
          return watchData;
        } else {
          return data;
        }
      });
    }
    if (onBoardingReview && employee.emergencyContacts.length > 0) {
      dispatch(storeSectionDataInLocal(updated));
      dispatch(
        addEmployeeContact({
          ...data,
          employeeId: employee?.id,
          callback: () => changeSection(6),
          section: "emergencyContactDetails",
          emergencyContactId: employee.emergencyContacts[0]?.id,
        })
      );
    } else {
      dispatch(storeSectionDataInLocal(updated));
      dispatch(
        addEmployeeContact({
          ...data,
          employeeId: employee?.id,
          callback: () => changeSection(6),
          section: "emergencyContactDetails",
        })
      );
    }
  };

  const { loading } = useRequestState({
    stateSelector: selectAddEmployeeContact,
    successMessageShown: true,
    errorShown: true,
  });

  useEffect(() => {
    if (employee) {
      dispatch(getEmployee({ employeeId: employee.id }));
    }
  }, [dispatch]);

  const employeeDetail = useSelector(selectSelectedEmployee);
  useEffect(() => {
    if (employeeDetail) {
      const contact = employeeDetail.emergencyContacts;
      if (contact?.length > 0) {
        const lastContact = contact[contact.length - 1];
        setValue("fullName", lastContact.fullName ?? "");
        setValue("relationship", lastContact.relationship ?? "");
        setValue("address", lastContact.address ?? "");
        setValue("city", lastContact.city ?? "");
        setValue("postCode", lastContact.postCode ?? "");
        setValue("mobileNumber", lastContact.mobileNumber ?? "");
        setValue("email", lastContact.email ?? "");
      }
    } else if (storeLocally) {
      setValue("fullName", storeLocally.fullName ?? "");
      setValue("relationship", storeLocally.relationship ?? "");
      setValue("address", storeLocally.address ?? "");
      setValue("city", storeLocally.city ?? "");
      setValue("postCode", storeLocally.postCode ?? "");
      setValue("mobileNumber", storeLocally.mobileNumber ?? "");
      setValue("email", storeLocally.email ?? "");
    }
  }, [employeeDetail, employee, storeLocally]);

  return (
    <Stack sx={{ width: "100%", backgroundColor: "#fff", borderRadius: "5px" }}>
      <Card sx={{ padding: "0px" }}>
        <CardContent
          sx={{
            padding: "0px",
            ":last-child": {
              paddingBottom: "0px",
            },
          }}
          component={"form"}
          onSubmit={handleSubmit(onSubmit)}
        >
          {onBoardingReview ? (
            <Box
              sx={{
                padding: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "20px !important",
                  fontWeight: 400,
                  paddingLeft: "20px",
                }}
                gutterBottom
              >
                Section 5 - Emergency contact details
              </Typography>

              <Button
                variant="contained"
                onClick={() => setIsEdit(!isEdit)}
                sx={{
                  backgroundColor: "#FE6B6B",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                Information Incorrect - Update or Revise
              </Button>
            </Box>
          ) : (
            <Typography
              variant="h6"
              sx={{
                paddingTop: "5px",
                paddingLeft: "20px",
                fontSize: "20px !important",
                fontWeight: 400,
              }}
              gutterBottom
            >
              Section 5 - Emergency contact details
            </Typography>
          )}
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              paddingX: "13%",
              paddingY: "1%",
              gap: "10px",
            }}
          >
            {data.sections[1].fields.map((field) => (
              <Box
                sx={{
                  width: "48%",
                  minWidth: "400px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "3px",
                }}
              >
                <Label>{field.label}</Label>

                {field.name !== "country" ? (
                  <Input
                    name={field.name}
                    control={control}
                    placeHolder={Strings.field.inputPlaceholder(
                      field.placeholder
                    )}
                    error={errors?.[field.name]?.message}
                    startIcon={
                      field.type === "date" ? (
                        <Icon
                          icon="radix-icons:calendar"
                          style={{ color: "#A9A9A9" }}
                        />
                      ) : null
                    }
                    disabled={onBoardingReview && !isEdit}
                  />
                ) : (
                  <Controller
                    name={"country"}
                    control={control}
                    render={({ field }) => (
                      <FormControl>
                        <Select
                          sx={{ width: "100%" }}
                          {...field}
                          name={field.name}
                          displayEmpty
                          input={
                            <OutlinedInput error={!!errors?.country?.message} />
                          }
                          renderValue={(selected) => {
                            if (selected?.length > 0) {
                              return selected;
                            } else {
                              return (
                                <Typography
                                  sx={{
                                    color: !!errors?.country?.message
                                      ? theme.palette.error.main
                                      : theme.palette.grey[500],
                                  }}
                                >
                                  {"Country"}
                                </Typography>
                              );
                            }
                          }}
                          disabled={onBoardingReview && !isEdit}
                        >
                          {countryOptions.map((item) => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                        {!!errors?.country?.message && (
                          <FormHelperText error={!!errors}>
                            {typeof errors.country.message === "string"
                              ? errors.country.message
                              : ""}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                )}
              </Box>
            ))}
          </Box>
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingX: "22px",
              paddingY: "10px",
              gap: 2, // Adds spacing between elements
              flexWrap: "nowrap", // Prevents breaking on smaller screens
            }}
          >
            {/* Cancel Button */}
            <Button
              variant="contained"
              onClick={() => {
                navigate(
                  onBoardingReview
                    ? PATH_DASHBOARD.onBoardingEmpList
                    : PATH_DASHBOARD.employeeList
                );
              }}
              sx={{
                backgroundColor: "#FE6B6B",
                fontSize: "14px",
                fontWeight: 400,
                minWidth: "100px",
                height: "40px",
              }}
            >
              Cancel
            </Button>

            {/* Confirmation Text - Expands to Fill Space */}
            <Typography
              variant="body1"
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: onBoardingReview ? "red" : "black",
                textAlign: "center",
                flexGrow: 1, // Makes it expand to fill space
                maxWidth: "70%", // Prevents it from getting too wide
              }}
            >
              {onBoardingReview
                ? "By Clicking “Confirm Button” You confirm that the information provided is correct to the best of your knowledge and no further amendments must be made."
                : "You must answer all questions to go to the next section."}
            </Typography>

            {/* Back Button */}
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#616E80",
                  minWidth: "100px",
                  height: "40px",
                  border: "1px solid #F1F4F7",
                }}
                onClick={() => {
                  const watchData = watch();
                  let updated;
                  if (fetchLocal?.length < 4) {
                    updated = [...fetchLocal, watchData];
                  } else {
                    updated = fetchLocal?.map((data: any, index: any) => {
                      if (index === 3) {
                        return watchData;
                      } else {
                        return data;
                      }
                    });
                  }
                  dispatch(storeSectionDataInLocal(updated));
                  changeSection(4);
                }}
              >
                Back
              </Button>

              {/* Confirm Information Button */}
              <LoadingButton
                loading={loading}
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#0177FB",
                  fontSize: "14px",
                  fontWeight: 400,
                  minWidth: onBoardingReview ? "180px" : "100px", // Increased width to prevent text wrapping
                  height: "40px",
                  whiteSpace: "nowrap", // Prevents text from breaking into two lines
                  paddingX: "16px", // Adds horizontal padding for better spacing
                }}
              >
                {onBoardingReview ? "Confirm Information" : "Next"}
              </LoadingButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};

export const Section6 = ({
  changeSection,
  employee,
  onBoardingReview,
}: Props) => {
  const navigate = useNavigate();
  const filePreviewDialog = useDialog();

  const employeeDetail = useSelector(selectSelectedEmployee);

  const { data } = useSelector(selectStoreSectionDataInLocal);
  const [selectedPreviewFile, setSelectedPreviewFile] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(false);
  const dataStoreLocally = data?.[4];

  const methods = useForm<any>({
    resolver: yupResolver(identifyRightToWorkSchema),
    defaultValues: {
      nationality: dataStoreLocally?.nationality ?? "",
      hasRightToWork: dataStoreLocally?.hasRightToWork ?? "",
    },
  });

  const {
    setValue,
    formState: { errors },
    watch,
  } = methods;

  const dispatch = useDispatch();
  const theme = useTheme();
  const uploadDocumentsPopup = useDialog();

  const { loading } = useRequestState({
    stateSelector: selectAddIdentifyRightToSuccess,
    successMessageShown: true,
    errorShown: true,
  });

  const menuItem = [
    { id: "Yes", name: "Yes" },
    { id: "No", name: "No" },
  ];

  const nationalities = [
    { id: "Indian", name: "Indian" },
    { id: "American", name: "American" },
    { id: "Canadian", name: "Canadian" },
    { id: "British", name: "British" },
    { id: "Australian", name: "Australian" },
    { id: "German", name: "German" },
    { id: "French", name: "French" },
    { id: "Japanese", name: "Japanese" },
    { id: "Chinese", name: "Chinese" },
    { id: "Mexican", name: "Mexican" },
    { id: "South African", name: "South African" },
    { id: "Italian", name: "Italian" },
    { id: "Brazilian", name: "Brazilian" },
    { id: "Spanish", name: "Spanish" },
    { id: "Russian", name: "Russian" },
    { id: "Argentinian", name: "Argentinian" },
    { id: "Nigerian", name: "Nigerian" },
    { id: "Colombian", name: "Colombian" },
    { id: "Egyptian", name: "Egyptian" },
    { id: "Korean", name: "Korean" },
    { id: "Turkish", name: "Turkish" },
    { id: "Swedish", name: "Swedish" },
    { id: "Norwegian", name: "Norwegian" },
    { id: "Finnish", name: "Finnish" },
    { id: "Belgian", name: "Belgian" },
    { id: "Swiss", name: "Swiss" },
    { id: "Danish", name: "Danish" },
    { id: "Saudi Arabian", name: "Saudi Arabian" },
    { id: "Indonesian", name: "Indonesian" },
    { id: "Malaysian", name: "Malaysian" },
    { id: "Philippine", name: "Philippine" },
    { id: "Thai", name: "Thai" },
    { id: "Vietnamese", name: "Vietnamese" },
    { id: "Irish", name: "Irish" },
    { id: "Portuguese", name: "Portuguese" },
    { id: "New Zealander", name: "New Zealander" },
    { id: "Israeli", name: "Israeli" },
    { id: "Romanian", name: "Romanian" },
    { id: "Bulgarian", name: "Bulgarian" },
    { id: "Hungarian", name: "Hungarian" },
    { id: "Czech", name: "Czech" },
    { id: "Polish", name: "Polish" },
    { id: "Ukrainian", name: "Ukrainian" },
    { id: "Kenyan", name: "Kenyan" },
    { id: "Uzbekistani", name: "Uzbekistani" },
    { id: "Pakistani", name: "Pakistani" },
    { id: "Bangladeshi", name: "Bangladeshi" },
    { id: "Sri Lankan", name: "Sri Lankan" },
    { id: "Iraqi", name: "Iraqi" },
    { id: "Lebanese", name: "Lebanese" },
    { id: "Syrian", name: "Syrian" },
    { id: "Jordanian", name: "Jordanian" },
    { id: "Qatari", name: "Qatari" },
    { id: "Kuwaiti", name: "Kuwaiti" },
    { id: "Omani", name: "Omani" },
    { id: "Bahraini", name: "Bahraini" },
    { id: "Yemeni", name: "Yemeni" },
    { id: "Maldivian", name: "Maldivian" },
    { id: "Nepalese", name: "Nepalese" },
    { id: "Mongolian", name: "Mongolian" },
    { id: "Bolivian", name: "Bolivian" },
    { id: "Peruvian", name: "Peruvian" },
    { id: "Chilean", name: "Chilean" },
    { id: "Paraguayan", name: "Paraguayan" },
    { id: "Uruguayan", name: "Uruguayan" },
    { id: "Venezuelan", name: "Venezuelan" },
    { id: "Ecuadorian", name: "Ecuadorian" },
    { id: "Surinamese", name: "Surinamese" },
    { id: "Guyanese", name: "Guyanese" },
    { id: "French Guianan", name: "French Guianan" },
    { id: "Samoan", name: "Samoan" },
    { id: "Tongan", name: "Tongan" },
    { id: "Fijian", name: "Fijian" },
    { id: "Vanuatuan", name: "Vanuatuan" },
    { id: "Papua New Guinean", name: "Papua New Guinean" },
    { id: "Solomon Islander", name: "Solomon Islander" },
    { id: "New Caledonian", name: "New Caledonian" },
    { id: "Bouvet Islander", name: "Bouvet Islander" },
    { id: "Greenlandic", name: "Greenlandic" },
    { id: "Icelandic", name: "Icelandic" },
    { id: "Faroese", name: "Faroese" },
  ];

  const proofOfRightToWOrk = [
    {
      linkedDocumentName: "Right to Work.pdf",
      documentName: "Right To Work Proof",
      id: "proofOfRightToWork",
      propertyName: "proofOfRightToWorkName",
    },
  ];

  const proofOfIdentity = [
    {
      linkedDocumentName: "Id Front.pdf",
      documentName: "Proof of ID Front",
      id: "proofOfIdentityFront",
      propertyName: "proofOfIdentityFrontName",
    },
    {
      linkedDocumentName: "Id Back.pdf",
      documentName: "Proof of ID Back",
      id: "proofOfIdentityBack",
      propertyName: "proofOfIdentityBackName",
    },
  ];

  useEffect(() => {
    if (employee) {
      dispatch(getEmployee({ employeeId: employee.id }));
      dispatch(getEmployeeRole({ page: 1, limit: 1000 }));
    }
  }, [dispatch]);
  useEffect(() => {
    if (employee) {
      dispatch(getEmployeeRole({ page: 1, limit: 1000 }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (employeeDetail) {
      const identity = employeeDetail.identityAndRightToWork;
      if (identity) {
        setValue("nationality", identity.nationality);
        setValue("hasRightToWork", identity.hasRightToWork);
      }
    } else if (dataStoreLocally) {
      setValue("nationality", dataStoreLocally.nationality);
      setValue("hasRightToWork", dataStoreLocally.hasRightToWork);
    }
  }, [employeeDetail, setValue, dataStoreLocally]);

  const [isProofOfWorkPopup, setIsProofOfWorkPopup] = useState<boolean>(false);

  const onSubmit = (submittedData: any) => {
    const requiredFields = [
      "nationality",
      "hasRightToWork",
      "proofOfIdentityBack",
      "proofOfIdentityFront",
      "proofOfRightToWork",
    ];

    dispatch(
      addIdentifyToRight({
        ...submittedData,
        employeeId: employee?.id,
        callback: () => changeSection(7),
      })
    );
    const watchData = watch();
    let updated;
    if (data && data?.length < 5) {
      updated = [...data, watchData];
    } else {
      updated = data?.map((data: any, index: any) => {
        if (index === 4) {
          return watchData;
        } else {
          return data;
        }
      });
    }
    dispatch(storeSectionDataInLocal(updated));
  };

  return (
    <Stack sx={{ width: "100%", backgroundColor: "#fff", borderRadius: "5px" }}>
      <Card sx={{ padding: "0px" }}>
        <CardContent
          sx={{
            padding: "0px",
            ":last-child": {
              paddingBottom: "0px",
            },
          }}
        >
          <FormProvider methods={methods}>
            {onBoardingReview ? (
              <Box
                sx={{
                  padding: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "20px !important",
                    fontWeight: 400,
                    paddingLeft: "20px",
                  }}
                  gutterBottom
                >
                  Section 6 - Identity & Right to Work
                </Typography>

                <Button
                  variant="contained"
                  onClick={() => setIsEdit(!isEdit)}
                  sx={{
                    backgroundColor: "#FE6B6B",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  Information Incorrect - Update or Revise
                </Button>
              </Box>
            ) : (
              <Typography
                variant="h6"
                sx={{
                  paddingTop: "5px",
                  paddingLeft: "20px",
                  fontSize: "20px !important",
                  fontWeight: 400,
                }}
                gutterBottom
              >
                Section 6 - Identity & Right to Work
              </Typography>
            )}
            <Divider sx={{ borderColor: "#F1F4F7" }} />
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
                paddingX: "5%",
                paddingY: "1%",
                gap: 3,
              }}
            >
              <Stack sx={{ flex: 0.5 }}>
                <Stack>
                  <Stack
                    sx={{ flexDirection: "col", gap: 2, alignItems: "start" }}
                  >
                    <Typography variant="body1" fontWeight={500}>
                      Nationality:
                    </Typography>
                    <Stack width={"100%"}>
                      <RHFSelect
                        name={"nationality"}
                        control={methods.control}
                        errors={errors}
                        placeholder={"Select Nationality"}
                        menuItemsData={nationalities}
                        disabled={onBoardingReview && !isEdit}
                      />
                    </Stack>
                  </Stack>
                </Stack>
                <Stack mt={2}>
                  <Typography
                    sx={{
                      color: theme.palette.grey[600],
                      fontWeight: 600,
                      fontStyle: "italic",
                    }}
                  >
                    Proof of Identity
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    // border: `1px solid ${theme.palette.grey[400]}`,
                    borderRadius: 1,
                    padding: 0,
                    display: "flex",
                  }}
                >
                  {employeeDetail &&
                    employeeDetail.proofOfIdentities?.map(
                      (proof: any, index: any) => (
                        <Stack
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            // border: `1.6px solid ${theme.palette.grey[400]}`,
                            mt: 1.5,
                            borderRadius: 1,
                            gap: 1,
                            padding: 0,
                            justifyContent: "center",
                            alignItems: "flex-start",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedPreviewFile({
                              name: proof.fileName,
                              url: proof.fileUrl,
                            });
                            filePreviewDialog.show();
                          }}
                        >
                          <Stack
                            sx={{
                              display: "flex",
                              flexDirection: "col",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            {/* <Stack
                              sx={{
                                // border: `1px solid ${theme.palette.grey[400]}`,
                                width: "100%",
                                height: "auto",
                                maxHeight: "130px",
                                padding: 1,
                                lineHeight: "1.5",
                                borderRadius: "5px",
                                justifyContent: "center",
                                overflowY: "auto",
                                marginLeft: 1
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  fontStyle: "italic",
                                  fontWeight: 500,
                                  color: theme.palette.grey[700],
                                }}
                              >
                                Description
                              </Typography>
                              <Typography
                                sx={{
                                  height: "max-content",
                                  cursor: "pointer",
                                }}
                              >
                                {proof.documentName}
                              </Typography>
                            </Stack> */}
                            <Stack
                              sx={{
                                // border: `1px solid ${theme.palette.grey[400]}`,
                                flexDirection: "column",
                                justifyContent: "space-between",
                                pl: 0,
                                width: "100%",
                                borderRadius: "5px",
                                padding: 0,
                              }}
                            >
                              <Stack
                                sx={{
                                  border: `1px solid #A1A1A1`,
                                  p: 1,
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  borderRadius: "10px",
                                  ":hover": {
                                    backgroundColor: "#ECECEC",
                                  },
                                  color: " #535455",
                                  minWidth: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "90%",
                                    cursor: "pointer",
                                  }}
                                >
                                  {proof?.fileUrl &&
                                    (proof?.fileUrl?.endsWith(".jpg") ||
                                    proof?.fileUrl?.endsWith(".jpeg") ||
                                    proof?.fileUrl?.endsWith(".webp") ? (
                                      <Icon
                                        icon="line-md:image-twotone"
                                        height={"25px"}
                                        width={"25px"}
                                        style={{
                                          marginRight: "10px",
                                          color: "blue",
                                        }}
                                      />
                                    ) : proof?.fileUrl?.endsWith(".png") ? (
                                      <SvgColor
                                        src="/assets/icons/file-png.svg"
                                        style={{ marginRight: "10px" }}
                                        color="blue"
                                      />
                                    ) : proof?.fileUrl?.endsWith(".pdf") ? (
                                      <SvgColor
                                        src="/assets/icons/pdfIcon.svg"
                                        style={{ marginRight: "10px" }}
                                        color="red"
                                      />
                                    ) : (
                                      <Icon
                                        icon="bxs:file"
                                        height={"25px"}
                                        width={"25px"}
                                        style={{
                                          marginRight: "10px",
                                          color: "gray",
                                        }}
                                      />
                                    ))}

                                  <div>
                                    {proof.fileName.length > 55
                                      ? proof.fileName.substring(0, 55) + "..."
                                      : proof.fileName}
                                  </div>
                                </div>

                                {proof?.fileUrl && (
                                  <SvgColor
                                    src="/assets/icons/remove.svg"
                                    height={"40px"}
                                    width={"30px"}
                                    onClick={() =>
                                      dispatch(
                                        deleteProofOfIdentity({
                                          employeeId: employee.id,
                                          documentId: proof.id,
                                        })
                                      )
                                    }
                                    style={{
                                      marginLeft: "10px",
                                    }}
                                    color="535455"
                                  />
                                )}
                              </Stack>
                            </Stack>

                            {/* <Stack
                              sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <RowActionsIdentity
                                data={proof}
                                handleOnDelete={() =>
                                  dispatch(
                                    deleteProofOfIdentity({
                                      employeeId: employee.id,
                                      documentId: proof.id,
                                    })
                                  )
                                }
                                handleOnPreview={() => {
                                  setSelectedPreviewFile({
                                    name: proof.fileName,
                                    url: proof.fileUrl,
                                  });
                                  filePreviewDialog.show();
                                }}
                              />
                            </Stack> */}
                          </Stack>
                        </Stack>
                      )
                    )}

                  <Stack
                    sx={{
                      mt: 1.5,
                      border: `1.5px solid ${theme.palette.grey[400]}`,
                      borderRadius: "4px",
                      padding: "0px",
                    }}
                  >
                    <IconButton
                      sx={{
                        gap: 0.4,
                        alignSelf: "flex-start",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        paddingHor: 2,
                      }}
                      onClick={() => {
                        setIsProofOfWorkPopup(false);
                        if (
                          (onBoardingReview && isEdit) ||
                          (!onBoardingReview && !isEdit)
                        ) {
                          uploadDocumentsPopup.show();
                        }
                      }}
                    >
                      <SvgColor
                        src={sourceUrl.icons.upload}
                        sx={{ backgroundColor: theme.palette.grey[500] }}
                      />
                      <Typography
                        sx={{
                          color: theme.palette.grey[500],
                          fontSize: "14px",
                          marginLeft: 0.5,
                        }}
                      >
                        Upload Proof of Identity ( ID, Passport, Driving License
                        )
                      </Typography>
                    </IconButton>
                  </Stack>
                </Stack>
              </Stack>
              <Stack sx={{ flex: 0.5 }}>
                <Stack>
                  <Stack
                    sx={{ flexDirection: "col", gap: 2, alignItems: "start" }}
                  >
                    <Typography variant="body1" fontWeight={500}>
                      Do you, the operative have the right to work in uk?
                    </Typography>
                    <Stack width={"100%"}>
                      <RHFSelect
                        name={"hasRightToWork"}
                        control={methods.control}
                        errors={errors}
                        placeholder={"Select Right to Work"}
                        menuItemsData={menuItem}
                        disabled={onBoardingReview && !isEdit}
                      />
                    </Stack>
                  </Stack>
                </Stack>
                <Stack mt={2}>
                  <Typography
                    sx={{
                      color: theme.palette.grey[600],
                      fontWeight: 600,
                      fontStyle: "italic",
                      // marginBlockEnd: "0.5em",
                    }}
                  >
                    Proof of Right To Work
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    // border: `1px solid ${theme.palette.grey[400]}`,
                    borderRadius: 1,
                    padding: 0,
                  }}
                >
                  {employeeDetail &&
                    employeeDetail.proofOfRightToWork?.map(
                      (proof: any, index: any) => (
                        <Stack
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            // border: `1.6px solid ${theme.palette.grey[400]}`,
                            mt: 1.5,
                            borderRadius: 1,
                            gap: 1,
                            padding: 0,
                            justifyContent: "center",
                            alignItems: "flex-start",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedPreviewFile({
                              name: proof.fileName,
                              url: proof.fileUrl,
                            });
                            if (
                              (onBoardingReview && isEdit) ||
                              (!onBoardingReview && !isEdit)
                            ) {
                              filePreviewDialog.show();
                            }
                          }}
                        >
                          <Stack
                            sx={{
                              display: "flex",
                              flexDirection: "col",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            {/* <Stack
                              sx={{
                                border: `1px solid ${theme.palette.grey[400]}`,
                                width: "90%",
                                height: "auto",
                                maxHeight: "130px",
                                padding: 1,
                                lineHeight: "1.5",
                                borderRadius: "5px",
                                justifyContent: "center",
                                overflowY: "auto",
                                marginLeft: 1
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  fontStyle: "italic",
                                  fontWeight: 500,
                                  color: theme.palette.grey[700],
                                }}
                              >
                                Description
                              </Typography>
                              <Typography
                                sx={{
                                  height: "max-content",
                                  cursor: "pointer",
                                }}
                              >
                                {proof.documentName}
                              </Typography>
                            </Stack> */}
                            <Stack
                              sx={{
                                border: `1px solid #A1A1A1`,
                                p: 1,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderRadius: "10px",
                                ":hover": {
                                  backgroundColor: "#ECECEC",
                                },
                                color: " #535455",
                                minWidth: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "90%",
                                }}
                              >
                                {proof?.fileUrl &&
                                  (proof?.fileUrl?.endsWith(".jpg") ||
                                  proof?.fileUrl?.endsWith(".jpeg") ||
                                  proof?.fileUrl?.endsWith(".webp") ? (
                                    <Icon
                                      icon="line-md:image-twotone"
                                      height={"25px"}
                                      width={"25px"}
                                      style={{
                                        marginRight: "10px",
                                        color: "blue",
                                      }}
                                    />
                                  ) : proof?.fileUrl?.endsWith(".png") ? (
                                    <SvgColor
                                      src="/assets/icons/file-png.svg"
                                      style={{ marginRight: "10px" }}
                                      color="blue"
                                    />
                                  ) : proof?.fileUrl?.endsWith(".pdf") ? (
                                    <SvgColor
                                      src="/assets/icons/pdfIcon.svg"
                                      style={{ marginRight: "10px" }}
                                      color="red"
                                    />
                                  ) : (
                                    <Icon
                                      icon="bxs:file"
                                      height={"25px"}
                                      width={"25px"}
                                      style={{
                                        marginRight: "10px",
                                        color: "gray",
                                      }}
                                    />
                                  ))}

                                <div>
                                  {proof.fileName.length > 55
                                    ? proof.fileName.substring(0, 55) + "..."
                                    : proof.fileName}
                                </div>
                              </div>

                              {proof?.fileUrl && (
                                <SvgColor
                                  src="/assets/icons/remove.svg"
                                  height={"40px"}
                                  width={"30px"}
                                  onClick={() =>
                                    dispatch(
                                      deleteProofOfWork({
                                        employeeId: employee.id,
                                        documentId: proof.id,
                                      })
                                    )
                                  }
                                  style={{
                                    marginLeft: "10px",
                                  }}
                                  color="535455"
                                />
                              )}
                            </Stack>

                            {/* <Stack
                              sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <RowActionsWork
                                data={proof}
                                employeeDetails={employeeDetail}
                                handleOnDelete={() =>
                                  dispatch(
                                    deleteProofOfWork({
                                      employeeId: employee.id,
                                      documentId: proof.id,
                                    })
                                  )
                                }
                                handleOnPreview={() => {
                                  setSelectedPreviewFile({
                                    name: proof.fileName,
                                    url: proof.fileUrl,
                                  });
                                  filePreviewDialog.show();
                                }}
                              />
                            </Stack> */}
                          </Stack>
                        </Stack>
                      )
                    )}
                  <Stack
                    sx={{
                      mt: 1.5,
                      border: `1.5px solid ${theme.palette.grey[400]}`,
                      borderRadius: "4px",
                    }}
                  >
                    <IconButton
                      sx={{
                        gap: 0.4,
                        alignSelf: "flex-start",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        paddingHor: 2,
                      }}
                      onClick={() => {
                        setIsProofOfWorkPopup(true);
                        if (
                          (onBoardingReview && isEdit) ||
                          (!onBoardingReview && !isEdit)
                        ) {
                          uploadDocumentsPopup.show();
                        }
                      }}
                    >
                      <SvgColor
                        src={sourceUrl.icons.upload}
                        sx={{ backgroundColor: theme.palette.grey[500] }}
                      />
                      <Typography
                        sx={{
                          color: theme.palette.grey[500],
                          fontSize: "14px",
                          marginLeft: 0.5,
                        }}
                      >
                        Upload Proof for right to work in uk. ( Settlement
                        Status, Visa, British Passport){" "}
                      </Typography>
                    </IconButton>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
            <Divider sx={{ borderColor: "#F1F4F7" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingX: "22px",
                paddingY: "10px",
                gap: 2, // Adds spacing between elements
                flexWrap: "nowrap", // Prevents breaking on smaller screens
              }}
            >
              {/* Cancel Button */}
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    onBoardingReview
                      ? PATH_DASHBOARD.onBoardingEmpList
                      : PATH_DASHBOARD.employeeList
                  );
                }}
                sx={{
                  backgroundColor: "#FE6B6B",
                  fontSize: "14px",
                  fontWeight: 400,
                  minWidth: "100px",
                  height: "40px",
                }}
              >
                Cancel
              </Button>

              {/* Confirmation Text - Expands to Fill Space */}
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: onBoardingReview ? "red" : "black",
                  textAlign: "center",
                  flexGrow: 1, // Makes it expand to fill space
                  maxWidth: "70%", // Prevents it from getting too wide
                }}
              >
                {onBoardingReview
                  ? "By Clicking “Confirm Button” You confirm that the information provided is correct to the best of your knowledge and no further amendments must be made."
                  : "You must answer all questions to go to the next section."}
              </Typography>

              {/* Back Button */}
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#616E80",
                    minWidth: "100px",
                    height: "40px",
                    border: "1px solid #F1F4F7",
                  }}
                  onClick={() => {
                    const watchData = watch();
                    let updated;
                    if (data && data?.length < 5) {
                      updated = [...data, watchData];
                    } else {
                      updated = data?.map((data: any, index: any) => {
                        if (index === 4) {
                          return watchData;
                        } else {
                          return data;
                        }
                      });
                    }
                    dispatch(storeSectionDataInLocal(updated));
                    changeSection(5);
                  }}
                >
                  Back
                </Button>

                {/* Confirm Information Button */}
                <LoadingButton
                  loading={loading}
                  onClick={methods.handleSubmit(onSubmit)}
                  sx={{
                    backgroundColor: "#0177FB",
                    fontSize: "14px",
                    fontWeight: 400,
                    minWidth: onBoardingReview ? "180px" : "100px", // Increased width to prevent text wrapping
                    height: "40px",
                    whiteSpace: "nowrap", // Prevents text from breaking into two lines
                    paddingX: "16px", // Adds horizontal padding for better spacing
                  }}
                  variant="contained"
                  type="submit"
                >
                  {onBoardingReview ? "Confirm Information" : "Next"}
                </LoadingButton>
              </Box>
            </Box>
            {uploadDocumentsPopup.visible && (
              <UploadAdditionalInformation
                isProofOfWork={isProofOfWorkPopup}
                employeeId={employee.id}
                open
                onClose={uploadDocumentsPopup.hide}
              />
            )}
          </FormProvider>
        </CardContent>
      </Card>
      {filePreviewDialog.visible && (
        <FilePreviewDialog
          open
          onClose={() => {
            filePreviewDialog.hide();
          }}
          fileUrl={selectedPreviewFile && selectedPreviewFile.url}
          fileName={selectedPreviewFile && selectedPreviewFile.name}
        />
      )}
    </Stack>
  );
};

export const employeeRoles = [
  { id: "Brick Layer", name: "Brick Layer" },
  { id: "Labourer", name: "Labourer" },
  { id: "Insulation Fixer", name: "Insulation Fixer" },
  { id: "Metal Fixer", name: "Metal Fixer" },
  { id: "Scaffolder", name: "Scaffolder" },
  { id: "Manager", name: "Manager" },
  { id: "Supervisor", name: "Supervisor" },
];

export const Section7 = ({
  changeSection,
  employee,
  onBoardingReview,
}: Props) => {
  const { data: dataStored } = useSelector(selectStoreSectionDataInLocal);

  const [isEdit, setIsEdit] = useState(false);

  const dataStoredLocally = dataStored?.[5];

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(haveBeenWorkingSchema),
    defaultValues: {
      employeeRole: get(dataStoredLocally, "employeeRole"),
      tradeWorkTime: get(dataStoredLocally, "tradeWorkTime"),
    },
  });

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isCSCSDocumentUploadDialog, setIsCSCSDocumentUploadDialog] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    reset({
      employeeRole: get(
        dataStoredLocally,
        "employeeRole",
        employee?.employeeRole
      ),
      tradeWorkTime: get(
        dataStoredLocally,
        "tradeWorkTime",
        employee?.tradeWorkTime
      ),
    });
  }, [
    employee,
    reset,
    dataStoredLocally?.employeeRole,
    dataStoredLocally?.tradeWorkTime,
    dataStoredLocally,
  ]);

  useEffect(() => {
    if (employee) {
      dispatch(getEmployee({ employeeId: employee.id }));
    }
  }, [dispatch]);

  const employeeDetail = useSelector(selectSelectedEmployee);
  const employeeRolesForDropDown = useSelector(selectEmployeeRole);

  const { loading } = useRequestState({
    stateSelector: selectUpdateEmployeeAction,
    successMessageShown: true,
    errorShown: true,
  });

  const handleSaveData = () => {
    const watchData = watch();
    let updated;
    if (dataStored && dataStored?.length < 6) {
      updated = [...dataStored, watchData];
    } else {
      updated = dataStored?.map((data: any, index: any) => {
        if (index === 5) {
          return watchData;
        } else {
          return data;
        }
      });
    }
    dispatch(storeSectionDataInLocal(updated));
  };

  const onSubmit = (submittedData: any) => {
    dispatch(
      updateEmployeeAction({
        employeeId: employee.id,
        ...submittedData,
        callback: () => changeSection(8),
      })
    );
    handleSaveData();
  };
  return (
    <Stack sx={{ width: "100%", backgroundColor: "#fff", borderRadius: "5px" }}>
      <Card sx={{ padding: "0px" }}>
        <CardContent
          component={"form"}
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            padding: "0px",
            ":last-child": {
              paddingBottom: "0px",
            },
          }}
        >
          {onBoardingReview ? (
            <Box
              sx={{
                padding: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "20px !important",
                  fontWeight: 400,
                  paddingLeft: "20px",
                }}
                gutterBottom
              >
                Section 7 - Trade and Training
              </Typography>

              <Button
                variant="contained"
                onClick={() => setIsEdit(!isEdit)}
                sx={{
                  backgroundColor: "#FE6B6B",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                Information Incorrect - Update or Revise
              </Button>
            </Box>
          ) : (
            <Typography
              variant="h6"
              sx={{
                paddingTop: "5px",
                paddingLeft: "20px",
                fontSize: "20px !important",
                fontWeight: 400,
              }}
              gutterBottom
            >
              Section 7 - Trade and Training
            </Typography>
          )}
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "80%",
              margin: "auto",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "40%",
                minWidth: "200px",
                display: "flex",
                flexDirection: "column",
                gap: "3px",
              }}
            >
              <Label>Employee Role / Trade</Label>
              <RHFSelect
                name={"employeeRole"}
                control={control}
                errors={errors}
                placeholder={"Select Employee Role"}
                menuItemsData={employeeRolesForDropDown}
                disabled={onBoardingReview && !isEdit}
              />
            </Box>
            <Box
              sx={{
                width: "40%",
                minWidth: "200px",
                gap: "3px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Label>How Long Have you been working in this trade?</Label>
              <Input
                name="tradeWorkTime"
                control={control}
                error={errors.tradeWorkTime?.message}
                placeHolder="How Long Have you been working in this trade?"
                disabled={onBoardingReview && !isEdit}
              />
            </Box>
          </Box>
          <Divider sx={{ my: "10px" }} />

          <Typography
            variant="h6"
            sx={{
              paddingTop: "5px",
              paddingLeft: "20px",
              fontSize: "20px !important",
              fontWeight: 400,
            }}
            gutterBottom
          >
            Mandatory Qualifications Schemes ( MQS )
            {!employee.employeeCSCS && (
              <Button
                variant="contained"
                sx={{
                  marginInline: "20px",
                }}
                onClick={() => {
                  setIsCSCSDocumentUploadDialog(true);
                  setIsModalOpen(true);
                }}
                disabled={onBoardingReview && !isEdit}
              >
                Add MQS
              </Button>
            )}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "10px",
              justifyContent: "center",
              width: "90%",
              margin: "auto",
            }}
          >
            {employee.employeeCSCS && (
              <DocumentCard
                employeeCertification={employee?.employeeCSCS}
                isCscs={true}
                isPreview={true}
                employee={employee}
              />
            )}
          </Box>
          <Divider sx={{ my: "10px" }} />
          <Typography
            variant="h6"
            sx={{
              paddingTop: "5px",
              paddingLeft: "20px",
              fontSize: "20px !important",
              fontWeight: 400,
            }}
            gutterBottom
          >
            Other Qualification and Training
            {employee.qualificationAndCertification.length === 0 && (
              <Button
                variant="contained"
                sx={{
                  marginInline: "20px",
                }}
                onClick={() => {
                  setIsCSCSDocumentUploadDialog(false);
                  setIsModalOpen(true);
                }}
                disabled={onBoardingReview && !isEdit}
              >
                Add Additional Qualifications
              </Button>
            )}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "10px",
              justifyContent: "center",
              width: "90%",
              margin: "auto",
            }}
          >
            {employee.qualificationAndCertification.map(
              (emp: any, index: number) => {
                return (
                  <DocumentCard
                    employeeCertification={emp}
                    isCscs={false}
                    isPreview={true}
                    employee={employee}
                    key={index}
                  />
                );
              }
            )}

            {employee.qualificationAndCertification.length > 0 && (
              <Box
                sx={{
                  cursor: "pointer",
                  border: "1px solid #98A5B5",
                  width: "100%",
                  marginBlockEnd: "10px",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  icon="ei:plus"
                  width="50"
                  height="50"
                  style={{ color: "#98A5B5" }}
                  onClick={() => {
                    setIsCSCSDocumentUploadDialog(false);
                    setIsModalOpen(true);
                  }}
                />
              </Box>
            )}
          </Box>
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingX: "22px",
              paddingY: "10px",
              gap: 2, // Adds spacing between elements
              flexWrap: "nowrap", // Prevents breaking on smaller screens
            }}
          >
            {/* Cancel Button */}
            <Button
              variant="contained"
              onClick={() => {
                navigate(
                  onBoardingReview
                    ? PATH_DASHBOARD.onBoardingEmpList
                    : PATH_DASHBOARD.employeeList
                );
              }}
              sx={{
                backgroundColor: "#FE6B6B",
                fontSize: "14px",
                fontWeight: 400,
                minWidth: "100px",
                height: "40px",
              }}
            >
              Cancel
            </Button>

            {/* Confirmation Text - Expands to Fill Space */}
            <Typography
              variant="body1"
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: onBoardingReview ? "red" : "black",
                textAlign: "center",
                flexGrow: 1, // Makes it expand to fill space
                maxWidth: "70%", // Prevents it from getting too wide
              }}
            >
              {onBoardingReview
                ? "By Clicking “Confirm Button” You confirm that the information provided is correct to the best of your knowledge and no further amendments must be made."
                : "You must answer all questions to go to the next section."}
            </Typography>

            {/* Back Button */}
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#616E80",
                  minWidth: "100px",
                  height: "40px",
                  border: "1px solid #F1F4F7",
                }}
                onClick={() => {
                  handleSaveData();
                  changeSection(6);
                }}
              >
                Back
              </Button>

              {/* Confirm Information Button */}
              <LoadingButton
                loading={loading}
                sx={{
                  backgroundColor: "#0177FB",
                  fontSize: "14px",
                  fontWeight: 400,
                  minWidth: onBoardingReview ? "180px" : "100px", // Increased width to prevent text wrapping
                  height: "40px",
                  whiteSpace: "nowrap", // Prevents text from breaking into two lines
                  paddingX: "16px", // Adds horizontal padding for better spacing
                }}
                variant="contained"
                type="submit"
              >
                {onBoardingReview ? "Confirm Information" : "Next"}
              </LoadingButton>
            </Box>
          </Box>
        </CardContent>
      </Card>

      <AddAdditionalDocumentDialog
        isCscsUploadDialog={isCSCSDocumentUploadDialog}
        isModalOpen={isModalOpen}
        onModalCloseClick={() => {
          const watchData = watch();
          let updated;
          if (dataStored && dataStored?.length < 6) {
            updated = [...dataStored, watchData];
          } else {
            updated = dataStored?.map((data: any, index: any) => {
              if (index === 5) {
                return watchData;
              } else {
                return data;
              }
            });
          }
          dispatch(storeSectionDataInLocal(updated));
          setIsModalOpen(false);
        }}
        employee={employee}
        isFromTrainingMatrix={false}
      />
    </Stack>
  );
};

export const Section8 = ({
  changeSection,
  employee,
  onBoardingReview,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const departmentDialog = useDialog();
  const employmentTypeDialog = useDialog();

  const { data: valueStored } = useSelector(selectStoreSectionDataInLocal);
  const [isEdit, setIsEdit] = useState(false);
  const valueStoredLocally = valueStored?.[6];

  const methods = useForm<any>({
    resolver: yupResolver(section8Schema),
    defaultValues: {
      accountNumber: get(valueStoredLocally, "accountNumber", ""),
      bankName: get(valueStoredLocally, "bankName", ""),
      branchAddress: get(valueStoredLocally, "branchAddress", ""),
      department: get(valueStoredLocally, "id", ""),
      employmentType: get(valueStoredLocally, "employmentType.id", ""),
      nationalInsurance: get(valueStoredLocally, "nationalInsurance", ""),
      sortCode: get(valueStoredLocally, "sortCode", ""),
      uniqueTaxReferenceNumber: get(
        valueStoredLocally,
        "uniqueTaxReferenceNumber",
        ""
      ),
    },
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    reset({
      accountNumber: get(
        employee,
        "employeePayment.accountNumber",
        valueStoredLocally?.accountNumber
      ),
      bankName: get(
        employee,
        "employeePayment.bankName",
        valueStoredLocally?.bankName
      ),
      branchAddress: get(
        employee,
        "employeePayment.branchAddress",
        valueStoredLocally?.branchAddress
      ),
      department: get(
        employee,
        "department.id",
        valueStoredLocally?.department
      ),
      employmentType: get(
        employee,
        "employmentType.id",
        valueStoredLocally?.employmentType
      ),
      nationalInsurance: get(
        employee,
        "employeePayment.nationalInsurance",
        valueStoredLocally?.nationalInsurance
      ),
      sortCode: get(
        employee,
        "employeePayment.sortCode",
        valueStoredLocally?.sortCode
      ),
      uniqueTaxReferenceNumber: get(
        employee,
        "employeePayment.uniqueTaxReferenceNumber",
        valueStoredLocally?.uniqueTaxReferenceNumber
      ),
    });
  }, [
    employee,
    reset,
    valueStoredLocally?.accountNumber,
    valueStoredLocally?.bankName,
    valueStoredLocally?.branchAddress,
    valueStoredLocally?.department,
    valueStoredLocally?.employmentType,
    valueStoredLocally?.nationalInsurance,
    valueStoredLocally?.sortCode,
    valueStoredLocally?.uniqueTaxReferenceNumber,
  ]);

  const { loading } = useRequestState({
    stateSelector: selectAddPaymentDetailsSuccess,
    successMessageShown: true,
    errorShown: true,
  });

  useEffect(() => {
    dispatch(getEmployementType({ page: 1, limit: 1000 }));
    dispatch(getDepartment({ page: 1, limit: 1000 }));
  }, [dispatch]);

  const employeesType = useSelector(selectEmployementType);
  const departmentData = useSelector(selectDepartment);

  const onSubmit = (data: any) => {
    dispatch(
      addPaymentDetails({
        employeeId: employee.id,
        ...data,
        employmentTypeId: data?.employmentType,
        departmentId: data?.department,
        callback: () => changeSection(9),
        isAdd: !employee.employeePayment ? true : false,
      })
    );
    const watchData = watch();
    let updated;
    if (valueStored && valueStored?.length < 7) {
      updated = [...valueStored, watchData];
    } else {
      updated = valueStored?.map((data: any, index: any) => {
        if (index === 6) {
          return watchData;
        } else {
          return data;
        }
      });
    }
    dispatch(storeSectionDataInLocal(updated));
  };
  return (
    <Stack sx={{ width: "100%", backgroundColor: "#fff", borderRadius: "5px" }}>
      <Card sx={{ padding: "0px" }}>
        <CardContent
          component={"form"}
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            padding: "0px",
            ":last-child": {
              paddingBottom: "0px",
            },
          }}
        >
          {onBoardingReview ? (
            <Box
              sx={{
                padding: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "20px !important",
                  fontWeight: 400,
                  paddingLeft: "20px",
                }}
                gutterBottom
              >
                Section 8 - Payment Details
              </Typography>

              <Button
                variant="contained"
                onClick={() => setIsEdit(!isEdit)}
                sx={{
                  backgroundColor: "#FE6B6B",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                Information Incorrect - Update or Revise
              </Button>
            </Box>
          ) : (
            <Typography
              variant="h6"
              sx={{
                paddingTop: "5px",
                paddingLeft: "20px",
                fontSize: "20px !important",
                fontWeight: 400,
              }}
              gutterBottom
            >
              Section 8 - Payment Details
            </Typography>
          )}
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              paddingX: "13%",
              paddingY: "1%",
              gap: "10px",
            }}
          >
            {data.sections[4].fields.map((fie) => (
              <Box
                sx={{
                  width: "48%",
                  minWidth: "400px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
                key={fie.id}
              >
                <Label>{fie.label}</Label>
                {fie?.name === "employmentType" ||
                fie?.name === "department" ? (
                  <RHFSelect
                    name={fie.name}
                    control={control}
                    errors={errors}
                    placeholder={
                      fie?.name === "employmentType"
                        ? "Employment Type"
                        : "Department"
                    }
                    menuItemsData={
                      fie?.name === "employmentType"
                        ? employeesType?.data ?? []
                        : departmentData?.data ?? []
                    }
                    addButtonName={
                      fie?.name === "employmentType"
                        ? "Add New Employment Type"
                        : "Department"
                    }
                    onAddOption={() => {
                      fie?.name === "employmentType"
                        ? employmentTypeDialog.show()
                        : departmentDialog.show();
                    }}
                    returnId={true}
                    disabled={onBoardingReview && !isEdit}
                  />
                ) : fie.name == "accountNumber" || fie.name == "sortCode" ? (
                  <MaskedInput
                    formatStyle={fie.name == "sortCode" ? "dash" : "space"}
                    name={fie.name}
                    control={control}
                    error={errors?.[fie.name]?.message}
                    placeHolder={fie.placeholder}
                    startIcon={
                      fie.type === "date" ? (
                        <Icon
                          icon="radix-icons:calendar"
                          style={{ color: "#A9A9A9" }}
                        />
                      ) : null
                    }
                    disabled={onBoardingReview && !isEdit}
                  />
                ) : (
                  <Input
                    name={fie.name}
                    control={control}
                    error={errors?.[fie.name]?.message}
                    placeHolder={fie.placeholder}
                    startIcon={
                      fie.type === "date" ? (
                        <Icon
                          icon="radix-icons:calendar"
                          style={{ color: "#A9A9A9" }}
                        />
                      ) : null
                    }
                    disabled={onBoardingReview && !isEdit}
                  />
                )}
              </Box>
            ))}
          </Box>
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingX: "22px",
              paddingY: "10px",
              gap: 2, // Adds spacing between elements
              flexWrap: "nowrap", // Prevents breaking on smaller screens
            }}
          >
            {/* Cancel Button */}
            <Button
              variant="contained"
              onClick={() => {
                navigate(
                  onBoardingReview
                    ? PATH_DASHBOARD.onBoardingEmpList
                    : PATH_DASHBOARD.employeeList
                );
              }}
              sx={{
                backgroundColor: "#FE6B6B",
                fontSize: "14px",
                fontWeight: 400,
                minWidth: "100px",
                height: "40px",
              }}
            >
              Cancel
            </Button>

            {/* Confirmation Text - Expands to Fill Space */}
            <Typography
              variant="body1"
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: onBoardingReview ? "red" : "black",
                textAlign: "center",
                flexGrow: 1, // Makes it expand to fill space
                maxWidth: "70%", // Prevents it from getting too wide
              }}
            >
              {onBoardingReview
                ? "By Clicking “Confirm Button” You confirm that the information provided is correct to the best of your knowledge and no further amendments must be made."
                : "You must answer all questions to go to the next section."}
            </Typography>

            {/* Back Button */}
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#616E80",
                  minWidth: "100px",
                  height: "40px",
                  border: "1px solid #F1F4F7",
                }}
                onClick={() => {
                  const watchData = watch();
                  let updated;
                  if (valueStored && valueStored?.length < 7) {
                    updated = [...valueStored, watchData];
                  } else {
                    updated =
                      valueStored &&
                      valueStored?.length > 0 &&
                      valueStored?.map((data: any, index: any) => {
                        if (index === 6) {
                          return watchData;
                        } else {
                          return data;
                        }
                      });
                  }
                  dispatch(storeSectionDataInLocal(updated));
                  changeSection(7);
                }}
              >
                Back
              </Button>

              {/* Confirm Information Button */}
              <LoadingButton
                loading={loading}
                sx={{
                  backgroundColor: "#0177FB",
                  fontSize: "14px",
                  fontWeight: 400,
                  minWidth: onBoardingReview ? "180px" : "100px", // Increased width to prevent text wrapping
                  height: "40px",
                  whiteSpace: "nowrap", // Prevents text from breaking into two lines
                  paddingX: "16px", // Adds horizontal padding for better spacing
                }}
                variant="contained"
                type="submit"
              >
                {onBoardingReview ? "Confirm Information" : "Next"}
              </LoadingButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};

export const Section9 = ({
  changeSection,
  employee,
  onBoardingReview,
}: Props) => {
  const { medicalInformation } = employee;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: dataStore } = useSelector(selectStoreSectionDataInLocal);

  const [isEdit, setIsEdit] = useState(false);

  const dataStoreLocally = dataStore?.[7];

  const methods = useForm<any>({
    resolver: yupResolver(identifyRightToWorkSchema),
    defaultValues: dataStoreLocally,
  });
  const {
    watch,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(section9Schema),
    defaultValues: dataStoreLocally,
  });

  useEffect(() => {
    reset({
      outOfWork: get(
        employee,
        "medicalInformation.outOfWork",
        dataStoreLocally?.outOfWork
      ),
      outOfWorkDuration: get(
        employee,
        "medicalInformation.outOfWorkDuration",
        dataStoreLocally?.outOfWorkDuration
      ),
      beenInHospital: get(
        employee,
        "medicalInformation.beenInHospital",
        dataStoreLocally?.beenInHospital
      ),
      hospitalReason: get(
        employee,
        "medicalInformation.hospitalReason",
        dataStoreLocally?.hospitalReason
      ),
      hasHealthCondition: get(
        employee,
        "medicalInformation.hasHealthCondition",
        dataStoreLocally?.hasHealthCondition
      ),
      healthConditionDetails: get(
        employee,
        "medicalInformation.healthConditionDetails",
        dataStoreLocally?.healthConditionDetails
      ),
      understandsAlcoholDrugTesting: get(
        employee,
        "medicalInformation.understandsAlcoholDrugTesting",
        dataStoreLocally?.understandsAlcoholDrugTesting
      ),
    });
  }, [
    employee,
    dataStoreLocally?.beenInHospital,
    dataStoreLocally?.hasHealthCondition,
    dataStoreLocally?.healthConditionDetails,
    dataStoreLocally?.hospitalReason,
    dataStoreLocally?.outOfWork,
    dataStoreLocally?.outOfWorkDuration,
    dataStoreLocally?.understandsAlcoholDrugTesting,
    methods,
  ]);

  const { loading } = useRequestState({
    stateSelector: selectAddMedicalInformation,
    successMessageShown: true,
    errorShown: true,
  });

  const onSubmit = (data: any) => {
    dispatch(
      addMedicalInformation({
        ...data,
        employeeId: employee?.id,
        isAdd: !employee.medicalInformation ? true : false,
        callback: () => changeSection(10),
      })
    );
    const watchData = watch();
    let updated;
    if (dataStore && dataStore?.length < 8) {
      updated = [...dataStore, watchData];
    } else {
      updated = dataStore?.map((data: any, index: any) => {
        if (index === 7) {
          return watchData;
        } else {
          return data;
        }
      });
    }
    dispatch(storeSectionDataInLocal(updated));
  };

  const menuItem = [
    { id: "Yes", name: "Yes" },
    { id: "No", name: "No" },
  ];
  return (
    <Stack sx={{ width: "100%", backgroundColor: "#fff", borderRadius: "5px" }}>
      <Card sx={{ padding: "0px" }}>
        <CardContent
          sx={{
            padding: "0px",
            ":last-child": {
              paddingBottom: "0px",
            },
          }}
          component={"form"}
          onSubmit={handleSubmit(onSubmit)}
        >
          {onBoardingReview ? (
            <Box
              sx={{
                padding: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "20px !important",
                  fontWeight: 400,
                  paddingLeft: "20px",
                }}
                gutterBottom
              >
                {"Section 9 - Health & Medical History "}
              </Typography>

              <Button
                variant="contained"
                onClick={() => setIsEdit(!isEdit)}
                sx={{
                  backgroundColor: "#FE6B6B",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                Information Incorrect - Update or Revise
              </Button>
            </Box>
          ) : (
            <Typography
              variant="h6"
              sx={{
                paddingTop: "5px",
                paddingLeft: "20px",
                fontSize: "20px !important",
                fontWeight: 400,
              }}
              gutterBottom
            >
              {"Section 9 - Health & Medical History "}
            </Typography>
          )}
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              paddingX: "8%",
              paddingY: "1%",
              gap: "10px",
            }}
          >
            {data.sections[5].fields.map((fie) => (
              <Box
                sx={{
                  width: "48%",
                  minWidth: "400px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "3px",
                }}
              >
                <Label>{fie.label}</Label>
                {fie?.type === "select" ? (
                  <RHFSelect
                    name={fie.name}
                    control={control}
                    errors={errors}
                    placeholder={fie?.placeholder}
                    menuItemsData={menuItem}
                    disabled={onBoardingReview && !isEdit}
                  />
                ) : (
                  <Input
                    name={fie.name}
                    control={control}
                    placeHolder={Strings.field.inputPlaceholder("Answer")}
                    error={errors?.[fie.name]?.message}
                    startIcon={
                      fie.type == "date" ? (
                        <Icon
                          icon="radix-icons:calendar"
                          style={{ color: "#A9A9A9" }}
                        />
                      ) : null
                    }
                    disabled={onBoardingReview && !isEdit}
                  />
                )}
              </Box>
            ))}
          </Box>
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingX: "22px",
              paddingY: "10px",
              gap: 2, // Adds spacing between elements
              flexWrap: "nowrap", // Prevents breaking on smaller screens
            }}
          >
            {/* Cancel Button */}
            <Button
              variant="contained"
              onClick={() => {
                navigate(
                  onBoardingReview
                    ? PATH_DASHBOARD.onBoardingEmpList
                    : PATH_DASHBOARD.employeeList
                );
              }}
              sx={{
                backgroundColor: "#FE6B6B",
                fontSize: "14px",
                fontWeight: 400,
                minWidth: "100px",
                height: "40px",
              }}
            >
              Cancel
            </Button>

            {/* Confirmation Text - Expands to Fill Space */}
            <Typography
              variant="body1"
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: onBoardingReview ? "red" : "black",
                textAlign: "center",
                flexGrow: 1, // Makes it expand to fill space
                maxWidth: "70%", // Prevents it from getting too wide
              }}
            >
              {onBoardingReview
                ? "By Clicking “Confirm Button” You confirm that the information provided is correct to the best of your knowledge and no further amendments must be made."
                : "You must answer all questions to go to the next section."}
            </Typography>

            {/* Back Button */}
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#616E80",
                  minWidth: "100px",
                  height: "40px",
                  border: "1px solid #F1F4F7",
                }}
                onClick={() => {
                  const watchData = watch();
                  let updated;
                  if (dataStore && dataStore?.length < 8) {
                    updated = [...dataStore, watchData];
                  } else {
                    updated = dataStore?.map((data: any, index: any) => {
                      if (index === 7) {
                        return watchData;
                      } else {
                        return data;
                      }
                    });
                  }
                  dispatch(storeSectionDataInLocal(updated));
                  changeSection(8);
                }}
              >
                Back
              </Button>

              {/* Confirm Information Button */}
              <LoadingButton
                loading={loading}
                sx={{
                  backgroundColor: "#0177FB",
                  fontSize: "14px",
                  fontWeight: 400,
                  minWidth: onBoardingReview ? "180px" : "100px", // Increased width to prevent text wrapping
                  height: "40px",
                  whiteSpace: "nowrap", // Prevents text from breaking into two lines
                  paddingX: "16px", // Adds horizontal padding for better spacing
                }}
                variant="contained"
                type="submit"
              >
                {onBoardingReview ? "Confirm Information" : "Next"}
              </LoadingButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};

export const Section10 = ({
  changeSection,
  employee,
  onBoardingReview,
}: Props) => {
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    watch,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(section10Schema),
  });
  const dispatch = useDispatch();

  const { data: dataStore } = useSelector(selectStoreSectionDataInLocal);
  const dataStoreLocally = dataStore?.[8];

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    reset({
      termsAndConditions: get(
        employee,
        "employmentDeclaration.termsAndConditions",
        dataStoreLocally
      ),
      healthAndSafetyPolicy: get(
        employee,
        "employmentDeclaration.healthAndSafetyPolicy",
        dataStoreLocally
      ),
      informationAccuracy: get(
        employee,
        "employmentDeclaration.informationAccuracy",
        dataStoreLocally
      ),
      printName: get(
        employee,
        "employmentDeclaration.printName",
        dataStoreLocally
      ),
      date: onBoardingReview
        ? dayjs(employee?.employmentDeclaration?.date, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          ) || dayjs()
        : get(employee, "employmentDeclaration.date", dataStoreLocally) ||
          dayjs(),

      // date: dayjs(employee?.employmentDeclaration?.date, "YYYY-MM-DD").format("DD/MM/YYYY") || get(employee, "employmentDeclaration.date", dataStoreLocally)|| dayjs().format("DD/MM/YYYY"),
    });
  }, [employee, dataStoreLocally, reset]);

  const signaturePadRef = useRef<SignaturePad>(null);
  const navigate = useNavigate();
  const clearSignature = () => {
    signaturePadRef.current?.clear();
  };

  useEffect(() => {
    if (employee?.employmentDeclaration && signaturePadRef.current) {
      signaturePadRef.current.fromDataURL(
        employee.employmentDeclaration.signature
      );
    }
  }, [employee]);
  const { loading } = useRequestState({
    stateSelector: selectAddDeclaration,
    successMessageShown: true,
    errorShown: true,
  });

  useEffect(() => {
    if (employee) {
      setValue("printName", `${employee.firstName} ${employee.lastName}`);
    }
  }, [employee]);

  const onSubmit = (form: any) => {
    dispatch(storeSectionDataInLocal({}));
    if (signaturePadRef.current) {
      const signature = signaturePadRef.current.toDataURL();
      dispatch(
        addEmployeeDeclaration({
          ...form,
          signature,
          employeeId: employee?.id,
          date: form?.date
            ? dayjs(form?.date, "DD/MM/YYYY").toISOString()
            : null,
          callback: () => {
            dispatch(storeSectionDataInLocal({}));
            onBoardingReview
              ? changeSection(11)
              : navigate(PATH_DASHBOARD.onBoardingEmpList);
          },
        })
      );
      dispatch(storeSectionDataInLocal([]));
    }
  };

  const handleOnSubmit = (form: any) => {
    if (signaturePadRef.current) {
      const signature = signaturePadRef.current.toDataURL();
      dispatch(
        addEmployeeDeclaration({
          ...form,
          signature,
          employeeId: employee?.id,
          date: form?.date
            ? dayjs(form?.date, "DD/MM/YYYY").toISOString()
            : null,
          callback: () => {
            dispatch(storeSectionDataInLocal({}));
            onBoardingReview
              ? changeSection(11)
              : navigate(PATH_DASHBOARD.employeeList);
          },
        })
      );
      const watchData = watch();
      let updated;

      if (dataStore && dataStore?.length < 9) {
        updated = [...dataStore, watchData];
      } else {
        updated = dataStore?.map((data: any, index: any) => {
          if (index === 8) {
            return watchData;
          } else {
            return data;
          }
        });
      }

      dispatch(storeSectionDataInLocal(updated));
    }
  };

  const menuItem = [
    { id: "Yes", name: "Yes" },
    { id: "No", name: "No" },
  ];
  return (
    <Stack sx={{ width: "100%", backgroundColor: "#fff", borderRadius: "5px" }}>
      <Card sx={{ padding: "0px" }}>
        <CardContent
          sx={{
            padding: "0px",
            ":last-child": {
              paddingBottom: "0px",
            },
          }}
          component={"form"}
          onSubmit={handleSubmit(onBoardingReview ? handleOnSubmit : onSubmit)}
        >
          {onBoardingReview ? (
            <Box
              sx={{
                padding: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "20px !important",
                  fontWeight: 400,
                  paddingLeft: "20px",
                }}
                gutterBottom
              >
                Section 10 - Employment Declaration
              </Typography>

              <Button
                variant="contained"
                onClick={() => setIsEdit(!isEdit)}
                sx={{
                  backgroundColor: "#FE6B6B",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                Information Incorrect - Update or Revise
              </Button>
            </Box>
          ) : (
            <Typography
              variant="h6"
              sx={{
                paddingTop: "5px",
                paddingLeft: "20px",
                fontSize: "20px !important",
                fontWeight: 400,
              }}
              gutterBottom
            >
              Section 10 - Employment Declaration
            </Typography>
          )}
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            component="form"
            sx={{
              // display: "flex",
              // flexDirection: "row",
              // flexWrap: "wrap",
              // justifyContent: "space-between",
              paddingX: "13%",
              paddingY: "1%",
            }}
          >
            <Box
              sx={{
                width: "48%",
                minWidth: "400px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Label sx={{ fontSize: "12px", fontWeight: 400 }}>
                Have you read and agree with our terms and conditions to work
                with us?
              </Label>
              <RHFSelect
                name={"termsAndConditions"}
                control={control}
                errors={errors}
                placeholder={"Terms And Condition"}
                menuItemsData={menuItem}
                sx={{
                  height: "48px",
                  marginTop: "5px",
                }}
                disabled={onBoardingReview && !isEdit}
              />
            </Box>
            <Box
              sx={{
                width: "48%",
                minWidth: "400px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Label
                sx={{ fontSize: "12px", fontWeight: 400, marginTop: "10px" }}
              >
                Have you read and agree with our Health & Safety Policy to work
                with us?
              </Label>
              <RHFSelect
                name={"healthAndSafetyPolicy"}
                control={control}
                errors={errors}
                placeholder={"Health Safety Policy"}
                menuItemsData={menuItem}
                sx={{
                  height: "48px",
                  marginTop: "5px",
                }}
                disabled={onBoardingReview && !isEdit}
              />
            </Box>
            <Box
              sx={{
                width: "48%",
                minWidth: "400px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Label
                sx={{ fontSize: "12px", fontWeight: 400, marginTop: "10px" }}
              >
                Do you agree that all information you have provided to us are
                all accurate to the best of your knowledge?
              </Label>
              <RHFSelect
                name={"informationAccuracy"}
                control={control}
                errors={errors}
                placeholder={"Do you Agree"}
                menuItemsData={menuItem}
                sx={{
                  height: "48px",
                  marginTop: "5px",
                }}
                disabled={onBoardingReview && !isEdit}
              />
            </Box>
          </Box>
          <Divider sx={{ my: "10px" }} />

          <Typography
            variant="h6"
            sx={{
              paddingTop: "5px",
              paddingLeft: "20px",
              fontSize: "20px !important",
              fontWeight: 400,
            }}
            gutterBottom
          >
            Employee Signature
          </Typography>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "space-between",
              paddingX: "13%",
              paddingY: "1%",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                width: "48%",
                minWidth: "400px",
                display: "flex",
                flexDirection: "column",
                gap: "3px",
                mb: 2,
              }}
            >
              <Label sx={{ fontSize: "12px", fontWeight: 400 }}>
                Print Name
              </Label>
              <Input
                control={control}
                error={errors?.printName?.message}
                name="printName"
                placeHolder={Strings.field.inputPlaceholder("Print Name")}
                sx={{
                  height: "48px",
                  marginTop: "5px",
                }}
                disabled={onBoardingReview && !isEdit}
              />
            </Box>

            <Box
              sx={{
                width: "48%",
                minWidth: "400px",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Label sx={{ fontSize: "12px", fontWeight: 400 }}>
                Signature Here In Box
              </Label>
              <Box
                sx={{
                  width: "100%",
                  borderRadius: "14px",
                  border: "0.8px solid #98A5B5",
                  gap: "3px",
                }}
              >
                <SignaturePad
                  ref={signaturePadRef}
                  options={{
                    minWidth: 1,
                    maxWidth: 2,
                    penColor: "#98A5B5",
                  }}
                />
              </Box>
              {signaturePadRef.current && (
                <Button
                  variant="contained"
                  sx={{ marginTop: "8px" }}
                  onClick={clearSignature}
                  disabled={onBoardingReview && !isEdit}
                >
                  Clear Sign
                </Button>
              )}
            </Box>
            <Box
              sx={{
                width: "48%",
                minWidth: "400px",
                display: "flex",
                flexDirection: "column",
                marginTop: "4px",
                gap: "5px",
              }}
            >
              <Label>Date</Label>
              <RHFDatePicker
                name="date"
                control={control}
                setValue={setValue}
                label=""
                defaultValue={dayjs()}
                trigger={trigger}
                // disabled={true}
                disabled={onBoardingReview && !isEdit}
              />
            </Box>
          </Box>
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingX: "22px",
              paddingY: "10px",
              gap: 2, // Adds spacing between elements
              flexWrap: "nowrap", // Prevents breaking on smaller screens
            }}
          >
            {/* Cancel Button */}
            <Button
              variant="contained"
              onClick={() => {
                navigate(
                  onBoardingReview
                    ? PATH_DASHBOARD.onBoardingEmpList
                    : PATH_DASHBOARD.employeeList
                );
              }}
              sx={{
                backgroundColor: "#FE6B6B",
                fontSize: "14px",
                fontWeight: 400,
                minWidth: "100px",
                height: "40px",
              }}
            >
              Cancel
            </Button>

            {/* Confirmation Text - Expands to Fill Space */}
            <Typography
              variant="body1"
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: onBoardingReview ? "red" : "black",
                textAlign: "center",
                flexGrow: 1, // Makes it expand to fill space
                maxWidth: "70%", // Prevents it from getting too wide
              }}
            >
              {onBoardingReview
                ? "By Clicking “Confirm Button” You confirm that the information provided is correct to the best of your knowledge and no further amendments must be made."
                : "You must answer all questions to go to the next section."}
            </Typography>

            {/* Back Button */}
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#616E80",
                  minWidth: "100px",
                  height: "40px",
                  border: "1px solid #F1F4F7",
                }}
                onClick={() => {
                  const watchData = watch();
                  let updated;

                  // Ensure dataStore is always an array
                  const safeDataStore = Array.isArray(dataStore)
                    ? dataStore
                    : [];

                  if (safeDataStore.length < 9) {
                    updated = [...safeDataStore, watchData];
                  } else {
                    updated = safeDataStore.map((data: any, index: number) => {
                      return index === 8 ? watchData : data;
                    });
                  }
                  dispatch(storeSectionDataInLocal(updated));
                  changeSection(9);
                }}
              >
                Back
              </Button>

              {/* Confirm Information Button */}
              <LoadingButton
                loading={loading}
                sx={{
                  backgroundColor: "#0177FB",
                  fontSize: "14px",
                  fontWeight: 400,
                  minWidth: onBoardingReview ? "180px" : "100px", // Increased width to prevent text wrapping
                  height: "40px",
                  whiteSpace: "nowrap", // Prevents text from breaking into two lines
                  paddingX: "16px", // Adds horizontal padding for better spacing
                }}
                variant="contained"
                type="submit"
              >
                {onBoardingReview ? "Confirm Information" : "Next"}
              </LoadingButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};

export const Section11 = ({
  changeSection,
  employee,
  onBoardingReview,
}: Props) => {
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    watch,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(section11Schema),
  });
  const dispatch = useDispatch();

  const { data: dataStore } = useSelector(selectStoreSectionDataInLocal);

  const dataStoreLocally = dataStore?.[8];

  useEffect(() => {
    reset({
      termsAndConditions: get(
        dataStoreLocally,
        "employeePresentedAllInformation"
      ),
      healthAndSafetyPolicy: get(dataStoreLocally, "accurateAndValid"),
      printName: get(dataStoreLocally, "printName"),
      date: get(dataStoreLocally, "date"),
    });
  }, [dataStoreLocally, reset]);

  const signaturePadRef = useRef<SignaturePad>(null);
  const navigate = useNavigate();
  const clearSignature = () => {
    signaturePadRef.current?.clear();
  };

  const { loading } = useRequestState({
    stateSelector: selectAddDeclaration,
    successMessageShown: true,
    errorShown: true,
  });

  // useEffect(() => {
  //   if (employee) {
  //     setValue("printName", `${employee.firstName} ${employee.lastName}`);
  //   }
  // }, [employee]);

  const onSubmit = (form: any) => {
    dispatch(storeSectionDataInLocal({}));
    if (signaturePadRef.current) {
      const signature = signaturePadRef.current.toDataURL();
      dispatch(
        addEmployeeInductor({
          ...form,
          signature,
          employeeId: employee?.id,
          date: form?.date
            ? dayjs(form?.date, "DD/MM/YYYY").toISOString()
            : null,
          callback: () => {
            dispatch(storeSectionDataInLocal({}));
            navigate(PATH_DASHBOARD.onBoardingEmpList);
          },
        })
      );
      dispatch(storeSectionDataInLocal([]));
    }
  };

  const menuItem = [
    { id: "Yes", name: "Yes" },
    { id: "No", name: "No" },
  ];
  return (
    <Stack sx={{ width: "100%", backgroundColor: "#fff", borderRadius: "5px" }}>
      <Card sx={{ padding: "0px" }}>
        <CardContent
          sx={{
            padding: "0px",
            ":last-child": {
              paddingBottom: "0px",
            },
          }}
          component={"form"}
          onSubmit={handleSubmit(onSubmit)}
          // onSubmit={handleSubmit(onSubmit)}
        >
          {onBoardingReview ? (
            <Box
              sx={{
                padding: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "20px !important",
                  fontWeight: 400,
                  paddingLeft: "20px",
                }}
                gutterBottom
              >
                Section 11 - Inductor Declaration
              </Typography>

              {/* <Button
                variant="contained"
                onClick={() => {
                  navigate(PATH_DASHBOARD.onBoardingEmpList);
                }}
                sx={{
                  backgroundColor: "#FE6B6B",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                Information Incorrect - Update or Revise
              </Button> */}
            </Box>
          ) : (
            <Typography
              variant="h6"
              sx={{
                paddingTop: "5px",
                paddingLeft: "20px",
                fontSize: "20px !important",
                fontWeight: 400,
              }}
              gutterBottom
            >
              Section 11 - Inductor Declaration
            </Typography>
          )}
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            component="form"
            sx={{
              // display: "flex",
              // flexDirection: "row",
              // flexWrap: "wrap",
              // justifyContent: "space-between",
              paddingX: "13%",
              paddingY: "1%",
            }}
          >
            <Box
              sx={{
                width: "48%",
                minWidth: "400px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Label sx={{ fontSize: "12px", fontWeight: 400 }}>
                Has the employee been presented with all the information
                regarding his position including all contracts, employment
                manuals, policies or any other documents related to his
                position?
              </Label>
              <Stack width={"100%"}>
                <RHFSelect
                  name={"employeePresentedAllInformation"}
                  control={control}
                  errors={errors}
                  placeholder={"Select Answer Yes or no question"}
                  menuItemsData={menuItem}
                  // sx={{
                  //   height: "48px",
                  //   marginTop: "5px",
                  // }}
                />
              </Stack>
              {/* <Stack width={"100%"}>
                <RHFSelect
                  name={"nationality"}
                  control={control}
                  errors={errors}
                  placeholder={"Select Nationality"}
                  menuItemsData={menuItem}
                  // disabled={onBoardingReview && !isEdit}
                />
              </Stack> */}
            </Box>
            <Box
              sx={{
                width: "48%",
                minWidth: "400px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Label
                sx={{ fontSize: "12px", fontWeight: 400, marginTop: "10px" }}
              >
                Do you confirm that you have reviewed all information provided
                is accurate and valid to the best of your knowledge.
              </Label>
              <RHFSelect
                name={"accurateAndValid"}
                control={control}
                errors={errors}
                placeholder={"Yes or no question"}
                menuItemsData={menuItem}
                sx={{
                  height: "48px",
                  marginTop: "5px",
                }}
              />
            </Box>
          </Box>
          <Divider sx={{ my: "10px" }} />

          <Typography
            variant="h6"
            sx={{
              paddingTop: "5px",
              paddingLeft: "20px",
              fontSize: "20px !important",
              fontWeight: 400,
            }}
            gutterBottom
          >
            Inductor Signature
          </Typography>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "space-between",
              paddingX: "13%",
              paddingY: "1%",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                width: "48%",
                minWidth: "400px",
                display: "flex",
                flexDirection: "column",
                gap: "3px",
              }}
            >
              <Label sx={{ fontSize: "12px", fontWeight: 400 }}>
                Print Name
              </Label>
              <Input
                control={control}
                // error={errors?.printName?.message}
                name="printName"
                placeHolder={"Inductor Name"}
                sx={{
                  height: "48px",
                  marginTop: "5px",
                }}
              />
              {errors?.printName?.message && (
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "red",
                    marginTop: "4px",
                    minHeight: "18px",
                  }}
                >
                  {String(errors.printName.message)}{" "}
                  {/* Cast error message to string */}
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                width: "48%",
                minWidth: "400px",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Label sx={{ fontSize: "12px", fontWeight: 400 }}>
                Signature Here In Box
              </Label>
              <Box
                sx={{
                  width: "100%",
                  borderRadius: "14px",
                  border: "0.8px solid #98A5B5",
                  gap: "3px",
                }}
              >
                <SignaturePad
                  ref={signaturePadRef}
                  options={{
                    minWidth: 1,
                    maxWidth: 2,
                    penColor: "#98A5B5",
                  }}
                />
              </Box>
              {signaturePadRef.current && (
                <Button
                  variant="contained"
                  sx={{ marginTop: "8px" }}
                  onClick={clearSignature}
                >
                  Clear Sign
                </Button>
              )}
            </Box>
            <Box
              sx={{
                width: "48%",
                minWidth: "400px",
                display: "flex",
                flexDirection: "column",
                marginTop: "4px",
                gap: "5px",
              }}
            >
              <Label>Date</Label>
              <RHFDatePicker
                name="date"
                control={control}
                setValue={setValue}
                label=""
                defaultValue={null}
                trigger={trigger}
              />
            </Box>
          </Box>
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "22px",
              paddingY: "10px",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                navigate(
                  onBoardingReview
                    ? PATH_DASHBOARD.onBoardingEmpList
                    : PATH_DASHBOARD.employeeList
                );
              }}
              sx={{
                backgroundColor: "#FE6B6B",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              Cancel
            </Button>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Button
                variant="outlined"
                onClick={() => {
                  const watchData = watch();
                  let updated;

                  const safeDataStore = Array.isArray(dataStore)
                    ? dataStore
                    : [];

                  if (safeDataStore.length < 10) {
                    updated = [...safeDataStore, watchData];
                  } else {
                    updated = safeDataStore.map((data: any, index: number) => {
                      return index === 9 ? watchData : data;
                    });
                  }
                  dispatch(storeSectionDataInLocal(updated));
                  changeSection(10);
                }}
              >
                Back
              </Button>
              <LoadingButton
                loading={loading}
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#0177FB",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                Approve Employment
              </LoadingButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};
