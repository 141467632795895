import { QrCode } from "@mui/icons-material";

export const sourceUrl = {
  header: {
    logo: "/Logo.svg",
    logo2: "/logo2.svg",
  },
  company: {
    add_image: "/assets/images/upload_button_image.png",
    selectAllCompany: "/assets/icons/viewAllProject.svg",
  },
  sidebar: {
    companyLogo2: "/company_logo2.png",
  },
  icons: {
    mailbox: "/assets/icons/mailbox.svg",
    notificationBell: "/assets/icons/notification_bell.svg",
    addIcon: "/assets/icons/addIcon.svg",
    revisionLog: "/assets/icons/database.svg",
    reviseInfo: "/assets/icons/ReviseInfo.svg",
    exportInfo: "/assets/icons/exportnote.svg",
    trash: "/assets/icons/trash.svg",
    inducted: "/assets/icons/inducted.svg",
    number: "/assets/icons/Frame.svg",
    induction: "/assets/icons/induction.svg",
    filter: "/assets/icons/search.svg",
    delete: "/assets/icons/delete.svg",
    edit: "/assets/icons/edit.svg",
    upload: "/assets/icons/Upload.svg",
    male_profile: "/assets/images/male_profile.svg",
    female_profile: "/assets/images/female_profile.svg",
    deleteFile: "/assets/icons/deleteFile.svg",
    planIcon: "/assets/icons/planIcon.svg",
    blackCreditCard: "/assets/icons/blackCreditCard.svg",
    whiteCreditCard: "/assets/icons/whiteCreditCard.svg",
    checkIcon: "/assets/icons/check_icon.svg",
    upArrow: "/assets/icons/upArrow.svg",
    downArrow: "/assets/icons/downArrow.svg",
    cross: "/assets/icons/cross.svg",
    tick: "/assets/icons/tick.svg",
  },
};
