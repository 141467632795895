import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";

import { Input, Label } from "@app/components";
import { PATH_DASHBOARD } from "@app/routes/paths";
import { RHFDatePicker, RHFSelect } from "@app/components/hook-form";
import {
  selectAllEmployee,
  selectBookProjectSuccess,
  selectDuplicateEmployee,
} from "./selector";
import { ICheckDuplicateEmployee } from "./types";
import {
  bookProjectAction,
  checkDuplicateAction,
  removeAssignedProjectAction,
} from "./slice";
import { selectProjectData } from "../project/selectors";
import { useRequestState } from "@app/hooks/use-request-state";
import { Strings } from "@app/constants/strings";
import { useSnackbar } from "notistack";
import QrDialogue from "@app/components/qr-dialogue/QrDialogue";
import { selectCompanyId } from "../company/selectors";

const schema = yup.object().shape({
  firstName: yup.string().required("First name field is required"),
  lastName: yup.string().required("Last name field is required"),
  dateOfBirth: yup.string().required("Date of birth field is required"),
});
export const CheckDuplicateEmployee = () => {
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    trigger,
    setError,
    formState: { errors },
  } = useForm<ICheckDuplicateEmployee>({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projects = useSelector(selectProjectData);
  const [bookProject, setBookProject] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQrModalOpen, setIsQrModalOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const bookDates = watch("bookedDate");
  const projectData = watch("projectForInduction");
  const employeeData = useSelector(selectAllEmployee) || {};
  console.log("employeeData", employeeData);
  const employee = employeeData?.data?.[0]?.employee || null;
  const companyId = useSelector(selectCompanyId);

  const handleDuplicateCheck = (data: ICheckDuplicateEmployee) => {
    dispatch(
      checkDuplicateAction({
        ...data,
        dateOfBirth: data?.dateOfBirth,
      })
    );
  };

  const { data: selectDuplicate, loading } = useRequestState({
    stateSelector: selectDuplicateEmployee,
    successMessageShown: true,
    errorShown: true,
  });
  console.log("selectDuplicate", selectDuplicate);

  const handleCancel = () => {
    navigate(-1);
  };

  const { loading: checkDuplicateLoading } = useRequestState({
    stateSelector: selectBookProjectSuccess,
  });

  const handleBookInduction = () => {
    const bookDate = watch("bookedDate");
    const formattedDate = bookDate;
    // ? dayjs(bookDate, "DD/MM/YYYY").format("D MMM YYYY")
    // : "";

    if (!formattedDate) {
      setError("bookedDate", {
        type: "manual",
        message: "Booked date is required",
      });
      return;
    }

    const project = watch("projectForInduction");
    const foundProject = projects.find(
      (item: any) => (item.id ? item.name : item.id) === project
    );

    setBookProject((prev: any) => {
      const isAlreadyAdded = prev.some(
        (project: any) => project.projectId === foundProject.id
      );

      let isProjectInducted = false;
      if (selectDuplicate) {
        if (selectDuplicate?.data?.employeeProjects) {
          isProjectInducted = selectDuplicate?.data?.employeeProjects.some(
            (project: any) => project.project.id === foundProject.id
          );
        }
      }

      if (isAlreadyAdded || isProjectInducted) {
        enqueueSnackbar("Project already added!", {
          variant: "error",
        });
        return prev;
      }

      return [
        ...prev,
        {
          projectId: foundProject.id,
          projectName: foundProject.name,
          bookedDate: formattedDate,
        },
      ];
    });
    setValue("projectForInduction", null);
    setValue("bookedDate", null);
  };

  useEffect(() => {
    if (selectDuplicate && !selectDuplicate.isUserExist) {
      setIsModalOpen(true);
    }
  }, [selectDuplicate, dispatch]);

  const handleSaveBookProjects = (employeeId: string) => {
    const newBookedProject = bookProject.map((project: any) => ({
      projectId: project.projectId,
      // bookedDate: isNaN(Date.parse(project.bookedDate))? dayjs(project.bookedDate, "DD MMM YYYY") :new Date(new Date(Date.parse(project.bookedDate)).setDate(new Date(Date.parse(project.bookedDate)).getDate()+1)).toISOString(),
      bookedDate: dayjs(project.bookedDate, "DD MMM YYYY").isValid()
        ? dayjs(project.bookedDate, "DD MMM YYYY").toISOString()
        : new Date().toISOString(),
    }));

    dispatch(
      bookProjectAction({
        employeeId,
        projects: newBookedProject,
        companyId,
        navigate: () => {
          if (selectDuplicate?.isUserExist) {
            navigate(PATH_DASHBOARD.employeeList);
          } else {
            // api call for qr code generation
            setIsQrModalOpen(true);
          }
        },
      })
    );
  };
  return (
    <Stack sx={{ borderRadius: "8px", padding: "20px" }}>
      <Stack
        sx={{ width: "100%", backgroundColor: "#fff", borderRadius: "5px" }}
      >
        <Card sx={{ padding: "0px" }}>
          <CardContent
            sx={{
              padding: "0px",
              ":last-child": {
                paddingBottom: "0px",
              },
            }}
            component={"form"}
            onSubmit={handleSubmit(handleDuplicateCheck)}
          >
            <Typography
              variant="h6"
              sx={{
                paddingTop: "5px",
                paddingLeft: "20px",
                fontSize: "20px !important",
                fontWeight: 400,
              }}
              gutterBottom
            >
              Check for Duplicate
            </Typography>
            <Divider sx={{ borderColor: "#F1F4F7" }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-between",
                paddingX: "20px",
                paddingY: "12px",
              }}
            >
              <Box sx={{ width: "48%", minWidth: "400px" }}>
                <Label>First Name</Label>
                <Input
                  name="firstName"
                  control={control}
                  placeHolder={Strings.field.inputPlaceholder("First Name")}
                  error={errors.firstName?.message}
                />
              </Box>
              <Box sx={{ width: "48%", minWidth: "400px" }}>
                <Label>Last Name</Label>
                <Input
                  name="lastName"
                  control={control}
                  placeHolder={Strings.field.inputPlaceholder("Last Name")}
                  error={errors.lastName?.message}
                />
              </Box>
              <Box
                sx={{
                  width: "48%",
                  minWidth: "400px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Label>Date of Birth</Label>

                <RHFDatePicker
                  control={control}
                  setValue={setValue}
                  name="dateOfBirth"
                  label={""}
                  trigger={trigger}
                />
              </Box>
            </Box>
            <Divider sx={{ borderColor: "#F1F4F7" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingX: "22px",
                paddingY: "10px",
              }}
            >
              <Button variant="contained" color="error" onClick={handleCancel}>
                Cancel
              </Button>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Button variant="outlined" onClick={handleCancel}>
                  Back
                </Button>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                >
                  Next
                </LoadingButton>
              </Box>
            </Box>
          </CardContent>
        </Card>
        {selectDuplicate && (
          <Dialog open={isModalOpen}>
            <Stack sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "400",
                  padding: "5px",
                  lineHeight: "24px",
                }}
              >
                {selectDuplicate?.isUserExist
                  ? "Duplicate Found"
                  : "Duplicate not found"}
              </Typography>
              <Divider sx={{ color: "#F1F4F7" }} />
            </Stack>
            <DialogContent
              sx={{
                width: "600px",
                display: "flex",
                justifyContent: "center",
                padding: "5px",
              }}
            >
              <Card
                sx={{
                  width: "500px",
                  borderRadius: "8px",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  {selectDuplicate?.data?.profileUrl && (
                    <Box
                      sx={{
                        width: "223px",
                        height: "192px",
                        borderRadius: "5px",
                        border: "1px solid #98A5B5",
                        objectFit: "fill",
                      }}
                      component={"img"}
                      src={selectDuplicate?.data?.profileUrl}
                    ></Box>
                  )}

                  <Stack
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                        }}
                      >
                        Name :{" "}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                        }}
                      >
                        Last Name :{" "}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                        }}
                      >
                        Date Of Birth :{" "}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        width: "100%",
                      }}
                    >
                      <Typography
                        color="primary"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                        }}
                      >
                        {selectDuplicate?.data?.firstName}
                      </Typography>
                      <Typography
                        color="primary"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                        }}
                      >
                        {selectDuplicate?.data?.lastName}
                      </Typography>
                      <Typography
                        color="primary"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                        }}
                      >
                        {dayjs(
                          selectDuplicate?.data?.dateOfBirth,
                          "DD/MM/YYYY"
                        ).format("DD MMMM YYYY")}
                      </Typography>
                    </Box>
                  </Stack>

                  <Stack sx={{ width: "100%" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "24px",
                      }}
                    >
                      Projects inducted:
                    </Typography>
                  </Stack>
                  {!selectDuplicate?.data?.employeeProjects &&
                  bookProject.length === 0 ? (
                    <Stack
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "300",
                          fontStyle: "italic",
                          color: "#48494A",
                        }}
                      >
                        No active inductions
                      </Typography>
                    </Stack>
                  ) : (
                    <>
                      {selectDuplicate?.data?.employeeProjects &&
                        selectDuplicate?.data?.employeeProjects.map(
                          (item: any, index: number) => (
                            <Stack
                              sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                color="primary"
                                sx={{ fontSize: "16px", fontWeight: "500" }}
                              >
                                {index + 1}. {item?.project?.name}
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <Typography
                                  color="primary"
                                  sx={{ fontSize: "14px", fontWeight: "400" }}
                                >
                                  inducted:{item?.inductedDate}
                                </Typography>
                                <Typography
                                  color="error"
                                  sx={{
                                    fontSize: "13px",
                                    fontWeight: "300",
                                    cursor: "pointer",
                                    fontStyle: "italic",
                                  }}
                                  onClick={() => {
                                    dispatch(
                                      removeAssignedProjectAction(item.id)
                                    );
                                  }}
                                >
                                  Remove
                                </Typography>
                              </Box>
                            </Stack>
                          )
                        )}

                      {bookProject?.length > 0 &&
                        bookProject?.map(
                          (bookedProject: any, index: number) => (
                            <Stack
                              sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  color: "#1455B6",
                                }}
                              >
                                {index + 1}. {bookedProject?.projectName}
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "700",
                                    color: "#1455B6",
                                  }}
                                >
                                  Booked: {bookedProject?.bookedDate}
                                </Typography>
                                <Typography
                                  component={"span"}
                                  color="error"
                                  sx={{
                                    fontSize: "13px",
                                    fontWeight: "300",
                                    cursor: "pointer",
                                    fontStyle: "italic",
                                  }}
                                  onClick={() => {
                                    setBookProject((prev: any) =>
                                      prev.filter(
                                        (item: any) =>
                                          item?.projectId !=
                                          bookedProject?.projectId
                                      )
                                    );
                                  }}
                                >
                                  Remove
                                </Typography>
                              </Box>
                            </Stack>
                          )
                        )}
                    </>
                  )}
                  <Stack sx={{ width: "100%" }}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                      Book another Project induction
                    </Typography>
                  </Stack>
                  <Stack component={"form"} sx={{ width: "100%", gap: "5px" }}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Label>Select Project For Induction</Label>
                      <RHFSelect
                        name={"projectForInduction"}
                        control={control}
                        errors={errors}
                        placeholder={"Select Project For Induction"}
                        menuItemsData={projects ?? []}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Label>Select Booked Date</Label>
                      <RHFDatePicker
                        control={control}
                        setValue={setValue}
                        name="bookedDate"
                        label=""
                        disablePast={true}
                        trigger={trigger}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={handleBookInduction}
                        sx={{ borderRadius: "5px" }}
                        disabled={!bookDates || !projectData}
                      >
                        Book Induction
                      </Button>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </DialogContent>
            <Divider sx={{ borderColor: "#F1F4F7" }} />
            <Stack
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
                paddingY: "8px",
                paddingX: "12px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setIsModalOpen(false);
                  setBookProject([]);
                  setValue("projectForInduction", null);
                  setValue("bookedDate", null);
                }}
                sx={{
                  backgroundColor: "#FE6B6B",
                  borderRadius: "5px",
                  width: "66px",
                  height: "34px",
                }}
              >
                Cancel
              </Button>
              <Typography
                sx={{
                  fontSize: "15px",
                }}
              >
                Book employee for induction to enable invitation.
              </Typography>
              <LoadingButton
                loading={checkDuplicateLoading}
                disabled={
                  !selectDuplicate?.isUserExist
                    ? bookProject.length === 0
                      ? true
                      : false
                    : false
                }
                variant="contained"
                onClick={() => {
                  handleSaveBookProjects(selectDuplicate?.data?.id);
                }}
                sx={{ height: "34px", borderRadius: "24px", width: "155px" }}
              >
                Generate Invitation
              </LoadingButton>
            </Stack>
          </Dialog>
        )}
        <QrDialogue
          isOpen={isQrModalOpen}
          onClose={() => setIsQrModalOpen(false)}
          qrUrl={employee?.invitationLink}
          qrPhotoUrl={employee?.qrCodeUrl}
          onComplete={() => console.log("Complete clicked")}
          onCancel={() => navigate(PATH_DASHBOARD.employeeList)}
          employeeName={employee?.firstName + "_" + employee?.lastName}
        />
      </Stack>
    </Stack>
  );
};
