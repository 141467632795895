import { RootState } from "@app/redux/root-reducer";
import { select } from "redux-saga/effects";

export const selectDuplicateEmployee = (store: RootState) =>
  store.employeeReducer.duplicateEmployee;
export const selectAllEmployee = (store: RootState) =>
  store.employeeReducer.employee.data;
export const selectDuplicateEmployeeStatus = (store: RootState) =>
  store.employeeReducer.duplicateEmployee.status;
export const selectAllEmployeeMeta = (store: RootState) =>
  store.employeeReducer.employee.data;
export const selectSelectedEmployee = (store: RootState) =>
  store.employeeReducer.selectedEmployee.data?.employee;

export const selectSelectedEmployeeData = (store: RootState) =>
  store.employeeReducer.selectedEmployee.data;

export const selectSelectedEmployeeProjects = (store: RootState) =>
  store.employeeReducer.selectedEmployeeProjects.data;

export const selectSelectedEmployeeProjectStatus = (store: RootState) =>
  store.employeeReducer.selectedEmployeeProjects.status;

export const selectBookProjectSuccess = (store: RootState) =>
  store.employeeReducer.employee;

export const selectSelectedEmployeeEmergencyContacts = (store: RootState) =>
  store.employeeReducer.selectedEmployeeEmergencyContacts.data;

export const selectAddEmployeeContact = (store: RootState) =>
  store.employeeReducer.addEmployeeContact;

export const selectEmergencyContact = (store: RootState) =>
  store.employeeProfileReducer.emergencyContact;

export const selectAddIdentifyRightToSuccess = (store: RootState) =>
  store.employeeReducer.addIdentifyToRight;
export const selectAddPaymentDetailsSuccess = (store: RootState) =>
  store.employeeReducer.addPaymentDetails;
export const selectAddMedicalInformation = (store: RootState) =>
  store.employeeReducer.addMedicalInformation;
export const selectAddDeclaration = (store: RootState) =>
  store.employeeReducer.addEmployeeDeclaration;
export const selectUpdatePersonalDetails = (store: RootState) =>
  store.employeeReducer.updatePersonalDetails;
export const selectUpdateEmployeeAction = (store: RootState) =>
  store.employeeReducer.updateEmployee;
export const selectIsSectionVideoComplete = (store: RootState) =>
  store.employeeReducer.isSectionOneVideoComplete;
export const selectRemoveAssignedProject = (store: RootState) =>
  store.employeeReducer.removeAssignProject;
export const selectStoreSectionDataInLocal = (store: RootState) =>
  store.employeeReducer.storeSectionDataLocal;

export const selectDeleteCscsRecord = (store: RootState) =>
  store.employeeReducer.deleteCscs;

export const selectDeleteQualification = (store: RootState) =>
  store.employeeReducer.deleteQualification;

export const selectEmplooyeeDocument = (store: RootState) =>
  store.employeeReducer.selectedEmployee;
export const selectAddProofOfWork = (store: RootState) =>
  store.employeeReducer.addProofOfWork;

export const selectAddEmployeeCscs = (store: RootState) =>
  store.employeeReducer.addEmployeeCscs;
export const selectAddEmployeeQualification = (store: RootState) =>
  store.employeeReducer.addEmployeeQualification;

export const selectOnboardingAllEmployee = (store: RootState) =>
  store.employeeReducer.onboardingEmployee.data;

export const selectOnboardingAllEmployeeMeta = (store: RootState) =>
  store.employeeReducer.onboardingEmployee.data;
