import { all, call, put, takeLatest } from "redux-saga/effects";

import { request } from "@app/services";
import {
  addKnowledgeBase,
  addKnowledgeBaseFailed,
  addKnowledgeBaseSuccess,
  addNewCategory,
  addNewCategoryFailed,
  addNewCategorySuccess,
  deleteCategory,
  deleteCategoryFailed,
  deleteCategorySuccess,
  deleteKnowledgeBase,
  deleteKnowledgeBaseFailed,
  deleteKnowledgeBaseSuccess,
  getCategories,
  getCategoriesFailed,
  getCategoriesSuccess,
  getKnowledgeBase,
  getKnowledgeBaseCategory,
  getKnowledgeBaseCategoryFailed,
  getKnowledgeBaseCategorySuccess,
  getKnowledgeBaseFailed,
  getKnowledgeBaseSuccess,
} from "./slice";
import * as endpoints from "./endpoints";
import { remove } from "lodash";

function* addKnowledgeBaseSaga(action: any): any {
  try {
    const {
      heading,
      revisionNumber,
      date,
      description,
      categoryId,
      files,
      id,
      removeFiles,
    } = action.payload;
    let result;
    const formData = new FormData();
    formData.append("heading", heading);
    formData.append("revisionNumber", revisionNumber);
    formData.append("date", date);
    formData.append("description", description);
    formData.append("categoryId", categoryId);

    if (Array.isArray(removeFiles) && removeFiles.length) {
      removeFiles.forEach(({ fileUrl }: any) => fileUrl && formData.append("removeFiles", fileUrl));
    }

    if (Array.isArray(files) && files.length) {
      const removeFileUrls = new Set(
        removeFiles?.map(({ fileUrl }: any) => fileUrl?.trim()).filter(Boolean)
      );

      files
        .filter(({ fileUrl }: any) => !fileUrl || !removeFileUrls.has(fileUrl.trim()))
        .forEach((file: any) => formData.append("files", file));
    }

    if (id) {
      result = yield call(
        request.put,
        `${endpoints.updateKnowledgeBase}${id}`,
        formData
      );
    } else {
      result = yield call(request.post, endpoints.addKnowledgeBase, formData);
    }

    yield put(addKnowledgeBaseSuccess(result));
  } catch (err) {
    yield put(addKnowledgeBaseFailed(err));
  }
}


function* addNewCategorySaga(action: any): any {
  try {
    const { name, companyId, id } = action.payload;
    let result;
    if (id) {
      result = yield call(request.put, endpoints.updateCategory + id, {
        name,
        companyId,
      });
    } else {
      result = yield call(request.post, endpoints.addNewCategory, {
        name,
        companyId,
      });
    }
    yield put(addNewCategorySuccess(result.message));
    yield put(getCategories({ page: 1, companyId }));
  } catch (err: any) {
    yield put(addNewCategoryFailed(err));
  }
}
//
function* getCategoriesSaga(action: ReturnType<typeof getCategories>): any {
  const { page, limit = 100, companyId } = action.payload;
  try {
    const result = yield call(
      request.get,
      endpoints.getCategories + companyId,
      {
        params: {
          limit,
          page,
        },
      }
    );
    yield put(getCategoriesSuccess(result.data ? result.data.data : null));
  } catch (err: any) {
    yield put(getCategoriesFailed(err));
  }
}
//
function* getKnowledgeBaseSaga(
  action: ReturnType<typeof getKnowledgeBase>
): any {
  const { companyId } = action.payload;
  try {
    const result = yield call(
      request.get,
      endpoints.getKnowledgeBase + companyId
    );
    yield put(getKnowledgeBaseSuccess(result.data));
  } catch (err: any) {
    yield put(getKnowledgeBaseFailed(err));
  }
}
//
function* getKnowledgeBaseCategorySaga(
  action: ReturnType<typeof getKnowledgeBaseCategory>
): any {
  const { categoryId } = action.payload;
  try {
    const result = yield call(
      request.get,
      endpoints.getKnowledgeBaseCategory + categoryId
    );
    yield put(getKnowledgeBaseCategorySuccess(result.data));
  } catch (err: any) {
    yield put(getKnowledgeBaseCategoryFailed(err));
  }
}
//
function* deleteKnowledgeBaseSaga(action: any): any {
  try {
    const { id, companyId } = action.payload;

    const result = yield call(
      request.delete,
      endpoints.deleteKnowledgeBaseArticle + id
    );

    yield put(deleteKnowledgeBaseSuccess(result.message));
    yield put(getKnowledgeBase({ companyId }));
  } catch (err: any) {
    yield put(deleteKnowledgeBaseFailed(err));
  }
}
//
function* deleteCategorySaga(action: any): any {
  try {
    const { id, companyId } = action.payload;

    const result = yield call(
      request.delete,
      endpoints.deleteKnowledgeBase + id
    );

    yield put(deleteCategorySuccess(result.message));
    yield put(getCategories({ page: 1, companyId }));
  } catch (err: any) {
    yield put(deleteCategoryFailed(err));
  }
}
//
export function* knowledgeSaga() {
  yield all([
    takeLatest(addKnowledgeBase, addKnowledgeBaseSaga),
    takeLatest(addNewCategory, addNewCategorySaga),
    takeLatest(getCategories, getCategoriesSaga),
    takeLatest(getKnowledgeBase, getKnowledgeBaseSaga),
    takeLatest(getKnowledgeBaseCategory, getKnowledgeBaseCategorySaga),
    takeLatest(deleteCategory, deleteCategorySaga),
    takeLatest(deleteKnowledgeBase, deleteKnowledgeBaseSaga),
  ]);
}
