import React, { useState } from "react";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import AppTableHeadCell from "./AppTableHeadCell";
import UpDownIcon from "./UpDownIcon";
import { RowActions } from "./row-action";

interface Props {
  data: any;
}

const DesignationTable = ({ data }: Props) => {
  const [selectedRows, setSelectedRows] = useState<readonly string[]>([]);
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data?.data.map((n: any) => n.id);
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
  };

  const handleSelectSingleDesignationCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string | number
  ) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, id.toString()]);
      return;
    }
    setSelectedRows((prev) => prev.filter((item) => item !== id));
  };

  return (
    <TableContainer
      sx={{
        backgroundColor: "#FFFFFF",
        marginTop: "10px",
        borderRadius: "0px",
        borderTopRightRadius: "5px",
        minHeight: "600px",
        borderTopLeftRadius: "5px",
        "&::-webkit-scrollbar": {
          display: "none", // Chrome, Safari, Edge
        },
      }}
    >
      <Table sx={{ minWidth: 650 }}>
        <TableHead sx={{ borderBottom: "1px", borderBottomColor: "red" }}>
          <TableRow>
            <TableCell
              width={"20px"}
              padding="checkbox"
              sx={{ backgroundColor: "#fff" }}
            >
              {data.data && (
                <Checkbox
                  color="primary"
                  checked={
                    data?.data.length > 0 &&
                    selectedRows.length === data.data.length
                  }
                  onChange={handleSelectAllClick}
                />
              )}
            </TableCell>
            <AppTableHeadCell
              cellProps={{
                sx: {
                  minWidth: "170px",
                  padding: "0px 12px",
                  background: "#fff",
                  fontWeight: 400,
                },
              }}
              typographyProps={{
                sx: {
                  fontWeight: 400,
                  fontSize: "14px",
                },
              }}
              title="Designation ID"
              icon={null}
            />

            <AppTableHeadCell
              cellProps={{
                sx: {
                  minWidth: "270px",
                  padding: "0px 12px",
                  background: "#fff",
                },
              }}
              typographyProps={{
                sx: {
                  fontWeight: 400,
                  fontSize: "14px",
                },
              }}
              title="Designation Name"
              icon={null}
            />
            <AppTableHeadCell
              cellProps={{
                sx: {
                  minWidth: "470px",
                  padding: "0px 12px",
                  background: "#fff",
                },
              }}
              typographyProps={{
                sx: {
                  fontWeight: 400,
                  fontSize: "14px",
                },
              }}
              title="Parent Designation"
            />
            <AppTableHeadCell
              cellProps={{
                sx: {
                  minWidth: "20px",
                  padding: "0px 12px",
                  background: "#fff",
                },
                align: "left",
              }}
              typographyProps={{
                sx: {
                  fontWeight: 400,
                  fontSize: "14px",
                },
              }}
              title="Actions"
            />
          </TableRow>
        </TableHead>

        <TableBody>
          {data.data && data.data.length > 0 ? (
            data.data.map((item: any) => (
              <TableRow key={item.id}>
                <TableCell width={"20px"} padding="checkbox">
                  <Checkbox
                    checked={selectedRows.includes(item.id)}
                    size="small"
                    sx={{ color: "#616E80" }}
                    inputProps={{ "aria-label": "select all desserts" }}
                    onChange={(e) =>
                      handleSelectSingleDesignationCheck(e, item.id)
                    }
                  />
                </TableCell>
                <TableCell width={"20px"}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      textWrap: "nowrap",
                    }}
                  >
                    {item.id}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      textWrap: "nowrap",
                    }}
                  >
                    {item.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    {item.parentDesignation ? item.parentDesignation.name : "-"}
                  </Typography>
                </TableCell>
                <TableCell align="left" width={"20px"}>
                  <RowActions data={item} />
                </TableCell>
              </TableRow>
            ))
          ) : (
            // No Designation Found Row
            <TableRow>
              <TableCell colSpan={5} align="center">
                <Typography
                  sx={{ fontSize: "14px", fontWeight: "500", color: "gray" }}
                >
                  No Designation Found
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DesignationTable;
