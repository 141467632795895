import React, { useCallback } from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
  Button,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";

interface RHFSelectProps {
  name: string;
  control: any;
  errors?: any;
  placeholder?: string;
  menuItemsData: Array<{ id: any; name: string }>;
  onAddOption?: () => void;
  sx?: object;
  addButtonName?: string;
  size?: any;
  disabled?: boolean;
  returnId?: boolean;
}

const RHFSelect: React.FC<RHFSelectProps> = ({
  name,
  control,
  errors,
  placeholder = "Select an option",
  menuItemsData = [],
  onAddOption,
  addButtonName,
  sx = {},
  size = "14",
  disabled,
  returnId,
}) => {
  const theme = useTheme();

  // Determine if this field should have a default first item
  const shouldSetDefaultValue = name === "contractorName" && returnId === true;

  // Get default value memoized
  const getDefaultValue = useCallback(() => {
    if (shouldSetDefaultValue && menuItemsData?.length > 0) {
      return returnId ? menuItemsData[0].id : menuItemsData[0].name;
    }
    return "";
  }, [shouldSetDefaultValue, menuItemsData, returnId]);

  // Create add button click handler
  const handleAddButtonClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onAddOption?.();
    },
    [onAddOption]
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={getDefaultValue()}
      render={({ field }) => {
        // Set default value if needed
        if (
          shouldSetDefaultValue &&
          !field.value &&
          menuItemsData?.length > 0
        ) {
          // Using setTimeout to avoid React state update during render
          setTimeout(() => {
            field.onChange(
              returnId ? menuItemsData[0].id : menuItemsData[0].name
            );
          }, 0);
        }

        // Find selected item
        const selectedItem = field.value
          ? menuItemsData.find((item) =>
              returnId ? item.id === field.value : item.name === field.value
            )
          : null;

        return (
          <FormControl fullWidth>
            <Select
              {...field}
              name={field.name}
              displayEmpty
              error={!!errors?.[name]?.message}
              sx={{
                borderRadius: 0.5,
                ...sx,
                "& .MuiSelect-icon": {
                  color: "#535455",
                  borderRadius: "4px",
                },
              }}
              size={size}
              value={field.value || ""}
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <Typography
                      sx={{
                        color: errors?.[name]?.message
                          ? theme.palette.error.main
                          : theme.palette.grey[500],
                      }}
                    >
                      {placeholder}
                    </Typography>
                  );
                }
                return (
                  selectedItem?.name ||
                  (returnId ? "" : selected) ||
                  placeholder
                );
              }}
              disabled={disabled}
            >
              {menuItemsData.length > 0 ? (
                menuItemsData.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={returnId ? item.id : item.name}
                  >
                    {item.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>
                  <Typography>No options available</Typography>
                </MenuItem>
              )}

              {onAddOption && menuItemsData.length === 0 && (
                <Box display="flex" justifyContent="center" width="100%">
                  <Button
                    startIcon={<AddIcon />}
                    sx={{ border: "none" }}
                    variant="outlined"
                    size="small"
                    onClick={handleAddButtonClick}
                  >
                    {addButtonName}
                  </Button>
                </Box>
              )}
            </Select>

            {errors?.[name]?.message && (
              <FormHelperText error>
                {String(errors[name].message)}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export { RHFSelect };
