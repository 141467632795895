import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Icon } from "@iconify/react";

interface StepItem {
  stepIndex: number;
  title: string;
  isComplete: boolean;
}

type Props = {
  steps: StepItem[];
};

export const Stepper: React.FC<Props> = ({ steps }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const stepWidth =
    steps.length > 5
      ? "90px"
      : isMobile
      ? "100px"
      : isTablet
      ? "120px"
      : "150px";

  const getLastStepWidth = () => {
    if (steps.length > 5) {
      return isMobile ? "40px" : isTablet ? "50px" : "60px";
    }
    return isMobile ? "50px" : isTablet ? "60px" : "70px";
  };

  const getLastStepMargin = () => {
    if (isMobile) return "0.5rem";
    if (isTablet) return "1rem";
    return "1.5rem";
  };

  const getLastTitleSpacing = () => {
    if (isMobile) return "-2rem";
    if (isTablet) return "-2.5rem";
    return "1rem";
  };

  return (
    <Box
      sx={{
        width: "100%",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
          height: "6px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#888",
          borderRadius: "3px",
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{
          minWidth: "max-content",
          p: 2,
          height: "auto",
        }}
      >
        {steps.map((item, index) => (
          <Box
            key={item.stepIndex}
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              width:
                index === steps.length - 1 ? getLastStepWidth() : stepWidth,
              alignItems: "center",
              mt: index === 0 ? 2.5 : 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                position: "relative",
              }}
            >
              {index === 0 ? (
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <Box
                    component={"div"}
                    sx={{
                      width: "50%",
                      height: "4px",
                      backgroundColor: item.isComplete ? "#9FD9A7" : "#F35454",
                      transition: "background-color 0.3s ease",
                    }}
                  />
                  <Box
                    sx={{
                      borderRadius: "50%",
                      border: "1.8px solid",
                      borderColor: item.isComplete ? "#9FD9A7" : "#F35454",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "white",
                      transition: "all 0.3s ease",
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                      zIndex: 2,
                      "&:hover": {
                        transform: "translateX(-50%) scale(1.1)",
                      },
                    }}
                  >
                    <Icon
                      icon="lets-icons:check-fill"
                      width="24"
                      height="24"
                      color={item.isComplete ? "#9FD9A7" : "#F35454"}
                    />
                  </Box>
                  <Box
                    component={"div"}
                    sx={{
                      width: "50%",
                      height: "4px",
                      backgroundColor: item.isComplete ? "#9FD9A7" : "#F35454",
                      transition: "background-color 0.3s ease",
                    }}
                  />
                </Box>
              ) : (
                <>
                  {index !== 0 && (
                    <Box
                      component={"div"}
                      sx={{
                        width: "100%",
                        height: "4px",
                        backgroundColor: steps[index - 1].isComplete
                          ? "#9FD9A7"
                          : "#F35454",
                        transition: "background-color 0.3s ease",
                      }}
                    />
                  )}
                  <Box
                    sx={{
                      borderRadius: "50%",
                      border: "1.8px solid",
                      borderColor: item.isComplete ? "#9FD9A7" : "#F35454",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "white",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                  >
                    <Icon
                      icon="lets-icons:check-fill"
                      width="24"
                      height="24"
                      color={item.isComplete ? "#9FD9A7" : "#F35454"}
                    />
                  </Box>
                  {index !== steps.length - 1 && (
                    <Box
                      component={"div"}
                      sx={{
                        width: "100%",
                        height: "4px",
                        backgroundColor: item.isComplete
                          ? "#9FD9A7"
                          : "#F35454",
                        transition: "background-color 0.3s ease",
                      }}
                    />
                  )}
                </>
              )}
            </Box>
            <Typography
              sx={{
                color: item.isComplete ? "#9FD9A7" : "#F35454",
                textAlign: "center",
                mt: index === 0 ? 2.5 : 1,
                fontWeight: "500",
                fontSize: isMobile ? "11px" : "13px",
                whiteSpace: "nowrap",
                transition: "color 0.3s ease",
                ...(index === steps.length - 1 && {
                  ml: getLastStepMargin(),
                  mr: getLastTitleSpacing(),
                  position: "relative",
                  left: "1rem",
                }),
              }}
            >
              {item.title}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
