export const changePassword = "/user/password";
export const changeProfilePicture = "/user/upload-profile-picture";
export const getUser = "/user";
export const updateCompanyProfile = "/company/update/";
export const createCompanyDocuments = "/company";

export const getGeneralSettings = "/general-settings/";
export const getProjectUsers = "/employee/project";
export const getOnboardingQuestionnaire = "/questionnaire/";
// General List endpoints
export const postGeneralList = "/company-general-list";
export const getGeneralListByCompany = "/company-general-list/company/";
export const deleteGeneralListById = "/company-general-list/";
//comapny users endpoints 
export const getCompanyUsers = "/company"; 
export const createUser = "/user";  // created comapny user
export const deleteCompanyUser = '/user/company/';
export const updateCompanyUser = '/user/company/';

// system users endpoints 

export const getSystemUsers = "/user/myusers";

export const deleteSystemUser = '/user/myuser';
