import {
  FileCopy,
  Handshake,
  MonetizationOn,
  Pending,
  Timer,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { TimeClock } from "@mui/x-date-pickers";
import { MonthlySalesTable } from "./SalesTable";
import SalesCard from "./SalesCard";

export const AdminSales = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",

        gap: 3,
        bgcolor: "#F8FAFC",
        p: 3,
        height: "100vh",
      }}
    >
      <Box sx={{ display: "flex", gap: 3, width: "100%" }}>
        <SalesCard
          title="Invoice"
          values={[
            { label: "Paid Invoices", value: 15, color: "text-blue-500" },
            { label: "Due Invoices", value: 5, color: "text-red-500" },
          ]}
          icon={<FileCopy fontSize="large" />}
        />
        <SalesCard
          title="Finance"
          values={[
            { label: "Total Expenses", value: 45, color: "text-blue-500" },
            { label: "Total Earning", value: 340, color: "text-red-500" },
          ]}
          icon={<MonetizationOn fontSize="large" />}
        />
        <SalesCard
          title="Total Pending Amount"
          values={[{ label: "Total ", value: 7, color: "text-blue-500" }]}
          icon={<Pending fontSize="large" />}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 3,
          flexGrow: 1,
        }}
      >
        <MonthlySalesTable />
      </Box>
    </Box>
  );
};
