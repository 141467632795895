export const addKnowledgeBase = "/knowledge-base/article";
export const addNewCategory = "/knowledge-base";
export const getCategories = "/knowledge-base/company/";
export const getKnowledgeBase = '/knowledge-base/articles/company/'
export const getKnowledgeBaseCategory = "/knowledge-base/articles/category/"
export const updateCategory = "/knowledge-base/";
export const updateKnowledgeBase = "/knowledge-base/article/";
export const deleteKnowledgeBase = "/knowledge-base/"
export const deleteKnowledgeBaseArticle = '/knowledge-base/article/'

