import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
    getEmployeeRole,
    getEmployeeRoleFailed,
    getEmployeeRoleSuccess,
    addEmployeeRoleAction
    ,
    deleteEmployeeRole,
    deleteEmployeeRoleSuccess,
    deleteEmployeeRoleFailed,
    addEmployeeRoleSuccessAction

} from "./slice";
import { request } from "@app/services";
import * as endpoint from "./endpoints";
import { selectCompanyId } from "../company/selectors";
import { selectEmployeeRole } from "./selector";

function* fetchEmployeeRole(action: ReturnType<typeof getEmployeeRole>): any {
    try {
        const companyId: string = yield select(selectCompanyId);
        console.log("companyId", companyId);
        
        const { page, limit } = action.payload;
        if (companyId) {
            const { data } = yield call(
                request.get,
                `${endpoint.employeeRoleByCompanyId}${companyId}?page=${page}&limit=${limit}`
            );
            yield put(getEmployeeRoleSuccess({ data }));
        }
    } catch (error: any) {
        yield put(getEmployeeRoleFailed(error));
    }
}

function* addEmployeeRole(action: ReturnType<typeof addEmployeeRoleAction>): any {
    try {
        const { callback, id, ...rest } = action.payload;
        const companyId: string = yield select(selectCompanyId);
        if (id) {
            const response = yield call(request.put, endpoint.deleteEmployeeRole + id, {
                companyId,
                ...rest,
            });
            yield put(getEmployeeRole({ page: 1, limit: 1000 }));
            yield put(addEmployeeRoleSuccessAction(response));
        } else {
            const response = yield call(request.post, `${endpoint.employeeRole}`, {
                companyId,
                ...rest,
            });

            yield put(getEmployeeRole({ page: 1, limit: 1000 }));
            yield put(addEmployeeRoleSuccessAction(response));
        }

        callback();
    } catch (error: any) {
        yield put(getEmployeeRoleFailed);
    }
}
function* deleteRoleSagas(
    action: ReturnType<typeof deleteEmployeeRole>
): any {
    try {
        const { id } = action.payload;

        const result = yield call(request.delete, endpoint.deleteEmployeeRole + id);

        const existingDepartment = yield select(selectEmployeeRole);
        const updatedData = {
            ...existingDepartment,
            data: existingDepartment?.data?.filter((depart: any) => depart.id !== id),
        };
        // yield put(getEmployeeRoleSuccess({ data: updatedData }));

        yield put(deleteEmployeeRoleSuccess(result.message));
        yield put(getEmployeeRole({ page: 1, limit: 1000 }));
    } catch (err: any) {
        yield put(deleteEmployeeRoleFailed(err));
    }
}
export function* roleSagas() {
    yield all([
        takeLatest(getEmployeeRole, fetchEmployeeRole),
        takeLatest(addEmployeeRoleAction, addEmployeeRole),
        takeLatest(deleteEmployeeRole, deleteRoleSagas),
    ]);
}
