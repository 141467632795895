import { Company } from "@app/modules/company";

export interface CompanyTableProps {
  data: Company[];
}

export const columns = [
  { id: "checkbox", width: "40px" },
  { id: "index", label: "#", width: "40px" },
  { id: "logo", label: "Preview", width: "120px" },
  { id: "name", label: "Company Name" },
  { id: "dateCreated", label: "Date Created" },
  { id: "status", label: "Company Status" },
  { id: "actions", label: "Action", width: "80px", align: "center" },
];

export interface CompanyProfileForm {
  name: string;
  registered_address: string;
  company_type: string;
  company_number: string;
  main_contact: string;
  company_email: string;
  company_phone: string;
  companyLogo: File | null;
  // companyBigLogo: File | null;
  companySmallLogo: File | null;
  registered_address_lat: number;
  registered_address_lng: number;
}

export const companyDocumentColumns = [
  { id: "checkbox", width: "40px" },
  { id: "Doc Ref", label: "Document Ref" },
  { id: "name", label: "Document Name" },
  { id: "description", label: "Description" },
  { id: "documentSize", label: "File Size" },
  { id: "actions", label: "Action Buttons", width: "80px", align: "center" },
  { id: "action", label: "Action", width: "80px", align: "center" },
];
export const projectDataColumns = [
  { id: "checkbox", width: "40px" },
  { id: "#", label: "#" },
  { id: "preview", label: "Preview" },
  { id: "projectName", label: "Project Name" },
  { id: "users", label: "users" },
  { id: "createdAt", label: "Date Created" },
  { id: "status", label: "Company Status" },
  { id: "actions", label: "Action", width: "80px", align: "center" },
  // { id: "action", label: "Action", width: "80px", align: "center" },
];

export const userDataColumns = [
  { id: "checkbox", width: "40px" },
  { id: "User Ref", label: "User Ref", width: "40px" },
  { id: "status", label: "User Status" },
  { id: "name", label: "User Name" },
  { id: "email", label: "Email Address" },
  { id: "company", label: "Copany Assigned" },
  { id: "project", label: "Project Assigned" },
  { id: "permission level", label: "Permission Level" },
  { id: "billable", label: "Billable" },
  { id: "action", label: "Action", width: "80px", align: "center" },
];

export const projectUserDataColumnns = [
  { id: "checkbox", width: "40px" },

  { id: "userRef", label: "User Ref" },
  { id: "status", label: "User Status" },
  { id: "name", label: "User Name" },
  { id: "dateOfBirth", label: "Date of Birth" },
  { id: "email", label: "Email Address" },
  { id: "action", label: "Action", width: "80px", align: "center" },
];

export interface ProjectProfileForm {
  name: string;
  registered_address: string;
  client_name: string;
  client_email: string;
  client_phone: string;
  imageUrl: File | null;
}

export type CustomReferencingFormValues = {
  employeeId: string;
  onboardingWaitingList: string;
  inductionBookings: string;
  contractorsId: string;
  qualificationId: string;
  generalListId: string;
  defaultListId: string;
  description: string;
  employmentStatusItems: StatusItem[];
  inductionStatusItems: StatusItem[];
  checkForDuplicates: any[];
};

export interface StatusItem {
  status_name: string;
  color: string;
  valueType: "default" | "automatic" | "manual";
  changeValue?: string;
}
