import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { request } from "@app/services";
import * as endpoints from "./endpoints";
import {
  addProject,
  addProjectFailed,
  addProjectSuccess,
  deleteSelectedProject,
  getProjects,
  getProjectsFailed,
  getProjectsSuccess,
} from "./slice";
import { selectProjectData } from "./selectors";

function* createProject(action: ReturnType<typeof addProject>): any {
  try {
    const { companyId, imageUpload, projectName } = action.payload;
    const formData = new FormData();
    const projectData = yield select(selectProjectData);

    formData.append("name", projectName);
    formData.append("file", imageUpload);
    const { data } = yield call(
      request.post,
      `${endpoints.company}/${companyId}${endpoints.projects}`,
      formData
    );
    const newProjectdata = [...projectData, data];
    console.log("nre ----", newProjectdata);
    yield put(getProjectsSuccess({ data: newProjectdata }));
  } catch (error: any) {
    yield put(addProjectFailed(error));
  }
}

function* fetchProjects(action: ReturnType<typeof getProjects>) {
  try {
    const { companyId } = action.payload;
    if (companyId) {
      const { data } = yield call(
        request.get,
        `${endpoints.company}/${companyId}${endpoints.projects}`
      );
      yield put(getProjectsSuccess({ data }));
    }
  } catch (error: any) {
    yield put(getProjectsFailed(error));
  }
}

function* deleteProject(action: any): any {
  try {
    const data = yield call(
      request.delete,
      `${endpoints.projectDelete}/${action.payload}`
    );
    const projects = yield select(selectProjectData);
    if (data.status === true) {
      const updatedProjects = projects.filter(
        (project: any) => project.id !== action.payload
      );
      yield put(getProjectsSuccess({ data: updatedProjects }));
    } else {
      yield put(getProjectsSuccess({ data: projects }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* projectSaga() {
  yield all([
    takeLatest(addProject, createProject),
    takeLatest(getProjects, fetchProjects),
    takeLatest(deleteSelectedProject, deleteProject),
  ]);
}

export { projectSaga };
