// @mui
import { Theme } from "@mui/material/styles";
import {
  Box,
  Switch,
  SxProps,
  Button,
  Typography,
  Select,
  MenuItem,
  Pagination,
  PaginationItem,
  SelectChangeEvent,
} from "@mui/material";
import UpDownIcon from "./UpDownIcon";
import { ChangeEvent } from "react";

// ----------------------------------------------------------------------

interface Props {
  rowPerPageOptions?: number[];
  onPageDenseChange: (event: SelectChangeEvent<number | string>) => void;
  currentPage?: number;
  totalPage?: number;
  itemsPerPage?: number;
  totalItems?: number;
  showingCustomText?: string;
  sx?: SxProps;
  onPageChange?: (event: ChangeEvent<unknown>, value: number) => void;
}

export default function TablePaginationCustom({
  rowPerPageOptions = [10, 20, 30, 40, 50],
  onPageDenseChange,
  currentPage,
  totalItems,
  itemsPerPage,
  totalPage,
  sx,
  showingCustomText,
  onPageChange,
}: Props) {
  return (
    <Box
      sx={{
        position: "relative",
        padding: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "0px 4px 10px #00000040",
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          Show
        </Typography>
        <Select
          defaultValue={10}
          sx={{
            border: "1px solid #F1F4F7",
            borderRadius: "5px",
            height: "35px",
          }}
          onChange={onPageDenseChange}
          IconComponent={() => <UpDownIcon />}
        >
          {rowPerPageOptions.map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          items
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {showingCustomText ?? ""}
        </Typography>
        <Pagination
          count={totalPage}
          siblingCount={1}
          boundaryCount={2}
          onChange={onPageChange}
          showLastButton={false}
          renderItem={(item) => {
            if (item.type === "previous") {
              return (
                <Button
                  onClick={item.onClick}
                  disabled={item.disabled}
                  sx={{
                    color: "#616E80",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #F1F4F7",
                    fontSize: "13px",
                    fontWeight: "400",
                    marginRight: "4px",
                  }}
                >
                  Previous
                </Button>
              );
            }

            if (item.type === "next") {
              return (
                <Button
                  onClick={item.onClick}
                  disabled={item.disabled}
                  sx={{
                    color: "#616E80",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #F1F4F7",
                    marginLeft: "4px",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  Next
                </Button>
              );
            }
            if (item.page != null && item.page != 10)
              return (
                <Button
                  onClick={item.onClick}
                  disabled={item.disabled}
                  sx={{
                    color: item.selected ? "#FFFFFF" : "#616E80",
                    backgroundColor: item.selected ? "#0177FB" : "#FFFFFF",
                    border: "1px solid #F1F4F7",
                    marginLeft: "3px",
                    minWidth: "30px",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  {item.page}
                </Button>
              );
          }}
        />
      </Box>
      {/* <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                labelRowsPerPage="Show"
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    "& .MuiTablePagination-spacer": {
                        display: "none"
                    },
                    "& .MuiTablePagination-toolbar": {
                        display: "flex",
                        width: "100%",
                        "& .MuiTablePagination-selectLabel": {
                            fontSize: "14px",
                            fontWeight: "600"
                        }
                    }

                }}
                slo
                slotProps={{
                    select: {
                        defaultValue: 20,
                        sx: {
                            border: "1px solid #F1F4F7",
                            padding: "5px",
                            borderRadius: "7px"
                        },
                        IconComponent: () => <UpDownIcon />
                    },
                    actions: {
                        nextButton: {
                            component: ((props) => <Button
                                variant='outlined'
                                onClick={props.onClick}
                                sx={{
                                    color: "#616E80",
                                    backgroundColor: "#FFFFFF",
                                    borderColor: "#616E80"
                                }}>
                                Next
                            </Button>)
                        },
                        previousButton: {
                            component: ((props) => <Button
                                variant='outlined'
                                onClick={props.onClick}
                                sx={{
                                    color: "#616E80",
                                    backgroundColor: "#FFFFFF",
                                    borderColor: "#616E80"
                                }}>
                                Previous
                            </Button>)
                        },

                    }
                }}
                {...other}
            /> */}
    </Box>
  );
}
