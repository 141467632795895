import React from 'react';
import { Grid, Paper, Box, Typography } from '@mui/material';

const contractData = [
  { 
    title: 'Total Contracts', 
    count: 5, 
    value: '£ 6,600,580.00', 
    paid: '£ 275,000.00', 
    icon: <img src="/assets/icons/Vector.png" alt="Total Contracts" width={40} height={40} />
    
  },
  { 
    title: 'Ongoing Contracts', 
    count: 1, 
    value: '£ 6,600,580.00', 
    paid: '£ 275,000.00', 
    icon: <img src="/assets/icons/Group.png" alt="Ongoing Contracts" width={40} height={40} />,
    background: '#E6FFDA'  // Green background
  },
  { 
    title: 'Contracts on Hold', 
    count: 1, 
    value: '£ 6,600,580.00', 
    paid: '£ 275,000.00', 
    icon: <img src="/assets/icons/Group.png" alt="Inactive Contracts" width={40} height={40} />,
    background: '#FFEBEB'  
  },
  { 
    title: 'Closed Contracts', 
    count: 3, 
    value: '£ 13,650,580.00', 
    paid: '£ 300,000.00', 
    icon: <img src="/assets/icons/Group.png" alt="Cancelled Contracts" width={40} height={40} />
  }
];

export const ContractCards = () => {
  const getBackgroundColor = (title: string) => {
    switch (title) {
      case 'Ongoing Contracts':
        return '#E6FFDA';
      case 'Contracts on Hold':
        return '#FFEBEB';
      default:
        return 'white';
    }
  };

  const getCountColor = (title: string) => {
    switch (title) {
      case 'Total Contracts':
        return '#4084EF';
      case 'Closed Contracts':
        return '#000000';  
      case 'Ongoing Contracts':
        return '#14AE5C'; 
      case 'Contracts on Hold':
        return '#FB0000';
      default:
        return 'inherit';
    }
  };

  const getValueColor = (title: string) => {
    switch (title) {
      case 'Total Contracts':
        return '#4084EF';
      case 'Closed Contracts':
        return '#000000';  
      case 'Ongoing Contracts':
        return '#14AE5C';

      case 'Contracts on Hold':
        return '#FB0000';
      default:
        return 'text.secondary';
    }
  };

  return (
    <Grid container spacing={2} sx={{ mb: 3 }}>
      {contractData.map((contract, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Paper sx={{ 
            p: 2, 
            borderRadius: 1,
            bgcolor: getBackgroundColor(contract.title)
          }}>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
              {contract.title}
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'flex-start'
            }}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 2 
              }}>
                <Typography 
                  variant="h4" 
                  sx={{ 
                    color: getCountColor(contract.title),
                    minWidth: '30px',
                    fontWeight: 700
                  }}
                >
                  {contract.count}
                </Typography>
                <Box>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: getValueColor(contract.title),
                      fontWeight: 700
                    }}
                  >
                    {contract.value}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: getValueColor(contract.title),
                      fontWeight: 700
                    }}
                  >
                    {contract.paid}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ ml: 2 }}>
                {contract.icon}
              </Box>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};
