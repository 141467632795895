import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import AppTableHeadCell from "@app/components/table/AppTableHeadCell";
import TablePaginationCustom from "@app/components/table/TablePagination";
import { SvgColor } from "@app/components/svg-color";
import { sourceUrl } from "@app/constants/source_url";
import { useDialog } from "@app/hooks/use-dialog";
import { useRequestState } from "@app/hooks/use-request-state";
import {
  selectEmergencyContact,
  selectSelectedEmployeeEmergencyContacts,
} from "../add_employee/selector";
import { AddEmergencyContact } from "./AddEmergencyContact";
import { RowActions } from "./RowActions";
import { getEmployeeEmergencyContact } from "../add_employee/slice";
import { removeEmergencyContact } from "./slice";

const EmergencyDetails = ({ edit = false }: any) => {
  const addEmergencyContactDialog = useDialog();
  const dispatch = useDispatch();
  const [pageValue, setPageValue] = useState(1);
  const [pageLimitValue, setPageLimitValue] = useState<number | string>(10);
  const employeeEmergencyContact = useSelector(
    selectSelectedEmployeeEmergencyContacts
  );

  useRequestState({
    stateSelector: selectEmergencyContact,
    successMessageShown: true,
    errorShown: true,
  });

  useEffect(() => {
    dispatch(
      getEmployeeEmergencyContact({
        page: pageValue,
        limit: pageLimitValue,
      })
    );
  }, [dispatch]);
  return (
    <>
      {edit && (
        <Button
          variant="contained"
          sx={{
            width: "235px",
            height: "34px",
            mt: 1,
            textWrap: "nowrap",
            fontSize: "14px",
            fontWeight: "400",
          }}
          startIcon={<SvgColor src={sourceUrl.icons.addIcon} />}
          onClick={() => {
            addEmergencyContactDialog.show();
          }}
        >
          Add New Emergency Contact
        </Button>
      )}
      <TableContainer
        sx={{
          backgroundColor: "#FFFFFF",
          marginTop: "10px",
          borderRadius: "0px",
          borderTopRightRadius: "5px",
          borderTopLeftRadius: "5px",
          minHeight: "600px",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead sx={{ borderBottom: "1px" }}>
            <TableRow>
              <TableCell
                width={"100px"}
                sx={{
                  backgroundColor: "#fff",
                  padding: "0px 12px",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                  fontWeight: 500,
                  borderLeft: "0.5px solid #F1F4F7",
                }}
              >
                <Checkbox
                  color="primary"
                  size="small"
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
                #
              </TableCell>
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "300px",
                    padding: "0px 12px",
                    background: "#fff",
                    fontSize: "14px",
                    fontWeight: 500,
                    borderLeft: "0.5px solid #F1F4F7",
                  },
                }}
                title="Name"
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "200px",
                    padding: "0px 12px",
                    background: "#fff",
                    fontSize: "14px",
                    fontWeight: 500,
                    borderLeft: "0.5px solid #F1F4F7",
                  },
                }}
                title="Email"
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "180px",
                    padding: "0px 12px",
                    background: "#fff",
                    fontSize: "14px",
                    fontWeight: 500,
                    borderLeft: "0.5px solid #F1F4F7",
                  },
                }}
                title="Phone Number"
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "180px",
                    padding: "0px 12px",
                    background: "#fff",
                    fontSize: "14px",
                    fontWeight: 500,
                    borderLeft: "0.5px solid #F1F4F7",
                  },
                }}
                title="Relationship"
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "160px",
                    padding: "0px 12px",
                    background: "#fff",
                    fontSize: "14px",
                    fontWeight: 500,
                    borderLeft: "0.5px solid #F1F4F7",
                  },
                }}
                title="Address"
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    padding: "0px 12px",
                    background: "#fff",
                    fontSize: "14px",
                    fontWeight: 500,
                    borderLeft: "0.5px solid #F1F4F7",
                  },
                  align: "right",
                }}
                title="Action"
              />
            </TableRow>
          </TableHead>

          <TableBody>
            {employeeEmergencyContact &&
              employeeEmergencyContact.data?.map((emr: any, index: number) => (
                <TableRow key={emr.id || index}>
                  <TableCell
                    width={"100px"}
                    sx={{ border: "0.5px solid #F1F4F7" }}
                  >
                    <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                      <Checkbox
                        size="small"
                        sx={{
                          color: "#616E80",
                        }}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                      <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                        {index + 1}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell
                    sx={{ border: "0.5px solid #F1F4F7" }}
                    width={"200px"}
                  >
                    <Stack>
                      <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                        {emr.fullName}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell
                    width={"300px"}
                    sx={{ border: "0.5px solid #F1F4F7" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        textWrap: "nowrap",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      {emr.email}
                    </Typography>
                  </TableCell>
                  <TableCell
                    width={"20px"}
                    sx={{ border: "0.5px solid #F1F4F7" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        textWrap: "nowrap",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      {emr.mobileNumber}
                    </Typography>
                  </TableCell>
                  <TableCell
                    width={"20px"}
                    sx={{ border: "0.5px solid #F1F4F7" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        textWrap: "nowrap",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      {emr.relationship}
                    </Typography>
                  </TableCell>
                  <TableCell
                    width={"20px"}
                    sx={{ border: "0.5px solid #F1F4F7" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        textWrap: "nowrap",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      {emr.address},{emr.city && emr.city + ","}
                      {emr.postCode && emr.postCode}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: "50px" }}>
                    <RowActions
                      data={emr}
                      deleteAction={removeEmergencyContact}
                      DialogComponent={AddEmergencyContact}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider sx={{ borderColor: "#F1F4F7" }} />
      <TablePaginationCustom
        onPageDenseChange={(e) => {
          setPageLimitValue(e.target.value);
        }}
        totalItems={
          employeeEmergencyContact && employeeEmergencyContact.totalItems
        }
        totalPage={
          employeeEmergencyContact && employeeEmergencyContact.totalPages
        }
        itemsPerPage={
          employeeEmergencyContact && employeeEmergencyContact.itemsPerPage
        }
        currentPage={
          employeeEmergencyContact && employeeEmergencyContact.currentPage
        }
        showingCustomText={
          employeeEmergencyContact && employeeEmergencyContact.showing
        }
        onPageChange={(e, currentPage) => {
          setPageValue(currentPage);
        }}
        sx={{
          background: "#fff",
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      />
      {addEmergencyContactDialog.visible && (
        <AddEmergencyContact open onClose={addEmergencyContactDialog.hide} />
      )}
    </>
  );
};

export { EmergencyDetails };
