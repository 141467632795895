// import React from "react";

// const ProjectDocuments = () => {
//   return <div>Project Documents</div>;
// };

// export default ProjectDocuments;

import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Box,
  Paper,
  IconButton,
  Stack,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { companyDocumentFormSchema, formSchema } from "@app/modules/company/validator";
import { enqueueSnackbar } from "notistack";
import { addCompany } from "@app/modules/company/slice";
import { useDispatch, useSelector } from "react-redux";
import { selectCompanyData } from "@app/modules/company/selectors";
import { DialogBox } from "@app/components/dialog";
import { Input, Label, MenuPopover, useMenuPopover } from "@app/components";
import { Strings } from "@app/constants/strings";
import { SvgColor } from "@app/components/svg-color";
import { Icon } from "@iconify/react";
import { columns, companyDocumentColumns } from "../../types";
import { RowActions } from "../../row-action";
import { companyDocuments, selectprojectDocuments } from "@app/modules/account-information/selector";
import {  createProjectDocuments, getProjectDocuments } from "@app/modules/account-information/slice";
import { useLocation } from "react-router";
import {  IprojectDocument } from "@app/modules/company";
import TablePaginationCustom from "@app/components/table/TablePagination";
import FilePreviewDialog from "@app/components/file-preview/FilePreview";
import { useDialog } from "@app/hooks/use-dialog";

const ProjectDocuments = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const companies = useSelector(selectCompanyData);
  const data = useSelector(selectCompanyData);
  const [selectedRows, setSelectedRows] = useState<readonly string[]>([]);
  const [pageValue, setPageValue] = useState(1);
  const [pageLimitValue, setPageLimitValue] = useState<number | string>(10);
  const location = useLocation();
  const companyId: any = location.state.companyId;
  const porject:any = location.state.project;
  const projectId:any = porject.id;
  const companyDocument = useSelector(selectprojectDocuments) || [];
  const companyDocumentData = companyDocument.data || [];
  const [selectedPreviewFile, setSelectedPreviewFile] = useState<any>(null);

  const actions = [
      {
        label: "Delete",
        // callback: () => dispatch(deleteSelectedCompany(data?.id)),
      }
    ];

  useEffect(() => {
    if (projectId && pageValue && pageLimitValue) {
      const payload: any = {
        page: pageValue,
        limit: pageLimitValue,
        projectId: projectId,
      };
      dispatch(getProjectDocuments(payload))
    }
  }, [dispatch, pageValue, pageLimitValue,projectId]);

  const menuPopover = useMenuPopover();

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedRows(data.map((n: any) => n.id));
      return;
    }
    setSelectedRows([]);
  };

  const handleSelectSingleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    setSelectedRows((prev) =>
      event.target.checked ? [...prev, id] : prev.filter((item) => item !== id)
    );
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IprojectDocument>({
    resolver: yupResolver(companyDocumentFormSchema),
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    reset();
    setOpen(false);
  };
  const onSubmit = (data: IprojectDocument) => {
    dispatch(createProjectDocuments({ data, projectId }));
    handleClose();
  };

  const filePreviewDialog = useDialog();

  const downloadFile = async (fileUrl: string) => {
    if (!fileUrl) {
      alert("File URL not available");
      return;
    }
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileUrl.split("/").pop() || "document";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); // Clean up
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("Failed to download file.");
    }
  };

  const handlePreviewFile = async (fileUrl: string) => {
    // const response:any = await fetch(fileUrl, { method: 'HEAD' });
    const filename = fileUrl.split('/').pop();
    setSelectedPreviewFile({
      fileName: filename,
      fileUrl: fileUrl,
    });

    filePreviewDialog.show();
  };

  const generateSize= async(fileUrl:string)=>{
    const response:any = await fetch(fileUrl, { method: 'HEAD' });
    const sizeInBytes = response.headers.get('content-length');
    const sizeInKB = sizeInBytes / 1024;
    const sizeInMB = sizeInKB / 1024;
    return sizeInKB;
     
  }

  return (
    <>
      <Box sx={{ width: "80%", padding: "0px", marginX: "auto" }}>
        <Typography
          sx={{
            color: "#616E80",
            fontStyle: "italic",
            fontSize: "16px",
            fontWeight: "600",
            marginLeft: "10px",
            // gap: "42px",
            // marginY: "14px",
          }}
        >
          System Allowance
        </Typography>
        <Box sx={{ marginTop: "20px", display: "flex", gap: "20px" }}>
          <Box>
            <Typography sx={{ fontStyle: "bold", fontSize: "16px", fontWeight: "600" }}>
              Storage
            </Typography>
          </Box>
          <Box
            sx={{
              height: 6,
              backgroundColor: "#E0E0E0",
              borderRadius: 1,
              overflow: "hidden",
              flex: 1,
              minWidth: "325px",
              maxWidth: "325px",
              marginTop: "10px",
            }}
          >
            <Box
              sx={{
                width: `80%`,
                height: "100%",
                backgroundColor: "#0177FB",
                transition: "width 0.3s ease",
              }}
            />

          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", color: "#0177FB", marginTop: "2px", marginLeft: "0px" }}>
              85.00% ( 42.5GB / 50GB from)
            </Typography>
          </Box>
        </Box>
        <Box>
          <Button sx={{ marginTop: "20px", marginLeft: "10px" }} variant="contained" onClick={handleOpen}>
            Add Additional Document
          </Button>

        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Paper
          sx={{ width: "100%", borderRadius: "8px", overflow: "hidden", mb: 2 }}
        >
          <TableContainer>
            <Table>
              {/* HEADER SECTION */}
              <TableHead>
                <TableRow>
                  {companyDocumentColumns?.map((col: any) => (
                    <TableCell
                      key={col.id}
                      sx={{
                        width: col.width || "auto",
                        color: "#6B7280",
                        fontWeight: 500,
                        fontSize: "14px",
                        textAlign: col.id === "checkbox" ? "left" : "center",
                        backgroundColor: "transparent",
                      }}
                    >
                      {col.id === "checkbox" ? (
                        <Checkbox
                          size="small"
                          indeterminate={
                            selectedRows.length > 0 &&
                            selectedRows.length < data.length
                          }
                          checked={
                            data.length > 0 && selectedRows.length === data.length
                          }
                          onChange={handleSelectAllClick}
                          disabled={data.length === 0}
                          sx={{ padding: 0 }}
                        />
                      ) : (
                        col.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* BODY SECTION */}
              <TableBody>
                {companyDocumentData.length > 0 ? (
                  companyDocumentData.map((document: any, index: number) => (
                    <TableRow key={document.id} sx={{ height: "55px" }}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          size="small"
                          checked={selectedRows.includes(document.id)}
                          onChange={(e) => handleSelectSingleCheck(e, document.id)}
                        />
                      </TableCell>
                      <TableCell sx={{ color: "#6B7280", fontSize: "14px", textAlign: "center" }}>
                        DOC-{index + 1}
                      </TableCell>

                      {/* Company Logo Preview */}
                      <TableCell sx={{ textAlign: "center" }}>
                        {document.name}
                      </TableCell>

                      {/* Company Name */}
                      <TableCell
                        sx={{
                          textAlign: "center"
                        }}
                      >
                        {document.description}
                      </TableCell>

                      {/* Date Created */}
                      <TableCell
                        sx={{
                          textAlign: "center"
                        }}
                      >
                        {document.documentSize || 0}
                      </TableCell>

                      {/* Company Status */}
                      <TableCell sx={{ display: "flex", justifyContent: "space-around" }}>
                        <Button sx={{ backgroundColor: "#F2F2F2", color: "#000000", borderRadius: "20px !important", marginRight: "10px" }} onClick={() => downloadFile(document.documentUrl)}>
                          download
                        </Button>
                        <Button sx={{ backgroundColor: "#F2F2F2", color: "#000000", borderRadius: "20px !important", }} onClick={() => handlePreviewFile(document.documentUrl)}>
                          view
                        </Button>
                      </TableCell>

                      <TableCell>
                      <IconButton onClick={menuPopover.show} sx={{ color: "black" }}>
                             <Icon icon="eva:more-vertical-fill" />
                        </IconButton>
                           {/* Menu Popover */}
                           <MenuPopover
                             open={menuPopover.rowMenuAnchorElement}
                             arrow="right-top"
                             sx={{ width: 180 }}
                             onClose={menuPopover.hide}
                           >
                            {actions?.map((action) => (
                                    <MenuItem
                                      key={action.label}
                                      // onClick={() => {
                                      //   // onClickAction();
                                      //   // action.callback?.(project);
                                      // }}
                                      // disabled={action.disabled}
                                    >
                                      {action.label}
                                    </MenuItem>
                                  ))}
                           </MenuPopover>
                      </TableCell>  
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      align="center"
                      sx={{ py: 3 }}
                    >
                      <Typography variant="body1" color="text.secondary">
                        No Documents found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePaginationCustom
            onPageDenseChange={(e) => {
              setPageLimitValue(e.target.value);
            }}
            totalItems={companyDocument.totalItems}
            totalPage={companyDocument.totalPages}
            itemsPerPage={companyDocument.itemsPerPage}
            currentPage={companyDocument.currentPage}
            showingCustomText={companyDocument.showing}
            onPageChange={(e, currentPage) => {
              setPageValue(currentPage);
            }}
            sx={{
              background: "#fff",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
          />
        </Paper>

        {/* CREATE NEW BUTTON */}
        {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: "4px", textTransform: "none", px: 2 }}
            onClick={handleOpen}
          >
            Create New Company
          </Button>
        </Box> */}
        <DialogBox
          open={open}
          onClose={handleClose}
          onSave={handleSubmit(onSubmit)} // Handle form submission
          title="Create New Document"
          saveButtonText="Save"
          cancelButtonText="Cancel"
          saveButtonStyle={{ fontWeight: "400", fontSize: "14px" }}
          cancelButtonStyle={{ fontWeight: "400", fontSize: "14px" }}
        >
          <Stack>
            <Label>Document Name</Label>
            <Input
              name="name"
              control={control}
              label="Document Name"
              placeHolder={Strings.field.inputPlaceholder("Document Name")}
            // error={errors?.companyName?.message}
            />
          </Stack>

          <Stack>
            <Label>Description</Label>
            <Input
              name="description"
              control={control}
              label="description"
              placeHolder={Strings.field.inputPlaceholder("Description Name")}
            // error={errors?.companyName?.message}
            />
          </Stack>

          <Stack mb={"15px"}>
            <Label>Upload </Label>
            <Controller
              name="documentUrl"
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="uploadSmallCompanyLogo"
                    onChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        field.onChange(e.target.files[0]); // Pass the file to react-hook-form
                      }
                    }}
                  />
                  {!field.value ? (
                    <Stack
                      component="label"
                      htmlFor="uploadSmallCompanyLogo"
                      sx={{
                        width: "100%",
                        height: "120px",
                        border: "1px solid #98A5B5",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        cursor: "pointer",
                      }}
                    >
                      <SvgColor
                        src={`/assets/icons/Upload.svg`}
                        sx={{ width: 80, height: 80, color: "#CCCCCC" }}
                      />
                      <Typography variant="body2" color="#CCCCCC">
                        Click to upload an image
                      </Typography>
                      {errors?.documentUrl && (
                        <Typography variant="body2" color="error">
                          {String(errors?.documentUrl?.message)}
                        </Typography>
                      )}
                    </Stack>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                      }}
                    >
                      <Box
                        component="img"
                        src={URL.createObjectURL(field.value)}
                        sx={{
                          width: "100%",
                          height: "120px",
                          borderRadius: "8px",
                          display: "flex",
                          objectFit: "contain",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          cursor: "pointer",
                        }}
                      />
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "5px",
                          right: "14px",
                        }}
                        onClick={() => {
                          field.onChange(null);
                        }}
                      >
                        <Icon
                          icon="fontisto:close"
                          width="24"
                          height="24"
                          color="#98A5B5"
                        />
                      </IconButton>
                    </Box>
                  )}
                </>
              )}
            />
          </Stack>
        </DialogBox>
        {filePreviewDialog.visible && (
          <FilePreviewDialog
            open
            onClose={() => {
              filePreviewDialog.hide();
            }}
            fileUrl={selectedPreviewFile && selectedPreviewFile.fileUrl}
            fileName={selectedPreviewFile && selectedPreviewFile.fileName}
          />
        )}
      </Box>
    </>
  );
};

export default ProjectDocuments;
