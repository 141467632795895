import { RequestStatus } from "@app/constants/request";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRequestFailed, IRequestState } from "@app/types";
import { IDepartmentState } from "./types";

const initialState: IDepartmentState = {
  departments: {
    status: RequestStatus.IDLE,
  },
  deleteDepartment: {},
  addDepartment: {},
};

const DepartmentSlice = createSlice({
  initialState,
  name: "department",
  reducers: {
    getDepartment(state, action: PayloadAction<{ page: any; limit: any }>) {
      state.departments.status = RequestStatus.RUNNING;
    },
    getDepartmentSuccess(state, action: PayloadAction<IRequestState>) {
      state.departments.data = action.payload.data;
      state.departments.status = RequestStatus.SUCCESS;
    },
    getDepartmentFailed(state, action: PayloadAction<IRequestFailed>) {
      state.departments.data = action.payload;
      state.departments.status = RequestStatus.ERROR;
    },
    addDepartmentAction(state, action: PayloadAction<any>) {
      state.addDepartment.status = RequestStatus.RUNNING;
    },
    addDepartmentSuccessAction(state, action: PayloadAction<any>) {
      state.addDepartment.status = RequestStatus.SUCCESS;
      state.addDepartment.data = action.payload
    },
    addDepartmentFailedAction(state, action: PayloadAction<IRequestFailed>) {
      state.addDepartment.error = action.payload;
      state.addDepartment.status = RequestStatus.ERROR;
    },
    deleteDepartment(state, action: PayloadAction<any>) {
      state.deleteDepartment.status = RequestStatus.RUNNING;
    },
    deleteDepartmentSuccess(state, action) {
      state.deleteDepartment.status = RequestStatus.SUCCESS;
      state.deleteDepartment.data = action.payload.data;
    },
    deleteDepartmentFailed(state, action: PayloadAction<IRequestFailed>) {
      state.deleteDepartment.status = RequestStatus.ERROR;
      state.deleteDepartment.error = action.payload;
    },
  },
});

export const departmentReducer = DepartmentSlice.reducer;
export const {
  getDepartment,
  getDepartmentFailed,
  getDepartmentSuccess,
  addDepartmentAction,
  addDepartmentFailedAction,
  addDepartmentSuccessAction,
  deleteDepartment,
  deleteDepartmentSuccess,
  deleteDepartmentFailed,
} = DepartmentSlice.actions;
