import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Badge,
  Card,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Icon } from "@iconify/react";

import { SvgColor } from "@app/components/svg-color";
import { sourceUrl } from "@app/constants/source_url";
import { useDialog } from "@app/hooks/use-dialog";
import { UploadAdditionalInformation } from "./UploadAdditionalInformation";
import { editIdentifyRightWorkAction } from "./slice";
import { useRequestState } from "@app/hooks/use-request-state";
import { selectEditIdentifyRightWork } from "./selector";
import {
  deleteProofOfIdentity,
  deleteProofOfWork,
} from "../add_employee/slice";
import { RowActionsIdentity } from "./RowActionIdentity";
import { RowActionsRightToWork } from "./RowActionRighToWork";
import FilePreviewDialog from "@app/components/file-preview/FilePreview";
import { TruncatedTypography } from "@app/components";

const IdentifyRightToWork = ({
  edit = false,
  identifyRightWork,
  employee,
}: any) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { control } = useForm({
    defaultValues: {
      nationality: identifyRightWork?.nationality,
      rightToWork: identifyRightWork?.hasRightToWork,
    },
  });
  const uploadDocumentsPopup = useDialog();
  const filePreviewDialog = useDialog();

  useRequestState({
    stateSelector: selectEditIdentifyRightWork,
    successMessageShown: true,
    errorShown: true,
  });

  const nationalities = [
    { label: "Indian", value: "INDIAN" },
    { label: "American", value: "AMERICAN" },
    { label: "Canadian", value: "CANADIAN" },
    { label: "British", value: "BRITISH" },
    { label: "Australian", value: "AUSTRALIAN" },
    { label: "German", value: "GERMAN" },
    { label: "French", value: "FRENCH" },
    { label: "Japanese", value: "JAPANESE" },
    { label: "Chinese", value: "CHINESE" },
    { label: "Mexican", value: "MEXICAN" },
    { label: "South African", value: "SOUTH_AFRICAN" },
    { label: "Italian", value: "ITALIAN" },
    { label: "Brazilian", value: "BRAZILIAN" },
    { label: "Spanish", value: "SPANISH" },
    { label: "Russian", value: "RUSSIAN" },
    { label: "Argentinian", value: "ARGENTINIAN" },
    { label: "Nigerian", value: "NIGERIAN" },
    { label: "Colombian", value: "COLOMBIAN" },
    { label: "Egyptian", value: "EGYPTIAN" },
    { label: "Korean", value: "KOREAN" },
    { label: "Turkish", value: "TURKISH" },
    { label: "Swedish", value: "SWEDISH" },
    { label: "Norwegian", value: "NORWEGIAN" },
    { label: "Finnish", value: "FINNISH" },
    { label: "Belgian", value: "BELGIAN" },
    { label: "Swiss", value: "SWISS" },
    { label: "Danish", value: "DANISH" },
    { label: "Saudi Arabian", value: "SAUDI_ARABIAN" },
    { label: "Indonesian", value: "INDONESIAN" },
    { label: "Malaysian", value: "MALAYSIAN" },
    { label: "Philippine", value: "PHILIPPINE" },
    { label: "Thai", value: "THAI" },
    { label: "Vietnamese", value: "VIETNAMESE" },
    { label: "Irish", value: "IRISH" },
    { label: "Portuguese", value: "PORTUGUESE" },
    { label: "New Zealander", value: "NEW_ZEALANDER" },
    { label: "Israeli", value: "ISRAELI" },
    { label: "Romanian", value: "ROMANIAN" },
    { label: "Bulgarian", value: "BULGARIAN" },
    { label: "Hungarian", value: "HUNGARIAN" },
    { label: "Czech", value: "CZECH" },
    { label: "Polish", value: "POLISH" },
    { label: "Ukrainian", value: "UKRAINIAN" },
    { label: "Kenyan", value: "KENYAN" },
    { label: "Uzbekistani", value: "UZBEKISTANI" },
    { label: "Pakistani", value: "PAKISTANI" },
    { label: "Bangladeshi", value: "BANGLADESHI" },
    { label: "Sri Lankan", value: "SRI_LANKAN" },
    { label: "Iraqi", value: "IRAQI" },
    { label: "Lebanese", value: "LEBANESE" },
    { label: "Syrian", value: "SYRIAN" },
    { label: "Jordanian", value: "JORDANIAN" },
    { label: "Qatari", value: "QATARI" },
    { label: "Kuwaiti", value: "KUWAITI" },
    { label: "Omani", value: "OMANI" },
    { label: "Bahraini", value: "BAHRAINI" },
    { label: "Yemeni", value: "YEMENI" },
    { label: "Maldivian", value: "MALDIVIAN" },
    { label: "Nepalese", value: "NEPALESE" },
    { label: "Mongolian", value: "MONGOLIAN" },
    { label: "Bolivian", value: "BOLIVIAN" },
    { label: "Peruvian", value: "PERUVIAN" },
    { label: "Chilean", value: "CHILEAN" },
    { label: "Paraguayan", value: "PARAGUAYAN" },
    { label: "Uruguayan", value: "URUGUAYAN" },
    { label: "Venezuelan", value: "VENEZUELAN" },
    { label: "Ecuadorian", value: "ECUADORIAN" },
    { label: "Surinamese", value: "SURINAMESE" },
    { label: "Guyanese", value: "GUYANESE" },
    { label: "French Guianan", value: "FRENCH_GUIANAN" },
    { label: "Samoan", value: "SAMOAN" },
    { label: "Tongan", value: "TONGAN" },
    { label: "Fijian", value: "FIJIAN" },
    { label: "Vanuatuan", value: "VANUATUAN" },
    { label: "Papua New Guinean", value: "PAPUA_NEW_GUINEAN" },
    { label: "Solomon Islander", value: "SOLOMON_ISLANDER" },
    { label: "New Caledonian", value: "NEW_CALEDONIAN" },
    { label: "Bouvet Islander", value: "BOUVET_ISLAND" },
    { label: "Greenlandic", value: "GREENLANDIC" },
    { label: "Icelandic", value: "ICELANDIC" },
    { label: "Faroese", value: "FAROESE" },
  ];

  const rightToWork = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "No" },
  ];

  const proofOfIdentity = [
    {
      linkedDocumentName: "Id Front.pdf",
      documentName: "Proof of ID Front",
      id: "proofOfIdentityFront",
    },
    {
      linkedDocumentName: "Id Back.pdf",
      documentName: "Proof of ID Back",
      id: "proofOfIdentityBack",
    },
  ];

  const proofOfRightToWOrk = [
    {
      linkedDocumentName: "Right to Work.pdf",
      documentName: "Right To Work Proof",
      id: "proofOfRightToWork",
    },
  ];

  const [isProofOfWorkPopup, setIsProofOfWorkPopup] = useState<boolean>(false);
  const [selectedPreviewFile, setSelectedPreviewFile] = useState<any>(null);

  const methods = useForm();

  useEffect(() => {
    methods.reset({
      nationality: identifyRightWork?.nationality,
      rightToWork: identifyRightWork?.hasRightToWork,
      proofOfRightToWork: identifyRightWork?.proofOfRightToWork,
      proofOfIdentityFront: identifyRightWork?.proofOfIdentityFront,
      proofOfIdentityBack: identifyRightWork?.proofOfIdentityBack,
    });
  }, [identifyRightWork, methods]);

  return (
    <>
      <Card
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          padding: 2,
          gap: 1.5,
        }}
      >
        <Stack sx={{ flex: 0.5 }}>
          <Stack>
            <Stack sx={{ flexDirection: "row", gap: 10 }}>
              <Typography
                variant="body1"
                fontWeight={500}
                sx={{ fontSize: "16px" }}
              >
                Nationality:
              </Typography>
              {edit ? (
                <Controller
                  name={"nationality"}
                  control={control}
                  render={({ field }) => (
                    <Select
                      sx={{ width: "50%" }}
                      {...field}
                      displayEmpty
                      onChange={(event: any) => {
                        const selectedValue = event.target.value;
                        dispatch(
                          editIdentifyRightWorkAction({
                            nationality: selectedValue,
                            employeeId: employee.id,
                          })
                        );
                        field.onChange(selectedValue);
                      }}
                      renderValue={(selected: any) => {
                        if (!selected) {
                          return (
                            <Typography
                              sx={{
                                color: theme.palette.grey[500],
                              }}
                            >
                              {"Nationality"}
                            </Typography>
                          );
                        }
                        return selected;
                      }}
                      name={field.name}
                      size="small"
                    >
                      {nationalities.map((item) => (
                        <MenuItem value={item.label}>{item.label}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
              ) : (
                <Typography
                  variant="body1"
                  color="primary"
                  fontWeight={500}
                  sx={{ fontSize: "16px" }}
                >
                  {identifyRightWork?.nationality
                    ? identifyRightWork?.nationality
                    : "None"}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack mt={2} ml={1.5}>
            <Typography
              sx={{
                color: theme.palette.grey[600],
                fontWeight: 600,
                fontStyle: "italic",
                fontSize: "16px",
              }}
            >
              Proof of Identity
            </Typography>
            <Typography
              sx={{
                fontSize: "10px",
                fontStyle: "italic",
                fontWeight: 500,
                color: theme.palette.grey[700],
                marginTop: "10px",
              }}
            >
              Uploaded Document
            </Typography>
          </Stack>
          {/* {!identifyRightWork && (
            <Typography
              sx={{
                color: theme.palette.grey[600],
                fontWeight: 600,
                fontStyle: "italic",
              }}
            >
              No Proof of Identity Uploaded Click On Revise Information
            </Typography>
          )} */}
          <Stack
            sx={{
              // border: `1px solid ${theme.palette.grey[400]}`,
              borderRadius: 1,
              padding: 0,
            }}
          >
            {employee.proofOfIdentities?.length === 0 && (
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  color: theme.palette.grey[600],
                }}
              >
                No Data Found
              </Typography>
            )}
            {employee &&
              employee.proofOfIdentities?.map((proof: any, index: any) => (
                <Stack
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 0,
                    // margin: 1,
                    gap: 1,
                    // border: `1.6px solid ${theme.palette.grey[400]}`,
                    justifyContent: "center",
                    borderRadius: 1,
                  }}
                >
                  <Stack
                    sx={{
                      width: "100%",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <Stack
                      sx={{
                        // border: `1px solid ${theme.palette.grey[400]}`,
                        width: "100%",
                        padding: 1,
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontStyle: "italic",
                          fontWeight: 500,
                          color: theme.palette.grey[700],
                        }}
                      >
                        Linked Document
                      </Typography>
                      <TruncatedTypography
                        text={proof.documentName ?? "NA"}
                        height={20}
                        lines={1}
                      />
                    </Stack>
                    <Stack>
                      {edit && (
                        <RowActionsIdentity
                          data={proof}
                          handleOnDelete={() =>
                            dispatch(
                              deleteProofOfIdentity({
                                employeeId: employee.id,
                                documentId: proof.id,
                              })
                            )
                          }
                          handleOnPreview={() => {
                            setSelectedPreviewFile({
                              name: proof.fileName,
                              url: proof.fileUrl,
                            });
                            filePreviewDialog.show();
                          }}
                        />
                      )}
                    </Stack> */}
                  </Stack>
                  <Stack
                  // sx={{
                  //   width: "100%",
                  //   border: `1px solid ${theme.palette.grey[400]}`,
                  //   padding: 1,
                  //   borderRadius: 1,
                  // }}
                  >
                    {/* <Typography
                      sx={{
                        fontSize: "10px",
                        fontStyle: "italic",
                        fontWeight: 500,
                        color: theme.palette.grey[700],
                      }}
                    >
                      Linked Document Name
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {proof.fileName}
                    </Typography> */}
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        fontStyle: "italic",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Stack
                        sx={{
                          border: `1px solid #A1A1A1`,
                          p: 1,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: "10px",
                          ":hover": {
                            backgroundColor: "#ECECEC",
                          },
                          color: " #535455",
                          minWidth: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "90%",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedPreviewFile({
                              name: proof.fileName,
                              url: proof.fileUrl,
                            });
                            filePreviewDialog.show();
                          }}
                        >
                          {proof?.fileUrl &&
                            (proof?.fileUrl?.endsWith(".jpg") ||
                            proof?.fileUrl?.endsWith(".jpeg") ||
                            proof?.fileUrl?.endsWith(".webp") ? (
                              <Icon
                                icon="line-md:image-twotone"
                                height={"25px"}
                                width={"25px"}
                                style={{ marginRight: "10px", color: "blue" }}
                              />
                            ) : proof?.fileUrl?.endsWith(".png") ? (
                              <SvgColor
                                src="/assets/icons/file-png.svg"
                                style={{ marginRight: "10px" }}
                                color="blue"
                              />
                            ) : proof?.fileUrl?.endsWith(".pdf") ? (
                              <SvgColor
                                src="/assets/icons/pdfIcon.svg"
                                style={{ marginRight: "10px" }}
                                color="red"
                              />
                            ) : (
                              <Icon
                                icon="bxs:file"
                                height={"25px"}
                                width={"25px"}
                                style={{ marginRight: "10px", color: "gray" }}
                              />
                            ))}

                          <div>
                            {proof.fileName.length > 55
                              ? proof.fileName.substring(0, 55) + "..."
                              : proof.fileName}
                          </div>
                        </div>

                        {/* {employeeCertification?.frontOfDocumentUrl && (
                                    <IconButton
                                      onClick={() => {
                                        setSelectedPreviewFile({
                                          name: employeeCertification.frontOfDocumentName,
                                          url: employeeCertification.frontOfDocumentUrl,
                                        });
                                        filePreviewDialog.show();
                                      }}
                                    >
                                      <Visibility
                                        sx={{
                                          color: theme.palette.grey[500],
                                          cursor: "pointer",
                                        }}
                                      />
                                    </IconButton>
                                  )} */}
                        {proof?.fileName && edit && (
                          <SvgColor
                            src="/assets/icons/remove.svg"
                            height={"40px"}
                            width={"30px"}
                            onClick={() =>
                              dispatch(
                                deleteProofOfIdentity({
                                  employeeId: employee.id,
                                  documentId: proof.id,
                                })
                              )
                            }
                            style={{
                              marginLeft: "10px",
                            }}
                            color="535455"
                          />
                        )}
                      </Stack>
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            {edit && (
              <Stack
                sx={{
                  mt: 1.5,
                  border: `1.5px solid ${theme.palette.grey[400]}`,
                  borderRadius: "4px",
                }}
              >
                <IconButton
                  sx={{
                    gap: 0.4,
                    alignSelf: "flex-start",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingHor: 2,
                  }}
                  onClick={() => {
                    setIsProofOfWorkPopup(false);
                    console.log("Hello");
                    uploadDocumentsPopup.show();
                  }}
                >
                  <SvgColor
                    src={sourceUrl.icons.upload}
                    sx={{ backgroundColor: theme.palette.grey[500] }}
                  />
                  <Typography
                    sx={{
                      color: theme.palette.grey[500],
                      fontSize: "14px",
                      marginLeft: 0.5,
                    }}
                  >
                    Upload Additional Information
                  </Typography>
                </IconButton>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack sx={{ flex: 0.5 }}>
          <Stack>
            <Stack sx={{ flexDirection: "row", gap: 5 }}>
              <Typography
                variant="body1"
                fontWeight={500}
                sx={{ fontSize: "16px" }}
              >
                Do you have the right to work?:
              </Typography>
              {edit ? (
                <Controller
                  name={"rightToWork"}
                  control={control}
                  render={({ field }) => (
                    <Select
                      sx={{ width: "50%" }}
                      {...field}
                      name={field.name}
                      displayEmpty
                      size="small"
                      onChange={(event: any) => {
                        const selectedValue = event.target.value;
                        dispatch(
                          editIdentifyRightWorkAction({
                            hasRightToWork: selectedValue,
                            employeeId: employee.id,
                          })
                        );
                        field.onChange(selectedValue);
                      }}
                      renderValue={(selected: any) => {
                        if (!selected) {
                          return (
                            <Typography
                              sx={{
                                color: theme.palette.grey[500],
                              }}
                            >
                              {"Right to Work"}
                            </Typography>
                          );
                        }
                        return selected;
                      }}
                    >
                      {rightToWork.map((item) => (
                        <MenuItem value={item.label}>{item.label}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
              ) : (
                <Typography variant="body1" color="primary" fontWeight={500}>
                  {identifyRightWork?.hasRightToWork
                    ? identifyRightWork?.hasRightToWork
                    : ""}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack mt={2} ml={1.5}>
            <Typography
              sx={{
                color: theme.palette.grey[600],
                fontWeight: 600,
                fontStyle: "italic",
                fontSize: "16px",
              }}
            >
              Proof of Right To Work
            </Typography>
            <Typography
              sx={{
                fontSize: "10px",
                fontStyle: "italic",
                fontWeight: 500,
                color: theme.palette.grey[700],
                marginTop: "10px",
              }}
            >
              Uploaded Document
            </Typography>
          </Stack>
          <Stack
            sx={{
              // border: `1px solid ${theme.palette.grey[400]}`,
              borderRadius: 1,
              padding: 0,
            }}
          >
            {employee.proofOfRightToWork?.length === 0 && (
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  color: theme.palette.grey[600],
                }}
              >
                No Data Found
              </Typography>
            )}
            {employee &&
              employee.proofOfRightToWork?.map((proof: any, index: any) => (
                <Stack
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 0,
                    // margin: 1,
                    gap: 1,
                    // border: `1.6px solid ${theme.palette.grey[400]}`,
                    justifyContent: "center",
                    borderRadius: 1,
                  }}
                >
                  <Stack
                    sx={{
                      width: "100%",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <Stack
                      sx={{
                        // border: `1px solid ${theme.palette.grey[400]}`,
                        width: "100%",
                        padding: 1,
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontStyle: "italic",
                          fontWeight: 500,
                          color: theme.palette.grey[700],
                        }}
                      >
                        Linked Document
                      </Typography>
                      <TruncatedTypography
                        text={proof.documentName ?? "NA"}
                        height={20}
                        lines={1}
                      />
                      </Stack>
                    <Stack>
                      {!edit && (
                        <RowActionsRightToWork
                          data={proof}
                          handleOnDelete={() =>
                            dispatch(
                              deleteProofOfWork({
                                employeeId: employee.id,
                                documentId: proof.id,
                              })
                            )
                          }
                          handleOnPreview={() => {
                            setSelectedPreviewFile({
                              name: proof.fileName,
                              url: proof.fileUrl,
                            });
                            filePreviewDialog.show();
                          }}
                        />
                      )}
                    </Stack> */}
                  </Stack>
                  <Stack
                  // sx={{
                  //   width: "100%",
                  //   border: `1px solid ${theme.palette.grey[400]}`,
                  //   padding: 1,
                  //   borderRadius: 1,
                  // }}
                  >
                    {/* <Typography
                      sx={{
                        fontSize: "10px",
                        fontStyle: "italic",
                        fontWeight: 500,
                        color: theme.palette.grey[700],
                      }}
                    >
                      Linked Document Name
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {proof.fileName}
                    </Typography> */}
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        fontStyle: "italic",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Stack
                        sx={{
                          border: `1px solid #A1A1A1`,
                          p: 1,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: "10px",
                          ":hover": {
                            backgroundColor: "#ECECEC",
                          },
                          color: " #535455",
                          minWidth: "100%",
                        }}
                        onClick={() => {
                          setSelectedPreviewFile({
                            name: proof.fileName,
                            url: proof.fileUrl,
                          });
                          filePreviewDialog.show();
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {proof?.fileUrl &&
                            (proof?.fileUrl?.endsWith(".jpg") ||
                            proof?.fileUrl?.endsWith(".jpeg") ||
                            proof?.fileUrl?.endsWith(".webp") ? (
                              <Icon
                                icon="line-md:image-twotone"
                                height={"25px"}
                                width={"25px"}
                                style={{ marginRight: "10px", color: "blue" }}
                              />
                            ) : proof?.fileUrl?.endsWith(".png") ? (
                              <SvgColor
                                src="/assets/icons/file-png.svg"
                                style={{ marginRight: "10px" }}
                                color="blue"
                              />
                            ) : proof?.fileUrl?.endsWith(".pdf") ? (
                              <SvgColor
                                src="/assets/icons/pdfIcon.svg"
                                style={{ marginRight: "10px" }}
                                color="red"
                              />
                            ) : (
                              <Icon
                                icon="bxs:file"
                                height={"25px"}
                                width={"25px"}
                                style={{ marginRight: "10px", color: "gray" }}
                              />
                            ))}

                          <div>
                            {proof.fileName.length > 55
                              ? proof.fileName.substring(0, 55) + "..."
                              : proof.fileName}
                          </div>
                        </div>

                        {/* {employeeCertification?.frontOfDocumentUrl && (
                                    <IconButton
                                      onClick={() => {
                                        setSelectedPreviewFile({
                                          name: employeeCertification.frontOfDocumentName,
                                          url: employeeCertification.frontOfDocumentUrl,
                                        });
                                        filePreviewDialog.show();
                                      }}
                                    >
                                      <Visibility
                                        sx={{
                                          color: theme.palette.grey[500],
                                          cursor: "pointer",
                                        }}
                                      />
                                    </IconButton>
                                  )} */}
                        {proof?.fileName && edit && (
                          <SvgColor
                            src="/assets/icons/remove.svg"
                            height={"40px"}
                            width={"30px"}
                            onClick={() =>
                              dispatch(
                                deleteProofOfWork({
                                  employeeId: employee.id,
                                  documentId: proof.id,
                                })
                              )
                            }
                            style={{
                              marginLeft: "10px",
                            }}
                            color="535455"
                          />
                        )}
                      </Stack>
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            {edit && (
              <Stack
                sx={{
                  mt: 1.5,
                  border: `1.5px solid ${theme.palette.grey[400]}`,
                  borderRadius: "4px",
                }}
              >
                <IconButton
                  sx={{
                    gap: 0.4,
                    alignSelf: "flex-start",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingHor: 2,
                  }}
                  onClick={() => {
                    setIsProofOfWorkPopup(true);
                    uploadDocumentsPopup.show();
                  }}
                >
                  <SvgColor
                    src={sourceUrl.icons.upload}
                    sx={{ backgroundColor: theme.palette.grey[500] }}
                  />
                  <Typography
                    sx={{
                      color: theme.palette.grey[500],
                      fontSize: "14px",
                      marginLeft: 0.5,
                    }}
                  >
                    Upload Additional Information
                  </Typography>
                </IconButton>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Card>
      {filePreviewDialog.visible && (
        <FilePreviewDialog
          open
          onClose={() => {
            filePreviewDialog.hide();
          }}
          fileUrl={selectedPreviewFile && selectedPreviewFile.url}
          fileName={selectedPreviewFile && selectedPreviewFile.name}
        />
      )}
      {uploadDocumentsPopup.visible && (
        <UploadAdditionalInformation
          isProofOfWork={isProofOfWorkPopup}
          employeeId={employee.id}
          open
          onClose={uploadDocumentsPopup.hide}
        />
      )}
    </>
  );
};

export { IdentifyRightToWork };
