import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { configureStore as reduxConfigureStore } from '@reduxjs/toolkit';

import { persistRootReducer } from './root-reducer';
import { rootSaga } from './root-saga';


function configureStore() {
  const sagaMiddleware = createSagaMiddleware();

  // New middleware can be added here

  const store = reduxConfigureStore({
    reducer: persistRootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
        },
      }).concat(sagaMiddleware),
    devTools: false,
  });

  const persistor = persistStore(store);

  // Run sagas
  rootSaga.forEach(sagaMiddleware.run);

  return { store, persistor };
}

export { configureStore };
