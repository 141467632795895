import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  Stack,
  styled,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { HeaderProps } from "./types";
import { logout } from "@app/modules/auth";
import {
  selectActivePathState,
  selectIsAuthenticated,
} from "@app/modules/common";
import { sourceUrl } from "@app/constants/source_url";
import { SvgColor } from "../svg-color";
import { PATH_DASHBOARD } from "@app/routes/paths";
import { useRequestState } from "@app/hooks/use-request-state";
import { selectGetUser } from "@app/modules/account-information/selector";
import { getUser } from "@app/modules/account-information/slice";
import { NotificationCard } from "@app/modules/notification/NotifactionCard";

const AppToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: "#11171F",
  width: "100%",
  height: "64px",
  boxShadow: "none",

  [theme.breakpoints.down("sm")]: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  [theme.breakpoints.up("md")]: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  [theme.breakpoints.down("lg")]: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
}));

export const Header = (headerProps: HeaderProps) => {
  const selectActivePage = useSelector(selectActivePathState);
  console.log("ActivePage________", selectActivePage);
  const { isAuthHeader, isSmallSideBar = true, isOnboarding } = headerProps;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  console.log(pathname);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showNotificationCard, setShowNotificationCard] = useState(false); // State to manage NotificationCard visibility

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationToggle = () => {
    setShowNotificationCard((prev) => !prev);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const popOverButton = [
    { label: "Account Profile", value: "accountProfile" },
    { label: "Log Out", value: "logout" },
  ];

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const { data } = useRequestState({
    stateSelector: selectGetUser,
  });

  // console.log("company user Data from header_______", data);

  return (
    <AppBar position="fixed" sx={{ boxShadow: "none" }}>
      <AppToolbar
        sx={{
          backgroundColor: !isAuthHeader ? "#fff" : "#11171F",
        }}
      >
        {isAuthHeader ? (
          <Box
            sx={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr .8fr",
              alignItems: "center",
            }}
          >
            <Box
              component={"img"}
              src={sourceUrl.header.logo}
              sx={{ justifySelf: "flex-end" }}
            />
            {isAuthenticated && !isOnboarding && (
              <Button
                variant="contained"
                sx={{
                  marginRight: "10px",
                  justifySelf: "flex-end",
                }}
                onClick={() => {
                  localStorage.removeItem("open");
                  dispatch(logout());
                }}
              >
                logout
              </Button>
            )}
          </Box>
        ) : (
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              width: "100%",
              marginRight: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: isSmallSideBar ? "50px" : "260px",
                  boxSizing: "border-box",
                  backgroundColor: "#11171F",
                  height: "64px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "4px",
                }}
              >
                <Box
                  component={"img"}
                  src={
                    isSmallSideBar
                      ? sourceUrl.header.logo2
                      : sourceUrl.header.logo
                  }
                />
                <Divider />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginLeft: "20px",
                }}
              >
                <Typography
                  sx={{ fontSize: "20px", fontWeight: "700", color: "#000000" }}
                >
                  {selectActivePage && selectActivePage}
                </Typography>
                {/* <Typography sx={{ fontSize: "14px", fontWeight: "600", color: "#616E80" }}>Home - Employee</Typography> */}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "rgba(98, 102, 114, 0.3)",
                  fontWeight: 600,
                  color: "#000",
                  borderRadius: "8px !important",
                  "&:hover": {
                    backgroundColor: theme.palette.grey[400],
                    boxShadow: "none",
                  },
                  fontSize: "14px",
                }}
              >
                Human Resource
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "30px",
                  fontWeight: 600,
                  gap: "4px",
                  borderRadius: "8px !important",
                  "&:hover": {
                    boxShadow: "none",
                  },
                  fontSize: "14px",
                }}
              >
                Switch System
                <Icon icon="ep:arrow-down-bold" />
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                position: "relative",
              }}
            >
              <IconButton onClick={handleNotificationToggle}>
                <Badge color="primary" variant="dot">
                  <SvgColor src={sourceUrl.icons.notificationBell} />
                </Badge>
              </IconButton>
              {showNotificationCard && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "40px",
                    right: "0",
                    transform: "translateX(-55%)",
                    width: "420px",
                    zIndex: 10,
                  }}
                >
                  <NotificationCard
                    title="New Message"
                    message="You have a new message from John Doe."
                    timestamp="2 hours ago"
                    avatar="https://mui.com/static/images/avatar/1.jpg"
                    onMarkAsRead={() => console.log("Marked as read")}
                    onShowAll={() => console.log("Show all notifications")}
                  />
                </Box>
              )}
              <Button
                variant="contained"
                size="small"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "23px",
                  fontWeight: 600,
                  padding: "0px 14px",
                  gap: "4px",
                  borderRadius: "8px !important",
                  "&:hover": {
                    backgroundColor: theme.palette.grey[400],
                    boxShadow: "none",
                  },
                  fontSize: "14px",
                }}
              >
                Site Manager
              </Button>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                  marginRight: "4px",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              >
                <Avatar
                  src={
                    data?.profilePicture
                      ? data?.profilePicture
                      : "https://mui.com/static/images/avatar/1.jpg"
                  }
                  sizes="small"
                  sx={{ height: "26px", width: "26px", marginTop: "4px" }}
                />
                <Typography
                  sx={{ color: "#616E80", fontWeight: "600", fontSize: "15px" }}
                >
                  {data ? data.username : "John wick"}
                </Typography>
                <Icon
                  icon="ep:arrow-down-bold"
                  width={18}
                  height={18}
                  color="#616E80"
                />
              </Box>
            </Box>
          </Stack>
        )}
      </AppToolbar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            borderRadius: 0,
            mt: 1.7,
          },
        }}
      >
        {popOverButton?.map((popover) => (
          <Box
            onClick={() => {
              if (popover.value === "accountProfile") {
                navigate(PATH_DASHBOARD.accountInformation);
              } else if (popover.value === "logout") {
                localStorage.removeItem("open");
                dispatch(logout());
              }
            }}
            sx={{ cursor: "pointer", borderRadius: 0 }}
          >
            <Typography
              sx={{
                px: 2,
                py: 1,
                color: popover?.value === "logout" ? "#FD0202" : "#616E80",
              }}
            >
              {popover?.label}
            </Typography>
          </Box>
        ))}
      </Popover>
    </AppBar>
  );
};
