import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
  Card,
  CardContent,
  Divider,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useRequestState } from "@app/hooks/use-request-state";
import { SvgColor } from "@app/components/svg-color";
import { sourceUrl } from "@app/constants/source_url";
import { selectCompanyId } from "@app/modules/company/selectors";
import AppTableHeadCell from "./AppTableHeadCell";
import { deleteCategory, getCategories } from "../slice";
import { selectCategoriesState, selectDeleteCategoryState } from "../selectors";
import { AddCategory } from "../addCategory";
import { Spacing } from "@app/components/spacing";
import { useNavigate } from "react-router-dom";
import { useDeleteAlert } from "@app/hooks/useAlertDialogue";

const ArticleTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showDeleteAlert } = useDeleteAlert();

  const companyId = useSelector(selectCompanyId);

  const [editClicked, setEditClicked] = useState<any>();

  useEffect(() => {
    dispatch(getCategories({ page: 1, companyId }));
  }, [companyId, dispatch]);

  const { data: category } = useRequestState({
    stateSelector: selectCategoriesState,
  });

  const handleEditClick = (item: any) => {
    setEditClicked(item);
  };

  useRequestState({
    stateSelector: selectDeleteCategoryState,
    successMessageShown: true,
    errorShown: true,
  });

  return (
    <TableContainer>
      <Card sx={{ padding: "0px" }}>
        <CardContent
          sx={{
            padding: "0px",
            ":last-child": {
              paddingBottom: "0px",
            },
          }}
          component={"form"}
        >
          <Typography
            sx={{ padding: "17px", fontWeight: 400, fontSize: "20.18px" }}
            gutterBottom
          >
            Article Categories
          </Typography>
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              paddingX: "20px",
              paddingY: "12px",
            }}
          >
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  height: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#555",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#f1f1f1",
                },
              }}
            >
              <Table sx={{ minWidth: 650 }}>
                <TableHead
                  sx={{ borderBottom: "1px", borderBottomColor: "red" }}
                >
                  <TableRow>
                    <AppTableHeadCell
                      cellProps={{
                        sx: {
                          minWidth: "140px",
                          padding: "20px 12px 8px 16px",
                          background: "#fff",
                        },
                      }}
                      typographyProps={{
                        sx: {
                          fontSize: "12.39px",
                          fontWeight: 500,
                          color: "#99A5B5",
                        },
                      }}
                      title="#"
                    />
                    <AppTableHeadCell
                      cellProps={{
                        sx: {
                          minWidth: "360px",
                          padding: "20px 12px 8px 0px",
                          background: "#fff",
                        },
                      }}
                      typographyProps={{
                        sx: {
                          fontSize: "12.39px",
                          fontWeight: 500,
                          color: "#99A5B5",
                        },
                      }}
                      title="Category Heading"
                    />
                    <AppTableHeadCell
                      cellProps={{
                        sx: {
                          minWidth: "250px",
                          padding: "20px 12px 8px 0px",
                          background: "#fff",
                        },
                      }}
                      typographyProps={{
                        sx: {
                          fontSize: "12.39px",
                          fontWeight: 500,
                          color: "#99A5B5",
                        },
                      }}
                      title="Articles"
                    />
                    <AppTableHeadCell
                      cellProps={{
                        sx: {
                          minWidth: "50px",
                          padding: "20px 0px 8px 0px",
                          display: "flex",
                          mr: 5,
                          justifyContent: { md: "center", lg: "right" },
                          background: "#fff",
                          px: 0,
                          width: { md: "85%", lg: "100%" },
                        },
                      }}
                      typographyProps={{
                        sx: {
                          fontSize: "12.39px",
                          fontWeight: 500,
                          color: "#99A5B5",
                          mr: { lg: 6, md: 0 },
                        },
                      }}
                      title="Action"
                    />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {category &&
                    category?.length > 0 &&
                    category.map((items: any, index: number) => (
                      <TableRow key={items.id}>
                        <TableCell>
                          <Typography
                            sx={{
                              fontSize: "11.88px",
                              fontWeight: 400,
                              color: "#4D4F5C",
                              textWrap: "nowrap",
                            }}
                          >
                            {index + 1}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              fontSize: "11.88px",
                              fontWeight: 400,
                              color: "#4D4F5C",
                            }}
                          >
                            {items.name}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography
                            sx={{
                              fontSize: "11.88px",
                              fontWeight: 400,
                              color: "#4D4F5C",
                            }}
                          >
                            {items?.articles && items?.articles?.length}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            display: "flex",
                            gap: "7px",
                            width: { md: "85%", lg: "100%" },
                            justifyContent: { md: "center", lg: "right" },
                            mr: 5,
                            px: 0,
                          }}
                        >
                          <Button
                            variant="outlined"
                            startIcon={
                              <SvgColor
                                sx={{ width: "14.63px", height: "13px" }}
                                src={sourceUrl.icons.edit}
                              />
                            }
                            sx={{
                              color: "#99A5B5",
                              backgroundColor: "#FFFFFF",
                              borderColor: "#99A5B5",
                              fontWeight: 400,
                              fontSize: "12.49px",
                              height: "35.09px",
                              width: "57.47px",
                              gap: "0.27px",
                              padding: "5.79px 5px 6.3px 5px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              handleEditClick(items);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            startIcon={
                              <SvgColor
                                sx={{
                                  width: "11.38px",
                                  height: "13px",
                                  paddingRight: "8px",
                                }}
                                src={sourceUrl.icons.delete}
                              />
                            }
                            sx={{
                              color: "#99A5B5",
                              backgroundColor: "#FFFFFF",
                              borderColor: "#99A5B5",
                              fontWeight: 400,
                              fontSize: "12.49px",
                              height: "35.09px",
                              width: "66.65px",
                              padding: "5.79px 5px 6.3px 5px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              showDeleteAlert({
                                description:
                                  "Are you sure you want to delete this category?",
                                onConfirm: () =>
                                  dispatch(
                                    deleteCategory({
                                      id: items?.id,
                                      companyId,
                                    })
                                  ),
                              });
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                ml: 1.5,
                width: "100%",
                paddingTop: "5px",
                height: "73px",
              }}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  height: "34px",
                  fontSize: "14px",
                  fontWeight: 400,
                  backgroundColor: "#FE6B6B",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </Box>
            <Divider sx={{ borderColor: "#F1F4F7" }} />
          </Box>
        </CardContent>
        {editClicked?.id && editClicked?.id?.length > 0 && (
          <AddCategory
            isModalOpen={true}
            setIsModalOpen={() => {
              setEditClicked(null);
            }}
            data={editClicked}
          />
        )}
      </Card>
    </TableContainer>
  );
};

export default ArticleTable;
