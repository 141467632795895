import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Box,
  Paper,
  IconButton,
  Stack,
  MenuItem,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { selectCompanyData } from "@app/modules/company/selectors";
import { DialogBox } from "@app/components/dialog";
import { Input, Label, MenuPopover, useMenuPopover, } from "@app/components";
import { Strings } from "@app/constants/strings";
import { SvgColor } from "@app/components/svg-color";
import { Icon } from "@iconify/react";
import { columns, projectUserDataColumnns } from "../../types";
import { getProjectUsers } from "@app/modules/account-information/slice";
import { useLocation } from "react-router";
import FilePreviewDialog from "@app/components/file-preview/FilePreview";
import { useDialog } from "@app/hooks/use-dialog";
import { IProjectFormSchema } from "@app/modules/project";
import { formSchema } from "@app/modules/project/validator";
import { enqueueSnackbar } from "notistack";
import { addProject } from "@app/modules/project/slice";
import { selectProjectUsers } from "@app/modules/account-information/selector";
import TablePaginationCustom from "@app/components/table/TablePagination";

const ProjectUsers = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const data = useSelector(selectCompanyData);
  const [selectedRows, setSelectedRows] = useState<readonly string[]>([]);
  const location = useLocation();
  const companyId: any = location.state.companyId;
  const projectId: any = location.state?.project?.id;
  const [selectedPreviewFile, setSelectedPreviewFile] = useState<any>(null);
  const [projectData, setProjectData] = useState([]);
  const [pageValue, setPageValue] = useState(1);
  const [pageLimitValue, setPageLimitValue] = useState<number | string>(10);
  const projectUsers: any = useSelector(selectProjectUsers);
  const menuPopover = useMenuPopover();
  const actions = [
    {
      label: "Delete",
      // callback: () => dispatch(deleteSelectedCompany(data?.id)),
    },
  ];

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedRows(data.map((n: any) => n.id));
      return;
    }
    setSelectedRows([]);
  };

  const handleSelectSingleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    setSelectedRows((prev) =>
      event.target.checked ? [...prev, id] : prev.filter((item) => item !== id)
    );
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IProjectFormSchema>({
    resolver: yupResolver(formSchema),
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    reset();
    setOpen(false);
  };


  useEffect(() => {
    if (projectId) {
      dispatch(
        getProjectUsers({
          page: pageValue,
          limit: pageLimitValue,
          projectId: projectId,
        })
      );
    }
  }, [projectId, dispatch, pageValue, pageLimitValue]);

  const onSubmit = (data: IProjectFormSchema) => {
    if (companyId) {
      const projectName = projectData?.find((project: any) => project.name === data.projectName);
      if (projectName) {
        enqueueSnackbar(`${data.projectName} already exist`, {
          variant: "error",
        });
        return
      } else {
        dispatch(addProject({ companyId, ...data }));
        handleClose();
      }
    } else {
      handleClose();
    }

  };

  const filePreviewDialog = useDialog();




  return (
    <>
      <Box sx={{ width: "80%", padding: "0px", marginX: "auto" }}>
        <Box>
          <Button sx={{ marginTop: "20px", marginLeft: "10px" }} variant="contained" onClick={handleOpen} disabled={true}>
            Select Additional Users
          </Button>
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Paper
          sx={{ width: "100%", borderRadius: "8px", overflow: "hidden", mb: 2 }}
        >
          <TableContainer>
            <Table>
              {/* HEADER SECTION */}
              <TableHead>
                <TableRow>
                  {projectUserDataColumnns?.map((col: any) => (
                    <TableCell
                      key={col.id}
                      sx={{
                        width: col.width || "auto",
                        color: "#6B7280",
                        fontWeight: 500,
                        fontSize: "14px",
                        textAlign: col.id === "checkbox" ? "left" : "center",
                        backgroundColor: "transparent",
                      }}
                    >
                      {col.id === "checkbox" ? (
                        <Checkbox
                          size="small"
                          indeterminate={
                            selectedRows.length > 0 &&
                            selectedRows.length < data.length
                          }
                          checked={
                            data.length > 0 && selectedRows.length === data.length
                          }
                          onChange={handleSelectAllClick}
                          disabled={data.length === 0}
                          sx={{ padding: 0 }}
                        />
                      ) : (
                        col.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* BODY SECTION */}
              <TableBody>
                {projectUsers?.data?.data.length > 0 ? (
                  projectUsers?.data?.data.map((project: any, index: number) => (
                    <TableRow key={project.id} sx={{ height: "55px" }}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          size="small"
                          checked={selectedRows.includes(project.id)}
                          onChange={(e) => handleSelectSingleCheck(e, project.id)}
                        />
                      </TableCell>
                      <TableCell sx={{ color: "#6B7280", fontSize: "14px", textAlign: "center" }}>
                        SUP - {index + 1}
                      </TableCell>
                      <TableCell sx={{ color: "#6B7280", fontSize: "14px", display: "flex", alignItems: "center", justifyItems: "center" }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: project.isActive
                              ? "#E0FCDC"
                              : "#F3F4F6",
                            color: project.isActive ? "#256029" : "#6B7280",
                            borderRadius: "16px",
                            padding: "4px 10px",
                            fontSize: "14px",
                            fontWeight: 500,
                            width: "fit-content",
                            gap: "6px",
                            ml: 13
                          }}
                        >
                          <Box
                            sx={{
                              width: "22px",
                              height: "22px",
                              backgroundColor: project.isActive
                                ? "#34A853"
                                : "#9CA3AF",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              ml: -1,
                            }}
                          >
                            {project.isActive && (
                              <img
                                src="/assets/images/check_icon.png"
                                alt="check-circle-icon"
                                style={{ width: "22px", height: "22px" }}
                              />
                            )}
                          </Box>
                          {project.isActive ? "Active" : "Inactive"}
                        </Box>
                      </TableCell>
                      {/* Company Name */}
                      <TableCell
                        sx={{ textAlign: "center" }}
                      >
                        {project.firstName} {project.lastName}
                      </TableCell>

                      {/* Date Created */}

                      {/* Company Status */}
                      <TableCell
                        sx={{ textAlign: "center" }}
                      >
                        {new Date(project.dateOfBirth).toLocaleDateString()}
                      </TableCell>
                      {/* email */}
                      <TableCell
                        sx={{ textAlign: "center" }}
                      >
                        {project.email}
                      </TableCell>

                      {/* Actions */}
                      <TableCell>
                        <IconButton onClick={menuPopover.show} sx={{ color: "black" }}>
                          <Icon icon="eva:more-vertical-fill" />
                        </IconButton>
                        {/* Menu Popover */}
                        <MenuPopover
                          open={menuPopover.rowMenuAnchorElement}
                          arrow="right-top"
                          sx={{ width: 180 }}
                          onClose={menuPopover.hide}
                        >
                          {actions?.map((action) => (
                            <MenuItem
                              key={action.label}
                            // onClick={() => {
                            //   // onClickAction();
                            //   // action.callback?.(project);
                            // }}
                            // disabled={action.disabled}
                            >
                              {action.label}
                            </MenuItem>
                          ))}
                        </MenuPopover>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      align="center"
                      sx={{ py: 3 }}
                    >
                      <Typography variant="body1" color="text.secondary">
                        No Documents found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePaginationCustom
            onPageDenseChange={(e) => {
              setPageLimitValue(e.target.value);
            }}
            totalItems={projectUsers?.data?.totalItems}
            totalPage={projectUsers?.data?.totalPages}
            itemsPerPage={projectUsers?.data?.itemsPerPage}
            currentPage={projectUsers?.data?.currentPage}
            showingCustomText={projectUsers?.data?.showing}
            onPageChange={(e, currentPage) => {
              setPageValue(currentPage);
            }}
            sx={{
              background: "#fff",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
          />
        </Paper>
        <DialogBox
          open={open}
          onClose={handleClose}
          onSave={handleSubmit(onSubmit)}
          title="Create New Project"
          saveButtonText="Save"
          cancelButtonText="Cancel"
          saveButtonStyle={{ fontWeight: "400", fontSize: "14px" }}
          cancelButtonStyle={{ fontWeight: "400", fontSize: "14px" }}
        >
          <Label>Project Name</Label>
          <Input
            name="projectName"
            control={control}
            label="Company Name"
            placeHolder={Strings.field.inputPlaceholder("Project Name")}
          // error={errors?.projectName?.message}
          />
          <Label>Upload Picture</Label>
          <Controller
            name="imageUpload"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <>
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  style={{ display: "none" }}
                  id="uploadProjectPic"
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      field.onChange(e.target.files[0]); // Pass the file to react-hook-form
                    }
                  }}
                />
                {!field.value ? (
                  <Stack
                    component="label"
                    htmlFor="uploadProjectPic"
                    sx={{
                      width: "100%",
                      height: "120px",
                      border: "1px solid #e5e7eb",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                  >
                    <SvgColor
                      src={`/assets/icons/Upload.svg`}
                      sx={{ width: 80, height: 80, color: "#98A5B5" }}
                    />
                    <Typography variant="body2" color="textSecondary">
                      Click to upload an image
                    </Typography>
                    {/* {errors?.imageUpload?.message && (
                              <Typography variant="body2" color="error"></Typography>
                            )} */}
                  </Stack>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                    }}
                  >
                    <Box
                      component="img"
                      src={URL.createObjectURL(field.value)}
                      sx={{
                        width: "100%",
                        height: "120px",
                        borderRadius: "8px",
                        display: "flex",
                        objectFit: "contain",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        cursor: "pointer",
                      }}
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: "5px",
                        right: "14px",
                      }}
                      onClick={() => {
                        field.onChange(null);
                      }}
                    >
                      <Icon
                        icon="fontisto:close"
                        width="28"
                        height="28"
                        color="#98A5B5"
                      />
                    </IconButton>
                  </Box>
                )}
              </>
            )}
          />
        </DialogBox>
        {filePreviewDialog.visible && (
          <FilePreviewDialog
            open
            onClose={() => {
              filePreviewDialog.hide();
            }}
            fileUrl={selectedPreviewFile && selectedPreviewFile.fileUrl}
            fileName={selectedPreviewFile && selectedPreviewFile.fileName}
          />
        )}
      </Box>
    </>
  );
};

export default ProjectUsers;