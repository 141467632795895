import { Outlet } from "react-router-dom";
import { Header } from "@app/components";
import { Stack } from "@mui/material";

export const OnboardingLayout = () => {
  return (
    <>
      <Header isAuthHeader={true} isOnboarding={true} />
      <Stack
        sx={{
          width: "100%",
          backgroundColor: "#fff",
          borderRadius: "5px",
          overflowY: "auto",
          mt: "80px",
          mb: "20px",
          mx: "auto",
        }}
      >
        <Outlet />
      </Stack>
    </>
  );
};
