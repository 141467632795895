/* eslint-disable @typescript-eslint/no-explicit-any */
/*
 * Root Reducer
 * combine all reducers to create root reducer
 */

import { employeeReducer } from "@app/modules/add_employee/slice";
import { authReducer, logout } from "@app/modules/auth/slice";
import { bootstrapReducer } from "@app/modules/bootstrap";
import { commonReducer } from "@app/modules/common";
import { companyReducer } from "@app/modules/company/slice";
import { projectReducer } from "@app/modules/project/slice";
import { designationReducer } from "@app/modules/designation/slice";
import { combineReducers, type Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import Storage from "redux-persist/lib/storage";
import { departmentReducer } from "@app/modules/department/slice";
import { employementTypeReducer } from "@app/modules/EmployeeType/slice";
import { accountInformationReducer } from "@app/modules/account-information/slice";
import { contractorReducer } from "@app/modules/Contractor/slice";
import { employeeProfileReducer } from "@app/modules/employee-profile/slice";
import { knowledgeReducer } from "@app/modules/knowledge/slice";
import { employeeRoleReducer } from "@app/modules/employee-roles/slice";
import { subscriptionInformationReducer } from "./../modules/account-information/plans-billing/slice";
import { qualificationReducer } from "@app/modules/qualification/slice";
import { adminReducer } from "@app/modules/admin/slice";

/* -----[ persist configurations ]------ */
const rootPersistConfig = {
  key: "root",
  storage: Storage,
  whitelist: ["version", "commonReducer", "companyReducer", "projectReducer"],
};

// Preserver below slice even after logout
const preserveAlways = ["bootstrapReducer"];
const stateVersion = 1; // Update it whenever change state structure of whitelist slice

const appReducer = combineReducers({
  bootstrapReducer,
  commonReducer,
  authReducer,
  companyReducer,
  projectReducer,
  employeeReducer,
  designationReducer,
  departmentReducer,
  employementTypeReducer,
  employeeRoleReducer,
  accountInformationReducer,
  contractorReducer,
  employeeProfileReducer,
  knowledgeReducer,
  subscriptionInformationReducer,
  qualificationReducer,
  adminReducer,
  version: (state = stateVersion) => state,
});

const rootReducer = (
  state: ReturnType<typeof appReducer>,
  action: Action<any>
) => {
  if (action.type === logout.type) {
    const newState: any = Object.keys(state).reduce((acc, key) => {
      if (preserveAlways.includes(key)) {
        (acc as any)[key] = (state as any)[key];
      } else {
        (acc as any)[key] = undefined;
      }
      return acc;
    }, {});
    return appReducer(newState, action);
  }

  return appReducer(state, action);
};

const persistRootReducer = persistReducer(
  rootPersistConfig,
  (state, action) => {
    const migratedState = migrateState(state);
    return rootReducer(migratedState, action);
  }
);

const migrateState = (state: any) => {
  if (state !== undefined && state.version !== stateVersion) {
    return {
      ...state,
      version: stateVersion,
    };
  }

  return state;
};

export type RootState = ReturnType<typeof rootReducer>;

export { persistRootReducer };
