import "react-quill/dist/quill.snow.css";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  useTheme,
  IconButton,
} from "@mui/material";
import ReactQuill, { Quill } from "react-quill";
import QuillResizeImage from "quill-resize-image";
import dayjs from "dayjs";
import { get, remove } from "lodash";

import { Input, Label } from "@app/components";
import { SvgColor } from "@app/components/svg-color";
import {
  FormProvider,
  RHFDatePicker,
  RHFFilePickerField,
  RHFSelect,
} from "@app/components/hook-form";
import { useDialog } from "@app/hooks/use-dialog";
import { sourceUrl } from "@app/constants/source_url";
import { useRequestState } from "@app/hooks/use-request-state";
import { IAddNewArticle } from "./types";
import { addKnowledgeBase, getCategories } from "./slice";
import { UploadFiles } from "./upload-files";
import { selectAddKnowledgeBase, selectCategoriesState } from "./selectors";
import { selectCompanyId } from "../company/selectors";
import { LoadingButton } from "@mui/lab";
import { Strings } from "@app/constants/strings";
import { AddCategory } from "./addCategory";
import { Replay } from "@mui/icons-material";

Quill.register("modules/imageResize", QuillResizeImage);
const schema = yup.object().shape({
  articleHeading: yup.string().required("article  title required"),
  revisionNumber: yup.string().required("revision number required"),
});
export const AddNewArticle = () => {
  const theme = useTheme();
  const { state } = useLocation();
  const items = state?.items;

  const [removeFiles, setRemoveFiles] = useState<any[]>([]);
  const methods = useForm<IAddNewArticle>({
    resolver: yupResolver(schema),
    defaultValues: {
      id: get(items, "id", ""),
      articleHeading: get(items, "heading", ""),
      revisionNumber: get(items, "revisionNumber", ""),
      date: get(items, "date", ""),
      description: get(items, "description", "") || "", // Make sure to have a fallback empty string
      categoryId: get(items?.category, "id", ""),
      files: Array.isArray(get(items, "files", []))
        ? get(items, "files", []).map((file: any) => ({
          fileName: get(file, "fileName", ""),
          fileUrl: get(file, "fileUrl", ""),
        }))
        : [],
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    watch,
  } = methods;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addFileDialog = useDialog();

  const companyId = useSelector(selectCompanyId);

  const handleCancel = () => {
    navigate(-1);
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
  };

  useEffect(() => {
    dispatch(getCategories({ page: 1, companyId }));
  }, [companyId, dispatch]);

  const handleNavigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const { loading } = useRequestState({
    stateSelector: selectAddKnowledgeBase,
    successMessageShown: true,
    errorShown: true,
    onSuccess: handleNavigateBack,
  });

  interface EditorProps {
    name: string;
    defaultValue?: string;
    form: any;
  }

  const Editor = ({ name, defaultValue, form }: EditorProps) => {
    const { control } = form;

    return (
      <Stack
        style={{
          position: "relative",
          border: "1px solid #ccc",
          borderRadius: "4px",
          overflow: "hidden",
          maxHeight: "250px",
        }}
      >
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue || ""}
          render={({ field }) => (
            <ReactQuill
              theme="snow"
              modules={modules}
              formats={formats}
              value={field.value}
              onChange={field.onChange}
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            />
          )}
        />
      </Stack>
    );
  };

  const { data } = useRequestState({
    stateSelector: selectCategoriesState,
  });
 
  const onUploadFile = (data: any) => {
    addFileDialog.hide();
    const currentFiles = watch("files") || [];
    setValue("files", [...currentFiles, data]);
  };

  // const handleRemoveFile = (indexToRemove: number) => {
  //   const currentFiles = watch("files") || [];
  //   const updatedFiles = currentFiles.filter(
  //     (_: any, index: any) => index !== indexToRemove
  //   );
  //   setValue("files", updatedFiles);
  // };
  // const handleRemoveFile = (indexToRemove: number) => {
  //   const currentFiles = watch("files") || [];
  //   // Extract the file URL before removing
  //   const removedFile = currentFiles[indexToRemove];
  //   setRemoveFiles([...removeFiles, removedFile]);
  //   // setValue("files", [...currentFiles, removedFile]);

  // };
  const handleRemoveFile = (indexToRemove: number) => {
    const currentFiles = watch("files") || [];
  
    // Extract the file before removing
    const removedFile = currentFiles[indexToRemove];
  
    // Update removeFiles state
    setRemoveFiles([...removeFiles, removedFile]);
  
    // Remove the file from the form field
    const updatedFiles = currentFiles.filter((_:any, index:any) => index !== indexToRemove);
    setValue("files", updatedFiles, { shouldValidate: true });
  };
  


  const handleRetrieveFile = (file: any) => {
    const currentFiles = watch("files") || [];
    const removedFileIndex = removeFiles.findIndex((f: any) => f.fileUrl === file.fileUrl);
    if (removedFileIndex === -1) return;
    const removedFile = removeFiles[removedFileIndex];
    const updatedRemoveFiles = removeFiles.filter((_, index) => index !== removedFileIndex);
    setRemoveFiles(updatedRemoveFiles);
    // setValue("files", updatedFiles);
  };




  const handleAddNewArticle = (form: any) => {
    const {
      articleHeading,
      revisionNumber,
      date,
      description,
      categoryId,
      files,
      id,
    } = form;

    const {
      control,
      formState: { errors },
      handleSubmit,
    } = methods;

    dispatch(
      addKnowledgeBase({
        heading: articleHeading,
        revisionNumber: revisionNumber,
        date: date,
        description,
        categoryId,
        files,
        id,
        removeFiles: removeFiles,
      })
    );
  };

  const files = watch("files") || [];
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getFilePreview = (file: any) => {
    if (typeof file === "object") {
      if (file?.type) {
        // Handle File objects
        if (
          file.type.includes("jpeg") ||
          file.type.includes("jpg") ||
          file.type.includes("png")
        ) {
          return URL.createObjectURL(file);
        } else if (file.type.includes("pdf")) {
          return "/pdfIcon.png";
        }
      } else if (file?.fileUrl && file?.fileName) {
        // Handle file objects from edit state
        if (
          file.fileName.endsWith(".jpg") ||
          file.fileName.endsWith(".jpeg") ||
          file.fileName.endsWith(".png")
        ) {
          return file.fileUrl;
        } else if (file.fileName.endsWith(".pdf")) {
          return "/pdfIcon.png";
        }
      }
    } else if (typeof file === "string") {
      // Handle string-based file URLs
      if (
        file.endsWith(".jpg") ||
        file.endsWith(".jpeg") ||
        file.endsWith(".png")
      ) {
        return file;
      } else if (file.endsWith(".pdf")) {
        return "/pdfIcon.png";
      }
    }
    return file;
  };

  const getFileName = (file: any) => {
    if (typeof file === "object") {
      if (file?.name) {
        return file.name; // For new uploads
      } else if (file?.fileName) {
        return file.fileName; // For edit mode with fileName
      }
    } else if (typeof file === "string") {
      return file.split("/").pop(); // Extract file name from URL
    }
    return "";
  };

  return (
    <FormProvider methods={methods}>
      <Stack sx={{ borderRadius: "8px", padding: "20px" }}>
        <Stack
          sx={{ width: "100%", backgroundColor: "#fff", borderRadius: "5px" }}
        >
          <Card sx={{ padding: "0px" }}>
            <CardContent
              sx={{
                padding: "0px",
                ":last-child": {
                  paddingBottom: "0px",
                },
                gap: "15px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  paddingTop: "5px",
                  paddingLeft: "20px",
                  fontWeight: 400,
                  fontSize: "20px",
                  lineHeight: "24px",
                }}
                gutterBottom
              >
                Add New Article
              </Typography>
              <Divider sx={{ borderColor: "#F1F4F7" }} />
              <Box
                sx={{
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  px: 10,
                  paddingY: "12px",
                }}
              >
                <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
                  <Box sx={{ width: "50%" }}>
                    <Label>Article Heading</Label>
                    <Input
                      name="articleHeading"
                      control={control}
                      placeHolder={Strings.field.inputPlaceholder(
                        "Article title"
                      )}
                      error={errors.articleHeading?.message}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Label>Article Category</Label>
                    <RHFSelect
                      name={"categoryId"}
                      control={control}
                      errors={errors}
                      placeholder={"Category"}
                      menuItemsData={data ?? []}
                      sx={{ borderRadius: "5px", border: "1px solid #98A5B5" }}
                      addButtonName={"Add New Category"}
                      onAddOption={() => {
                        setIsModalOpen(true);
                      }}
                      returnId={true}
                    />
                  </Box>
                </Box>

                <Box sx={{ width: "50%" }}>
                  <Label>Revision Number</Label>
                  <Input
                    name="revisionNumber"
                    control={control}
                    placeHolder={Strings.field.inputPlaceholder(
                      "Revision Number"
                    )}
                    error={errors.revisionNumber?.message}
                  />
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Label>Date</Label>
                  <RHFDatePicker
                    name="date"
                    control={control}
                    trigger={() => { }}
                    defaultValue={
                      items?.date ? dayjs(items?.date, "DD/MM/YYYY") : null
                    }
                    setValue={setValue}
                    label={""}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  px: 10,
                  paddingY: "10px",
                }}
              >
                <Label>Description</Label>
                <Editor
                  name="description"
                  defaultValue={get(items, "description", "")}
                  form={methods}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  paddingY: "10px",
                  px: 10,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Label>Upload Files</Label>
                </Box>

                <Box sx={{ mt: 2 }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ flexWrap: "wrap", gap: 2, mb: 2 }}
                  >
                    {files.map((file: any, index: number) => {
                      const isRemoved = Array.isArray(removeFiles)
                        ? removeFiles.some((removedFile: any) => {
                          if (file.fileUrl && removedFile.fileUrl) {
                            return removedFile.fileUrl === file.fileUrl;
                          }
                          return file instanceof Blob && removedFile instanceof Blob && file === removedFile;
                        }): false;
                      return (
                        <Box
                          key={index}
                          sx={{
                            position: "relative",
                            border: "1px solid #98A5B5",
                            borderRadius: "5px",
                            width: "150px",
                            height: "150px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "8px",
                            opacity: isRemoved ? 0.4 : 1,
                            // pointerEvents: isRemoved ? "none" : "auto",
                          }}
                        >
                          {isRemoved ? (
                            <IconButton
                              size="small"
                              sx={{
                                position: "absolute",
                                top: 2,
                                right: 2,
                                backgroundColor: "#FE6B6B",
                                color: "#fff",
                                "&:hover": {
                                  backgroundColor: "#d85858",
                                },
                                width: "24px",
                                height: "24px",
                              }}
                              onClick={() => handleRetrieveFile(file)}
                            >
                              <Replay />
                            </IconButton>
                          ) : <IconButton
                            size="small"
                            sx={{
                              position: "absolute",
                              top: 2,
                              right: 2,
                              backgroundColor: "#FE6B6B",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#d85858",
                              },
                              width: "24px",
                              height: "24px",
                            }}
                            onClick={() => handleRemoveFile(index)}
                          >
                            ✕
                          </IconButton>}
                          <Box
                            component="img"
                            src={getFilePreview(file)}
                            alt={getFileName(file)}
                            sx={{
                              maxWidth: "100%",
                              maxHeight: "100px",
                              objectFit: "contain",
                            }}
                          />
                          <Typography
                            variant="caption"
                            sx={{
                              textAlign: "center",
                              mt: 1,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "100%",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getFileName(file)}
                          </Typography>
                        </Box>
                      );
                    })}

                  </Stack>
                </Box>

                <RHFFilePickerField
                  name={"file"}
                  label={"Choose a file"}
                  control={control}
                  onUploadFile={onUploadFile}
                  isArticle={true}
                />
              </Box>
              <Divider sx={{ borderColor: "#F1F4F7" }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingX: "40px",
                  paddingY: "10px",
                  height: "73px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => handleCancel()}
                  sx={{
                    backgroundColor: "#FE6B6B",
                    color: "#fff",
                    height: "34px",
                    width: "66px",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  Cancel
                </Button>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    onClick={handleSubmit(handleAddNewArticle)}
                    sx={{
                      height: "34px",
                      width: "68px",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </Box>

              <AddCategory
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />

              {addFileDialog.visible && (
                <UploadFiles
                  open
                  onClose={addFileDialog.hide}
                  onUploadFile={onUploadFile}
                />
              )}


            </CardContent>
          </Card>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
