import React from 'react';
import { Typography, SxProps, Theme } from '@mui/material';
import { IndicatorCircleProps } from './types';

export const IndicatorCircle: React.FC<IndicatorCircleProps> = ({
    color,
    size = 10,
    status,
    sx
}) => {
    const statusColors: Record<string, string> = {
        success: '#11EF4F',
        warning: '#FFC107',
        danger: '#F44336',
        info: '#2196F3',
    };

    const circleColor = color || (status ? statusColors[status] : '#000');

    return (
        <Typography
            component="span"
            sx={{
                display: 'inline-block',
                width: size,
                height: size,
                borderRadius: '50%',
                backgroundColor: circleColor,
                ...sx,
            }}
        />
    );
};
