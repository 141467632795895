/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";

/**
 * Same as useEffect, except it will never runs on mount.
 *
 * @param {Function} effect - A useEffect effect.
 * @param {Array<any>} [dependencies] - useEffect dependency list.
 */
export const useEffectExceptOnMount = (
  effect: Function,
  dependencies: any[]
) => {
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      const unmount = effect();
      return () => unmount?.();
    }
    mounted.current = true;
  }, dependencies);

  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );
};
