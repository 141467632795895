import {
  MenuPopover,
  RowActions as MenuRowActions,
  useMenuPopover,
} from "@app/components";
import { useDialog } from "@app/hooks/use-dialog";
import { Icon } from "@iconify/react";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { useRequestState } from "@app/hooks/use-request-state";
import { useDeleteAlert } from "@app/hooks/useAlertDialogue";

const RowActions = ({
  data,
  deleteAction,
  DialogComponent,
  notEditable = false,
}: any) => {
  const dispatch = useDispatch();

  const menuPopover = useMenuPopover();
  const editDialog = useDialog();
  const { showDeleteAlert } = useDeleteAlert();

  const actions = [
    ...(notEditable
      ? []
      : [
          {
            label: "Edit",
            callback: editDialog.show,
          },
        ]),
    {
      label: "Delete",
      callback: () =>
        showDeleteAlert({
          description: `Are you sure you want to delete ${data?.name}? This action cannot be undone.`,
          onConfirm: () => dispatch(deleteAction({ id: data?.id })), // Perform deletion on confirm
        }),
    },
  ];

  return (
    <>
      <IconButton onClick={menuPopover.show} sx={{ color: "default" }}>
        <Icon icon="eva:more-vertical-fill" />
      </IconButton>

      <MenuPopover
        open={menuPopover.rowMenuAnchorElement}
        arrow="right-top"
        sx={{ width: 180 }}
        onClose={menuPopover.hide}
      >
        <MenuRowActions actions={actions} onClickAction={menuPopover.hide} />
      </MenuPopover>
      {DialogComponent && editDialog.visible && (
        <DialogComponent
          open
          onClose={() => {
            editDialog.hide();
          }}
          editData={data}
        />
      )}
    </>
  );
};

export { RowActions };
