import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { Input } from "@app/components";
import {
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { FormProvider, RHFSelect } from "@app/components/hook-form";
import { editMedicalHistory } from "./slice";
import { useRequestState } from "@app/hooks/use-request-state";
import { selectEditMedicalHistory } from "./selector";
import { forwardRef, useImperativeHandle } from "react";

const MedicalHistory = forwardRef(({ edit, employee, medicalHistory }: any, ref) => {
  const theme = useTheme();
  const methods = useForm({
    defaultValues: {
      beenInHospital: employee?.medicalInformation?.beenInHospital,
      hasHealthCondition: employee?.medicalInformation?.hasHealthCondition,
      healthConditionDetails:
        employee?.medicalInformation?.healthConditionDetails,
      hospitalReason: employee?.medicalInformation?.hospitalReason,
      outOfWork: employee?.medicalInformation?.outOfWork,
      outOfWorkDuration: employee?.medicalInformation?.outOfWorkDuration,
      understandsAlcoholDrugTesting:
        employee?.medicalInformation?.understandsAlcoholDrugTesting,
      id: employee?.medicalInformation?.id,
    },
  });
  const { control } = methods;
  const dispatch = useDispatch();

  const { loading } = useRequestState({
    stateSelector: selectEditMedicalHistory,
    successMessageShown: true,
    errorShown: true,
  });

  const medicalInformation = [
    {
      id: 1,
      question: "Have you been out of work before starting this job?",
      answer: "No",
      inputName: "outOfWork",
      inputType: "select",
      value: [
        { name: "Yes", id: "Yes" },
        { name: "No", id: "No" },
      ],
    },
    {
      id: 2,
      question: "Please specify how long have you been out of work.",
      answer: "N/A",
      inputName: "outOfWorkDuration",
      inputType: "texField",
    },
    {
      id: 3,
      question: "Have you been in the hospital in the last 3 months",
      answer: "No",
      inputName: "beenInHospital",
      inputType: "select",
      value: [
        { name: "Yes", id: "Yes" },
        { name: "No", id: "No" },
      ],
    },
    {
      id: 4,
      question:
        "Please specify why you have been in the hospital in the last 3 months before starting this job",
      answer: "N/A",
      inputName: "hospitalReason",
      inputType: "texField",
    },
    {
      id: 5,
      question:
        "Do you suffer from any health condition or take any medication that could affect you at work?",
      answer: "No",
      inputName: "hasHealthCondition",
      inputType: "select",
      value: [
        { name: "Yes", id: "Yes" },
        { name: "No", id: "No" },
      ],
    },
    {
      id: 6,
      question: "Please give us more details of your medical condition.",
      answer: "N/A",
      inputName: "healthConditionDetails",
      inputType: "texField",
    },
    {
      id: 7,
      question:
        "Do you understand that we carryout random alcohol and drug testing? ",
      answer: "No",
      inputName: "understandsAlcoholDrugTesting",
      inputType: "select",
      value: [
        { name: "Yes", id: "Yes" },
        { name: "No", id: "No" },
      ],
    },
  ];

  useRequestState({
    stateSelector: selectEditMedicalHistory,
    successMessageShown: true,
    errorShown: true,
  });

  const onSubmit = (form: any) => {
    dispatch(editMedicalHistory({ ...form, employeeId: employee?.id }));
  };

  useImperativeHandle(ref, () => ({
    submitForm: methods.handleSubmit(onSubmit),
  }));

  return (
    <>
      <Card
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          padding: 2,
          gap: 1.5,
        }}
      >
        <FormProvider methods={methods}>
          <Stack>
            <Stack>
              <Stack sx={{ flex: 1 }}>
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <Stack sx={{ pl: 4 }}>
                          <Typography
                            sx={{
                              color: "#616E80",
                              fontWeight: 600,
                              fontStyle: "italic",
                              fontSize: "16px",
                            }}
                          >
                            Medical Information
                          </Typography>
                        </Stack>
                      </TableRow>
                      {medicalInformation?.map((medical, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flex: 1,
                          }}
                        >
                          <TableCell
                            sx={{
                              borderBottom: "none",
                              fontWeight: 700,
                              flex: 0.8,
                            }}
                          >
                            {medical?.question}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "none",
                              color: theme.palette.primary.main,
                              flex: 0.2,
                              display: "flex",
                            }}
                          >
                            {edit ? (
                              medical.inputType.toLowerCase().trim() ===
                                "select" ? (
                                <Stack
                                  sx={{
                                    width: "100%",
                                  }}
                                >
                                  <RHFSelect
                                    name={medical?.inputName}
                                    control={control}
                                    sx={{ width: "100%", flex: 1 }}
                                    menuItemsData={medical?.value ?? []}
                                    size="small"
                                  />
                                </Stack>
                              ) : (
                                <Input
                                  name={medical.inputName}
                                  control={control}
                                  size={"small"}
                                  placeHolder={medical?.question}
                                />
                              )
                            ) : (
                              <Typography
                                variant="body1"
                                color="primary"
                                sx={{ fontSize: "16px", fontWeight: 500 }}
                              >
                                {medicalHistory
                                  ? medicalHistory[medical.inputName]
                                  : "N/A"}
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Stack>
          </Stack>
        </FormProvider>
      </Card>
    </>
  );
});

export { MedicalHistory };
