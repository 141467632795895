import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

import { FormProvider } from "@app/components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRequestState } from "@app/hooks/use-request-state";
import { Input } from "@app/components";
import { addNewCategory } from "./slice";
import { selectCompanyId } from "../company/selectors";
import { selectAddNewCategoryState } from "./selectors";
import { ICreateCategory } from "./types";
import { Strings } from "@app/constants/strings";

const schema = yup.object().shape({
  name: yup.string().required("Name required"),
});
interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: any;
  data?: any;
}
const AddCategory = ({ isModalOpen, setIsModalOpen, data }: IProps) => {
  const dispatch = useDispatch();
  const methods = useForm<ICreateCategory>({
    resolver: yupResolver(schema),
    defaultValues: {
      id: data?.id,
      name: data?.name,
    },
  });

  const closePopup = useCallback(() => {
    methods.reset({
      id: "",
      name: "",
    });
    setIsModalOpen(false);
  }, []);

  const { loading } = useRequestState({
    stateSelector: selectAddNewCategoryState,
    successMessageShown: true,
    errorShown: true,
    onSuccess: closePopup,
  });

  const companyId = useSelector(selectCompanyId);

  const onSubmit = (form: any) => {
    dispatch(addNewCategory({ ...form, companyId: companyId }));
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;
  return (
    <Dialog open={isModalOpen}>
      <FormProvider methods={methods}>
        <Stack sx={{ width: "100%" }}>
          <Divider sx={{ color: "#F1F4F7" }} />
        </Stack>
        <DialogContent
          sx={{
            width: "600px",
            display: "flex",
            padding: "5px",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "400",
              ml: 1,
              color: "#000000",
            }}
          >
            Create Category
          </Typography>
          <Divider />
        </DialogContent>
        <Divider sx={{ borderColor: "#F1F4F7" }} />
        <Stack
          sx={{ paddingY: "5px", paddingX: "13%", justifyContent: "center" }}
        >
          <Card>
            <Box sx={{ width: "100%", padding: "15px" }}>
              <Typography
                sx={{ color: "#616E80", fontSize: "14px", fontWeight: "400" }}
              >
                Category Name
              </Typography>
              <Input
                name="name"
                control={control}
                placeHolder={Strings.field.inputPlaceholder("Category Name")}
                error={errors.name?.message}
              />
            </Box>
          </Card>
        </Stack>
        <Divider />
        <Stack
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            paddingY: "8px",
            paddingX: "12px",
          }}
        >
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setIsModalOpen(false);
            }}
            sx={{
              ml: 1,
              backgroundColor: "#FE6B6B",
              fontSize: "14px",
              fontWeight: "400",
              width: "66px",
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: "#0177FB",
              fontSize: "14px",
              fontWeight: "400",
              width: "66px",
            }}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Dialog>
  );
};

export { AddCategory };
