import { Box, Stack, Typography, Button, Card, Divider } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PATH_INVITATION } from "@app/routes/paths";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getSelectedCompanyById } from "../company/slice";

export const InvitationScreen = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const employeeId = searchParams.get("id");
  const companyId = searchParams.get("company");
  const dispatch = useDispatch();
  // testing my mac

  useEffect(() => {
    dispatch(getSelectedCompanyById({ id: companyId }));
  }, [dispatch, employeeId, companyId]);

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          backgroundColor: "#fff",
          borderRadius: "5px",
          mx: "auto",
        }}
      >
        <Card
          style={{
            fontFamily: "Arial, sans-serif",
            lineHeight: "1.6",
            maxWidth: "800px",
            margin: "0px auto",
            borderRadius: "8px",
            textAlign: "left",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              paddingTop: "5px",
              display: "flex",
              justifyContent: "center",
            }}
            gutterBottom
          >
            Invitation to Start Company Induction
          </Typography>
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Stack sx={{ padding: "40px" }}>
            <p>Dear Sir or Madam,</p>

            <p>
              Welcome to <strong>IP Builders Brickwork Limited!</strong>
            </p>

            <p>
              We are pleased to have you join our team and take the first step
              in getting to know our company better through the online induction
              program. This program is designed to familiarize you with our
              values, procedures, and expectations, ensuring you have a smooth
              and informed start to your journey with us.
            </p>

            <h3 style={{ marginBottom: "10px", marginTop: "20px" }}>
              What to Expect:
            </h3>
            <ul style={{ paddingLeft: "20px" }}>
              <li>
                An overview of our company’s history, mission, and vision.
              </li>
              <li>Key policies, safety protocols, and employee guidelines.</li>
              <li>
                Insights into our projects and the role you’ll play in
                contributing to our success.
              </li>
            </ul>

            <h3 style={{ marginBottom: "10px", marginTop: "20px" }}>
              How to Begin:
            </h3>
            <ul style={{ paddingLeft: "20px" }}>
              <li>Start by watching the introductory video.</li>
              <li>
                After the video, you will be required to fill out our induction
                form. This form ensures we have all the necessary details to set
                you up effectively within the company.
              </li>
            </ul>

            <p>
              To access the induction, simply click the button below to start
              your induction.
            </p>

            <p>
              If you have any questions or need assistance during the induction,
              please contact our HR team at{" "}
              <strong>[HR Contact Email or Number]</strong>.
            </p>

            <p>
              We’re excited to have you on board and look forward to achieving
              great things together.
            </p>

            <p>Best regards,</p>

            <p>
              <strong>HR Department</strong>
              <br />
              <strong>IP Builders Brickwork Limited</strong>
            </p>
          </Stack>
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingX: "22px",
              paddingY: "10px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ padding: "15px" }}
              onClick={() => {
                navigate(PATH_INVITATION.addEmployee, {
                  state: { id: employeeId, companyId },
                });
              }}
            >
              Start Your Induction
            </Button>
          </Box>
        </Card>
      </Stack>
    </>
  );
};
