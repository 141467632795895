import { forwardRef, useEffect, useImperativeHandle } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { RHFTextField } from "@app/components/hook-form";
import {
  Button,
  Card,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { Input, MaskedInput } from "@app/components";
import { getEmployementType } from "../EmployeeType/slice";
import { selectEmployementType } from "../EmployeeType/selector";
import { getDepartment } from "../department/slice";
import { selectDepartment } from "../department/selector";
import { get } from "lodash";
import { addPaymentDetails } from "../add_employee/slice";
import { Strings } from "@app/constants/strings";

const PaymentInformation = forwardRef(({ employee, edit }: any, ref) => {
  const theme = useTheme();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      accountNumber: get(employee, "employeePayment.accountNumber", ""),
      bankName: get(employee, "employeePayment.bankName", ""),
      branchAddress: get(employee, "employeePayment.branchAddress", ""),
      department: get(employee, "department.id", ""),
      employmentType: get(employee, "employmentType.id", ""),
      nationalInsurance: get(employee, "employeePayment.nationalInsurance", ""),
      sortCode: get(employee, "employeePayment.sortCode", ""),
      uniqueTaxReferenceNumber: get(
        employee,
        "employeePayment.uniqueTaxReferenceNumber",
        ""
      ),
    },
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEmployementType({ page: 1, limit: 1000 }));
    dispatch(
      getDepartment({
        page: 1,
        limit: 1000,
      })
    );
  }, [dispatch]);

  const employeesType = useSelector(selectEmployementType);

  const department = useSelector(selectDepartment);
  const onSubmit = (data: any) => {
    dispatch(
      addPaymentDetails({
        employeeId: employee.id,
        ...data,
        employmentTypeId: data?.employmentType,
        departmentId: data?.department,
        callback: () => {},
        isAdd: false,
      })
    );
  };

  useImperativeHandle(ref, () => ({
    submitForm: handleSubmit(onSubmit),
  }));

  return (
    <>
      <Card component={"form"} onSubmit={handleSubmit(onSubmit)}>
        <Stack
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            padding: 2,
            gap: 1.5,
          }}
        >
          <Stack sx={{ flex: 0.5 }}>
            <Stack>
              <Stack sx={{ flex: 1 }}>
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <Stack sx={{ pl: 4 }}>
                          <Typography
                            sx={{
                              color: "#616E80",
                              fontWeight: 600,
                              fontStyle: "italic",
                              fontSize: "16px",
                            }}
                          >
                            Tax Information
                          </Typography>
                        </Stack>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            fontWeight: 500,
                            fontSize: "16px",
                            width: "280px"
                          }}
                        >
                          National Insurance:
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            color: theme.palette.primary.main,
                          }}
                        >
                          {edit ? (
                            <Input
                              name="nationalInsurance"
                              size="small"
                              control={control}
                              placeHolder={Strings.field.inputPlaceholder(
                                "National Insurance"
                              )}
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              color="primary"
                              sx={{ fontSize: "16px", fontWeight: 500 }}
                            >
                              {employee?.employeePayment &&
                                employee?.employeePayment?.nationalInsurance}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            fontWeight: 500,
                            fontSize: "16px",
                          }}
                        >
                          Unique Tax Reference Number:
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            color: theme.palette.primary.main,
                          }}
                        >
                          {edit ? (
                            <Input
                              name="uniqueTaxReferenceNumber"
                              size="small"
                              control={control}
                              placeHolder={Strings.field.inputPlaceholder(
                                "Unique Tax Reference Number"
                              )}
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              color="primary"
                              sx={{ fontWeight: 500, fontSize: "16px" }}
                            >
                              {employee?.employeePayment &&
                                employee?.employeePayment
                                  ?.uniqueTaxReferenceNumber}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <Stack sx={{ pl: 4 }}>
                          <Typography
                            sx={{
                              color: theme.palette.grey[600],
                              fontWeight: 600,
                              fontStyle: "italic",
                              fontSize: "16px",
                            }}
                          >
                            Payment Details
                          </Typography>
                        </Stack>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            fontWeight: 500,
                            fontSize: "16px",
                          }}
                        >
                          Bank Name
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            color: theme.palette.primary.main,
                          }}
                        >
                          {edit ? (
                            <Input
                              name="bankName"
                              size="small"
                              control={control}
                              placeHolder={Strings.field.inputPlaceholder(
                                "Bank Name"
                              )}
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              color="primary"
                              sx={{ fontWeight: 500, fontSize: "16px" }}
                            >
                              {employee?.employeePayment &&
                                employee?.employeePayment?.bankName}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ borderBottom: "none", fontWeight: 700 }}
                        >
                          Branch Address
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            fontWeight: 500,
                            fontSize: "16px",
                          }}
                        >
                          {edit ? (
                            <Input
                              name="branchAddress"
                              size="small"
                              control={control}
                              placeHolder={Strings.field.inputPlaceholder(
                                "Branch Address"
                              )}
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              color="primary"
                              sx={{ fontWeight: 500, fontSize: "16px" }}
                            >
                              {employee?.employeePayment &&
                                employee?.employeePayment?.branchAddress}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            fontWeight: 500,
                            fontSize: "16px",
                          }}
                        >
                          Account Number
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            color: theme.palette.primary.main,
                          }}
                        >
                          {edit ? (
                            <MaskedInput
                              name="accountNumber"
                              size="small"
                              control={control}
                              placeHolder={Strings.field.inputPlaceholder(
                                "Account Number"
                              )}
                              formatStyle="space"
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              color="primary"
                              sx={{ fontWeight: 500, fontSize: "16px" }}
                            >
                              {employee?.employeePayment &&
                                employee?.employeePayment?.accountNumber}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            fontWeight: 500,
                            fontSize: "16px",
                          }}
                        >
                          Sort Code
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            color: theme.palette.primary.main,
                          }}
                        >
                          {edit ? (
                            <MaskedInput
                              name="sortCode"
                              size="small"
                              control={control}
                              placeHolder={Strings.field.inputPlaceholder(
                                "Sort Code"
                              )}
                              formatStyle="dash"
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              color="primary"
                              sx={{ fontWeight: 500, fontSize: "16px" }}
                            >
                              {employee?.employeePayment &&
                                employee?.employeePayment?.sortCode}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Stack>
          </Stack>
          <Stack sx={{ flex: 0.5 }}>
            <Stack>
              <Typography
                sx={{
                  color: theme.palette.grey[600],
                  fontWeight: 600,
                  fontStyle: "italic",
                  fontSize: "16px",
                }}
              >
                Employment Information
              </Typography>
              <Stack sx={{ flex: 1 }}>
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            fontWeight: 500,
                            fontSize: "16px",
                            width: "280px"
                          }}
                        >
                          Employment Type:
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            color: theme.palette.primary.main,
                          }}
                        >
                          {edit ? (
                            <Controller
                              name={"employmentType"}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  sx={{ width: "90%" }}
                                  {...field}
                                  displayEmpty
                                  value={field.value}
                                  name={field.name}
                                  size="small"
                                >
                                  {employeesType?.data?.map((item: any) => (
                                    <MenuItem value={item?.id}>
                                      {item?.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              color="primary"
                              sx={{ fontWeight: 500, fontSize: "16px" }}
                            >
                              {employee?.employmentType &&
                                employee?.employmentType?.name}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            fontWeight: 500,
                            fontSize: "16px",
                          }}
                        >
                          Department:
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            color: theme.palette.primary.main,
                          }}
                        >
                          {edit ? (
                            <Controller
                              name={"department"}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  sx={{ width: "90%" }}
                                  {...field}
                                  displayEmpty
                                  value={field.value}
                                  name={field.name}
                                  size="small"
                                >
                                  {department?.data?.map((item: any) => (
                                    <MenuItem value={item?.id}>
                                      {item?.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              color="primary"
                              sx={{ fontWeight: 500, fontSize: "16px" }}
                            >
                              {employee?.department &&
                                employee?.department?.name}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </>
  );
});

export { PaymentInformation };
