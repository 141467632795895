export const Strings = {
  validation: {
    chooseImg: "Please upload at least one image.",
    fieldRequired: (field:string)=>`${field} field is required`,
    uploadRequired: (field: string) => `Please upload the ${field}`,
    selectRequired: (field: string) => `Please select the ${field}`,
    selectFieldRequired: (field: string) => `Please select ${field}`,
    inputRequired: (label: string) => `Please enter ${label}`,
    intNumber: (field: string) => `${field} must be a integer value.`,
    notZero: (field: string) => `${field} cannot be zero.`,
    invalidEmail: "Please enter the valid email address",
    passwordNotMatch: "Password and Confirm Password do not match",
    passwordMustContain:
      "Password must contain at least 1 alphabet and 1 number",
    invalidMobileNumber:
      "Please enter a valid Mobile Number for Exp:- +971XXXXXXXXX",
    fiveImgError: "At least 5 images are required",
    oneImgError: "At least 1 images are required",
    singleImageUpload: "Please upload an image",
  },
  field: {
    email: "Email",
    password: "Password",
    repeatPassword: "Repeat Password",
    inputPlaceholder: (fieldName: string) => `Enter your ${fieldName.toLowerCase()}`
  },
  login: {
    login: "LOG IN",
    emailAddress: "Email Address",
    password: "Password",
    forgotPassword: "Forgot your Password?",
    register: "Register",
  },
  register: {
    register: "Register",
    emailAddress: "Email Address",
    password: "Password",
    plzCheckTheBelowBox: "Please check the below box to proceed",
    signUpNow: "Sign up now",
    repeatPassword: "Repeat Password",
  },
  selectCompanyScreen: {
    selectYourCompany: "Select your company",
    addNewCompany: "Add new company",
    selectCompany: "Select Company",
  },
  selectProjectScreen: {
    selectProject: "Select Project",
    viewAll: "View All",
    selectAllProject: "Select All Project",
    addNewProject: "Add new project",
  },
  sidebar: {},
  common: {
    success: "Success!",
    failed: "Failed!",
  },
  button: {
    logIn: "Log in",
  },
  knowledgeBase: {
    noCategoryFirstAddCategory: "You can't add article without any category so please add category first"
  }
};
