import React, { FC } from 'react'
import { Icon } from '@iconify/react'
import { Box } from '@mui/material'
import { UpDownIconProps } from '@app/components/table/types'

const UpDownIcon: FC<UpDownIconProps> = ({ onUpClick, onDownClick }) => {
    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            alignItems:"center",
            cursor:"pointer"
        }}>
            <Icon icon="flowbite:caret-up-outline" style={{ color: "#98A5B5" }} onClick={onUpClick}/>
            <Icon icon="flowbite:caret-down-outline" style={{ color: "#98A5B5" }} onClick={onDownClick}/>
        </Box>
    )
}

export default UpDownIcon