import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const Navbar = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: '12px 16px',
        backgroundColor: '#fff',
        borderBottom: '1px solid #E0E0E0',
        gap: 3
      }}
    >
      {/* Filter sections */}
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, gap: 6}}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography sx={{ color: '#7D7D7D', fontSize: '14px' }}>Project</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', gap: 4 }}>
            <Typography sx={{ fontSize: '14px' }}>All</Typography>
            <img 
              src="/assets/icons/downarrow.png" 
              alt="Dropdown" 
              style={{ 
                width: '12px', 
                height: '12px'
              }} 
            />
          </Box>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography sx={{ color: '#7D7D7D', fontSize: '14px' }}>Priority</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', gap: 4 }}>
            <Typography sx={{ fontSize: '14px' }}>All</Typography>
            <img 
              src="/assets/icons/downarrow.png" 
              alt="Dropdown" 
              style={{ 
                width: '12px', 
                height: '12px'
              }} 
            />
          </Box>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography sx={{ color: '#7D7D7D', fontSize: '14px' }}>Date</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', gap: 4 }}>
            <Typography sx={{ fontSize: '14px' }}>All</Typography>
            <img 
              src="/assets/icons/downarrow.png" 
              alt="Dropdown" 
              style={{ 
                width: '12px', 
                height: '12px'
              }} 
            />
          </Box>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography sx={{ color: '#7D7D7D', fontSize: '14px' }}>Due Date</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', gap: 4 }}>
            <Typography sx={{ fontSize: '14px' }}>All</Typography>
            <img 
              src="/assets/icons/downarrow.png" 
              alt="Dropdown" 
              style={{ 
                width: '12px', 
                height: '12px'
              }} 
            />
          </Box>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography sx={{ color: '#7D7D7D', fontSize: '14px' }}>Delivery Date</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', gap: 4 }}>
            <Typography sx={{ fontSize: '14px' }}>All</Typography>
            <img 
              src="/assets/icons/downarrow.png" 
              alt="Dropdown" 
              style={{ 
                width: '12px', 
                height: '12px'
              }} 
            />
          </Box>
        </Box>
      </Box>

      {/* Search and filter actions */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1 
        }}>
          <SearchIcon sx={{ color: '#7D7D7D', fontSize: '20px' }} />
          <input 
            placeholder="Search Keywords" 
            style={{ 
              border: 'none', 
              outline: 'none',
              fontSize: '14px',
              color: '#7D7D7D',
              width: '150px'
            }} 
          />
        </Box>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 1,
          cursor: 'pointer'
        }}>
          <img 
            src="/assets/icons/search.png" 
            alt="Filter" 
            style={{ 
              width: '20px', 
              height: '20px'
            }} 
          />
          <Typography sx={{ 
            fontSize: '12px',
            color: '#7D7D7D'
          }}>
            Filters
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;