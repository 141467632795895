import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Avatar,
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SendIcon from "@mui/icons-material/Send";
import AddCommentIcon from "@mui/icons-material/AddComment";

const invoices = [
  {
    ref: "INV00001-000001-001",
    status: "Paid",
    description: "Petru Lacatis - Invoice 001",
    date: "02/01/2023",
    paymentDate: "02/01/2023",
    netValue: "£ 300.00",
    vat: "£ 60.00",
    billAmount: "£ 360.00",
  },
  {
    ref: "INV00003-000001-002",
    status: "Paid",
    description: "Petru Lacatis - Invoice 002",
    date: "07/02/2023",
    paymentDate: "07/02/2023",
    netValue: "£ 600.00",
    vat: "£ 120.00",
    billAmount: "£ 720.00",
  },
  {
    ref: "INV00005-000001-003",
    status: "Paid",
    description: "Petru Lacatis - Invoice 003",
    date: "08/04/2023",
    paymentDate: "08/04/2023",
    netValue: "£ 300.00",
    vat: "£ 60.00",
    billAmount: "£ 360.00",
  },
  {
    ref: "INV00012-000001-004",
    status: "Paid",
    description: "Petru Lacatis - Invoice 004",
    date: "02/01/2024",
    paymentDate: "02/01/2024",
    netValue: "£ 1,200.00",
    vat: "£ 240.00",
    billAmount: "£ 1,440.00",
  },
];

const comments = [
  { name: "Sadie Sauer", time: "27-06-2024 03:55 am", message: "waewaewae" },
  { name: "Sadie Sauer", time: "27-06-2024 03:55 am", message: "wweaewawe" },
];

const UsersInvoice = () => {
  return (
    <Box p={3}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Invoice Ref.</TableCell>
              <TableCell>Payment Status</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Invoice Date</TableCell>
              <TableCell>Payment Date</TableCell>
              <TableCell>Net Value</TableCell>
              <TableCell>VAT</TableCell>
              <TableCell>Bill Amount</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((invoice) => (
              <TableRow key={invoice.ref}>
                <TableCell>{invoice.ref}</TableCell>
                <TableCell>
                  <Button 
                    variant="contained" 
                    size="small" 
                    sx={{
                      backgroundColor: "#3BD365",
                      borderRadius: "15px",
                    
                      minWidth: '80px',
                      padding: '4px 16px',
                      fontSize: '0.75rem',
                      color:"black"
                     
                    }}
                  >
                    {invoice.status}
                  </Button>
                </TableCell>
                <TableCell>{invoice.description}</TableCell>
                <TableCell>{invoice.date}</TableCell>
                <TableCell>{invoice.paymentDate}</TableCell>
                <TableCell>{invoice.netValue}</TableCell>
                <TableCell>{invoice.vat}</TableCell>
                <TableCell>{invoice.billAmount}</TableCell>
                <TableCell>
                        <IconButton size="small">
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <Box mt={3}>
        <Typography variant="h6">Client Note (Internal)</Typography>
        <TextField fullWidth multiline rows={4} placeholder="Write your notes here..." />
      </Box>

      <Box mt={3}>
        <Typography variant="h6">Comments (Internal)</Typography>
        {comments.map((comment, index) => (
          <Card key={index} sx={{ mt: 1, display: "flex", alignItems: "center", p: 1 }}>
            <Avatar sx={{ mr: 2 }}>S</Avatar>
            <CardContent>
              <Typography variant="subtitle1">{comment.name}</Typography>
              <Typography variant="body2" color="textSecondary">
                {comment.time}
              </Typography>
              <Typography>{comment.message}</Typography>
            </CardContent>
          </Card>
        ))}
        <TextField fullWidth placeholder="Write your message here" sx={{ mt: 2 }} />
        <Button variant="contained" startIcon={<SendIcon />} sx={{ mt: 1 }}>
          Send
        </Button>
      </Box> */}
    </Box>
  );
};

export default UsersInvoice;
