import { RequestStatus } from "@app/constants/request";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRequestFailed, IRequestState } from "@app/types";
import { IAdminState } from "./types";

const initialState: IAdminState = {
  dashboardData: {},
  systemUsersData: {},
  systemPermissionsData:{},
 
};

const AdminSlice = createSlice({
  initialState,
  name: "admin",
  reducers: {
    // Existing reducers for dashboardData
    getDashboardData(state, action: PayloadAction<{ id: number }>) {
      state.dashboardData.status = RequestStatus.RUNNING;
    },
    getDashboardDataSuccess(state, action: PayloadAction<IRequestState>) {
      state.dashboardData.status = RequestStatus.SUCCESS;
      state.dashboardData.data = action.payload;
    },
    getDashboardDataFailed(state, action: PayloadAction<IRequestFailed>) {
      state.dashboardData.status = RequestStatus.ERROR;
      state.dashboardData.error = action.payload;
    },

    // New reducers for userSettingData
    getUserSettingData(state, action: PayloadAction<{ id: number }>) {
      state.systemUsersData.status = RequestStatus.RUNNING;
    },
    getUserSettingDataSuccess(state, action: PayloadAction<IRequestState>) {
      state.systemUsersData.status = RequestStatus.SUCCESS;
      state.systemUsersData.data = action.payload;
    },
    getUserSettingDataFailed(state, action: PayloadAction<IRequestFailed>) {
      state.systemUsersData.status = RequestStatus.ERROR;
      state.systemUsersData.error = action.payload;
    },

    // New reducers for permissionsSettingData
    getPermissionsSettingData(state, action: PayloadAction<{ id: number }>) {
      state.systemPermissionsData.status = RequestStatus.RUNNING;
    },
    getPermissionsSettingDataSuccess(state, action: PayloadAction<IRequestState>) {
      state.systemPermissionsData.status = RequestStatus.SUCCESS;
      state.systemPermissionsData.data = action.payload;
    },
    getPermissionsSettingDataFailed(state, action: PayloadAction<IRequestFailed>) {
      state.systemPermissionsData.status = RequestStatus.ERROR;
      state.systemPermissionsData.error = action.payload;
    },
// New reducers for delete functionality
deleteUser(state, action: PayloadAction<{ id: string, query: string }>) {
  state.systemUsersData.status = RequestStatus.RUNNING;
},
deleteUserSuccess(state, action: PayloadAction<{ id: string }>) {
  state.systemUsersData.status = RequestStatus.SUCCESS;
  // Filter out the deleted user from the existing data
  if (state.systemUsersData.data?.data) {
    state.systemUsersData.data.data = state.systemUsersData.data.data.filter(
      (user: any) => user.id !== action.payload.id
    );
  }
},
deleteUserFailed(state, action: PayloadAction<IRequestFailed>) {
  state.systemUsersData.status = RequestStatus.ERROR;
  state.systemUsersData.error = action.payload;
},
// New reducers for edit functionality
updateUser(state, action: PayloadAction<{ id: string; data: any }>) {
  state.systemUsersData.status = RequestStatus.RUNNING;
},
updateUserSuccess(state, action: PayloadAction<any>) {
  state.systemUsersData.status = RequestStatus.SUCCESS;
  if (state.systemUsersData.data?.data) {
    const updatedUser = action.payload;
    const index = state.systemUsersData.data.data.findIndex(
      (user: any) => user.id === updatedUser.id
    );
    if (index !== -1) {
      state.systemUsersData.data.data[index] = updatedUser;
    }
  }
},
updateUserFailed(state, action: PayloadAction<IRequestFailed>) {
  state.systemUsersData.status = RequestStatus.ERROR;
  state.systemUsersData.error = action.payload;
},

  },
});

export const adminReducer = AdminSlice.reducer;
export const {
  getDashboardData,
  getDashboardDataSuccess,
  getDashboardDataFailed,
  // Export new actions for userSettingData
  getUserSettingData,
  getUserSettingDataSuccess,
  getUserSettingDataFailed,
  // Export new actions for permissionsSettingData
  getPermissionsSettingData,
  getPermissionsSettingDataSuccess,
  getPermissionsSettingDataFailed,
  deleteUser,
  deleteUserSuccess,
  deleteUserFailed,
  updateUser, 
  updateUserSuccess, 
  updateUserFailed, 
} = AdminSlice.actions;
