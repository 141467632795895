import { IEmployementTypeState } from "./types";
import { employementType } from "./endpoints";
import { RequestStatus } from "@app/constants/request";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRequestFailed, IRequestState } from "@app/types";
import { IEmployeeState } from "../add_employee/types";

const initialState: IEmployementTypeState = {
  employement: {
    status: RequestStatus.IDLE,
  },
  deleteEmployment: {},
  addEmploymentType: {},
};

const EmployementTypeSlice = createSlice({
  initialState,
  name: "employement",
  reducers: {
    getEmployementType(state, action: PayloadAction<any>) {
      state.employement.status = RequestStatus.RUNNING;
    },
    getEmployementTypeSuccess(state, action: PayloadAction<IRequestState>) {
      state.employement.data = action.payload.data;
      state.employement.status = RequestStatus.SUCCESS;
    },
    getEmployementTypeFailed(state, action: PayloadAction<IRequestFailed>) {
      state.employement.data = action.payload;
      state.employement.status = RequestStatus.ERROR;
    },
    addEmployementTypeAction(state, action: PayloadAction<any>) {
      state.addEmploymentType.status = RequestStatus.RUNNING;
    },
    addEmployementTypeSuccessAction(state, action: PayloadAction<any>) {
      state.addEmploymentType.status = RequestStatus.SUCCESS;
      state.addEmploymentType.data = action.payload;
    },
    addEmployementTypeFailedAction(
      state,
      action: PayloadAction<IRequestFailed>
    ) {
      state.addEmploymentType.error = action.payload;
      state.addEmploymentType.status = RequestStatus.ERROR;
    },
    deleteEmploymentTypeAction(state, action: PayloadAction<any>) {
      state.deleteEmployment.status = RequestStatus.RUNNING;
    },
    deleteEmploymentTypeSuccessAction(state, action: PayloadAction<any>) {
      state.deleteEmployment.status = RequestStatus.SUCCESS;
    },
    deleteEmploymentTypeFailedAction(
      state,
      action: PayloadAction<IRequestFailed>
    ) {
      state.deleteEmployment.data = action.payload;
      state.deleteEmployment.status = RequestStatus.ERROR;
    },
  },
});

export const employementTypeReducer = EmployementTypeSlice.reducer;
export const {
  getEmployementType,
  getEmployementTypeFailed,
  getEmployementTypeSuccess,
  addEmployementTypeAction,
  addEmployementTypeFailedAction,
  addEmployementTypeSuccessAction,
  deleteEmploymentTypeAction,
  deleteEmploymentTypeSuccessAction,
  deleteEmploymentTypeFailedAction,
} = EmployementTypeSlice.actions;
