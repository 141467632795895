import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  addDepartmentAction,
  addDepartmentSuccessAction,
  deleteDepartment,
  deleteDepartmentFailed,
  deleteDepartmentSuccess,
  getDepartment,
  getDepartmentFailed,
  getDepartmentSuccess,
} from "./slice";
import { request } from "@app/services";
import * as endpoint from "./endpoints";
import { selectCompanyId } from "../company/selectors";
import { selectDepartment } from "./selector";

function* fetchDepartment(action: ReturnType<typeof getDepartment>): any {
  try {
    const companyId: string = yield select(selectCompanyId);
    const { page, limit } = action.payload;
    if (companyId) {
      const { data } = yield call(
        request.get,
        `${endpoint.departmentByCompanyId}${companyId}?page=${page}&limit=${limit}`
      );
      yield put(getDepartmentSuccess({ data }));
    }
  } catch (error: any) {
    yield put(getDepartmentFailed(error));
  }
}

function* addDepartment(action: ReturnType<typeof addDepartmentAction>): any {
  try {
    const { callback, id, ...rest } = action.payload;
    const companyId: string = yield select(selectCompanyId);
    if (id) {
      const response = yield call(request.put, endpoint.deleteDepartment + id, {
        companyId,
        ...rest,
      });
      yield put(getDepartment({ page: 1, limit: 1000 }));
      yield put(addDepartmentSuccessAction(response));
    } else {
      const response = yield call(request.post, `${endpoint.department}`, {
        companyId,
        ...rest,
      });

      yield put(getDepartment({ page: 1, limit: 1000 }));
      yield put(addDepartmentSuccessAction(response));
    }

    callback();
  } catch (error: any) {
    yield put(getDepartmentFailed);
  }
}
function* deleteDepartmentSaga(
  action: ReturnType<typeof deleteDepartment>
): any {
  try {
    const { id } = action.payload;

    const result = yield call(request.delete, endpoint.deleteDepartment + id);

    const existingDepartment = yield select(selectDepartment);
    const updatedData = {
      ...existingDepartment,
      data: existingDepartment?.data?.filter((depart: any) => depart.id !== id),
    };
    yield put(getDepartmentSuccess({ data: updatedData }));

    yield put(deleteDepartmentSuccess(result.message));
  } catch (err: any) {
    yield put(deleteDepartmentFailed(err));
  }
}
export function* departmentSagas() {
  yield all([
    takeLatest(getDepartment, fetchDepartment),
    takeLatest(addDepartmentAction, addDepartment),
    takeLatest(deleteDepartment, deleteDepartmentSaga),
  ]);
}
