import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDesignationState } from "./types";
import { RequestStatus } from "@app/constants/request";
import { IRequestFailed } from "@app/types";
import { ICreateDesignationPayload } from "./designationTable/types";

const initialState: IDesignationState = {
  designations: {},
  deleteDesignation: {},
  addDesignation: {},
};
const designationSlice = createSlice({
  initialState,
  name: "designation",
  reducers: {
    getDesignationAction(
      state,
      action: PayloadAction<{ page: any; limit: any }>
    ) {
      state.designations.status = RequestStatus.RUNNING;
    },
    getDesignationSuccessAction(state, action) {
      state.designations.status = RequestStatus.SUCCESS;
      state.designations.data = action.payload.data;
    },
    getDesignationFailedAction(state, action: PayloadAction<IRequestFailed>) {
      state.designations.status = RequestStatus.SUCCESS;
      state.designations.data = action.payload;
    },
    addDesignationAction(state, action: any) {
      state.addDesignation.status = RequestStatus.RUNNING;
    },
    addDesignationSuccessAction(state, action: PayloadAction<any>) {
      state.addDesignation.status = RequestStatus.SUCCESS;
      state.addDesignation.data = action.payload;
    },
    addDesignationFailedAction(state, action: PayloadAction<IRequestFailed>) {
      state.addDesignation.status = RequestStatus.ERROR;
      state.addDesignation.error = action.payload;
    },
    deleteDesignation(state, action: PayloadAction<any>) {
      state.deleteDesignation.status = RequestStatus.RUNNING;
    },
    deleteDesignationSuccess(state, action) {
      state.deleteDesignation.status = RequestStatus.SUCCESS;
      state.deleteDesignation.data = action.payload.data;
    },
    deleteDesignationFailed(state, action: PayloadAction<IRequestFailed>) {
      state.deleteDesignation.status = RequestStatus.SUCCESS;
      state.deleteDesignation.data = action.payload;
    },
  },
});

export const designationReducer = designationSlice.reducer;

export const {
  getDesignationAction,
  getDesignationFailedAction,
  getDesignationSuccessAction,
  addDesignationAction,
  addDesignationFailedAction,
  addDesignationSuccessAction,
  deleteDesignation,
  deleteDesignationSuccess,
  deleteDesignationFailed,
} = designationSlice.actions;
