import React from "react";
import { Box, Button, Divider, Drawer, Stack, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { SidebarProps } from "./types";
import { useSelector } from "react-redux";
import { selectSelectedCompany } from "@app/modules/company/selectors";
import { GlobalStyles } from "@mui/system"; // Import GlobalStyles
import NavLists from "./NavLists";

export const Sidebar = ({ onHideSideBarButtonClick }: SidebarProps) => {
  const company = useSelector(selectSelectedCompany);

  return (
    <>
      <GlobalStyles
        styles={{
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#252D37",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#121820",
          },
        }}
      />
      <Drawer
        sx={{
          width: "180px",
          overflowY: "auto",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "260px",
            paddingBottom: "64px",
            marginTop: "64px",
            boxSizing: "border-box",
            backgroundColor: "#171F29",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box
          sx={{
            width: "260px",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
            padding: "4px",
            flexDirection: "column",
          }}
        >
          <Stack alignItems={"center"} display={"flex"}>
            <Box
              component="img"
              width="233px"
              src={company ? company.companyLogo : ""}
              sx={{ objectFit: "contain", height: "105px", my: "4px" }}
            />
          </Stack>
          <Box>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "16px",
                color: "#FFFFFF",
                lineHeight: "22px",
                width: "100%",
                px: "16px",
              }}
            >
              {company ? company?.name : ""}
            </Typography>
          </Box>
        </Box>
        <Stack sx={{ mt: "1px" }}>
          <NavLists />
        </Stack>
        <Stack
          sx={{
            height: "48px",
            width: "249px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Button
            endIcon={<Icon icon="ep:arrow-left-bold" />}
            sx={{
              color: "#98A5B5",
              fontStyle: "italic",
              fontSize: "14px",
              fontWeight: 400,
            }}
            onClick={() => {
              onHideSideBarButtonClick();
            }}
          >
            Hide Navigation
          </Button>
        </Stack>
      </Drawer>
    </>
  );
};
