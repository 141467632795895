import { IQualificationState } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRequestFailed, IRequestState } from "@app/types";
import { RequestStatus } from "@app/constants/request";

const initialState: IQualificationState = {
  qualifications: {},
  deleteQualifications: {},
  addQualifications: {},
};

const qualificationSlice = createSlice({
  initialState,
  name: "qualification",
  reducers: {
    getQualificationAction(
      state,
      action: PayloadAction<{ page: any; limit: any }>
    ) {
      state.qualifications.status = RequestStatus.RUNNING;
    },
    getQualificationSuccessAction(state, action: PayloadAction<IRequestState>) {
      state.qualifications.status = RequestStatus.SUCCESS;
      state.qualifications.data = action.payload.data;
    },
    getQualificationFailedAction(state, action: PayloadAction<IRequestFailed>) {
      state.qualifications.status = RequestStatus.ERROR;
      state.qualifications.error = action.payload;
    },
    addQualificationAction(state, action: PayloadAction<any>) {
      state.addQualifications.status = RequestStatus.RUNNING;
    },
    addQualificationSuccessAction(state, action: PayloadAction<any>) {
      state.addQualifications.status = RequestStatus.SUCCESS;
      state.addQualifications.data = action.payload;
    },
    addQualificationFailedAction(state, action: PayloadAction<IRequestFailed>) {
      state.addQualifications.status = RequestStatus.ERROR;
      state.addQualifications.error = action.payload;
    },
    deleteQualificationAction(state, action: PayloadAction<any>) {
      state.deleteQualifications.status = RequestStatus.RUNNING;
    },
    deleteQualificationSuccessAction(state, action) {
      state.deleteQualifications.status = RequestStatus.SUCCESS;
      state.deleteQualifications.data = action.payload.data;
    },
    deleteQualificationFailedAction(
      state,
      action: PayloadAction<IRequestFailed>
    ) {
      state.deleteQualifications.status = RequestStatus.ERROR;
      state.deleteQualifications.error = action.payload;
    },
  },
});

export const qualificationReducer = qualificationSlice.reducer;
export const {
  getQualificationAction,
  getQualificationSuccessAction,
  getQualificationFailedAction,
  addQualificationAction,
  addQualificationSuccessAction,
  addQualificationFailedAction,
  deleteQualificationAction,
  deleteQualificationSuccessAction,
  deleteQualificationFailedAction,
} = qualificationSlice.actions;
