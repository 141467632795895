import React, { FC, useCallback, useEffect, useRef } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegisterSchema } from "./validators";
import ReCAPTCHA from "react-google-recaptcha";
import { Strings } from "@app/constants/strings";
import { Input, Label } from "@app/components";
import { useDispatch, useSelector } from "react-redux";
import { IRegisterUserSchema } from "./types";
import { register } from "./slice";
import { selectRegisterState, selectRegisterStatus } from "./selectors";
import { useNavigate } from "react-router-dom";
import { LANDING_PAGE_PATH, PATH_AUTH } from "@app/routes/paths";
import { useRequestState } from "@app/hooks/use-request-state";
import { LoadingButton } from "@mui/lab";

export const RegisterScreen: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IRegisterUserSchema>({
    resolver: yupResolver(RegisterSchema),
  });
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const onSubmit = (data: IRegisterUserSchema) => {
    console.log(dispatch(register(data)));
  };

  const handleNavigateLogin = useCallback(() => {
    navigate(PATH_AUTH.login);
  }, [navigate]);

  const { loading } = useRequestState({
    stateSelector: selectRegisterState,
    errorShown: true,
    successMessageShown: true,
    onSuccess: handleNavigateLogin,
  });

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          width: 652,
          margin: "50px auto",
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            marginBottom: 2,
            textAlign: "center",
            fontWeight: "700",
            fontSize: "20px",
          }}
        >
          {Strings.register.register}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Label>{Strings.register.emailAddress}</Label>
          <Input
            sx={{ borderRadius: "5px", border: "1px" }}
            name="email"
            control={control}
            label="Email Address"
            placeHolder={Strings.field.inputPlaceholder(
              Strings.login.emailAddress
            )}
            error={errors.email?.message}
          />
          <Label sx={{ marginTop: "15px" }}>{Strings.register.password}</Label>
          <Input
            sx={{ borderRadius: "5px", border: "1px" }}
            name="password"
            control={control}
            label="Password"
            isPassword
            placeHolder={Strings.field.inputPlaceholder(Strings.login.password)}
            error={errors.password?.message}
          />
          <Label sx={{ marginTop: "15px" }}>
            {Strings.register.repeatPassword}
          </Label>
          <Input
            sx={{ borderRadius: "5px", border: "1px" }}
            name="confirmPassword"
            control={control}
            label="Password"
            isPassword
            placeHolder={Strings.field.inputPlaceholder(
              Strings.register.repeatPassword
            )}
            error={errors.confirmPassword?.message}
          />
          <Typography component={'span'} sx={{
                        fontSize: "13px",
                        fontStyle: "italic",
                        fontWeight: "400",
                        color: "#616E80",
                    }}>{Strings.register.plzCheckTheBelowBox}</Typography>
          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              opacity: "100%",
              marginTop: 2,
              backgroundColor: "#11EF4F",
              borderRadius: "5px",
              fontSize: "20px",
              fontWeight: "700",
            }}
          >
            {Strings.register.signUpNow}
          </LoadingButton>
        </form>
      </Box>
    </Container>
  );
};
