import { usePDF } from "react-to-pdf";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Typography,
  Stack,
  Dialog,
  Button,
  TableHead,
  Box,
} from "@mui/material";

import { selectSelectedCompany } from "../company/selectors";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { employee } from "./endpoints";
import { forwardRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pdfData } from "../add_employee/slice";
import { request } from "@app/services";
import * as endpoint from "../add_employee/endpoints";

const SignedDeclarationContract = ({ employee, open, onClose }: any) => {
  const [download,setSownload] = useState(false);
  const pdfGenerate = useSelector((state: any) => state.employee?.pdfGenerate || '');
  console.log(pdfGenerate,"data");
  useEffect(() => {
    if (pdfGenerate) {
      console.log(pdfGenerate,"data");
      
    }else{
        return
    }
  }, [pdfGenerate]);
  

  const dispatch = useDispatch();
  const data = {
    site: "West Cromwell Road",
    biometricNumber: "111339",
    payNo: "",
    personalDetails: {
      firstName: "George",
      lastName: "Emache",
      dob: "04 Mar 2001",
      employer: "IP BUILDERS",
      subcontractedTo: "IP BUILDERS",
      position: "BRICKLAYER",
      phone: "07440122160",
      address: "23 Mapleleafe Gardens, London, IG61LG",
      email: "gemacge@gmail.com",
      emergencyContactName: "Claudiu",
      emergencyContactPhone: "07884532175",
      fullHomeAddress: "23 mapleleafe gardens london IG61LG",
    },
    qualifications: {
      trainingScheme: "No",
      jobExperience: "5 years",
      outOfWork: "No",
    },
    medicalConditions: {
      ageOver18: "Yes",
      hospitalLast3Months: "No",
      healthConditions: "No",
      drugTesting: "Yes",
    },
    communication: {
      firstLanguage: "Romanian",
      englishUnderstanding: "Good",
    },
    rightToWork: "Yes",
    date: "31 Jul 2024",
  };


  const { toPDF, targetRef } = usePDF({ filename: "Contract.pdf" });
  const pdfDownload = async (pdfData: any) => {
    try {
      const response = await request.post(`${endpoint.employee}/pdf-generate`, pdfData);
      const pdfUrl:any = response;
  
      if (pdfUrl) {
        const fileResponse = await fetch(pdfUrl);
        const blob = await fileResponse.blob();
        const fileObjectUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileObjectUrl;
        link.setAttribute('download', 'Contract.pdf'); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(fileObjectUrl); 
  
      } else {
        console.error('No PDF URL received from the server');
      }
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };
  
  
  const company = useSelector(selectSelectedCompany);
  console.log("Sleected________", company);
  const employeePersonalDetails = [
    { label: "First Name", value: employee?.firstName },
    { label: "Last Name", value: employee?.lastName },
    { label: "Date of Birth", value: employee?.dateOfBirth },
    { label: "Gender", value: employee?.gender },
  ];
  const employeeContactDetails = [
    {
      label: "Contact Phone Numbers",
      value: employee?.contactDetails?.mobileNumber,
    },
    {
      label: "Full home address",
      value: `${employee?.contactDetails?.address} ${employee?.contactDetails?.city} ${employee?.contactDetails?.country}`,
    },
    {
      label: "Email Address",
      value: employee?.contactDetails?.emailAddress,
    },
  ];
  const employeeContractDetails = [
    {
      label: "Contractor",
      value: employee?.contractor?.name || '',
    },
    {
      label: "Designation",
      value: employee?.contractor?.name || '',
    },
    {
      label: "Employee Role",
      value: employee?.employeeRole || '',
    },
    {
      label: "Employment Type",
      value: employee?.employmentType?.name || '',
    },
  ];
  const employeeEmergencyContactDetails = [
    {
      label: "Name",
      value: employee?.emergencyContacts?.[0]?.fullName,
    },
    {
      label: "Email Address",
      value: employee?.emergencyContacts?.[0]?.email,
    },
    {
      label: "Address",
      value: employee?.emergencyContacts?.[0]?.address,
    },
    {
      label: "Relationship",
      value: employee?.emergencyContacts?.[0]?.relationship,
    },
    {
      label: "Phone Number",
      value: employee?.emergencyContacts?.[0]?.mobileNumber,
    },
  ];


  return (
    <>
      <Dialog open onClose={onClose} fullScreen>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            flex: 0.5,
            justifyContent: "flex-end",
            mt: 1,
            mr: 1,
            gap: 1,
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              pdfDownload(employee)
            }}

          >
            {"Download"}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onClose();
            }}
          >
            {"Close"}
          </Button>
        </Stack>
        <Stack sx={{ width: "100%" }}>
          <Stack
            spacing={3}
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <TableContainer sx={{ padding: "2px" }}>
              <Typography variant="h6" align="center" fontWeight="bold" sx={{ color: "black" }} >
                EMPLOYMENT & COMPANY ENROLLMENT RECORD
              </Typography>
              <Table
                sx={{
                  "& td, & th": {
                    borderBottom: "1px solid black",
                    padding: "0px",
                  },
                  "& th": {
                    verticalAlign: "center",
                  },
                  "& tr": {
                    height: "auto",
                  },
                }}
              >
                <Box style={{ width: "100%", height: "30px", border: "2px solid black", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "10px" }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ color: "black" }} >
                      Employee
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ color: "black" }}>
                      {/* Add content here if needed */}
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ color: "black" }}>
                      Employment Status:
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ color: "black" }}>
                      Joined Since:
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  <Box sx={{ width: "140%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        border: "2px solid black",
                        borderRight:"none",
                        marginTop:1,
                        padding: 1,
                      }}
                    >
                      <Box sx={{ flex: 1 }} >
                        {employeePersonalDetails.map((item, index) => (
                          <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                            {item.label}
                          </Typography>
                        ))}
                      </Box>
                      <Box sx={{ flex: 1 }} >
                        {employeePersonalDetails.map((item, index) => (
                          <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                            {item.value}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        border: "2px solid black",
                        borderRight:"none",
                        paddingLeft:1,
                        marginTop:1,
                        marginBottom: 1,
                      }}
                    >
                      <Box sx={{ flex: 1 }}>
                        {employeeContactDetails.map((item, index) => (
                          <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                            {item.label}
                          </Typography>
                        ))}
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        {employeeContactDetails.map((item, index) => (
                          <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                            {item.value}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ width: "588px", height: "234px", border: "2px solid black",margin:0 }}>
                    <img src={employee.profileUrl} alt="Employee" style={{ width: "100%", height: "100%" }} />
                  </Box>

                </Box>

                <Box sx={{ width: "100%", border: "2px solid black", display: "flex", flexDirection: "row",paddingTop:0, marginTop:0}}>
                  <Box sx={{ flex: 1, padding: "5px", border: "none" }}>
                    {employeeContractDetails.map((item, index) => (
                      <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                        {item.label}
                      </Typography>
                    ))}
                  </Box>
                  <Box sx={{ flex: 1,padding: "5px", border: "none",marginLeft:"60px"}}>
                    {employeeContractDetails.map((item, index) => (
                      <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                        {item.value}
                      </Typography>
                    ))}
                  </Box>
                  <Box sx={{flex:1}}></Box>
                </Box>
                <Box
                  sx={{
                    border: "2px solid black",
                    marginTop: "2px",
                    marginBottom: "2px",
                    backgroundColor: "#80808021",
                    width: "100%",
                    height: "30px"

                  }}
                >
                  <Typography variant="h6" fontWeight="bold" sx={{ color: "black", marginLeft: "4px" }}>
                    EMERGENCY CONTACT DETAILS
                  </Typography>
                </Box>


                <Box sx={{ width: "100%", border: "2px solid black", display: "flex", flexDirection: "row" }}>
                  <Box sx={{ flex: 1, padding: "5px", border: "none" }}>
                    {employeeEmergencyContactDetails.map((item, index) => (
                      <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                        {item.label}
                      </Typography>
                    ))}
                  </Box>
                  <Box sx={{ flex: 1, padding: "5px",marginLeft:"60px", border: "none" }}>
                    {employeeEmergencyContactDetails.map((item, index) => (
                      <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                        {item.value}
                      </Typography>
                    ))}
                  </Box>
                  <Box sx={{flex:1}}></Box>
                </Box>

                <Box
                  sx={{
                    border: "2px solid black",
                    marginTop: "2px",
                    marginBottom: "2px",
                    backgroundColor: "#80808021",
                    width: "100%",
                    padding: "2px"
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ color: "black", marginLeft: "4px" }}
                  >
                    IDENTITY & RIGHT TO WORK
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    border: "2px solid black",
                    marginTop: "2px",
                    marginBottom: "2px",
                    padding: "2px",
                    width: "100%"
                  }}
                >
                  {/* First Column */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Nationality
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Do you have right to work?
                    </Typography>
                  </Box>

                  {/* Second Column */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.identityAndRightToWork?.nationality}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.identityAndRightToWork?.hasRightToWork}
                    </Typography>
                  </Box>

                  {/* Third Column */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      ID Provided:
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Proof Provided:
                    </Typography>
                  </Box>

                  {/* Fourth Column */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.identityAndRightToWork?.proofOfIdentityFront ? "Yes" : "No"}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.identityAndRightToWork?.proofOfRightToWork ? "Yes" : "No"}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    border: "2px solid black",
                    marginBottom: "2px",
                    backgroundColor: "#80808021",
                    width: "100%",
                    padding: "2px"
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ color: "black", marginLeft: "4px" }}
                  >
                    PAYMENT INFORMATION
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    border: "2px solid black",
                    marginTop: "5px",
                    marginBottom: "2px",
                    padding: "2px",
                    width: "100%", // Ensuring the width is 116%
                    backgroundColor: "#fff",
                  }}
                >
                  {/* First Column - Labels */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      National Insurance Number
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Unique Tax Reference Number:
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Bank Name
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Bank Address
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Account Number
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Sort Code
                    </Typography>
                  </Box>

                  {/* Second Column - Values */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.employeePayment?.nationalInsurance}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.employeePayment?.uniqueTaxReferenceNumber}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.employeePayment?.bankName}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.employeePayment?.branchAddress}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.employeePayment?.accountNumber}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.employeePayment?.sortCode}
                    </Typography>
                  </Box>
                </Box>


                <Box
                  sx={{
                    border: "2px solid black",
                    marginTop: "2px",
                    marginBottom: "2px",
                    backgroundColor: "#80808021",
                    width: "100%",
                    padding: "2px"
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ color: "black", marginLeft: "4px" }}
                  >
                    QUALIFICATIONS & EXPERIENCE
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    border: "2px solid black",
                    marginTop: "2px",
                    marginBottom: "2px",
                    padding: "2px",
                    width: "100%", // Adjust if necessary
                    backgroundColor: "#fff",
                  }}
                >
                  {/* First Column - Questions */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Have qualifications and certificates of relevant training schemes been provided? e.g., CSCS
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      How long have you been working in this position?
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Have you been out of work before starting this job?
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Please specify how long you have been out of work.
                    </Typography>
                  </Box>

                  {/* Second Column - Answers (Replace with actual employee data if needed) */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {/* {employee?.qualifications?.provided} */}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {/* {employee?.experience?.yearsInPosition} */}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {/* {employee?.workHistory?.outOfWorkBefore} */}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {/* {employee?.workHistory?.outOfWorkDuration} */}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    border: "2px solid black",
                    marginTop: "2px",
                    marginBottom: "2px",
                    backgroundColor: "#80808021",
                    width: "100%",
                    padding: "2px"
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ color: "black", marginLeft: "4px" }}
                  >
                    MEDICAL & HEALTH CONDITIONS
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    border: "2px solid black",
                    marginTop: "2px",
                    marginBottom: "2px",
                    padding: "2px",
                    width: "100%", // Adjust as needed
                    backgroundColor: "#fff",
                  }}
                >
                  {/* First Column - Questions */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Have you been in the hospital in the last 3 months?
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Please specify why you have been in the hospital in the last 3 months.
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Do you suffer from any health condition or take any medication that could affect you at work?
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Please give us more details of your medical condition.
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Do you understand that we carry out random alcohol and drug testing?
                    </Typography>
                  </Box>

                  {/* Second Column - Answers */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.medicalInformation?.outOfWork}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.medicalInformation?.outOfWorkDuration}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.medicalInformation?.hasHealthCondition}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.medicalInformation?.healthConditionDetails}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.medicalInformation?.understandsAlcoholDrugTesting}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    border: "2px solid black",
                    marginTop: "2px",
                    marginBottom: "2px",
                    backgroundColor: "#80808021",
                    width: "100%",
                    padding: "2px"
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ color: "black", marginLeft: "4px" }}
                  >
                    EMPLOYMENT DECLARATION
                  </Typography>
                </Box>

                <TableBody>
                  <Box sx={{ display: "flex", justifyContent: "center", width: "100%", border: "2px solid black" }}>
                    <Box sx={{ borderRight: "2px solid black", width: "50%" }}>
                      <Typography sx={{ padding: "5px", width: "100%" }}>
                        <b>EMPLOYEE:</b>I confirm that that the above information is correct, I have
                        understood the
                        company rules, and I have been inducted in a language I understand. I have
                        read, understood and agree with the company terms and conditions of
                        employment, contract and policies.

                      </Typography>
                    </Box>
                    <Box sx={{ width: "50%" }}>
                      <Typography sx={{ padding: "5px" }}>
                        <b>INDUCTOR:</b> confirm that that the above information is correct, I have
                        understood the
                        company rules, and I have been inducted in a language I understand. I have
                        read, understood and agree with the company terms and conditions of
                        employment, contract and policies.
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center", width: "100%", borderLeft: "2px solid black", borderBottom: "2px solid black" }}>
                    <Box sx={{ borderRight: "2px solid black", width: "50%" }}>
                      <Box sx={{ borderBottom: "2px solid black", padding: "2px" }}>
                        Name :
                      </Box>
                      <Box sx={{ padding: "2px" }}>
                        Date :
                      </Box>
                    </Box>

                    <Box sx={{ borderRight: "2px solid black", width: "50%" }}>
                      Signed:
                    </Box>
                    <Box sx={{ borderRight: "2px solid black", width: "50%" }}>
                      <Box sx={{ borderBottom: "2px solid black", padding: "2px" }}>
                        Name :
                      </Box>
                      <Box sx={{ padding: "2px" }}>
                        Date :
                      </Box>

                    </Box>
                    <Box sx={{ borderRight: "2px solid black", width: "50%" }}>
                      Signed:
                    </Box>
                  </Box>
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
      </Dialog>


      <Dialog open  fullScreen style={{ opacity: "0", zIndex: "-9999", transform: "scale(1)", pointerEvents: "none" }}>
        <Stack ref={targetRef} style={{ width: "210mm !important", height: "297mm !important" }} >
          <Stack
            spacing={3}
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <TableContainer sx={{ padding: "5px" }}>
              <Typography variant="h6" align="center" fontWeight="bold" sx={{ color: "black", paddingBottom: "5px" }}  >
                EMPLOYMENT & COMPANY ENROLLMENT RECORD
              </Typography>
              <Table
                sx={{
                  "& td, & th": {
                    borderBottom: "1px solid black",
                    padding: "0px",
                  },
                  "& th": {
                    verticalAlign: "center",
                  },
                  "& tr": {
                    height: "auto",
                  },
                }}
              >
                <Box style={{ width: "100%", height: "30px", border: "2px solid black", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "10px" }}>
                  <Box sx={{ flex: 1 }} style={{ position: "relative", bottom: "10px", left: "10px" }}>
                    <Typography sx={{ color: "black" }} >
                      Employee
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }} style={{ position: "relative", bottom: "10px", left: "10px" }}>
                    <Typography sx={{ color: "black" }}>
                      {/* Add content here if needed */}
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }} style={{ position: "relative", bottom: "10px", left: "10px" }}>
                    <Typography sx={{ color: "black" }}>
                      Employment Status:
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }} style={{ position: "relative", bottom: "10px", left: "10px" }}>
                    <Typography sx={{ color: "black" }}>
                      Joined Since:
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  <Box sx={{ width: "140%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        border: "2px solid black",
                        padding: 1,
                      }}
                    >
                      <Box sx={{ flex: 1 }} style={{ paddingLeft: "10px" }}>
                        {employeePersonalDetails.map((item, index) => (
                          <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                            {item.label}
                          </Typography>
                        ))}
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        {employeePersonalDetails.map((item, index) => (
                          <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                            {item.value}
                          </Typography>
                        ))}
                      </Box>
                    </Box>


                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        border: "2px solid black",
                        padding: 1,
                      }}
                    >
                      <Box sx={{ flex: 1 }} style={{ paddingLeft: "10px" }}>
                        {employeeContactDetails.map((item, index) => (
                          <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                            {item.label}
                          </Typography>
                        ))}
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        {employeeContactDetails.map((item, index) => (
                          <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                            {item.value}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ width: "588px", height: "246px", border: "2px solid black" }}>
                    <img src={employee.profileUrl} alt="Employee" style={{ width: "100%", height: "100%" }} />
                  </Box>

                </Box>

                <Box sx={{ width: "100%", border: "2px solid black", display: "flex", flexDirection: "row", marginTop: "2px" }}>
                  <Box sx={{ flex: 1, padding: "5px", border: "none" }} style={{ paddingLeft: "10px" }}>
                    {employeeContractDetails.map((item, index) => (
                      <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                        {item.label}
                      </Typography>
                    ))}
                  </Box>
                  <Box sx={{ flex: 1, padding: "5px", border: "none" }}>
                    {employeeContractDetails.map((item, index) => (
                      <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                        {item.value}
                      </Typography>
                    ))}
                  </Box>
                </Box>
                <Box
                  style={{
                    border: "2px solid black",
                    backgroundColor: "#80808021"
                  }}
                >
                  <Typography variant="h6" fontWeight="bold"
                    style={{
                      position: "relative",
                      bottom: "10px",
                      left: "10px"
                    }}
                  >
                    EMERGENCY CONTACT DETAILS
                  </Typography>
                </Box>




                <Box sx={{ width: "100%", border: "2px solid black", display: "flex", flexDirection: "row" }}>
                  <Box sx={{ flex: 1, padding: "5px", border: "none" }} style={{ paddingLeft: "10px" }}>
                    {employeeEmergencyContactDetails.map((item, index) => (
                      <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                        {item.label}
                      </Typography>
                    ))}
                  </Box>
                  <Box sx={{ flex: 1, padding: "5px", border: "none" }}>
                    {employeeEmergencyContactDetails.map((item, index) => (
                      <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                        {item.value}
                      </Typography>
                    ))}
                  </Box>
                </Box>
                <Box
                  style={{
                    border: "2px solid black",
                    backgroundColor: "#80808021"
                  }}
                >
                  <Typography variant="h6" fontWeight="bold"
                    style={{
                      position: "relative",
                      bottom: "10px",
                      left: "10px"
                    }}
                  >
                    IDENTITY & RIGHT TO WORK
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    border: "2px solid black",
                    marginTop: "2px",
                    marginBottom: "2px",
                    padding: "2px",
                    width: "100%"
                  }}
                  style={{ paddingLeft: "10px" }}
                >
                  {/* First Column */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Nationality
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Do you have right to work?
                    </Typography>
                  </Box>

                  {/* Second Column */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.identityAndRightToWork?.nationality}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.identityAndRightToWork?.hasRightToWork}
                    </Typography>
                  </Box>

                  {/* Third Column */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      ID Provided:
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Proof Provided:
                    </Typography>
                  </Box>

                  {/* Fourth Column */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.identityAndRightToWork?.proofOfIdentityFront ? "Yes" : "No"}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.identityAndRightToWork?.proofOfRightToWork ? "Yes" : "No"}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    border: "2px solid black",
                    backgroundColor: "#80808021"
                  }}
                >
                  <Typography variant="h6" fontWeight="bold"
                    style={{
                      position: "relative",
                      bottom: "10px",
                      left: "10px"
                    }}
                  >
                    PAYMENT INFORMATION
                  </Typography>
                </Box>


                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    border: "2px solid black",
                    marginTop: "5px",
                    marginBottom: "2px",
                    padding: "2px",
                    width: "100%", // Ensuring the width is 116%
                    backgroundColor: "#fff",
                  }}
                  style={{ paddingLeft: "10px" }}
                >
                  {/* First Column - Labels */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      National Insurance Number
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Unique Tax Reference Number:
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Bank Name
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Bank Address
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Account Number
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Sort Code
                    </Typography>
                  </Box>

                  {/* Second Column - Values */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.employeePayment?.nationalInsurance}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.employeePayment?.uniqueTaxReferenceNumber}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.employeePayment?.bankName}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.employeePayment?.branchAddress}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.employeePayment?.accountNumber}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.employeePayment?.sortCode}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    border: "2px solid black",
                    backgroundColor: "#80808021"
                  }}
                >
                  <Typography variant="h6" fontWeight="bold"
                    style={{
                      position: "relative",
                      bottom: "10px",
                      left: "10px"
                    }}
                  >
                    QUALIFICATIONS & EXPERIENCE
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    border: "2px solid black",
                    marginTop: "2px",
                    marginBottom: "2px",
                    padding: "2px",
                    width: "100%", // Adjust if necessary
                    backgroundColor: "#fff",
                  }}
                >
                  {/* First Column - Questions */}
                  <Box sx={{ flex: 1, padding: "5px" }} style={{ paddingLeft: "10px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Have qualifications and certificates of relevant training schemes been provided? e.g., CSCS
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      How long have you been working in this position?
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Have you been out of work before starting this job?
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Please specify how long you have been out of work.
                    </Typography>
                  </Box>

                  {/* Second Column - Answers (Replace with actual employee data if needed) */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {/* {employee?.qualifications?.provided} */}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {/* {employee?.experience?.yearsInPosition} */}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {/* {employee?.workHistory?.outOfWorkBefore} */}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {/* {employee?.workHistory?.outOfWorkDuration} */}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    border: "2px solid black",
                    backgroundColor: "#80808021"
                  }}
                >
                  <Typography variant="h6" fontWeight="bold"
                    style={{
                      position: "relative",
                      bottom: "10px",
                      left: "10px"
                    }}
                  >
                    MEDICAL & HEALTH CONDITIONS
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    border: "2px solid black",
                    marginTop: "2px",
                    marginBottom: "2px",
                    padding: "2px",
                    width: "100%", // Adjust as needed
                    backgroundColor: "#fff",
                  }}
                >
                  {/* First Column - Questions */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Have you been in the hospital in the last 3 months?
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Please specify why you have been in the hospital in the last 3 months.
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Do you suffer from any health condition or take any medication that could affect you at work?
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Please give us more details of your medical condition.
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Do you understand that we carry out random alcohol and drug testing?
                    </Typography>
                  </Box>

                  {/* Second Column - Answers */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.medicalInformation?.outOfWork}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.medicalInformation?.outOfWorkDuration}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.medicalInformation?.hasHealthCondition}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.medicalInformation?.healthConditionDetails}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.medicalInformation?.understandsAlcoholDrugTesting}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    border: "2px solid black",
                    backgroundColor: "#80808021"
                  }}
                >
                  <Typography variant="h6" fontWeight="bold"
                    style={{
                      position: "relative",
                      bottom: "10px",
                      left: "10px"
                    }}
                  >
                    EMPLOYMENT DECLARATION
                  </Typography>
                </Box>

                <TableBody>
                  <Box sx={{ display: "flex", justifyContent: "center", width: "100%", border: "2px solid black" }}>
                    <Box sx={{ borderRight: "2px solid black", width: "50%" }}>
                      <Typography sx={{ padding: "5px", paddingBottom: "10px", width: "100%" }}>
                        <b>EMPLOYEE:</b>I confirm that that the above information is correct, I have
                        understood the
                        company rules, and I have been inducted in a language I understand. I have
                        read, understood and agree with the company terms and conditions of
                        employment, contract and policies.

                      </Typography>
                    </Box>
                    <Box sx={{ width: "50%" }}>
                      <Typography sx={{ padding: "5px", paddingBottom: "10px" }}>
                        <b>INDUCTOR:</b> confirm that that the above information is correct, I have
                        understood the
                        company rules, and I have been inducted in a language I understand. I have
                        read, understood and agree with the company terms and conditions of
                        employment, contract and policies.
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center", width: "100%", borderLeft: "2px solid black", borderBottom: "2px solid black" }}>
                    <Box sx={{ borderRight: "2px solid black", width: "50%" }}>
                      <Box sx={{ borderBottom: "2px solid black", padding: "5px", paddingBottom: "5px" }}>
                        Name :
                      </Box>
                      <Box sx={{ padding: "5px", paddingBottom: "5px" }}>
                        Date :
                      </Box>
                    </Box>

                    <Box sx={{ borderRight: "2px solid black", width: "50%", padding: "5px", paddingBottom: "5px" }}>
                      Signed:
                    </Box>
                    <Box sx={{ borderRight: "2px solid black", width: "50%" }}>
                      <Box sx={{ borderBottom: "2px solid black", padding: "5px", paddingBottom: "5px" }}>
                        Name :
                      </Box>
                      <Box sx={{ padding: "5px", paddingBottom: "5px" }}>
                        Date :
                      </Box>

                    </Box>
                    <Box sx={{ borderRight: "2px solid black", width: "50%", padding: "5px", paddingBottom: "5px" }}>
                      Signed:
                    </Box>
                  </Box>
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
      </Dialog>


    </>

  );
};



const SignedDeclarationContractPDF = ({ employee }:any) => {
  const { toPDF,targetRef } = usePDF({ filename: "Contract.pdf" });
  useEffect(() => {
    const timer = setTimeout(() => {
      if (targetRef.current) {
        toPDF();

      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [toPDF]);

  const company = useSelector(selectSelectedCompany);
  console.log("Sleected________", company);
  const employeePersonalDetails = [
    { label: "First Name", value: employee?.firstName },
    { label: "Last Name", value: employee?.lastName },
    { label: "Date of Birth", value: employee?.dateOfBirth },
    { label: "Gender", value: employee?.gender },
  ];
  const employeeContactDetails = [
    {
      label: "Contact Phone Numbers",
      value: employee?.contactDetails?.mobileNumber,
    },
    {
      label: "Full home address",
      value: `${employee?.contactDetails?.address} ${employee?.contactDetails?.city} ${employee?.contactDetails?.country}`,
    },
    {
      label: "Email Address",
      value: employee?.contactDetails?.emailAddress,
    },
  ];
  const employeeContractDetails = [
    {
      label: "Contractor",
      value: employee?.contractor?.name || '',
    },
    {
      label: "Designation",
      value: employee?.contractor?.name || '',
    },
    {
      label: "Employee Role",
      value: employee?.employeeRole || '',
    },
    {
      label: "Employment Type",
      value: employee?.employmentType?.name || '',
    },
  ];
  const employeeEmergencyContactDetails = [
    {
      label: "Name",
      value: employee?.emergencyContacts?.[0]?.fullName,
    },
    {
      label: "Email Address",
      value: employee?.emergencyContacts?.[0]?.email,
    },
    {
      label: "Address",
      value: employee?.emergencyContacts?.[0]?.address,
    },
    {
      label: "Relationship",
      value: employee?.emergencyContacts?.[0]?.relationship,
    },
    {
      label: "Phone Number",
      value: employee?.emergencyContacts?.[0]?.mobileNumber,
    },
  ];
  return (
    <>
      <Dialog open  fullScreen style={{ opacity: "0", zIndex: "-9999", transform: "scale(1)", pointerEvents: "none" }}>
        <Stack ref={targetRef} style={{ width: "210mm !important", height: "297mm !important" }} >
          <Stack
            spacing={3}
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <TableContainer sx={{ padding: "5px" }}>
              <Typography variant="h6" align="center" fontWeight="bold" sx={{ color: "black", paddingBottom: "5px" }}  >
                EMPLOYMENT & COMPANY ENROLLMENT RECORD
              </Typography>
              <Table
                sx={{
                  "& td, & th": {
                    borderBottom: "1px solid black",
                    padding: "0px",
                  },
                  "& th": {
                    verticalAlign: "center",
                  },
                  "& tr": {
                    height: "auto",
                  },
                }}
              >
                <Box style={{ width: "100%", height: "30px", border: "2px solid black", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "10px" }}>
                  <Box sx={{ flex: 1 }} style={{ position: "relative", bottom: "10px", left: "10px" }}>
                    <Typography sx={{ color: "black" }} >
                      Employee
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }} style={{ position: "relative", bottom: "10px", left: "10px" }}>
                    <Typography sx={{ color: "black" }}>
                      {/* Add content here if needed */}
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }} style={{ position: "relative", bottom: "10px", left: "10px" }}>
                    <Typography sx={{ color: "black" }}>
                      Employment Status:
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }} style={{ position: "relative", bottom: "10px", left: "10px" }}>
                    <Typography sx={{ color: "black" }}>
                      Joined Since:
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  <Box sx={{ width: "140%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        border: "2px solid black",
                        padding: 1,
                      }}
                    >
                      <Box sx={{ flex: 1 }} style={{ paddingLeft: "10px" }}>
                        {employeePersonalDetails.map((item, index) => (
                          <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                            {item.label}
                          </Typography>
                        ))}
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        {employeePersonalDetails.map((item, index) => (
                          <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                            {item.value}
                          </Typography>
                        ))}
                      </Box>
                    </Box>


                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        border: "2px solid black",
                        padding: 1,
                      }}
                    >
                      <Box sx={{ flex: 1 }} style={{ paddingLeft: "10px" }}>
                        {employeeContactDetails.map((item, index) => (
                          <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                            {item.label}
                          </Typography>
                        ))}
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        {employeeContactDetails.map((item, index) => (
                          <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                            {item.value}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ width: "588px", height: "246px", border: "2px solid black" }}>
                    <img src={employee.profileUrl} alt="Employee" style={{ width: "100%", height: "100%" }} />
                  </Box>

                </Box>

                <Box sx={{ width: "100%", border: "2px solid black", display: "flex", flexDirection: "row", marginTop: "2px" }}>
                  <Box sx={{ flex: 1, padding: "5px", border: "none" }} style={{ paddingLeft: "10px" }}>
                    {employeeContractDetails.map((item, index) => (
                      <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                        {item.label}
                      </Typography>
                    ))}
                  </Box>
                  <Box sx={{ flex: 1, padding: "5px", border: "none" }}>
                    {employeeContractDetails.map((item, index) => (
                      <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                        {item.value}
                      </Typography>
                    ))}
                  </Box>
                </Box>
                <Box
                  style={{
                    border: "2px solid black",
                    backgroundColor: "#80808021"
                  }}
                >
                  <Typography variant="h6" fontWeight="bold"
                    style={{
                      position: "relative",
                      bottom: "10px",
                      left: "10px"
                    }}
                  >
                    EMERGENCY CONTACT DETAILS
                  </Typography>
                </Box>




                <Box sx={{ width: "100%", border: "2px solid black", display: "flex", flexDirection: "row" }}>
                  <Box sx={{ flex: 1, padding: "5px", border: "none" }} style={{ paddingLeft: "10px" }}>
                    {employeeEmergencyContactDetails.map((item, index) => (
                      <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                        {item.label}
                      </Typography>
                    ))}
                  </Box>
                  <Box sx={{ flex: 1, padding: "5px", border: "none" }}>
                    {employeeEmergencyContactDetails.map((item, index) => (
                      <Typography key={index} gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                        {item.value}
                      </Typography>
                    ))}
                  </Box>
                </Box>
                <Box
                  style={{
                    border: "2px solid black",
                    backgroundColor: "#80808021"
                  }}
                >
                  <Typography variant="h6" fontWeight="bold"
                    style={{
                      position: "relative",
                      bottom: "10px",
                      left: "10px"
                    }}
                  >
                    IDENTITY & RIGHT TO WORK
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    border: "2px solid black",
                    marginTop: "2px",
                    marginBottom: "2px",
                    padding: "2px",
                    width: "100%"
                  }}
                  style={{ paddingLeft: "10px" }}
                >
                  {/* First Column */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Nationality
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Do you have right to work?
                    </Typography>
                  </Box>

                  {/* Second Column */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.identityAndRightToWork?.nationality}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.identityAndRightToWork?.hasRightToWork}
                    </Typography>
                  </Box>

                  {/* Third Column */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      ID Provided:
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Proof Provided:
                    </Typography>
                  </Box>

                  {/* Fourth Column */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.identityAndRightToWork?.proofOfIdentityFront ? "Yes" : "No"}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.identityAndRightToWork?.proofOfRightToWork ? "Yes" : "No"}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    border: "2px solid black",
                    backgroundColor: "#80808021"
                  }}
                >
                  <Typography variant="h6" fontWeight="bold"
                    style={{
                      position: "relative",
                      bottom: "10px",
                      left: "10px"
                    }}
                  >
                    PAYMENT INFORMATION
                  </Typography>
                </Box>


                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    border: "2px solid black",
                    marginTop: "5px",
                    marginBottom: "2px",
                    padding: "2px",
                    width: "100%", // Ensuring the width is 116%
                    backgroundColor: "#fff",
                  }}
                  style={{ paddingLeft: "10px" }}
                >
                  {/* First Column - Labels */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      National Insurance Number
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Unique Tax Reference Number:
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Bank Name
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Bank Address
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Account Number
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Sort Code
                    </Typography>
                  </Box>

                  {/* Second Column - Values */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.employeePayment?.nationalInsurance}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.employeePayment?.uniqueTaxReferenceNumber}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.employeePayment?.bankName}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.employeePayment?.branchAddress}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.employeePayment?.accountNumber}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.employeePayment?.sortCode}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    border: "2px solid black",
                    backgroundColor: "#80808021"
                  }}
                >
                  <Typography variant="h6" fontWeight="bold"
                    style={{
                      position: "relative",
                      bottom: "10px",
                      left: "10px"
                    }}
                  >
                    QUALIFICATIONS & EXPERIENCE
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    border: "2px solid black",
                    marginTop: "2px",
                    marginBottom: "2px",
                    padding: "2px",
                    width: "100%", // Adjust if necessary
                    backgroundColor: "#fff",
                  }}
                >
                  {/* First Column - Questions */}
                  <Box sx={{ flex: 1, padding: "5px" }} style={{ paddingLeft: "10px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Have qualifications and certificates of relevant training schemes been provided? e.g., CSCS
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      How long have you been working in this position?
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Have you been out of work before starting this job?
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Please specify how long you have been out of work.
                    </Typography>
                  </Box>

                  {/* Second Column - Answers (Replace with actual employee data if needed) */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {/* {employee?.qualifications?.provided} */}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {/* {employee?.experience?.yearsInPosition} */}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {/* {employee?.workHistory?.outOfWorkBefore} */}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {/* {employee?.workHistory?.outOfWorkDuration} */}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    border: "2px solid black",
                    backgroundColor: "#80808021"
                  }}
                >
                  <Typography variant="h6" fontWeight="bold"
                    style={{
                      position: "relative",
                      bottom: "10px",
                      left: "10px"
                    }}
                  >
                    MEDICAL & HEALTH CONDITIONS
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    border: "2px solid black",
                    marginTop: "2px",
                    marginBottom: "2px",
                    padding: "2px",
                    width: "100%", // Adjust as needed
                    backgroundColor: "#fff",
                  }}
                >
                  {/* First Column - Questions */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Have you been in the hospital in the last 3 months?
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Please specify why you have been in the hospital in the last 3 months.
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Do you suffer from any health condition or take any medication that could affect you at work?
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Please give us more details of your medical condition.
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      Do you understand that we carry out random alcohol and drug testing?
                    </Typography>
                  </Box>

                  {/* Second Column - Answers */}
                  <Box sx={{ flex: 1, padding: "5px" }}>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.medicalInformation?.outOfWork}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.medicalInformation?.outOfWorkDuration}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.medicalInformation?.hasHealthCondition}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.medicalInformation?.healthConditionDetails}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "14px" }} pl={0.5}>
                      {employee?.medicalInformation?.understandsAlcoholDrugTesting}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    border: "2px solid black",
                    backgroundColor: "#80808021"
                  }}
                >
                  <Typography variant="h6" fontWeight="bold"
                    style={{
                      position: "relative",
                      bottom: "10px",
                      left: "10px"
                    }}
                  >
                    EMPLOYMENT DECLARATION
                  </Typography>
                </Box>

                <TableBody>
                  <Box sx={{ display: "flex", justifyContent: "center", width: "100%", border: "2px solid black" }}>
                    <Box sx={{ borderRight: "2px solid black", width: "50%" }}>
                      <Typography sx={{ padding: "5px", paddingBottom: "10px", width: "100%" }}>
                        <b>EMPLOYEE:</b>I confirm that that the above information is correct, I have
                        understood the
                        company rules, and I have been inducted in a language I understand. I have
                        read, understood and agree with the company terms and conditions of
                        employment, contract and policies.

                      </Typography>
                    </Box>
                    <Box sx={{ width: "50%" }}>
                      <Typography sx={{ padding: "5px", paddingBottom: "10px" }}>
                        <b>INDUCTOR:</b> confirm that that the above information is correct, I have
                        understood the
                        company rules, and I have been inducted in a language I understand. I have
                        read, understood and agree with the company terms and conditions of
                        employment, contract and policies.
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center", width: "100%", borderLeft: "2px solid black", borderBottom: "2px solid black" }}>
                    <Box sx={{ borderRight: "2px solid black", width: "50%" }}>
                      <Box sx={{ borderBottom: "2px solid black", padding: "5px", paddingBottom: "5px" }}>
                        Name :
                      </Box>
                      <Box sx={{ padding: "5px", paddingBottom: "5px" }}>
                        Date :
                      </Box>
                    </Box>

                    <Box sx={{ borderRight: "2px solid black", width: "50%", padding: "5px", paddingBottom: "5px" }}>
                      Signed:
                    </Box>
                    <Box sx={{ borderRight: "2px solid black", width: "50%" }}>
                      <Box sx={{ borderBottom: "2px solid black", padding: "5px", paddingBottom: "5px" }}>
                        Name :
                      </Box>
                      <Box sx={{ padding: "5px", paddingBottom: "5px" }}>
                        Date :
                      </Box>

                    </Box>
                    <Box sx={{ borderRight: "2px solid black", width: "50%", padding: "5px", paddingBottom: "5px" }}>
                      Signed:
                    </Box>
                  </Box>
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
      </Dialog>

    </>
  )
}
export { SignedDeclarationContract,SignedDeclarationContractPDF };
