import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { selectCompanyId } from "../company/selectors";
import {
  bookProjectAction,
  bookProjectActionFailed,
  bookProjectActionSuccess,
  checkDuplicateAction,
  checkDuplicateActionFailed,
  checkDuplicateActionSuccess,
  getEmployees,
  getEmployeesFailed,
  getEmployeesSuccess,
  removeAssignedProjectAction,
  removeAssignedProjectActionFailed,
  removeAssignedProjectActionSuccess,
  addEmployeeCscsDocumentAction,
  updateEmployeeAction,
  addEmployeeQualificationDocumentAction,
  setDuplicateNull,
  getEmployee,
  getEmployeeSuccess,
  getEmployeeFailed,
  addEmployeeCscsDocumentActionFailed,
  addEmployeeQualificationDocumentActionFailed,
  addEmployeeQualificationDocumentActionSuccess,
  addEmployeeCscsDocumentActionSuccess,
  addEmployeeContact,
  addEmployeeContactSuccess,
  addEmployeeContactFailed,
  addIdentifyToRight,
  addIdentifyToRightSuccess,
  addIdentifyToRightFailed,
  addPaymentDetails,
  addPaymentDetailsSuccess,
  addPaymentDetailsFailed,
  addMedicalInformation,
  addMedicalInformationSuccess,
  addEmployeeDeclaration,
  addEmployeeDeclarationSuccess,
  addMedicalInformationFailed,
  addEmployeeDeclarationFailed,
  updatePersonalDetailsSuccess,
  updatePersonalDetailsFailed,
  updatePersonalDetails,
  getEmployeeProjects,
  getEmployeesProjectsSuccess,
  getEmployeeEmergencyContactSuccess,
  getEmployeesProjectsFailed,
  getEmployeeEmergencyContactFailed,
  getEmployeeEmergencyContact,
  updateInductionStatus,
  updateInductionStatusSuccess,
  updateInductionStatusFailed,
  employeeRecycleBinAction,
  employeeRecycleBinSuccessAction,
  employeeRecycleBinFailedAction,
  updateQualificationActionSuccess,
  updateQualificationActionFailed,
  updateQualificationAction,
  updateEmployeeActionSuccess,
  updateEmployeeActionFailed,
  addProofOfIdentity,
  addProofOfIdentitySuccess,
  addProofOfIdentityFailed,
  updateProofOfIdentitySuccess,
  updateProofOfIdentityFailed,
  deleteProofOfIdentitySuccess,
  deleteProofOfIdentityFailed,
  addProofOfWorkSuccess,
  addProofOfWorkFailed,
  updateProofOfWorkSuccess,
  updateProofOfWorkFailed,
  deleteProofOfWorkSuccess,
  deleteProofOfWorkFailed,
  updateProofOfIdentity,
  deleteProofOfIdentity,
  addProofOfWork,
  updateProofOfWork,
  deleteProofOfWork,
  deleteCscsAction,
  deleteCscsSuccess,
  deleteCscsFailed,
  deleteQualificationAction,
  deleteQualificationSuccess,
  deleteQualificationFailed,
  pdfData,
  deleteQualificationSuccessSingle,
  deleteQualificationFailedSingle,
  deleteCscsSingleImageAction,
  deleteQualificationActionSingle,
  getOnboardingEmployees,
  getOnboardingEmployeesSuccess,
  getOnboardingEmployeesFailed,
  addEmployeeInductor,
  addEmployeeInductorSuccess,
  addEmployeeInductorFailed,
  addDetailsOfContractSuccess,
  addDetailsOfContractFailed,
  addDetailsOfContract,
} from "./slice";
import { request } from "@app/services";
import * as endpoint from "./endpoints";
import {
  selectDuplicateEmployee,
  selectSelectedEmployee,
  selectSelectedEmployeeData,
  selectSelectedEmployeeEmergencyContacts,
  selectSelectedEmployeeProjects,
} from "./selector";
import { selectSelectedProject } from "../project/selectors";
import { employee } from "./endpoints";

function* fetchEmployees(action: any) {
  try {
    const { page = 1, limit = 2, projectId } = action.payload;
    const companyId: string = yield select(selectCompanyId);

    if (companyId) {
      const { data } = yield call(
        request.get,
        `${endpoint.employee}?companyId=${companyId}&page=${page}&limit=${limit}&projectId=${projectId}`
      );
      yield put(getEmployeesSuccess({ data }));
    }
  } catch (error: any) {
    yield put(getEmployeesFailed(error));
  }
}

function* fetchProjectEmployees(action: any): any {
  try {
    const { page = 1, limit = 2 } = action.payload;
    const companyId: string = yield select(selectCompanyId);
    const project: any = yield select(selectSelectedProject);

    if (companyId) {
      if (project) {
        console.log("project", project);
        if (project.id) {
          const { data } = yield call(
            request.get,
            `${endpoint.employee}${endpoint.project_based_employee}${project.id}?page=${page}&limit=${limit}`
          );
          yield put(getEmployeesSuccess({ data }));
        } else {
          const { data } = yield call(
            request.get,
            `${endpoint.employee}${endpoint.getTotalCompanyEmployee}${companyId}`
          );
          yield put(getEmployeesSuccess({ data }));
        }
      } else {
        const { data } = yield call(
          request.get,
          `${endpoint.employee}?companyId=${companyId}&page=${page}&limit=${limit}`
        );
        yield put(getEmployeesSuccess({ data }));
      }
    }
  } catch (error: any) {
    yield put(getEmployeesFailed(error));
  }
}

function* fetchEmployeeProject(action: any) {
  try {
    const { page = 1, limit = 10 } = action.payload;
    const { id } = yield select(selectSelectedEmployee);

    if (id) {
      const { data } = yield call(
        request.get,
        `${endpoint.employee}/${id}${endpoint.getEmployeeProjects}?&page=${page}&limit=${limit}`
      );
      yield put(getEmployeesProjectsSuccess({ data }));
    }
  } catch (error: any) {
    yield put(getEmployeesProjectsFailed(error));
  }
}

function* fetchEmployeeEmergencyContacts(action: any) {
  try {
    const { page = 1, limit = 10 } = action.payload;
    const { id } = yield select(selectSelectedEmployee);

    if (id) {
      const { data } = yield call(
        request.get,
        `${endpoint.employee}/${id}${endpoint.getEmergencyContact}?&page=${page}&limit=${limit}`
      );
      yield put(getEmployeeEmergencyContactSuccess({ data }));
    }
  } catch (error: any) {
    yield put(getEmployeeEmergencyContactFailed(error));
  }
}

function* fetchEmployee(action: any) {
  try {
    const { employeeId } = action.payload;
    if (employeeId) {
      const { data } = yield call(
        request.get,
        `${endpoint.employee}/${employeeId}`
      );
      yield put(getEmployeeSuccess({ data }));
    }
  } catch (error: any) {
    yield put(getEmployeeFailed(error));
  }
}

function* checkDuplicate(action: ReturnType<typeof checkDuplicateAction>) {
  try {
    debugger
    const companyId: string = yield select(selectCompanyId);
    const {data,status} = yield call(request.post, endpoint.checkDuplicate, {
      companyId: companyId,
      ...action.payload,
    });
    if(status)
    yield put(checkDuplicateActionSuccess({ data }));
    else
    yield put(checkDuplicateActionFailed(data));
  
  } catch (error: any) {
    yield put(checkDuplicateActionFailed(error));
  }
}

function* updateEmployeeProfile(
  action: ReturnType<typeof updateEmployeeAction>
) {
  try {
    const { employeeId, callback, ...rest } = action.payload;

    const formData = new FormData();

    for (const key in rest) {
      if (rest.hasOwnProperty(key)) {
        const value = rest[key];

        if (key === "imageUpload" && value) {
          formData.append("file", value);
        } else {
          formData.append(key, value);
        }
      }
    }

    const { data } = yield call(
      request.post,
      `${endpoint.employee}?employeeId=${employeeId}`,
      formData
    );

    yield put(updateEmployeeActionSuccess({ data }));

    if (callback) {
      callback();
    }
  } catch (error: any) {
    yield put(updateEmployeeActionFailed(error));
  }
}

function* bookProject(action: ReturnType<typeof bookProjectAction>) {
  try {
    const { navigate, ...rest } = action.payload;
    const { data } = yield call(request.post, endpoint.bookProject, rest);

    yield put(bookProjectActionSuccess({ data }));
    if (navigate) navigate();
  } catch (error: any) {
    yield put(bookProjectActionFailed(error));
  }
}

function* updateProjectInductionStatus(
  action: ReturnType<typeof updateInductionStatus>
): any {
  try {
    const { id, ...rest } = action.payload;
    const { data } = yield call(
      request.post,
      `${endpoint.employee}/${id}${endpoint.updateInductionStatus}`,
      rest
    );
    const existingProjects = yield select(selectSelectedEmployeeProjects);
    if (existingProjects) {
      console.log("existing projects----", existingProjects);
      const newProjects = {
        ...existingProjects,
        data: existingProjects.data.map((item: any) => {
          return item.id === id
            ? { ...item, inductedDate: data.inductedDate }
            : item;
        }),
      };
      yield put(getEmployeesProjectsSuccess({ data: newProjects }));
    }
  } catch (error: any) {
    yield put(updateInductionStatusFailed(error));
  }
}

function* employeeRecycleBinSaga(
  action: ReturnType<typeof employeeRecycleBinAction>
) {
  try {
    console.log("employeeRecycleBinSaga", action.payload);
    const { id, callback, pageLimit, pageValue } = action.payload;
    const { data } = yield call(
      request.get,
      `${endpoint.employee}/${id}${endpoint.employee_recycle_bin}`
    );
    yield put(employeeRecycleBinSuccessAction(data));
    yield put(getEmployees({ page: pageValue, limit: pageLimit }));
    callback();
  } catch (error: any) {
    yield put(employeeRecycleBinFailedAction(error));
  }
}

function* removeAssignedProject(
  action: ReturnType<typeof removeAssignedProjectAction>
): any {
  try {
    const response = yield call(
      request.delete,
      `${endpoint.removeEmployeeProject}/${action.payload.id}`
    );

    yield put(removeAssignedProjectActionSuccess(response));

    const selectDuplicate = yield select(selectDuplicateEmployee);

    if (selectDuplicate?.data) {
      const projects = selectDuplicate.data.employeeProjects.filter(
        (item: any) => item.id != response.data
      );
      const newDuplicateEmployee = {
        ...selectDuplicate,
        data: { ...selectDuplicate.data, employeeProjects: projects },
      };
      yield put(checkDuplicateActionSuccess({ data: newDuplicateEmployee }));
    }
    if (response.data) {
      const existingProjects = yield select(selectSelectedEmployeeProjects);
      if (existingProjects) {
        const newProjects = {
          ...existingProjects,
          data: existingProjects.data.filter(
            (item: any) => item.id !== response.data
          ),
        };
        yield put(getEmployeesProjectsSuccess({ data: newProjects }));
      }
    }
  } catch (error: any) {
    yield put(removeAssignedProjectActionFailed(error));
  }
}

function* addEmployeeCSCS(
  action: ReturnType<typeof addEmployeeCscsDocumentAction>
): any {
  try {
    const formData = new FormData();
    const { payload, callback } = action.payload;

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        const value = payload[key];

        if (key === "imageUpload" && value) {
          formData.append("file", value);
        } else {
          if (value) formData.append(key, value);
        }
      }
    }
    const selectedEmployee = yield select(selectSelectedEmployeeData);

    const result = yield call(request.post, `${endpoint.uploadCscs}`, formData);

    if (selectedEmployee) {
      yield put(getEmployee({ employeeId: selectedEmployee.employee.id }));
    }
    yield put(addEmployeeCscsDocumentActionSuccess(result));
    callback();
  } catch (error: any) {
    yield put(addEmployeeCscsDocumentActionFailed(error));
  }
}

function* addQualification(
  action: ReturnType<typeof addEmployeeQualificationDocumentAction>
): any {
  try {
    const formData = new FormData();
    const { payload, callback } = action.payload;

    console.log("Payload_________", payload);

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        const value = payload[key];

        if (key === "imageUpload" && value) {
          formData.append("file", value);
        } else {
          if (value) formData.append(key, value);
        }
      }
    }
    const selectedEmployee = yield select(selectSelectedEmployeeData);
    const result = yield call(
      request.post,
      `${endpoint.uploadQualification}`,
      formData
    );

    if (selectedEmployee) {
      yield put(getEmployee({ employeeId: selectedEmployee.employee.id }));
    }
    yield put(addEmployeeQualificationDocumentActionSuccess(result));
    callback();
  } catch (error: any) {
    yield put(addEmployeeQualificationDocumentActionFailed(error));
  }
}

function* addEmployeeContactSagas(
  action: ReturnType<typeof addEmployeeContact>
): any {
  try {
    let result;
    if (action.payload.section === "contactDetails") {
      if (action.payload.contactDetaiId) {
        result = yield call(
          request.post,
          `${endpoint.addContactDetails}${action.payload.employeeId}/contact-details?contactDetailId=${action.payload.contactDetaiId}`,
          action.payload
        );
      } else {
        result = yield call(
          request.post,
          `${endpoint.addContactDetails}${action.payload.employeeId}/contact-details`,
          action.payload
        );
      }
    } else {
      if (action.payload.emergencyContactId) {
        result = yield call(
          request.post,
          `${endpoint.addContactDetails}${action.payload.employeeId}/emergency-contact?emergencyContactId=${action.payload.emergencyContactId}`,
          action.payload
        );
        yield put(getEmployeeEmergencyContact({ page: 1, limit: 10 }));
      } else {
        result = yield call(
          request.post,
          `${endpoint.addContactDetails}${action.payload.employeeId}/emergency-contact`,
          action.payload
        );
        yield put(getEmployeeEmergencyContact({ page: 1, limit: 10 }));
      }
    }
    yield put(addEmployeeContactSuccess(result.data));

    action.payload.callback();
  } catch (err: any) {
    yield put(addEmployeeContactFailed(err));
  }
}

function* addIdentifyToRightSagas(
  action: ReturnType<typeof addIdentifyToRight>
): any {
  try {
    let result;
    const {
      hasRightToWork,
      nationality,
      employeeId,
      proofOfIdentityBack,
      proofOfIdentityFront,
      proofOfRightToWork,
    } = action.payload;

    const formData = new FormData();
    if (hasRightToWork) formData.append("hasRightToWork", hasRightToWork);
    if (nationality) formData.append("nationality", nationality);
    if (proofOfIdentityBack)
      formData.append("proofOfIdentityBack", proofOfIdentityBack);
    if (proofOfIdentityFront)
      formData.append("proofOfIdentityFront", proofOfIdentityFront);
    if (proofOfRightToWork)
      formData.append("proofOfRightToWork", proofOfRightToWork);

    result = yield call(
      request.put,
      `${endpoint.addIdentifyRightToWork}${employeeId}/identity-and-right-to-work`,
      formData
    );

    console.log("Response_________", result);

    yield put(addIdentifyToRightSuccess(result.message));

    action.payload.callback();
  } catch (err: any) {
    yield put(addIdentifyToRightFailed(err));
  }
}

function* addPaymentDetailsSagas(
  action: ReturnType<typeof addPaymentDetails>
): any {
  try {
    const {
      nationalInsurance,
      uniqueTaxReferenceNumber,
      bankName,
      accountNumber,
      branchAddress,
      sortCode,
      employeeId,
      employmentTypeId,
      departmentId,
      callback,
      isAdd,
    } = action.payload;

    // const filteredPayload = Object.fromEntries(
    //   Object.entries({
    //     nationalInsurance,
    //     uniqueTaxReferenceNumber,
    //     bankName,
    //     accountNumber,
    //     branchAddress,
    //     sortCode,
    //     employmentTypeId,
    //     departmentId,
    //     employeeId,
    //   }).filter(([_, value]) => value !== undefined && value.length > 0)
    // );

    const result = yield call(
      isAdd ? request.post : request.put,
      `${endpoint.employeePayment}`,
      action.payload
    );

    const selectedEmployee = yield select(selectSelectedEmployeeData);

    if (selectedEmployee) {
      yield put(getEmployee({ employeeId: selectedEmployee.employee.id }));
    }

    yield put(addPaymentDetailsSuccess(result.message));

    callback();
  } catch (err: any) {
    yield put(addPaymentDetailsFailed(err));
  }
}
function* addMedicalInformationSagas(
  action: ReturnType<typeof addMedicalInformation>
): any {
  try {
    const {
      outOfWork,
      outOfWorkDuration,
      beenInHospital,
      hospitalReason,
      hasHealthCondition,
      healthConditionDetails,
      understandsAlcoholDrugTesting,
      employeeId,
      isAdd,
    } = action.payload;

    const result = yield call(
      isAdd ? request.post : request.put,
      `${endpoint.addMedicalInformation}${employeeId}/medical-information`,
      {
        outOfWork,
        outOfWorkDuration,
        beenInHospital,
        hospitalReason,
        hasHealthCondition,
        healthConditionDetails,
        understandsAlcoholDrugTesting,
      }
    );
    console.log("Result________", result);

    yield put(addMedicalInformationSuccess(result.message));

    action.payload.callback();
  } catch (err: any) {
    yield put(addMedicalInformationFailed(err));
  }
}
function* addEmployeeDeclarationSagas(
  action: ReturnType<typeof addEmployeeDeclaration>
): any {
  try {
    const {
      termsAndConditions,
      healthAndSafetyPolicy,
      informationAccuracy,
      printName,
      signature,
      date,
      employeeId,
    } = action.payload;

    const result = yield call(
      request.post,
      `${endpoint.addDeclaration}${employeeId}/declaration`,
      {
        termsAndConditions,
        healthAndSafetyPolicy,
        informationAccuracy,
        printName,
        signature,
        date,
      }
    );

    yield put(addEmployeeDeclarationSuccess(result.message));

    action.payload.callback();
  } catch (err: any) {
    yield put(addEmployeeDeclarationFailed(err));
  }
}

function* updatePersonalDetailsSagas(
  action: ReturnType<typeof updatePersonalDetails>
): any {
  try {
    const {
      contractorName,
      dateOfBirth,
      firstName,
      gender,
      imageUpload,
      lastName,
    } = action.payload;
    const { callback, fromUpdateEmployeeProfile, employeeId, ...rest } =
      action.payload;
    const formData = new FormData();
    if (!fromUpdateEmployeeProfile) {
      if (contractorName) {
        formData.append("contractorId", contractorName);
      }
      formData.append("dateOfBirth", dateOfBirth);
      formData.append("fullName", `${firstName} ${lastName}`);
      formData.append("gender", gender);
      if (imageUpload) {
        formData.append("file", imageUpload);
      }
    } else {
      for (const key in rest) {
        if (rest.hasOwnProperty(key)) {
          const value = rest[key];

          if (key === "imageUpload" && value) {
            formData.append("file", value);
          } else {
            formData.append(key, value);
          }
        }
      }
    }
    console.log("FormData_________", rest);

    const result = yield call(
      request.put,
      `${endpoint.updateProfile}${employeeId}`,
      formData
    );

    console.log("Result_________", result);

    yield put(updatePersonalDetailsSuccess(result.message));
    action.payload.callback();
  } catch (err: any) {
    yield put(updatePersonalDetailsFailed(err));
  }
}

function* updateQualificationSaga(action: any): any {
  try {
    const { employeeId, qualifications, callback } = action.payload;

    const formData = new FormData();
    formData.append("employeeId", employeeId);
    qualifications.forEach((qualification: any, index: number) => {
      formData.append(`qualifications[${index}][id]`, qualification.id);
      formData.append(
        `qualifications[${index}][certificationName]`,
        qualification.certificationName
      );
      if (qualification.registrationNumber) {
        formData.append(
          `qualifications[${index}][registrationNumber]`,
          qualification.registrationNumber
        );
      }
      if (qualification.expiryDate) {
        formData.append(
          `qualifications[${index}][expiryDate]`,
          qualification.expiryDate
        );
      }
      if (qualification.files && qualification.files.front) {
        formData.append(
          `qualifications[${index}][front]`,
          qualification.files.front
        );
      }
      if (qualification.files && qualification.files.back) {
        formData.append(
          `qualifications[${index}][back]`,
          qualification.files.back
        );
      }
    });

    const result = yield call(
      request.put,
      `/employee/${employeeId}/qualifications`,
      formData
    );

    yield put(updateQualificationActionSuccess(result.message));
    callback();
  } catch (err) {
    yield put(updateQualificationActionFailed(err));
  }
}

function* createIdentitySaga(action: any): any {
  try {
    const { employeeId, documentName, file, callback } = action.payload;

    const formData = new FormData();
    formData.append("documentName", documentName);
    if (file) {
      formData.append("file", file);
    }

    const { data } = yield call(
      request.post,
      `${endpoint.employee}/${employeeId}${endpoint.identity_and_right_to_work}/identity`,
      formData
    );

    yield put(getEmployee({ employeeId }));

    yield put(addProofOfIdentitySuccess(data));
    callback();
  } catch (err) {
    yield put(addProofOfIdentityFailed(err));
  }
}

function* updateIdentitySaga(action: any): any {
  try {
    const { employeeId, documentName, file, callback } = action.payload;

    const formData = new FormData();
    formData.append("documentName", documentName);
    if (file) {
      formData.append("file", file);
    }

    const { data } = yield call(
      request.put,
      `${endpoint.employee}/${employeeId}${endpoint.identity_and_right_to_work}/identity`,
      formData
    );

    yield put(getEmployee({ employeeId }));
    yield put(updateProofOfIdentitySuccess(data));
    callback();
  } catch (err) {
    yield put(updateProofOfIdentityFailed(err));
  }
}

function* deleteIdentitySaga(action: any): any {
  try {
    const { employeeId, documentId } = action.payload;
    const selectedEmployee = yield select(selectSelectedEmployeeData);
    const { data } = yield call(
      request.delete,
      `${endpoint.employee}/${selectedEmployee.employee.id}${endpoint.identity_and_right_to_work}/identity/${documentId}`
    );

    yield put(getEmployee({ employeeId: selectedEmployee.employee.id }));
    yield put(deleteProofOfIdentitySuccess(data));
  } catch (err) {
    yield put(deleteProofOfIdentityFailed(err));
  }
}

function* addProofOfWorkSaga(action: any): any {
  try {
    const { employeeId, documentName, file, callback } = action.payload;

    const formData = new FormData();
    formData.append("documentName", documentName);
    if (file) {
      formData.append("file", file);
    }

    const { data } = yield call(
      request.post,
      `${endpoint.employee}/${employeeId}${endpoint.identity_and_right_to_work}/right_to_work`,
      formData
    );

    yield put(getEmployee({ employeeId }));
    yield put(addProofOfWorkSuccess(data));
    callback();
  } catch (err) {
    yield put(addProofOfWorkFailed(err));
  }
}

function* updateProofOfWorkSaga(action: any): any {
  try {
    const { employeeId, documentName, file, callback } = action.payload;

    const formData = new FormData();
    formData.append("documentName", documentName);
    if (file) {
      formData.append("file", file);
    }

    const { data } = yield call(
      request.put,
      `${endpoint.employee}/${employeeId}${endpoint.identity_and_right_to_work}/right_to_work`,
      formData
    );

    yield put(getEmployee({ employeeId }));
    yield put(updateProofOfWorkSuccess(data));
    callback();
  } catch (err) {
    yield put(updateProofOfWorkFailed(err));
  }
}

function* deleteProofOfWorkSaga(action: any): any {
  try {
    const { employeeId, documentId } = action.payload;

    const { data } = yield call(
      request.delete,
      `${endpoint.employee}/${employeeId}${endpoint.identity_and_right_to_work}/right_to_work/${documentId}`
    );

    yield put(getEmployee({ employeeId }));
    yield put(deleteProofOfWorkSuccess(data));
  } catch (err) {
    yield put(deleteProofOfWorkFailed(err));
  }
}

function* deleteCscsSaga(action: any): any {
  try {
    const { documentId } = action.payload;
    const selectedEmployee = yield select(selectSelectedEmployeeData);

    const { data } = yield call(
      request.delete,
      `${endpoint.employee}/${selectedEmployee.employee.id}/cscs/${documentId}`
    );

    if (selectedEmployee) {
      yield put(getEmployee({ employeeId: selectedEmployee.employee.id }));
    }
    yield put(deleteCscsSuccess(data));
  } catch (err) {
    yield put(deleteCscsFailed(err));
  }
}

function* deleteQualificationSaga(action: any): any {
  try {
    const { documentId } = action.payload;
    const selectedEmployee = yield select(selectSelectedEmployeeData);
    const { data } = yield call(
      request.delete,
      `${endpoint.employee}/${selectedEmployee.employee.id}/qualification/${documentId}`
    );

    if (selectedEmployee) {
      yield put(getEmployee({ employeeId: selectedEmployee.employee.id }));
    }
    yield put(deleteQualificationSuccess(data));
  } catch (err) {
    yield put(deleteQualificationFailed(err));
  }
}

function* pdfGenerate(action: any): any {
  const pdfData = action.payload;
  const response = yield call(
    request.post,
    `${endpoint.employee}/pdf-generate`,
    pdfData
  );
  console.log(response, "hii");
  yield put(pdfGenerate(response.data));
  return response;
}

function* deleteCscsSagaSingle(action: any): any {
  try {
    const { documentId, fileUrl } = action.payload;
    const selectedEmployee = yield select(selectSelectedEmployeeData);

    const { data } = yield call(
      request.delete,
      `${endpoint.employee}/${selectedEmployee.employee.id}/cscs/${documentId}?fileUrl=${fileUrl}`
    );

    if (selectedEmployee) {
      yield put(getEmployee({ employeeId: selectedEmployee.employee.id }));
    }
    yield put(deleteCscsSuccess(data));
  } catch (err) {
    yield put(deleteCscsFailed(err));
  }
}

function* deleteQualificationSagaSingle(action: any): any {
  try {
    const { documentId, fileUrl } = action.payload;
    const selectedEmployee = yield select(selectSelectedEmployeeData);
    const { data } = yield call(
      request.delete,
      `${endpoint.employee}/${selectedEmployee.employee.id}/qualification/${documentId}?fileUrl=${fileUrl}`
    );

    if (selectedEmployee) {
      yield put(getEmployee({ employeeId: selectedEmployee.employee.id }));
    }
    yield put(deleteQualificationSuccessSingle(data));
  } catch (err) {
    yield put(deleteQualificationFailedSingle(err));
  }
}

function* fetchProjectOnboardingEmployees(action: any): any {
  try {
    const { page = 1, limit = 2 } = action.payload;
    const companyId: string = yield select(selectCompanyId);
    const project: any = yield select(selectSelectedProject);
    console.log("project", project, "company", companyId);

    if (companyId) {
      if (project) {
        if (project.id) {
          const { data } = yield call(
            request.get,
            `${endpoint.employee}${endpoint.getOnboardingEmployee}${project.id}?page=${page}&limit=${limit}`
          );
          yield put(getOnboardingEmployeesSuccess({ data }));
        } else {
          const { data } = yield call(
            request.get,
            `${endpoint.employee}${endpoint.getCompanyTotalEmployee}${companyId}`
          );
          yield put(getOnboardingEmployeesSuccess({ data }));
        }
      } else {
        const { data } = yield call(
          request.get,
          `${endpoint.employee}?companyId=${companyId}&page=${page}&limit=${limit}`
        );
        yield put(getOnboardingEmployeesSuccess({ data }));
      }
    }
  } catch (error: any) {
    yield put(getOnboardingEmployeesFailed(error));
  }
}

function* addEmployeeInductorSagas(
  action: ReturnType<typeof addEmployeeInductor>
): any {
  try {
    const {
      employeePresentedAllInformation,
      accurateAndValid,
      printName,
      signature,
      date,
      employeeId,
    } = action.payload;

    const result = yield call(
      request.post,
      `${endpoint.addInductor}${employeeId}/inductor`,
      {
        employeePresentedAllInformation,
        accurateAndValid,
        printName,
        signature,
        date,
      }
    );

    yield put(addEmployeeInductorSuccess(result.message));

    action.payload.callback();
  } catch (err: any) {
    yield put(addEmployeeInductorFailed(err));
  }
}

function* createDetailsContractSaga(action: any): any {
  try {
    const { employeeId, description, RevisionNumber, file, callback } =
      action.payload;

    const formData = new FormData();
    formData.append("description", description);
    formData.append("revisionNumber", RevisionNumber);
    if (file) {
      formData.append("file", file);
    }

    const { data } = yield call(
      request.post,
      `${endpoint.employee}/${employeeId}${endpoint.details_of_contract}/contract`,
      formData
    );

    yield put(getEmployee({ employeeId }));

    yield put(addDetailsOfContractSuccess(data));
    callback();
  } catch (err) {
    yield put(addDetailsOfContractFailed(err));
  }
}

export function* employeeSaga() {
  yield all([
    takeLatest(getEmployees, fetchProjectEmployees),
    takeLatest(getEmployee, fetchEmployee),
    takeLatest(checkDuplicateAction, checkDuplicate),
    takeLatest(bookProjectAction, bookProject),
    takeLatest(removeAssignedProjectAction, removeAssignedProject),
    takeLatest(updateEmployeeAction, updateEmployeeProfile),
    takeLatest(addEmployeeCscsDocumentAction, addEmployeeCSCS),
    takeLatest(addEmployeeQualificationDocumentAction, addQualification),
    takeLatest(addEmployeeContact, addEmployeeContactSagas),
    takeLatest(addIdentifyToRight, addIdentifyToRightSagas),
    takeLatest(addPaymentDetails, addPaymentDetailsSagas),
    takeLatest(addMedicalInformation, addMedicalInformationSagas),
    takeLatest(addEmployeeDeclaration, addEmployeeDeclarationSagas),
    takeLatest(updatePersonalDetails, updatePersonalDetailsSagas),
    takeLatest(getEmployeeProjects, fetchEmployeeProject),
    takeLatest(getEmployeeEmergencyContact, fetchEmployeeEmergencyContacts),
    takeLatest(updateInductionStatus, updateProjectInductionStatus),
    takeLatest(employeeRecycleBinAction, employeeRecycleBinSaga),
    takeLatest(updateQualificationAction, updateQualificationSaga),
    takeLatest(addProofOfIdentity, createIdentitySaga),
    takeLatest(updateProofOfIdentity, updateIdentitySaga),
    takeLatest(deleteProofOfIdentity, deleteIdentitySaga),
    takeLatest(addProofOfWork, addProofOfWorkSaga),
    takeLatest(updateProofOfWork, updateProofOfWorkSaga),
    takeLatest(deleteProofOfWork, deleteProofOfWorkSaga),
    takeLatest(deleteCscsAction, deleteCscsSaga),
    takeLatest(deleteQualificationAction, deleteQualificationSaga),
    takeLatest(pdfData, pdfGenerate),
    takeLatest(deleteCscsSingleImageAction, deleteCscsSagaSingle),
    takeLatest(deleteQualificationActionSingle, deleteQualificationSagaSingle),
    takeLatest(getOnboardingEmployees, fetchProjectOnboardingEmployees),
    takeLatest(addEmployeeInductor, addEmployeeInductorSagas),
    takeLatest(addDetailsOfContract, createDetailsContractSaga),
  ]);
}
