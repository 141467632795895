import {
  MenuPopover,
  RowActions as MenuRowActions,
  useMenuPopover,
} from "@app/components";
import { useDialog } from "@app/hooks/use-dialog";
import { Icon } from "@iconify/react";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { useRequestState } from "@app/hooks/use-request-state";
import { selectDeleteQualification } from "../selector"; // Updated selector
import { deleteQualificationAction } from "../slice";
import { AddNewQualification } from "./AddNewQualification";
import { useDeleteAlert } from "@app/hooks/useAlertDialogue";

const RowActions = ({ data }: any) => {
  const dispatch = useDispatch();

  const menuPopover = useMenuPopover();
  const editDialog = useDialog();
  const { showDeleteAlert } = useDeleteAlert();
  const actions = [
    {
      label: "Edit",
      callback: editDialog.show,
    },
    {
      label: "Delete",
      callback: () =>
        showDeleteAlert({
          description: `Are you sure you want to delete ${data?.name}? This action cannot be undone.`,
          onConfirm: () =>
            dispatch(deleteQualificationAction({ id: data?.id })), // Perform deletion on confirm
        }),
    },
  ];

  useRequestState({
    stateSelector: selectDeleteQualification,
    successMessageShown: true,
    errorShown: true,
  });

  return (
    <>
      <IconButton onClick={menuPopover.show} sx={{ color: "default" }}>
        <Icon icon="eva:more-vertical-fill" />
      </IconButton>

      <MenuPopover
        open={menuPopover.rowMenuAnchorElement}
        arrow="right-top"
        sx={{ width: 180 }}
        onClose={menuPopover.hide}
      >
        <MenuRowActions actions={actions} onClickAction={menuPopover.hide} />
      </MenuPopover>

      {editDialog.visible && (
        <AddNewQualification open onClose={editDialog.hide} data={data} /> // Updated modal
      )}
    </>
  );
};

export { RowActions };
