import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Stack,
  Typography,
  Divider,
  Card,
  Button,
  IconButton,
  CircularProgress,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import { saveAs } from "file-saver";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

type Props = {
  open: boolean;
  onClose: () => void;
  fileUrl: string;
  fileName: string;
  width?: string;
  minHeight?: string;
};

const FilePreviewDialog = ({
  open,
  onClose,
  fileUrl,
  fileName,
  width,
  minHeight,
}: Props) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const getFileType = () => {
    const ext = fileName.split(".").pop()?.toLowerCase();
    return ext;
  };
  const renderPreview = () => {
    const fileType = getFileType();

    if (fileType === "pdf") {
      return (
        <iframe
          src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
          style={{
            width: width ? width : "100%",
            minHeight: minHeight ? minHeight : "1000px",
            overflowY: "scroll",
          }}
          title="PDF Preview"
        ></iframe>
      );
    }

    if (["doc", "docx", "xls", "xlsx"].includes(fileType || "")) {
      return (
        <DocViewer
          documents={[{ uri: fileUrl, fileType }]}
          pluginRenderers={DocViewerRenderers}
          style={{
            width: "100%",
            minHeight: "1000px",
          }}
        />
      );
    }

    if (["png", "jpg", "jpeg", "gif"].includes(fileType || "")) {
      return (
        <img
          src={fileUrl}
          alt="Preview"
          style={{ maxWidth: "100%", minHeight: "500px", borderRadius: "8px" }}
        />
      );
    }

    return (
      <Typography color="error" variant="body1">
        Unable to preview this file type: {fileName}. Please download to view.
      </Typography>
    );
  };

  const handleDownload = async () => {
    try {
      setLoading(true);
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      saveAs(blob, fileName);
      setLoading(false);
    } catch (error) {
      console.error("Download error: ", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          minHeight: "calc(100% - 64px)",
          maxHeight: "90vh",
        },
      }}
    >
      <Stack sx={{ width: "100%", padding: "8px 16px" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
            File Preview
          </Typography>
          <IconButton onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider sx={{ color: "#F1F4F7" }} />
      </Stack>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "16px",
        }}
      >
        <Card
          sx={{
            width: "100%",
            borderRadius: "8px",
            overflowY: "scroll",
          }}
        >
          <Stack
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={handleDownload}
              sx={{
                position: "absolute",
                top: "8px",
                right: "8px",
                zIndex: 2,
                backgroundColor: "white",
                boxShadow: 1,
                "&:hover": { backgroundColor: "lightgray" },
              }}
              aria-label="download"
            >
              {loading ? (
                <CircularProgress
                  sx={{ color: theme.palette.grey[500] }}
                  size={30}
                />
              ) : (
                <DownloadIcon />
              )}
            </IconButton>
            {renderPreview()}
          </Stack>
        </Card>
      </DialogContent>
      <Divider sx={{ color: "#F1F4F7" }} />
      <Stack
        sx={{
          padding: "8px 16px",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="contained" color="error" onClick={onClose}>
          Close
        </Button>
      </Stack>
    </Dialog>
  );
};

export default FilePreviewDialog;
