import { RootState } from "@app/redux/root-reducer";

export const selectCompanyData = (store: RootState) =>
  store.companyReducer.company.data;
export const selectCompanyId = (store: RootState) =>
  store.companyReducer.selectedCompanyId;
export const selectSelectedCompany = (store: RootState) =>
  store.companyReducer.selectedCompany;
export const selectCompanyDetail = (store: RootState) =>
  store.companyReducer.selectedCompanyDetailById;
export const getSelectedCompanyDetails = (store: RootState) =>
  store.companyReducer.selectedCompanyDetailById?.data;
