import { useNavigate } from "react-router-dom";
import {
  MenuPopover,
  RowActions as MenuRowActions,
  useMenuPopover,
} from "@app/components";
import { useDialog } from "@app/hooks/use-dialog";
import { Icon } from "@iconify/react";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { employeeRecycleBinAction } from "@app/modules/add_employee/slice";
import { PATH_DASHBOARD } from "@app/routes/paths";
import { useDeleteAlert } from "@app/hooks/useAlertDialogue";

const RowActions = ({ data, pageLimit, pageValue, onBoardingReview }: any) => {
  const dispatch = useDispatch();

  const menuPopover = useMenuPopover();
  const navigate = useNavigate();
  const { showDeleteAlert } = useDeleteAlert(); // Use the global alert

  const actions = [
    {
      label: "Edit",
      callback: () => {
        navigate(PATH_DASHBOARD.edit_employee_profile, {
          state: { employeeId: data?.id },
        });
      },
    },
    {
      label: "Delete",
      callback: () =>
        showDeleteAlert({
          description: `Are you sure you want to delete ${data?.name}? This action cannot be undone.`,
          onConfirm: () =>
            dispatch(
              employeeRecycleBinAction({
                id: data?.id,
                pageLimit,
                pageValue,
              })
            ),
        }),
    },
  ];

  const onBoardingAction = [
    {
      label: "Review",
      callback: () => {
        navigate(PATH_DASHBOARD.addEmployee, {
          state: { employeeId: data?.id },
        });
      },
    },
  ];

  return (
    <>
      <IconButton onClick={menuPopover.show} sx={{ color: "#000000", ml: 1 }}>
        <Icon icon="eva:more-vertical-fill" />
      </IconButton>

      <MenuPopover
        open={menuPopover.rowMenuAnchorElement}
        arrow="right-top"
        sx={{ width: 180 }}
        onClose={menuPopover.hide}
      >
        <MenuRowActions
          actions={onBoardingReview ? onBoardingAction : actions}
          onClickAction={menuPopover.hide}
        />
      </MenuPopover>
    </>
  );
};

export { RowActions };
