import { all, call, put, takeLatest } from "redux-saga/effects";

import { request } from "@app/services";
import { setToken } from "@app/modules/common";
import { prepareUserSessionSaga } from "../bootstrap";
import * as endpoints from "./endpoints";
import {
  login,
  loginSuccess,
  loginFailed,
  register,
  registerSuccess,
  registerFailed,
} from "./slice";

// ------------------------------------------------------

function* loginSaga(action: ReturnType<typeof login>): any {
  try {
    const { email, password } = action.payload;
    const { data, status, message } = yield call(
      request.post,
      endpoints.login,
      {
        email: email,
        password,
      }
    );
    if (status) {
      yield put(loginSuccess(data));
      yield put(setToken(data));
      yield call(prepareUserSessionSaga);
    } else {
      yield put(loginFailed({ message }));
    }
  } catch (error: any) {
    yield put(loginFailed(error));
  }
}

function* registerSaga(action: ReturnType<typeof register>): any {
  try {
    const { email, password, confirmPassword } = action.payload;
    const result = yield call(request.post, endpoints.register, {
      email,
      password,
      confirmPassword,
    });

    yield put(registerSuccess(result));
  } catch (error: any) {
    yield put(registerFailed(error));
  }
}

function* authSagas() {
  yield all([takeLatest(login, loginSaga), takeLatest(register, registerSaga)]);
}

export { authSagas };
