import { AuthGuard } from "@app/components/auth";
import { AuthLayout, DashboardLayout, LandingPageLayout } from "@app/layout";
import { LoginScreen, RegisterScreen } from "@app/modules/auth";
import { Navigate, useRoutes } from "react-router-dom";
import * as Configs from "@app/configs";
import { SelectCompanyScreen } from "@app/modules/company";
import { LANDING_PAGE_PATH, PATH_DASHBOARD, PATH_INVITATION } from "./paths";
import { EmployeeScreen } from "@app/modules/employees";
import SelectProjectScreen from "@app/modules/project/SelectProjectScreen";
import { AddEmployee, CheckDuplicateEmployee } from "@app/modules/add_employee";
import { EmployeeProfileScreen } from "@app/modules/employee-profile";
import EmployeeEditProfile from "@app/modules/employee-profile/EmployeeEditProfile";
import { AccountInformationScreen } from "@app/modules/account-information";
import { InvitationScreen } from "@app/modules/add_employee";
import { DepartmentScreen } from "@app/modules/department/department";
import { KnowledgeBaseScreen } from "@app/modules/knowledge/knowledge";
import { ArticleCategoryScreen } from "@app/modules/knowledge";
import { AddNewArticle } from "@app/modules/knowledge/addNewArticle";
import { DesignationScreen } from "@app/modules/designation/designation";
import { EmployeeTypeScreen } from "@app/modules/EmployeeType/employeeType";
import { ContractScreen } from "@app/modules/Contractor/contract";
import { SignedDeclarationContractPDF } from "@app/modules/employee-profile/SignedDeclarationContract";
import { EmployeeOnboardingScreen } from "@app/modules/onboading_employee/EmployeeOnboardingScreen";
import { EmployeeRolesScreen } from "@app/modules/employee-roles/employeeRoles";
import { AdminDashboard, AdminSales, AdminUsers } from "@app/modules/admin";
import { QualificationScreen } from "@app/modules/qualification";
import { CompanyInformationScreen } from "@app/modules/account-information/company-information/company-settings/CompanySettingsScreen";
import ProjectInformationScreen from "@app/modules/account-information/company-information/project-settings/ProjectSetitngScreen";
import AdminSetting from "@app/modules/admin/settings/AdminSetting";
import UsersPayment from "@app/modules/admin/users/UsersPayment";
import { OnboardingLayout } from "@app/modules/add_employee/OnboardingLayout";

export const Router = () => {
  return useRoutes([
    {
      path: "/",
      element: (
        <AuthGuard>
          <Navigate to={LANDING_PAGE_PATH.company} />
        </AuthGuard>
      ),
    },
    {
      path: "/auth",
      element: <AuthLayout />,
      children: [
        { path: "login", element: <LoginScreen /> },
        { path: "register", element: <RegisterScreen /> },
      ],
    },
    {
      path: PATH_DASHBOARD.root,
      element: <DashboardLayout />,
      children: [
        {
          path: PATH_DASHBOARD.employeeList,
          element: <EmployeeScreen />,
        },
        {
          path: PATH_DASHBOARD.addEmployee,
          element: <AddEmployee />,
        },
        {
          path: PATH_DASHBOARD.checkForDuplicates,
          element: <CheckDuplicateEmployee />,
        },
        {
          path: PATH_DASHBOARD.employee_profile,
          element: <EmployeeProfileScreen />,
        },
        {
          path: PATH_DASHBOARD.employeeRole,
          element: <EmployeeRolesScreen />,
        },
        {
          path: PATH_DASHBOARD.edit_employee_profile,
          element: <EmployeeEditProfile />,
        },
        {
          path: PATH_DASHBOARD.knowledge_base,
          element: <KnowledgeBaseScreen />,
        },
        {
          path: PATH_DASHBOARD.accountInformation,
          element: <AccountInformationScreen />,
        },
        {
          path: PATH_DASHBOARD.department,
          element: <DepartmentScreen />,
        },
        {
          path: PATH_DASHBOARD.contracter,
          element: <ContractScreen />,
        },

        {
          path: PATH_DASHBOARD.knowledge,
          element: <KnowledgeBaseScreen />,
        },
        {
          path: PATH_DASHBOARD.articleCategory,
          element: <ArticleCategoryScreen />,
        },
        {
          path: PATH_DASHBOARD.addNewArticle,
          element: <AddNewArticle />,
        },
        {
          path: PATH_DASHBOARD.designation,
          element: <DesignationScreen />,
        },
        {
          path: PATH_DASHBOARD.employeeType,
          element: <EmployeeTypeScreen />,
        },
        {
          path: PATH_DASHBOARD.onBoardingEmpList,
          element: <EmployeeOnboardingScreen />,
        },
        {
          path: PATH_DASHBOARD.adminDashboard,
          element: <AdminDashboard />,
        },
        {
          path: PATH_DASHBOARD.adminSales,
          element: <AdminSales />,
        },
        {
          path: PATH_DASHBOARD.adminUsers,
          element: <AdminUsers />,
        },
        {
          path: PATH_DASHBOARD.usersPayment,
          element: <UsersPayment />,
        },
        {
          path: PATH_DASHBOARD.qualifications,
          element: <QualificationScreen />,
        },
        {
          path: PATH_DASHBOARD.companyInformationScreen,
          element: <CompanyInformationScreen />,
        },
        {
          path: PATH_DASHBOARD.projectInformationScreen,
          element: <ProjectInformationScreen />,
        },
        {
          path: PATH_DASHBOARD.adminSetting,
          element: <AdminSetting />,
        },
      ],
    },
    {
      path: "company",
      element: <SelectCompanyScreen />,
    },
    {
      path: "company/:companyId/projects",
      element: <SelectProjectScreen />,
    },
    {
      path: "pdf-generate",
      element: <SignedDeclarationContractPDF />,
    },
    {
      path: PATH_INVITATION.root,
      element: <OnboardingLayout />,
      children: [
        {
          path: PATH_INVITATION.onboarding,
          element: <InvitationScreen />,
        },
        {
          path: PATH_INVITATION.addEmployee,
          element: <AddEmployee />,
        },
      ],
    },

    {
      path: "*",
      element: <></>,
    },
  ]);
};
