import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Avatar, Paper, CircularProgress } from '@mui/material';

import { Employee } from './types';
import { selectDashboardData } from './selectors';

import { getDashboardData } from './slice';
import { selectCompanyId } from '../company/selectors';

const NewMembersList: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector(selectCompanyId);

  const dashboardData = useSelector(selectDashboardData);

  useEffect(() => {
    dispatch(getDashboardData({ id: companyId }));
  }, [dispatch, companyId]);

  console.log('member employee Data:', dashboardData);

  const employees = dashboardData?.employees || [];

  return (
    <Paper 
      elevation={0} 
      sx={{ 
        p: 3, 
        borderRadius: 2, 
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)', 
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography variant="h6" fontWeight="medium" sx={{ mb: 2 }}>
        New Members This Week
      </Typography>
      
      {/* Main content */}
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: 2,
        flex: 1 // This will make the box take up available space
      }}>
        {employees.length > 0 ? (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'medium', color: '#64748B', pl: 1, pr: 1 }}>
              <Box sx={{ width: '10%' }}>
                <input type="checkbox" />
              </Box>
              <Box sx={{ width: '20%' }}>Member ID</Box>
              <Box sx={{ width: '40%' }}>Member Name</Box>
              <Box sx={{ width: '15%' }}>Status</Box>
              <Box sx={{ width: '15%' }}></Box>
            </Box>
            {employees.map((employee: Employee) => (
              <Box key={employee.id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1 }}>
                <Box sx={{ width: '10%' }}>
                  <input type="checkbox" />
                </Box>
                <Box sx={{ width: '20%', color: 'black', fontWeight: '400' }}>{employee.id}</Box>
                <Box sx={{ width: '40%', display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Avatar sx={{ width: 32, height: 32 }} src={employee.profileUrl} alt={employee.firstName} />
                  <Box>
                    <Typography variant="body2" fontWeight={500}>{employee.firstName}</Typography>
                  </Box>
                </Box>
                <Box sx={{ width: '15%' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box sx={{
                      width: 8,
                      height: 8,
                      borderRadius: '50%',
                      bgcolor: employee.employmentStatus === 'active' ? '#11EF4F' : '#FF4842'
                    }} />
                    <Typography variant="body2" color="#64748B">
                      {employee.employmentStatus === 'active' ? 'Active' : 'Inactive'}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ width: '15%', textAlign: 'right' }}>
                  <Typography variant="body2" color="#64748B">⋮</Typography>
                </Box>
              </Box>
            ))}
          </>
        ) : (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '200px'
          }}>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{
                fontWeight: 500,
                fontSize: '1.29rem'
              }}
            >
              No new members added this week.
            </Typography>
          </Box>
        )}
      </Box>

     
      <Box sx={{ 
        mt: 'auto', 
        pt: 2,
        borderTop: '1px solid #E5E7EB',
        textAlign: 'right' 
      }}>
        <Typography 
          variant="body2" 
          color="#3B82F6" 
          sx={{ 
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
        >
          Go to Members list
        </Typography>
      </Box>
    </Paper>
  );
};

export default NewMembersList;
