import { RequestStatus } from "@app/constants/request";
import { IEmployeeState } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRequestFailed, IRequestState } from "@app/types";

const initialState: IEmployeeState = {
  employee: {
    status: RequestStatus.IDLE,
  },
  duplicateEmployee: {
    status: RequestStatus.IDLE,
    data: null,
  },
  selectedEmployee: {
    status: RequestStatus.IDLE,
    data: null,
  },
  selectedEmployeeProjects: {
    status: RequestStatus.IDLE,
    data: null,
  },
  selectedEmployeeEmergencyContacts: {
    status: RequestStatus.IDLE,
    data: null,
  },
  isSectionOneVideoComplete: false,

  addEmployeeContact: {},
  addIdentifyToRight: {},
  addPaymentDetails: {},
  addMedicalInformation: {},
  addEmployeeDeclaration: {},
  updatePersonalDetails: {},
  updateEmployee: {},
  removeAssignProject: {},
  storeSectionDataLocal: {
    data: [],
  },
  deleteCscs: {},
  deleteQualification: {},
  addProofOfWork: {},
  addEmployeeCscs: {},
  addEmployeeQualification: {},
  pdfData: {},
  deleteCscsSingleImage: {},
  pdfGenerate: "",
  onboardingEmployee: {
    status: RequestStatus.IDLE,
  },
  addEmployeeInductor: {},
  addDetailsOfContract: {},
};

const EmployeeSlice = createSlice({
  initialState,
  name: "employee",
  reducers: {
    getEmployees(state, action) {
      state.employee.status = RequestStatus.RUNNING;
    },
    getEmployeesSuccess(state, action: PayloadAction<IRequestState>) {
      state.employee.data = action.payload.data;
      state.employee.status = RequestStatus.SUCCESS;
    },
    getEmployeesFailed(state, action: PayloadAction<IRequestFailed>) {
      state.employee.data = action.payload;
      state.employee.status = RequestStatus.ERROR;
    },
    getEmployeeProjects(state, action) {
      state.selectedEmployeeProjects.status = RequestStatus.RUNNING;
    },
    getEmployeesProjectsSuccess(state, action: PayloadAction<IRequestState>) {
      state.selectedEmployeeProjects.data = action.payload.data;
      state.selectedEmployeeProjects.status = RequestStatus.SUCCESS;
    },
    getEmployeesProjectsFailed(state, action: PayloadAction<IRequestFailed>) {
      state.selectedEmployeeProjects.data = action.payload;
      state.selectedEmployeeProjects.status = RequestStatus.ERROR;
    },
    getEmployee(state, action) {
      state.selectedEmployee.status = RequestStatus.RUNNING;
    },
    getEmployeeSuccess(state, action: PayloadAction<IRequestState>) {
      state.selectedEmployee.data = action.payload.data;
      state.selectedEmployee.status = RequestStatus.SUCCESS;
    },
    getEmployeeFailed(state, action: PayloadAction<IRequestFailed>) {
      state.selectedEmployee.data = action.payload;
      state.selectedEmployee.status = RequestStatus.ERROR;
    },
    getEmployeeEmergencyContact(state, action) {
      state.selectedEmployeeEmergencyContacts.status = RequestStatus.RUNNING;
    },
    getEmployeeEmergencyContactSuccess(
      state,
      action: PayloadAction<IRequestState>
    ) {
      state.selectedEmployeeEmergencyContacts.data = action.payload.data;
      state.selectedEmployeeEmergencyContacts.status = RequestStatus.SUCCESS;
    },
    getEmployeeEmergencyContactFailed(
      state,
      action: PayloadAction<IRequestFailed>
    ) {
      state.selectedEmployeeEmergencyContacts.data = action.payload;
      state.selectedEmployeeEmergencyContacts.status = RequestStatus.ERROR;
    },
    checkDuplicateAction(state, action) {
      state.duplicateEmployee.status = RequestStatus.RUNNING;
    },
    checkDuplicateActionFailed(state, action: PayloadAction<IRequestFailed>) {
      state.duplicateEmployee.data = action.payload;
      state.duplicateEmployee.status = RequestStatus.ERROR;
    },
    checkDuplicateActionSuccess(state, action) {
      state.duplicateEmployee.data = action.payload.data;
      state.duplicateEmployee.status = RequestStatus.SUCCESS;
    },
    updateEmployeeAction(state, action) {
      state.updateEmployee.status = RequestStatus.RUNNING;
    },
    updateEmployeeActionFailed(state, action: PayloadAction<IRequestFailed>) {
      state.updateEmployee.data = action.payload;
      state.updateEmployee.status = RequestStatus.ERROR;
    },
    updateEmployeeActionSuccess(state, action) {
      state.updateEmployee.data = action.payload.data;
      state.updateEmployee.status = RequestStatus.SUCCESS;
    },
    bookProjectAction(state, action) {
      state.employee.status = RequestStatus.RUNNING;
    },
    bookProjectActionSuccess(state, action) {
      state.employee.status = RequestStatus.SUCCESS;
      state.employee.data = action.payload;
    },
    bookProjectActionFailed(state, action: PayloadAction<IRequestFailed>) {
      state.employee.status = RequestStatus.ERROR;
      state.employee.error = action.payload;
    },
    removeAssignedProjectAction(state, action) {
      state.removeAssignProject.status = RequestStatus.RUNNING;
    },
    removeAssignedProjectActionSuccess(state, action: any) {
      state.removeAssignProject.status = RequestStatus.SUCCESS;
      state.removeAssignProject.data = action.payload;
    },
    removeAssignedProjectActionFailed(state, action: any) {
      state.removeAssignProject.status = RequestStatus.ERROR;
      state.removeAssignProject.error = action.payload;
    },
    addEmployeeCscsDocumentAction(state, action) {
      state.addEmployeeCscs.status = RequestStatus.RUNNING;
    },
    addEmployeeCscsDocumentActionFailed(state, action) {
      state.addEmployeeCscs.status = RequestStatus.ERROR;
      state.addEmployeeCscs.error = action.payload;
    },
    addEmployeeCscsDocumentActionSuccess(state, action) {
      state.addEmployeeCscs.status = RequestStatus.SUCCESS;
      state.addEmployeeCscs.data = action.payload;
    },
    addEmployeeQualificationDocumentAction(state, action) {
      state.addEmployeeQualification.status = RequestStatus.RUNNING;
    },
    addEmployeeQualificationDocumentActionFailed(state, action) {
      state.addEmployeeQualification.status = RequestStatus.ERROR;
      state.addEmployeeQualification.error = action.payload;
    },
    addEmployeeQualificationDocumentActionSuccess(state, action) {
      state.addEmployeeQualification.status = RequestStatus.SUCCESS;
      state.addEmployeeQualification.data = action.payload;
    },
    setDuplicateNull(state) {
      state.duplicateEmployee.data = null;
      state.isSectionOneVideoComplete = false;
    },
    setVideComplete(state) {
      state.isSectionOneVideoComplete = true;
    },
    addEmployeeContact(state, action) {
      state.addEmployeeContact.status = RequestStatus.RUNNING;
    },
    addEmployeeContactFailed(state, action) {
      state.addEmployeeContact.status = RequestStatus.ERROR;
      state.addEmployeeContact.error = action.payload;
    },
    addEmployeeContactSuccess(state, action) {
      state.addEmployeeContact.status = RequestStatus.SUCCESS;
      state.addEmployeeContact.data = action.payload;
    },
    addIdentifyToRight(state, action) {
      state.addIdentifyToRight.status = RequestStatus.RUNNING;
    },
    addIdentifyToRightFailed(state, action) {
      state.addIdentifyToRight.status = RequestStatus.ERROR;
      state.addIdentifyToRight.error = action.payload;
    },
    addIdentifyToRightSuccess(state, action) {
      state.addIdentifyToRight.status = RequestStatus.SUCCESS;
      state.addIdentifyToRight.data = action.payload;
    },
    addPaymentDetails(state, action) {
      state.addPaymentDetails.status = RequestStatus.RUNNING;
    },
    addPaymentDetailsFailed(state, action) {
      state.addPaymentDetails.status = RequestStatus.ERROR;
      state.addPaymentDetails.data = action.payload;
    },
    addPaymentDetailsSuccess(state, action) {
      state.addPaymentDetails.status = RequestStatus.SUCCESS;
      state.addPaymentDetails.data = action.payload;
    },
    addMedicalInformation(state, action) {
      state.addMedicalInformation.status = RequestStatus.RUNNING;
    },
    addMedicalInformationFailed(state, action) {
      state.addMedicalInformation.status = RequestStatus.ERROR;
      state.addMedicalInformation.data = action.payload;
    },
    addMedicalInformationSuccess(state, action) {
      state.addMedicalInformation.status = RequestStatus.SUCCESS;
      state.addMedicalInformation.data = action.payload;
    },
    addEmployeeDeclaration(state, action) {
      state.addEmployeeDeclaration.status = RequestStatus.RUNNING;
    },
    addEmployeeDeclarationFailed(state, action) {
      state.addEmployeeDeclaration.status = RequestStatus.ERROR;
      state.addEmployeeDeclaration.error = action.payload;
    },
    addEmployeeDeclarationSuccess(state, action) {
      state.addEmployeeDeclaration.status = RequestStatus.SUCCESS;
      state.addEmployeeDeclaration.data = action.payload;
    },
    updatePersonalDetails(state, action) {
      state.updatePersonalDetails.status = RequestStatus.RUNNING;
    },
    updatePersonalDetailsFailed(state, action) {
      state.updatePersonalDetails.status = RequestStatus.ERROR;
      state.updatePersonalDetails.data = action.payload;
    },
    updatePersonalDetailsSuccess(state, action) {
      state.updatePersonalDetails.status = RequestStatus.SUCCESS;
      state.updatePersonalDetails.data = action.payload;
    },
    updateInductionStatus(state, action) {
      state.updatePersonalDetails.status = RequestStatus.RUNNING;
    },
    updateInductionStatusFailed(state, action) {
      state.updatePersonalDetails.status = RequestStatus.ERROR;
    },
    updateInductionStatusSuccess(state, action) {
      state.updatePersonalDetails.status = RequestStatus.SUCCESS;
    },
    employeeRecycleBinAction(state, action) {
      state.updatePersonalDetails.status = RequestStatus.RUNNING;
    },
    employeeRecycleBinSuccessAction(state, action) {
      state.updatePersonalDetails.status = RequestStatus.SUCCESS;
    },
    employeeRecycleBinFailedAction(state, action) {
      state.updatePersonalDetails.status = RequestStatus.ERROR;
    },
    updateQualificationAction(state, action) {
      state.selectedEmployee.status = RequestStatus.RUNNING;
    },
    updateQualificationActionFailed(state, action) {
      state.selectedEmployee.status = RequestStatus.ERROR;
    },
    updateQualificationActionSuccess(state, action) {
      state.selectedEmployee.status = RequestStatus.SUCCESS;
    },
    addProofOfIdentity(state, action) {
      state.addIdentifyToRight.status = RequestStatus.RUNNING;
    },
    addProofOfIdentityFailed(state, action) {
      state.addIdentifyToRight.status = RequestStatus.ERROR;
      state.addIdentifyToRight.error = action.payload;
    },
    addProofOfIdentitySuccess(state, action) {
      state.addIdentifyToRight.status = RequestStatus.SUCCESS;
    },
    updateProofOfIdentity(state, action) {
      state.addIdentifyToRight.status = RequestStatus.RUNNING;
    },
    updateProofOfIdentityFailed(state, action) {
      state.addIdentifyToRight.status = RequestStatus.ERROR;
      state.addIdentifyToRight.error = action.payload;
    },
    updateProofOfIdentitySuccess(state, action) {
      state.addIdentifyToRight.status = RequestStatus.SUCCESS;
    },
    deleteProofOfIdentity(state, action) {
      state.addIdentifyToRight.status = RequestStatus.RUNNING;
    },
    deleteProofOfIdentityFailed(state, action) {
      state.addIdentifyToRight.status = RequestStatus.ERROR;
      state.addIdentifyToRight.error = action.payload;
    },
    deleteProofOfIdentitySuccess(state, action) {
      state.addIdentifyToRight.status = RequestStatus.SUCCESS;
    },
    addProofOfWork(state, action) {
      state.addProofOfWork.status = RequestStatus.RUNNING;
    },
    addProofOfWorkFailed(state, action) {
      state.addProofOfWork.status = RequestStatus.ERROR;
      state.addProofOfWork.error = action.payload;
    },
    addProofOfWorkSuccess(state, action) {
      state.addProofOfWork.status = RequestStatus.SUCCESS;
    },
    updateProofOfWork(state, action) {
      state.selectedEmployee.status = RequestStatus.RUNNING;
    },
    updateProofOfWorkFailed(state, action) {
      state.selectedEmployee.status = RequestStatus.ERROR;
      state.selectedEmployee.error = action.payload;
    },
    updateProofOfWorkSuccess(state, action) {
      state.selectedEmployee.status = RequestStatus.SUCCESS;
    },
    deleteProofOfWork(state, action) {
      state.selectedEmployee.status = RequestStatus.RUNNING;
    },
    deleteProofOfWorkFailed(state, action) {
      state.selectedEmployee.status = RequestStatus.ERROR;
      state.selectedEmployee.error = action.payload;
    },
    deleteProofOfWorkSuccess(state, action) {
      state.selectedEmployee.status = RequestStatus.SUCCESS;
    },
    storeSectionDataInLocal(state, action) {
      state.storeSectionDataLocal.data = null;
      state.storeSectionDataLocal.data = action.payload;
    },
    deleteCscsAction(state, action) {
      state.deleteCscs.status = RequestStatus.RUNNING;
    },
    deleteCscsSuccess(state, action) {
      state.deleteCscs.data = action.payload;
      state.deleteCscs.status = RequestStatus.SUCCESS;
    },
    deleteCscsFailed(state, action) {
      state.deleteCscs.error = action.payload;
      state.deleteCscs.status = RequestStatus.ERROR;
    },
    deleteQualificationAction(state, action) {
      state.deleteQualification.status = RequestStatus.RUNNING;
    },
    deleteQualificationSuccess(state, action) {
      state.deleteQualification.data = action.payload;
      state.deleteQualification.status = RequestStatus.SUCCESS;
    },
    deleteQualificationFailed(state, action) {
      state.deleteQualification.error = action.payload;
      state.deleteQualification.status = RequestStatus.ERROR;
    },
    deleteCscsSingleImageAction(state, action) {
      state.deleteCscs.status = RequestStatus.RUNNING;
    },
    deleteCscsSingleImageSuccess(state, action) {
      state.deleteCscs.data = action.payload;
      state.deleteCscs.status = RequestStatus.SUCCESS;
    },
    deleteCscsSingleImageFailed(state, action) {
      state.deleteCscs.error = action.payload;
      state.deleteCscs.status = RequestStatus.ERROR;
    },
    deleteQualificationActionSingle(state, action) {
      state.deleteQualification.status = RequestStatus.RUNNING;
    },
    deleteQualificationSuccessSingle(state, action) {
      state.deleteQualification.data = action.payload;
      state.deleteQualification.status = RequestStatus.SUCCESS;
    },
    deleteQualificationFailedSingle(state, action) {
      state.deleteQualification.error = action.payload;
      state.deleteQualification.status = RequestStatus.ERROR;
    },
    pdfData(state, action) {
      state.pdfData = action.payload;
    },
    pdfGenerateSuccess(state, action) {
      state.pdfGenerate.data = action.payload;
    },
    getOnboardingEmployees(state, action) {
      state.onboardingEmployee.status = RequestStatus.RUNNING;
    },
    getOnboardingEmployeesSuccess(state, action: PayloadAction<IRequestState>) {
      state.onboardingEmployee.data = action.payload.data;
      state.onboardingEmployee.status = RequestStatus.SUCCESS;
    },
    getOnboardingEmployeesFailed(state, action: PayloadAction<IRequestFailed>) {
      state.onboardingEmployee.data = action.payload;
      state.onboardingEmployee.status = RequestStatus.ERROR;
    },

    addEmployeeInductor(state, action) {
      state.addEmployeeInductor.status = RequestStatus.RUNNING;
    },

    addEmployeeInductorFailed(state, action) {
      state.addEmployeeDeclaration.status = RequestStatus.ERROR;
      state.addEmployeeDeclaration.error = action.payload;
    },

    addEmployeeInductorSuccess(state, action) {
      state.addEmployeeDeclaration.status = RequestStatus.SUCCESS;
      state.addEmployeeDeclaration.data = action.payload;
    },

    addDetailsOfContract(state, action) {
      state.addDetailsOfContract.status = RequestStatus.RUNNING;
    },
    addDetailsOfContractFailed(state, action) {
      state.addDetailsOfContract.status = RequestStatus.ERROR;
      state.addDetailsOfContract.error = action.payload;
    },
    addDetailsOfContractSuccess(state, action) {
      state.addDetailsOfContract.status = RequestStatus.SUCCESS;
      state.addDetailsOfContract.data = action.payload;
    },
  },
});

export const employeeReducer = EmployeeSlice.reducer;
export const {
  getEmployees,
  getEmployeesFailed,
  getEmployeesSuccess,
  getEmployee,
  getEmployeeFailed,
  getEmployeeSuccess,
  checkDuplicateAction,
  checkDuplicateActionFailed,
  checkDuplicateActionSuccess,
  bookProjectAction,
  bookProjectActionFailed,
  bookProjectActionSuccess,
  removeAssignedProjectAction,
  removeAssignedProjectActionFailed,
  removeAssignedProjectActionSuccess,
  updateEmployeeAction,
  setDuplicateNull,
  addEmployeeCscsDocumentAction,
  addEmployeeCscsDocumentActionFailed,
  addEmployeeCscsDocumentActionSuccess,
  addEmployeeQualificationDocumentAction,
  addEmployeeQualificationDocumentActionFailed,
  addEmployeeQualificationDocumentActionSuccess,
  addEmployeeContact,
  addEmployeeContactSuccess,
  addEmployeeContactFailed,
  addIdentifyToRight,
  addIdentifyToRightFailed,
  addIdentifyToRightSuccess,
  addPaymentDetails,
  addPaymentDetailsFailed,
  addPaymentDetailsSuccess,
  addMedicalInformation,
  addMedicalInformationFailed,
  addMedicalInformationSuccess,
  addEmployeeDeclaration,
  addEmployeeDeclarationFailed,
  addEmployeeDeclarationSuccess,
  updatePersonalDetails,
  updatePersonalDetailsFailed,
  updatePersonalDetailsSuccess,
  getEmployeeProjects,
  getEmployeesProjectsFailed,
  getEmployeesProjectsSuccess,
  getEmployeeEmergencyContact,
  getEmployeeEmergencyContactFailed,
  getEmployeeEmergencyContactSuccess,
  updateInductionStatus,
  updateInductionStatusFailed,
  updateInductionStatusSuccess,
  employeeRecycleBinAction,
  employeeRecycleBinFailedAction,
  employeeRecycleBinSuccessAction,
  updateQualificationAction,
  updateEmployeeActionFailed,
  updateQualificationActionSuccess,
  updateQualificationActionFailed,
  updateEmployeeActionSuccess,
  setVideComplete,
  addProofOfIdentity,
  addProofOfIdentityFailed,
  addProofOfIdentitySuccess,
  updateProofOfIdentity,
  updateProofOfIdentityFailed,
  updateProofOfIdentitySuccess,
  deleteProofOfIdentity,
  deleteProofOfIdentityFailed,
  deleteProofOfIdentitySuccess,
  addProofOfWork,
  addProofOfWorkFailed,
  addProofOfWorkSuccess,
  updateProofOfWork,
  updateProofOfWorkFailed,
  updateProofOfWorkSuccess,
  deleteProofOfWork,
  deleteProofOfWorkFailed,
  deleteProofOfWorkSuccess,
  storeSectionDataInLocal,
  deleteCscsAction,
  deleteCscsFailed,
  deleteCscsSuccess,
  deleteQualificationAction,
  deleteQualificationFailed,
  deleteQualificationSuccess,
  deleteCscsSingleImageAction,
  deleteCscsSingleImageSuccess,
  deleteCscsSingleImageFailed,
  deleteQualificationActionSingle,
  deleteQualificationSuccessSingle,
  deleteQualificationFailedSingle,
  pdfData,
  getOnboardingEmployees,
  getOnboardingEmployeesSuccess,
  getOnboardingEmployeesFailed,
  addEmployeeInductor,
  addEmployeeInductorFailed,
  addEmployeeInductorSuccess,
  addDetailsOfContract,
  addDetailsOfContractFailed,
  addDetailsOfContractSuccess,
} = EmployeeSlice.actions;
