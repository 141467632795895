import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "@app/routes/paths";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllEmployee,
  selectAllEmployeeMeta,
} from "../add_employee/selector";
import { useEffect, useState } from "react";
import { getEmployee, getEmployees } from "../add_employee/slice";
import { SvgColor } from "@app/components/svg-color";
import { sourceUrl } from "@app/constants/source_url";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Input } from "@app/components";
import { yupResolver } from "@hookform/resolvers/yup";
import EmployeeTable from "./EmployeeTable/Table";
import { IEmployementTypeName } from "./types";
import { addEmployementTypeAction, getEmployementType } from "./slice";
import { selectEmployementType } from "./selector";
import TablePaginationCustom from "@app/components/table/TablePagination";
import { useDialog } from "@app/hooks/use-dialog";
import { AddNewEmployeeType } from "./EmployeeTable/AddNewEmployee";

const schema = yup.object().shape({
  name: yup.string().required("employee type name required"),
});
export const EmployeeTypeScreen = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IEmployementTypeName>({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employmentDialog = useDialog();
  const employees = useSelector(selectEmployementType);
  const [pageValue, setPageValue] = useState(1);
  const [pageLimitValue, setPageLimitValue] = useState<number | string>(10);

  useEffect(() => {
    dispatch(getEmployementType({ page: pageValue, limit: pageLimitValue }));
  }, [dispatch, pageValue, pageLimitValue]);
  return (
    <Stack
      component={"div"}
      sx={{
        paddingTop: "30px",
        paddingBottom: "20px",
        border: "1px solid #F1F4F7",
      }}
    >
      <Stack
        component={"div"}
        sx={{
          display: "flex",
          gap: "20px",
        }}
      >
        <Stack
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: "20px",
            paddingLeft: "20px",
          }}
        >
          <Button
            variant="contained"
            startIcon={<SvgColor src={sourceUrl.icons.addIcon} />}
            onClick={() => {
              employmentDialog.show();
            }}
          >
            Add Employment Type
          </Button>
        </Stack>
      </Stack>
      <Stack sx={{ width: "94%", margin: "20px" }}>
        {/* {employees && employees.data?.length > 0 && ( */}
        <>
          <EmployeeTable data={employees ? employees : []} />
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <TablePaginationCustom
            onPageDenseChange={(e) => {
              setPageLimitValue(e.target.value);
            }}
            totalItems={employees?.totalItems}
            totalPage={employees?.totalPages}
            itemsPerPage={employees?.itemsPerPage}
            currentPage={employees?.currentPage}
            showingCustomText={employees?.showing}
            onPageChange={(e, currentPage) => {
              setPageValue(currentPage);
            }}
            sx={{
              background: "#fff",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
          />
        </>
        {/* )} */}
      </Stack>
      {employmentDialog.visible && (
        <AddNewEmployeeType open onClose={employmentDialog.hide} />
      )}
    </Stack>
  );
};
