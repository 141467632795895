import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Container, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { LANDING_PAGE_PATH, PATH_AUTH } from "@app/routes/paths";
import { useRequestState } from "@app/hooks/use-request-state";
import { Input, Label } from "../../components";
import { Strings } from "../../constants/strings";
import { ILoginSchema } from "./types";
import { login } from "./slice";
import { selectIsAuthenticated } from "../common/selectors";
import { selectLoginState } from "./selectors";
import { LoadingButton } from "@mui/lab";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

export const LoginScreen: FC = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ILoginSchema>({
    resolver: yupResolver(schema),
  });

  const { loading } = useRequestState({
    stateSelector: selectLoginState,
    successMessageShown: true,
    errorShown: true,
  });

  const onSubmit = (data: ILoginSchema) => {
    console.log("Form Submitted:", data);
    dispatch(login(data));
  };
  // Extract pathname and search
  const fromPath = location.state?.from?.pathname || "";
  const fromSearch = location.state?.from?.search || "";

  // Construct full path
  const fullPath = `${fromPath}${fromSearch}`;

  // Check if it's the invitation onboarding path
  const isInvitationPath =
    fromPath === "/invitation/onboarding" && fromSearch.includes("id=");

  // Fallback path
  const fallbackPath = LANDING_PAGE_PATH.company;

  const from = isInvitationPath ? fullPath : fallbackPath;

  useEffect(() => {
    if (isAuthenticated) {
      navigate(from);
    }
  }, [isAuthenticated]);

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          width: 600,
          margin: "50px auto",
          padding: 3,
          borderRadius: "5px",
          boxShadow: 3,
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            marginBottom: 2,
            textAlign: "center",
            fontWeight: "700",
            fontSize: "20px",
          }}
        >
          {Strings.login.login}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Label>{Strings.login.emailAddress}</Label>
          <Input
            name="email"
            control={control}
            label="Email Address"
            placeHolder={Strings.field.inputPlaceholder(
              Strings.login.emailAddress
            )}
            error={errors.email?.message}
            size={"small"}
            sx={{
              "& .MuiInputBase-fullWidth": {
                height: "50px",
              },
              mb: 1.3,
              mt: 0.4,
            }}
          />
          <Label sx={{ marginTop: "15px" }}>{Strings.login.password}</Label>
          <Input
            name="password"
            control={control}
            label="Password"
            isPassword
            placeHolder={Strings.field.inputPlaceholder(Strings.login.password)}
            error={errors.password?.message}
            size={"small"}
            sx={{
              "& .MuiInputBase-fullWidth": {
                height: "50px",
              },
              mt: 0.4,
            }}
          />
          <Box my={1}>
            <Typography
              component={"span"}
              sx={{
                textDecoration: "underline",
                fontSize: "13px",
                fontStyle: "italic",
                fontWeight: "400",
                color: "#616E80",
                cursor: "pointer",
              }}
            >
              {Strings.login.forgotPassword}
            </Typography>
          </Box>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={loading}
            fullWidth
            sx={{
              marginTop: 2,
              backgroundColor: "#0177FB",
              borderRadius: "5px",
              fontSize: "20px",
              fontWeight: "700",
              textTransform: "none",
            }}
          >
            {Strings.button.logIn}
          </LoadingButton>

          {/* <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              marginTop: 2,
              backgroundColor: "#11EF4F",
              borderRadius: "5px",
              fontSize: "20px",
              fontWeight: "700",
              mb: 3,
            }}
            onClick={() => {
              navigate(PATH_AUTH.register);
            }}
          >
            {Strings.login.register}
          </Button> */}
        </form>
      </Box>
    </Container>
  );
};
