import React, { lazy, Suspense } from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Card, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getUser } from "./slice";
import { selectGetUser } from "./selector";
import { useRequestState } from "@app/hooks/use-request-state";

// Lazy Loading Components
const Profile = lazy(() => import("./profile"));
const PlansAndBilling = lazy(() => import("./plans-billing/plans-and-billing"));
const SystemUsers = lazy(() => import("./system-user/system-user"));
const CompanyInformation = lazy(
  () => import("./company-information/CompanyInformation")
);

const TabPanel = ({
  children,
  value,
  index,
}: {
  children: React.ReactNode;
  value: number;
  index: number;
}) => {
  return (
    <Box role="tabpanel" hidden={value !== index} sx={{ p: 2 }}>
      {value === index && children}
    </Box>
  );
};

export const AccountInformationScreen = () => {
  const { data } = useRequestState({ stateSelector: selectGetUser });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationState = useLocation()?.state;
  const [tabIndex, setTabIndex] = useState(0);

  const methods = useForm({
    defaultValues: {
      username: data?.username || "",
      permissionLevel: data?.permissionLevel || "",
      email: data?.email || "",
      emailNotification: data?.emailNotification || "",
      textMessages: data?.textMessages || "",
    },
  });
  useEffect(() => {
    if (locationState?.tabIndex) {
      setTabIndex(locationState?.tabIndex);
    }
  }, [locationState]);

  useEffect(() => {
    if (data) {
      methods.reset({
        username: data.username,
        permissionLevel: data.permissionLevel,
        email: data.email,
        emailNotification: data.emailNotification,
        textMessages: data.textMessages,
      });
    }
  }, [data, methods]);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Stack sx={{ marginTop: "10px", padding: "20px" }}>
      <FormProvider {...methods}>
        <Card>
          <Stack sx={{ px: 4, py: 2 }}>
            <Typography sx={{ fontSize: "17px", fontWeight: 500 }}>
              Account Information
            </Typography>
          </Stack>
          <Box sx={{ borderTop: "1px solid #F1F4F7" }}>
            {/* Tabs Navigation */}
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="Settings Tabs"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#0177FB",
                  height: "3px",
                },
                "& .MuiTab-root": {
                  textTransform: "none",
                  fontWeight: 500,
                  color: "gray",
                  minWidth: 150,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "12px 16px",
                },
                "& .MuiTab-root:not(:last-of-type)": {
                  marginRight: "0px !important",
                },
                "& .Mui-selected": { color: "#0177FB" },
                borderBottom: "1px solid #F1F4F7",
              }}
            >
              <Tab label="Profile" sx={{ borderRight: "1px solid #F1F4F7" }} />
              <Tab
                label="Plans & Billing"
                sx={{ borderRight: "1px solid #F1F4F7" }}
              />
              <Tab
                label="System Users"
                sx={{ borderRight: "1px solid #F1F4F7" }}
              />
              <Tab
                label="Company Information"
                sx={{ borderRight: "1px solid #F1F4F7" }}
              />
            </Tabs>

            {/* Tabs Content with Lazy Loading */}
            <Suspense fallback={<Typography>Loading...</Typography>}>
              <TabPanel value={tabIndex} index={0}>
                <Profile methods={methods} />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <PlansAndBilling />
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <SystemUsers />
              </TabPanel>
              <TabPanel value={tabIndex} index={3}>
                <CompanyInformation />
              </TabPanel>
            </Suspense>
          </Box>
        </Card>
      </FormProvider>
    </Stack>
  );
};
