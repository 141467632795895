import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { Controller, useForm } from "react-hook-form";
import {
  Card,
  Divider,
  Stack,
  Table,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { Icon } from "@iconify/react";

import {
  employeeRecycleBinAction,
  getEmployee,
  updatePersonalDetails,
} from "../add_employee/slice";
import { Button } from "@app/components/button";
import { SvgColor } from "@app/components/svg-color";
import { sourceUrl } from "@app/constants/source_url";
import { Stepper } from "@app/components/Stepper";
import Status from "@app/components/status/Status";
import { PATH_DASHBOARD } from "@app/routes/paths";
import { RHFDatePicker, RHFSelect } from "@app/components/hook-form";
import { Input } from "@app/components";
import { steps } from "./EmployeeProfile";
import EmployeeProject from "./EmployeeProject";
import { EmergencyDetails } from "./EmergencyDetails";
import {
  selectSelectedEmployee,
  selectSelectedEmployeeData,
  selectSelectedEmployeeProjectStatus,
} from "../add_employee/selector";
import { IdentifyRightToWork } from "./IdentifyRightToWork";
import { PaymentInformation } from "./PaymentInformtaion";
import { TrainingMatrix } from "./TrainingMatrix";
import { DetailOfContract } from "./DetailOfContract";
import { MedicalHistory } from "./MedicalHistory";
// import { NotesComment } from "./NotesComments";
import { getEmployementType } from "../EmployeeType/slice";
import { selectEmployementType } from "../EmployeeType/selector";
import { getDesignationAction } from "../designation/slice";
import { selectDesignation } from "../designation/selectors";
import { getContractor } from "../Contractor/slice";
import { selectContractor } from "../Contractor/selector";
import { employeeRoles } from "../add_employee/addEmployeeSections";
import { Strings } from "@app/constants/strings";

export const EmploymentStatusArray = [
  { id: "active", name: "active" },
  { id: "Inactive", name: "Inactive" },
  { id: "Retired", name: "Retired" },
  { id: "Awaiting Review", name: "Awaiting Review" },
  { id: "On Leave", name: "On Leave" },
  { id: "Suspended", name: "Suspended" },
  { id: "Terminated", name: "Terminated" },
];

const EmployeeEditProfile = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const paymentFormRef = useRef<any>(null);
  const medicalHistoryFormRef = useRef<any>(null);
  const trainingMatrixFormRef = useRef<any>(null);

  const handleTrainingMatrixForm = () => {
    if (trainingMatrixFormRef.current) {
      trainingMatrixFormRef.current.submitForm();
    }
  };

  const handlePaymentSubmit = () => {
    if (paymentFormRef.current) {
      paymentFormRef.current.submitForm();
    }
  };

  const handleMedicalHistoryFormRef = () => {
    if (medicalHistoryFormRef.current) {
      medicalHistoryFormRef.current.submitForm();
    }
  };

  const [headClicked, setHeadClicked] = useState("projectInduced");

  const employeeProjectHead = [
    { label: "Project Inducted", id: "projectInduced" },
    { label: "Emergency Details", id: "emergencyDetails" },
    { label: "Identify & Right To Work", id: "identifyRightWork" },
    { label: "Payment Information", id: "paymentInformation" },
    { label: "Qualifications", id: "qualifications" },
    { label: "Details of Contract", id: "detailsOfContract" },
    { label: "Medical History", id: "medicalHistory" },
    // { label: "Notes & Comments", id: "notesComments" },
  ];

  const employee = useSelector(selectSelectedEmployee);
  const employeeExtraData = useSelector(selectSelectedEmployeeData);
  const employeesType = useSelector(selectEmployementType);
  const designation = useSelector(selectDesignation);
  const contractor = useSelector(selectContractor);
  const employeeProjectStatus = useSelector(
    selectSelectedEmployeeProjectStatus
  );
  const [profilePic, setProfilePic] = useState<File | null>(null);
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    dispatch(getEmployee({ employeeId: state.employeeId }));
    dispatch(getEmployementType({ page: 1, limit: 1000 }));
    dispatch(getDesignationAction({ page: 1, limit: 1000 }));
    dispatch(getContractor({ page: 1, limit: 1000 }));
  }, [dispatch, state?.employeeId, employeeProjectStatus]);

  const {
    control: editProfileControl,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useForm<any>();

  useEffect(() => {
    if (employee) {
      setValue("fullName", `${employee?.firstName} ${employee.lastName}`);
      setValue(
        "mobileNumber",
        employee?.contactDetails && employee?.contactDetails?.mobileNumber
      );
      setValue(
        "email",
        employee?.contactDetails?.emailAddress &&
          employee?.contactDetails?.emailAddress
      );
      if (employee?.gender !== "Unknown") {
        setValue("gender", employee?.gender);
      }
      setValue(
        "designationId",
        employee?.designation && employee?.designation?.id
      );
      setValue("employeeRole", employee?.employeeRole);
      setValue(
        "employmentTypeId",
        employee?.employmentType && employee?.employmentType?.id
      );
      setValue("contractor", employee?.contractor);
      setValue("employmentStatus", employee?.employmentStatus);
      setValue("dateOfBirth", employee?.dateOfBirth);
      setValue("contractorId", employee?.contractor?.id);
      setValue("address", employee?.contactDetails?.address);
    }
  }, [employee]);

  const onSubmit = (data: any) => {
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(
        ([_, value]) => value !== null && value !== undefined && value !== ""
      )
    );
    dispatch(
      updatePersonalDetails({
        employeeId: employee.id,
        fromUpdateEmployeeProfile: true,
        ...filteredData,
        callback: () => {
          handlePaymentSubmit();
          handleTrainingMatrixForm();
          handleMedicalHistoryFormRef();
          navigate(-1);
        },
      })
    );
  };
  return (
    <>
      {employee && (
        <Stack component={"div"} sx={{ padding: "20px" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              color="error"
              sx={{
                backgroundColor: "#C7152A",
                fontWeight: 400,
                height: "34px",
              }}
              variant="contained"
              startIcon={<SvgColor src={sourceUrl.icons.trash} />}
              onClick={() => {
                dispatch(
                  employeeRecycleBinAction({
                    id: employee.id,
                    callback: () => {
                      navigate(PATH_DASHBOARD.employeeList);
                    },
                  })
                );
              }}
            >
              Send To Recycle Bin
            </Button>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                color="error"
                sx={{
                  backgroundColor: "#F35454",
                  fontWeight: 400,
                  width: "95px",
                  height: "34px",
                }}
                startIcon={<Icon icon="iconamoon:close-light" />}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{
                  backgroundColor: "#3BD365",
                  fontWeight: 400,
                  width: "83px",
                  height: "34px",
                }}
                startIcon={<Icon icon="ic:twotone-check" />}
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </Button>
            </Box>
          </Box>
          <Card sx={{ padding: "0px", marginTop: "14px" }}>
            <Typography
              variant="h5"
              fontWeight={400}
              sx={{ padding: "5px 8px 5px 20px", fontSize: "20px" }}
            >
              Employee # {employee.id}
            </Typography>
            <Divider
              sx={{
                borderColor: "#F1F4F7",
              }}
            />
            <Stack
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                padding: "2px 8px",
                gap: "50px",
                mb: 2,
              }}
            >
              <Box sx={{ flex: 0.53, px: "10px" }}>
                <Typography
                  sx={{
                    color: "#616E80",
                    fontStyle: "italic",
                    fontSize: "16px",
                    fontWeight: "600",
                    marginLeft: "10px",
                    gap: "42px",
                    marginY: "14px",
                  }}
                >
                  Personal Information
                </Typography>
                <Table>
                  <TableRow
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <Stack sx={{ flex: 0.3 }}>
                      <Typography variant="body1" fontWeight={500}>
                        Full Name :
                      </Typography>
                    </Stack>
                    <Stack sx={{ flex: 0.7, height: "100%" }}>
                      <Input
                        name="fullName"
                        size="small"
                        placeHolder={Strings.field.inputPlaceholder(
                          "Full Name"
                        )}
                        control={editProfileControl}
                        sx={{ mb: 0 }}
                      />
                    </Stack>
                  </TableRow>

                  <TableRow
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <Stack sx={{ flex: 0.3 }}>
                      <Typography variant="body1" fontWeight={500}>
                        Date of Birth :
                      </Typography>
                    </Stack>
                    <Stack sx={{ flex: 0.7 }}>
                      <RHFDatePicker
                        name="dateOfBirth"
                        control={editProfileControl}
                        size="small"
                        setValue={setValue}
                        trigger={trigger}
                        defaultValue={
                          employee.dateOfBirth
                            ? dayjs(employee.dateOfBirth, "DD/MM/YYYY")
                            : null
                        }
                        label={""}
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "40px",
                            borderRadius: "5px",
                          },
                        }}
                      />
                    </Stack>
                  </TableRow>
                  <TableRow
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      alignItems: "center",
                    }}
                  >
                    <Stack sx={{ flex: 0.3 }}>
                      <Typography variant="body1" fontWeight={500}>
                        Gender :
                      </Typography>
                    </Stack>
                    <Stack sx={{ flex: 0.7 }}>
                      <RHFSelect
                        name={"gender"}
                        control={editProfileControl}
                        placeholder={"Select Gender"}
                        menuItemsData={[
                          { id: "male", name: "Male" },
                          { id: "female", name: "Female" },
                          { id: "other", name: "Other" },
                        ]}
                        size="small"
                        sx={{
                          height: "40px",
                        }}
                      />
                    </Stack>
                  </TableRow>
                </Table>
                <Typography
                  sx={{
                    color: "#616E80",
                    fontStyle: "italic",
                    fontSize: "16px",
                    fontWeight: "600",
                    marginLeft: "10px",
                    gap: "42px",
                    marginY: "14px",
                  }}
                >
                  Employment
                </Typography>
                <Table>
                  <TableRow
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      my: 1,
                      alignItems: "center",
                    }}
                  >
                    <Stack sx={{ flex: 0.3 }}>
                      <Typography variant="body1" fontWeight={500}>
                        Designation:
                      </Typography>
                    </Stack>
                    <Stack sx={{ flex: 0.7 }}>
                      <RHFSelect
                        name={"designationId"}
                        control={editProfileControl}
                        placeholder={"Designation"}
                        menuItemsData={designation?.data ?? []}
                        size="small"
                        sx={{
                          height: "40px",
                        }}
                        returnId={true}
                      />
                    </Stack>
                  </TableRow>
                  <TableRow
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      my: 1,
                      alignItems: "center",
                    }}
                  >
                    <Stack sx={{ flex: 0.3 }}>
                      <Typography variant="body1" fontWeight={500}>
                        Employee Role:
                      </Typography>
                    </Stack>
                    <Stack sx={{ flex: 0.7 }}>
                      <RHFSelect
                        name={"employeeRole"}
                        control={editProfileControl}
                        placeholder={"Employee Role"}
                        menuItemsData={employeeRoles.map((role) => ({
                          id: role.name,
                          name: role.name,
                        }))}
                        size="small"
                        sx={{
                          height: "40px",
                        }}
                        returnId={true}
                      />
                    </Stack>
                  </TableRow>
                  <TableRow
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      my: 1,
                      alignItems: "center",
                    }}
                  >
                    <Stack sx={{ flex: 0.3 }}>
                      <Typography variant="body1" fontWeight={500}>
                        Employee Type:
                      </Typography>
                    </Stack>
                    <Stack sx={{ flex: 0.7 }}>
                      <RHFSelect
                        name={"employmentTypeId"}
                        control={editProfileControl}
                        placeholder={"Employee Type"}
                        menuItemsData={employeesType?.data ?? []}
                        size="small"
                        sx={{
                          height: "40px",
                        }}
                        returnId={true}
                      />
                    </Stack>
                  </TableRow>
                  <TableRow
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      my: 1,
                      alignItems: "center",
                    }}
                  >
                    <Stack sx={{ flex: 0.3 }}>
                      <Typography variant="body1" fontWeight={500}>
                        Contractor:
                      </Typography>
                    </Stack>
                    <Stack sx={{ flex: 0.7 }}>
                      <RHFSelect
                        name={"contractorId"}
                        control={editProfileControl}
                        placeholder={"Contractor"}
                        menuItemsData={contractor?.data?.data ?? []}
                        size="small"
                        sx={{
                          height: "40px",
                        }}
                        returnId={true}
                      />
                    </Stack>
                  </TableRow>
                </Table>

                <Typography
                  sx={{
                    color: "#616E80",
                    fontStyle: "italic",
                    fontSize: "16px",
                    fontWeight: "600",
                    marginLeft: "10px",
                    gap: "42px",
                    marginY: "14px",
                  }}
                >
                  Contact Information
                </Typography>
                <Table>
                  <TableRow
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      alignItems: "center",
                    }}
                  >
                    <Stack sx={{ flex: 0.3 }}>
                      <Typography variant="body1" fontWeight={500}>
                        Contact Number:
                      </Typography>
                    </Stack>
                    <Stack sx={{ flex: 0.7 }}>
                      <Input
                        name="mobileNumber"
                        size="small"
                        placeHolder={Strings.field.inputPlaceholder(
                          "Contact Number"
                        )}
                        control={editProfileControl}
                        sx={{
                          height: "40px",
                        }}
                      />
                    </Stack>
                  </TableRow>
                  <TableRow
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      alignItems: "center",
                    }}
                  >
                    <Stack sx={{ flex: 0.3 }}>
                      <Typography variant="body1" fontWeight={500}>
                        Email:
                      </Typography>
                    </Stack>
                    <Stack sx={{ flex: 0.7 }}>
                      <Input
                        name="email"
                        size="small"
                        control={editProfileControl}
                        placeHolder={Strings.field.inputPlaceholder(
                          "Email Address"
                        )}
                        sx={{
                          height: "40px",
                        }}
                      />
                    </Stack>
                  </TableRow>
                  <TableRow
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      alignItems: "center",
                    }}
                  >
                    <Stack sx={{ flex: 0.3 }}>
                      <Typography variant="body1" fontWeight={500}>
                        Home Address:
                      </Typography>
                    </Stack>
                    <Stack sx={{ flex: 0.7 }}>
                      <Input
                        name="address"
                        size="small"
                        control={editProfileControl}
                        placeHolder={Strings.field.inputPlaceholder(
                          "Home Address"
                        )}
                        sx={{
                          height: "40px",
                        }}
                      />
                    </Stack>
                  </TableRow>
                </Table>
              </Box>
              <Box sx={{ flex: 0.3, my: "14px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "250px",
                  }}
                >
                  <Controller
                    name="file"
                    control={editProfileControl}
                    defaultValue={null}
                    render={({ field }) => (
                      <>
                        <input
                          type="file"
                          accept="image/jpeg, image/png"
                          style={{ display: "none" }}
                          id="uploadProfilePic"
                          ref={fileInputRef}
                          onChange={(e) => {
                            if (e.target.files && e.target.files[0]) {
                              field.onChange(e.target.files[0]);
                              setProfilePic(e.target.files[0]);
                            }
                          }}
                        />
                      </>
                    )}
                  />
                  <Box
                    component={"img"}
                    sx={{
                      width: "250px",
                      height: "250px",
                      borderRadius: "5px",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                    src={
                      profilePic
                        ? URL.createObjectURL(profilePic)
                        : employee?.profileUrl ??
                          (employee.gender && employee.gender == "female"
                            ? sourceUrl.icons.female_profile
                            : sourceUrl.icons.male_profile)
                    }
                  ></Box>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      marginTop: "8px",
                      alignSelf: "center",
                      width: "115px",
                      height: "24px",
                      fontSize: "14px",
                      textWrap: "nowrap",
                    }}
                    onClick={() => {
                      if (fileInputRef.current) {
                        fileInputRef.current.click();
                      }
                    }}
                  >
                    Change Picture
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                >
                  <Table>
                    <TableRow
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        marginBottom: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Stack sx={{ flex: 0.6 }}>
                        <Typography variant="body1" fontWeight={500}>
                          Employment Status:
                        </Typography>
                      </Stack>
                      <Stack sx={{ flex: 0.4 }}>
                        <RHFSelect
                          name="employmentStatus"
                          menuItemsData={EmploymentStatusArray}
                          control={editProfileControl}
                          size={"small"}
                          sx={{
                            height: "40px",
                          }}
                        />
                      </Stack>
                    </TableRow>
                    <TableRow
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        marginBottom: "10px",
                      }}
                    >
                      <Stack sx={{ flex: 0.6 }}>
                        <Typography variant="body1" fontWeight={500}>
                          Induction Status:
                        </Typography>
                      </Stack>
                      <Stack sx={{ flex: 0.4 }}>
                        <Status
                          sx={{
                            fontWeight: 600,
                            gap: 1,
                            px: 2,
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                          title={
                            employeeExtraData &&
                            employeeExtraData.employeeInductionStatus
                          }
                          status={
                            employeeExtraData &&
                            employeeExtraData.employeeInductionStatus
                          }
                          isEmployeeInductionStatus={true}
                        />
                      </Stack>
                    </TableRow>
                    <TableRow
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        marginBottom: "10px",
                      }}
                    >
                      <Stack sx={{ flex: 0.5 }}>
                        <Typography variant="body1" fontWeight={500}>
                          Projects Inducted:
                        </Typography>
                      </Stack>
                      <Stack sx={{ flex: 0.5 }}>
                        <Typography
                          variant="body1"
                          color="primary"
                          fontWeight={500}
                        >
                          {employeeExtraData
                            ? employeeExtraData.totalInductedProjects
                            : 0}
                        </Typography>
                      </Stack>
                    </TableRow>
                    <TableRow
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        marginBottom: "10px",
                      }}
                    >
                      <Stack sx={{ flex: 0.5 }}>
                        <Typography variant="body1" fontWeight={500}>
                          Latest Induction:
                        </Typography>
                      </Stack>
                      <Stack sx={{ flex: 0.5 }}>
                        <Typography
                          variant="body1"
                          color="primary"
                          fontWeight={500}
                        >
                          {employeeExtraData
                            ? employeeExtraData.latestInduction
                              ? dayjs(employeeExtraData.latestInduction).format(
                                  "DD MMMM YYYY"
                                )
                              : "N/A"
                            : "N/A"}
                        </Typography>
                      </Stack>
                    </TableRow>
                    <TableRow
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        marginBottom: "10px",
                      }}
                    >
                      <Stack sx={{ flex: 0.5 }}>
                        <Typography variant="body1" fontWeight={500}>
                          Joined Since:
                        </Typography>
                      </Stack>
                      <Stack sx={{ flex: 0.5 }}>
                        <Typography
                          variant="body1"
                          color="primary"
                          fontWeight={500}
                        >
                          {employee.createdAt
                            ? dayjs(employee.createdAt).format("DD MMMM YYYY")
                            : "N/A"}
                        </Typography>
                      </Stack>
                    </TableRow>
                  </Table>
                </Box>
              </Box>
            </Stack>
            {/* <Box sx={{ width: "100%", marginY: "10px" }}>
              <Stepper steps={steps} />
            </Box> */}
            <Box
              sx={{
                borderTop: "0.6px solid #F1F4F7 ",
                width: "100%",
                display: "flex",
                overflowX: "scroll",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {employeeProjectHead?.map((head, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: ".6px solid #F1F4F7 ",
                      padding: "10px",
                      boxSizing: "border-box",
                      borderBottom:
                        headClicked === head?.id
                          ? `2px solid ${theme.palette.primary.main}`
                          : "none",
                    }}
                    onClick={() => {
                      setHeadClicked(head?.id);
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: theme.palette.grey[600],
                        textWrap: "nowrap",
                      }}
                    >
                      {head?.label}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Card>
          {headClicked === "projectInduced" ? (
            <EmployeeProject edit={true} />
          ) : headClicked === "emergencyDetails" ? (
            <EmergencyDetails edit={true} />
          ) : headClicked === "identifyRightWork" ? (
            <IdentifyRightToWork
              edit={true}
              identifyRightWork={employee?.identityAndRightToWork}
              employee={employee}
            />
          ) : headClicked === "paymentInformation" ? (
            <PaymentInformation
              ref={paymentFormRef}
              edit={true}
              employee={employee}
            />
          ) : headClicked === "qualifications" ? (
            <TrainingMatrix
              edit={true}
              employee={employee}
              ref={trainingMatrixFormRef}
            />
          ) : headClicked === "detailsOfContract" ? (
            <DetailOfContract edit={true} employee={employee} />
          ) : headClicked === "medicalHistory" ? (
            <MedicalHistory
              edit={true}
              employee={employee}
              ref={medicalHistoryFormRef}
            />
          ) : // ) : headClicked === "notesComments" ? (
          // <NotesComment edit={true} />
          null}
        </Stack>
      )}
    </>
  );
};

export default EmployeeEditProfile;
