import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import {
  Button,
  Dialog,
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { FormProvider, RHFDatePicker } from "@app/components/hook-form";
import { useRequestState } from "@app/hooks/use-request-state";
import { selectCompanyId } from "../company/selectors";
import { getProjects } from "../project/slice";
import { selectProjectData } from "../project/selectors";
import { bookProjectAction } from "../add_employee/slice";
import {
  selectBookProjectSuccess,
  selectSelectedEmployee,
} from "../add_employee/selector";
import { addBookInductionSchema } from "./validators";
import { LoadingButton } from "@mui/lab";

interface Props {
  open: any;
  onClose: any;
}
const BookInduction = ({ open, onClose }: Props) => {
  const methods = useForm<any>({
    resolver: yupResolver(addBookInductionSchema),
  });
  const dispatch = useDispatch();
  const {
    control,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = methods;
  const employeeDetail = useSelector(selectSelectedEmployee);
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const companyId = useSelector(selectCompanyId);
  const allProject = useSelector(selectProjectData);

  useEffect(() => {
    dispatch(getProjects({ companyId }));
  }, [dispatch]);

  const { loading } = useRequestState({
    stateSelector: selectBookProjectSuccess,
    successMessageShown: true,
    errorShown: true,
  });

  const onsubmit = (data: any) => {
    const { project, inductionDate } = data;
    const formatedDate = inductionDate
      ? dayjs(inductionDate, "DD/MM/YYYY").format("D MMM YYYY")
      : "";
    dispatch(
      bookProjectAction({
        employeeId: employeeDetail.id,
        projects: [{ projectId: project.id, bookedDate: formatedDate }],
        navigate: () => {
          onClose();
        },
      })
    );
  };
  return (
    <Dialog
      open
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          width: "45%",
          maxWidth: "none",
        },
      }}
    >
      <FormProvider methods={methods}>
        <Stack sx={{ width: "100%" }}>
          <Typography sx={{ fontSize: "18px", fontWeight: 450, padding: 1.5 }}>
            Book Induction
          </Typography>
          <Divider sx={{ color: "#F1F4F7" }} />
        </Stack>
        <Divider sx={{ borderColor: "#F1F4F7" }} />

        <Stack sx={{ px: 7 }}>
          <Stack
            sx={{
              padding: 2,
              boxShadow: `0px 4px 10px ${theme.palette.grey[400]}`,
              borderRadius: 1,
              margin: 2,
            }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              Book another Project Induction
            </Typography>
            <Typography
              sx={{
                color: theme.palette.grey[600],
                fontSize: "13px",
              }}
            >
              Select Project For Induction
            </Typography>
            <Controller
              name="project"
              render={({ field }) => (
                <FormControl>
                  <Select
                    {...field}
                    name="project"
                    displayEmpty
                    input={<OutlinedInput />}
                    error={
                      errors?.project && errors?.project.message ? true : false
                    }
                    renderValue={(selected: any) => {
                      if (!selected) {
                        return (
                          <Typography sx={{ color: theme.palette.grey[400] }}>
                            Select Project
                          </Typography>
                        );
                      }

                      return selected.name;
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                    MenuProps={MenuProps}
                  >
                    {allProject &&
                      allProject?.length > 0 &&
                      allProject.map((project: any) => {
                        return (
                          <MenuItem value={project}>{project.name}</MenuItem>
                        );
                      })}
                  </Select>
                  {errors.project && !!errors?.project.message && (
                    <FormHelperText error>
                      {String(errors?.project?.message)}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Stack mt={1}>
              <Typography
                sx={{
                  color: theme.palette.grey[600],
                  fontSize: "13px",
                }}
              >
                Select Induction Date
              </Typography>
              <RHFDatePicker
                control={control}
                setValue={setValue}
                name="inductionDate"
                label={""}
                trigger={trigger}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            paddingY: "8px",
            paddingX: "12px",
            boxShadow: `10px 0px 0px ${theme.palette.grey[800]}`,
          }}
        >
          <Button variant="contained" color="error" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleSubmit(onsubmit)}
          >
            Book Induction
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Dialog>
  );
};
export { BookInduction };
