import { useState, useCallback } from "react";

// ----------------------------------------------------------------------

interface ReturnType {
  hide: VoidFunction;
  show: VoidFunction;
  visible: boolean;
}

export interface UseDialogProps {
  isVisible?: boolean;
}

export function useDialog(props?: UseDialogProps): ReturnType {
  const [visible, setIsVisible] = useState(!!props?.isVisible);

  const show = useCallback(() => {
    setIsVisible(true);
  }, []);

  const hide = useCallback(() => {
    setIsVisible(false);
  }, []);

  return {
    visible,
    show,
    hide,
  };
}
