import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Section1,
  Section10,
  Section11,
  Section2,
  Section3,
  Section4,
  Section5,
  Section6,
  Section7,
  Section8,
  Section9,
} from "./addEmployeeSections";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectAllEmployee, selectSelectedEmployee } from "./selector";
import { getEmployee, storeSectionDataInLocal } from "./slice";
import { Stepper } from "@app/components/Stepper";
import { getContractor } from "../Contractor/slice";

export const AddEmployee = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [currentSection, setCurrentSection] = useState(3);
  const changeSection = (section: number) => {
    setCurrentSection(section);
  };
  const employee = useSelector(selectSelectedEmployee);
  console.log("employee----", employee, state);
  useEffect(() => {
    dispatch(getEmployee({ employeeId: state?.id || state?.employeeId }));
    dispatch(storeSectionDataInLocal({}));
  }, [dispatch, state?.id, state?.employeeId]);

  const steps = Array.from(
    { length: state?.employeeId ? 11 : 10 },
    (_, index) => ({
      stepIndex: index + 1,
      title: `Section ${index + 1}`,
      isComplete: index + 1 < currentSection,
    })
  );

  return (
    <Stack sx={{ borderRadius: "8px", padding: "20px" }}>
      {employee &&
        (currentSection === 1 ? (
          <Section1 changeSection={changeSection} />
        ) : currentSection === 2 ? (
          <Section2 employee={employee} changeSection={changeSection} />
        ) : currentSection === 3 ? (
          <Section3
            employee={employee}
            changeSection={changeSection}
            onBoardingReview={state?.employeeId ? true : false}
          />
        ) : currentSection === 4 ? (
          <Section4
            employee={employee}
            changeSection={changeSection}
            onBoardingReview={state?.employeeId ? true : false}
          />
        ) : currentSection === 5 ? (
          <Section5
            employee={employee}
            changeSection={changeSection}
            onBoardingReview={state?.employeeId ? true : false}
          />
        ) : currentSection === 6 ? (
          <Section6
            employee={employee}
            changeSection={changeSection}
            onBoardingReview={state?.employeeId ? true : false}
          />
        ) : currentSection === 7 ? (
          <Section7
            employee={employee}
            changeSection={changeSection}
            onBoardingReview={state?.employeeId ? true : false}
          />
        ) : currentSection === 8 ? (
          <Section8
            employee={employee}
            changeSection={changeSection}
            onBoardingReview={state?.employeeId ? true : false}
          />
        ) : currentSection === 9 ? (
          <Section9
            employee={employee}
            changeSection={changeSection}
            onBoardingReview={state?.employeeId ? true : false}
          />
        ) : currentSection === 10 ? (
          <Section10
            employee={employee}
            changeSection={changeSection}
            onBoardingReview={state?.employeeId ? true : false}
          />
        ) : (
          currentSection === 11 && (
            <Section11
              employee={employee}
              changeSection={changeSection}
              onBoardingReview={state?.employeeId ? true : false}
            />
          )
        ))}
      <Stack
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#fff",
          borderRadius: "5px",
          marginTop: "12px",
        }}
      >
        <Stepper steps={steps} />
      </Stack>
    </Stack>
  );
};
