import React from "react";

interface SalesCardProps {
  title: string;
  values: { label: string; value: number | string; color?: string }[];
  icon: React.ReactNode;
}

export const SalesCard: React.FC<SalesCardProps> = ({
  title,
  values,
  icon,
}) => {
  return (
    <div className="w-[320px] rounded-xl shadow-md bg-white flex items-center px-6 py-5 flex-grow">
      <div className="w-full">
        {/* Title */}
        <p className="text-gray-700 text-base font-medium mb-3">{title}</p>

        {/* Values (Numbers and Labels) */}
        <div className="flex items-center justify-between">
          <div className="flex space-x-6">
            {values.map((item, index) => (
              <div key={index} className="flex flex-col items-center">
                <p
                  className={`text-xl font-bold ${item.color || "text-black"}`}
                >
                  {item.value}
                </p>
                <p className="text-gray-500 text-sm">{item.label}</p>
              </div>
            ))}
          </div>

          {/* Icon */}
          <div className="text-gray-500 text-2xl">{icon}</div>
        </div>
      </div>
    </div>
  );
};

export default SalesCard;
