import TablePaginationCustom from "@app/components/table/TablePagination";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { RowActions } from "./RowActions";
import AppTableHeadCell from "@app/components/table/AppTableHeadCell";
import { SvgColor } from "@app/components/svg-color";
import { sourceUrl } from "@app/constants/source_url";
import { useDialog } from "@app/hooks/use-dialog";
import { BookInduction } from "./BookInduction";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBookProjectSuccess,
  selectRemoveAssignedProject,
  selectSelectedEmployeeProjects,
} from "../add_employee/selector";
import Status from "@app/components/status/Status";
import { useEffect, useState } from "react";
import {
  getEmployeeProjects,
  removeAssignedProjectAction,
  updateInductionStatus,
} from "../add_employee/slice";
import { Controller, useForm } from "react-hook-form";
import { useRequestState } from "@app/hooks/use-request-state";
import dayjs from "dayjs";

const EmployeeProject = ({ edit = false, employee }: any) => {
  const bookInductionDialog = useDialog();
  const methods = useForm<any>();
  const { control, handleSubmit } = methods;
  const dispatch = useDispatch();
  const theme = useTheme();
  const [pageValue, setPageValue] = useState(1);
  const [selectedRows, setSelectedRows] = useState<readonly string[]>([]);
  const [pageLimitValue, setPageLimitValue] = useState<number | string>(10);
  const employeeProjects = useSelector(selectSelectedEmployeeProjects);
  const bookProjectSuccess = useSelector(selectBookProjectSuccess);

  useEffect(() => {
    console.log("employeeProjects", employeeProjects);
    dispatch(
      getEmployeeProjects({
        page: pageValue,
        limit: pageLimitValue,
      })
    );
  }, [dispatch, bookProjectSuccess]);

  useRequestState({
    stateSelector: selectRemoveAssignedProject,
    successMessageShown: true,
    errorShown: true,
  });

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (employeeProjects) {
        const newSelected = employeeProjects.data.map((n: any) => n.id);
        setSelectedRows(newSelected);
        return;
      }
    }
    setSelectedRows([]);
  };

  const handleSelectSingleInductedProjectCheck = (event: React.ChangeEvent<HTMLInputElement>, id: string | number) => {
    if (event.target.checked) {
      setSelectedRows((prev) => ([...prev, id.toString()]));
      return;
    }
    setSelectedRows((prev) => (prev.filter(item => item !== id)))
  }

  return (
    <>
      {edit && (
        <Button
          variant="contained"
          sx={{ width: "179px", mt: 1 }}
          startIcon={
            <SvgColor
              sx={{ width: "24px", height: "24px" }}
              src={sourceUrl.icons.addIcon}
            />
          }
          onClick={() => {
            bookInductionDialog.show();
          }}
        >
          <Typography sx={{ fontWeight: 400, fontSize: "13px" }}>
            Book New Induction
          </Typography>
        </Button>
      )}
      <TableContainer
        sx={{
          backgroundColor: "#FFFFFF",
          marginTop: "10px",
          borderRadius: "0px",
          borderTopRightRadius: "5px",
          borderTopLeftRadius: "5px",
          minHeight: "600px",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead sx={{ borderBottom: "1px", borderBottomColor: "red" }}>
            <TableRow>
              <TableCell
                width={"85px"}
                sx={{
                  padding: "0px 12px",
                  backgroundColor: "#fff",
                  display: "flex",
                  alignItems: "center",
                  borderLeft: "0.5px solid #F1F4F7",
                  justifyContent: "center",
                }}
              >
                {
                  employeeProjects &&
                  employeeProjects?.data?.length > 0
                  && (<Checkbox
                    color="primary"
                    size="small"
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                    checked={
                      employeeProjects?.data?.length > 0 &&
                      selectedRows.length === employeeProjects?.data?.length
                    }
                    onChange={handleSelectAllClick}
                  />)
                }

                #
              </TableCell>
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    padding: "0px 12px",
                    minWidth: "90px",
                    background: "#fff",
                    borderLeft: "0.5px solid #F1F4F7",
                  },
                }}
                title="Preview"
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "400px",
                    padding: "0px 12px",
                    background: "#fff",
                    borderLeft: "0.5px solid #F1F4F7",
                  },
                }}
                title="Project Name"
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "80px",
                    padding: "0px 12px",
                    background: "#fff",
                    borderLeft: "0.5px solid #F1F4F7",
                  },
                }}
                title="Booked on"
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "90px",
                    padding: "0px 12px",
                    background: "#fff",
                    borderLeft: "0.5px solid #F1F4F7",
                  },
                }}
                title="Inducted On"
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "90px",
                    padding: "0px 12px",
                    background: "#fff",
                    borderLeft: "0.5px solid #F1F4F7",
                  },
                }}
                title="Induction Status"
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "50px",
                    padding: "0px 12px",
                    background: "#fff",
                    borderLeft: "0.5px solid #F1F4F7",
                  },
                  align: "right",
                }}
                title="Actions"
              />
            </TableRow>
          </TableHead>

          <TableBody>
            {employeeProjects &&
              employeeProjects?.data?.length > 0 &&
              employeeProjects?.data?.map((item: any, index: number) => {
                return (
                  <TableRow>
                    <TableCell sx={{
                      width: "20px",
                    }} >
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          padding: "4px",
                        }}
                      >
                        <Checkbox
                          checked={selectedRows.includes(item.id)}
                          size="small"
                          sx={{
                            color: "#616E80",
                          }}
                          inputProps={{
                            "aria-label": "select all desserts",
                          }}
                          onChange={(e) => { handleSelectSingleInductedProjectCheck(e, item.id) }}
                        />
                        <Typography sx={{ textAlign: "center" }}>
                          {index + 1}
                        </Typography>
                      </Stack>
                    </TableCell>

                    <TableCell
                      sx={{
                        border: "0.5px solid #F1F4F7", width: "90px", padding: "12px 0px 12px 12px"

                      }}
                    >
                      <Box
                        sx={{
                          height: "90px",
                          maxWidth: "90px",
                          borderRadius: "5px",
                          objectFit: "fill",

                        }}
                        component={"img"}
                        src={item?.project?.imageUrl}
                      ></Box>
                    </TableCell>
                    <TableCell
                      width={"20px"}
                      sx={{ border: "0.5px solid #F1F4F7" }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          textWrap: "nowrap",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        {item?.project?.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      width={"20px"}
                      sx={{ border: "0.5px solid #F1F4F7" }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          textWrap: "nowrap",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                      >
                        {item?.bookingDate && dayjs(item?.bookingDate, "YYYY-MM-DD").format("DD MMMM YYYY")}
                      </Typography>
                    </TableCell>
                    <TableCell
                      width={"20px"}
                      sx={{ border: "0.5px solid #F1F4F7" }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          textWrap: "nowrap",
                          fontWeight: 400,
                          fontSize: "16px",
                        }}
                      >
                        {item?.inductedDate
                          ? dayjs(item?.inductedDate, "YYYY-MM-DD").format("DD MMMM YYYY")
                          : "Pending...."}
                      </Typography>
                    </TableCell>
                    <TableCell
                      width={"20px"}
                      sx={{ border: "0.5px solid #F1F4F7" }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          textWrap: "nowrap",
                        }}
                      >
                        {edit ? (
                          <Controller
                            name="inductionStatus"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                displayEmpty
                                size="small"
                                sx={{ width: "100%" }}
                                renderValue={(selected: any) => {
                                  if (!selected) {
                                    return (
                                      <Typography
                                        sx={{
                                          color: theme.palette.grey[500],
                                        }}
                                      >
                                        {item?.inductionStatus ??
                                          "Select Induction Status"}
                                      </Typography>
                                    );
                                  }
                                  return selected;
                                }}
                                onChange={(event) => {
                                  field.onChange(event);
                                  dispatch(
                                    updateInductionStatus({
                                      id: item.id,
                                      inductionStatus: event.target.value,
                                    })
                                  );
                                }}
                              >
                                <MenuItem value={"Booked For Induction"}>
                                  Booked For Induction
                                </MenuItem>
                                <MenuItem value={"Not Inducted"}>
                                  Not Inducted
                                </MenuItem>
                                <MenuItem value={"Inducted"}>Inducted</MenuItem>
                              </Select>
                            )}
                          />
                        ) : (
                          <Status
                            sx={{
                              fontWeight: 600,
                              pr: 3,
                              gap: 1,
                              alignItems: "center",
                            }}
                            title={
                              item?.inductionStatus == "Not Inducted"
                                ? "Booked"
                                : item?.inductionStatus
                            }
                            status={item?.inductionStatus}
                          />
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell width={"20px"}>
                      <RowActions
                        data={item}
                        deleteAction={removeAssignedProjectAction}
                        notEditable={true}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer >
      <Divider sx={{ borderColor: "#F1F4F7" }} />
      <TablePaginationCustom
        onPageDenseChange={(e) => {
          setPageLimitValue(e.target.value);
        }}
        totalItems={employeeProjects && employeeProjects.totalItems}
        totalPage={employeeProjects && employeeProjects.totalPages}
        itemsPerPage={employeeProjects && employeeProjects.itemsPerPage}
        currentPage={employeeProjects && employeeProjects.currentPage}
        showingCustomText={employeeProjects && employeeProjects.showing}
        onPageChange={(e, currentPage) => {
          setPageValue(currentPage);
        }}
        sx={{
          background: "#fff",
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      />
      {
        bookInductionDialog.visible && (
          <BookInduction open onClose={bookInductionDialog.hide} />
        )
      }
    </>
  );
};

export default EmployeeProject;