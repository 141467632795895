import React from 'react';
import { Button as MuiButton, ButtonProps } from '@mui/material';

interface Props extends ButtonProps {
  isActive?: boolean;
}

export const Button: React.FC<Props> = ({ isActive = false, ...rest }) => {
  return (
    <MuiButton
      variant={isActive ? 'contained' : 'outlined'}
      {...rest}
    />
  );
};
