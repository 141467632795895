import { useState, lazy, Suspense } from "react";
import { Box, Card, Stack, Tab, Tabs, Typography } from "@mui/material";

const CompanyDocuments = lazy(() => import("./tabs/CompanyDocuments"));
const CompanyProfile = lazy(() => import("./tabs/CompanyProfile"));
const CompanyUsers = lazy(() => import("./tabs/CompanyUsers"));
const EmployeeProfileSettings = lazy(
  () => import("./tabs/EmployeeProfileSettings")
);
const EnrollmentReport = lazy(() => import("./tabs/EnrollmentReport"));
const GeneralLists = lazy(() => import("./tabs/GeneralLists"));
const GeneralSettings = lazy(() => import("./tabs/GeneralSettings"));
const OnboardingQuestionnaire = lazy(
  () => import("./tabs/OnboardingQuestionnaire")
);
const ProjectList = lazy(() => import("./tabs/ProjectList"));

const TabPanel = ({
  children,
  value,
  index,
}: {
  children: React.ReactNode;
  value: number;
  index: number;
}) => {
  return (
    <Box role="tabpanel" hidden={value !== index} sx={{ p: 3 }}>
      {value === index && children}
    </Box>
  );
};

export const CompanyInformationScreen = () => {
  const [tabIndex, setTabIndex] = useState( 0);

  const tabs = [
    { label: "Company Profile", component: CompanyProfile },
    { label: "Company Documents", component: CompanyDocuments },
    { label: "Project List", component: ProjectList },
    { label: "Company Users", component: CompanyUsers },
    { label: "General Settings", component: GeneralSettings },
    { label: "Onboarding Questionnaire", component: OnboardingQuestionnaire },
    { label: "General Lists", component: GeneralLists },
    { label: "Enrollment Report", component: EnrollmentReport },
    { label: "Employee Profile Settings", component: EmployeeProfileSettings },
  ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Stack sx={{ marginTop: "10px", padding: "20px" }}>
      <Card>
        <Stack sx={{ px: 4, py: 2 }}>
          <Typography sx={{ fontSize: "17px", fontWeight: 500 }}>
            IP BUILDERS BRICKWORK LIMITED
          </Typography>
        </Stack>

        <Box sx={{ borderTop: "1px solid #F1F4F7" }}>
          {/* Tab Navigation */}
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="Company Information Tabs"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#0177FB",
                height: "3px",
              },
              "& .MuiTab-root": {
                textTransform: "none",
                fontWeight: 500,
                color: "gray",
                minWidth: 150,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "12px 16px",
              },
              "& .MuiTab-root:not(:last-of-type)": {
                marginRight: "0px !important",
              },
              "& .Mui-selected": { color: "#0177FB" },
              borderBottom: "1px solid #F1F4F7",
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                sx={{ borderRight: "1px solid #F1F4F7" }}
              />
            ))}
          </Tabs>

          {/* Tab Content */}
          {tabs.map((tab, index) => (
            <TabPanel key={index} value={tabIndex} index={index}>
              <Suspense
                fallback={
                  <Box sx={{ padding: 2, textAlign: "center" }}>Loading...</Box>
                }
              >
                <tab.component />
              </Suspense>
            </TabPanel>
          ))}
        </Box>
      </Card>
    </Stack>
  );
};

export default CompanyInformationScreen;
