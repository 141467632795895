import { SvgColor } from "@app/components/svg-color";
import TablePaginationCustom from "@app/components/table/TablePagination";
import { useDialog } from "@app/hooks/use-dialog";
import { Search } from "@mui/icons-material";
import {
  Box,
  Divider,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { sourceUrl } from "@app/constants/source_url";
import QualificationTable from "./qualificationsTable/Table";
import { selectQualification } from "./selector";
import { addQualificationAction, getQualificationAction } from "./slice";
import { AddNewQualification } from "./qualificationsTable/AddNewQualification";

const schema = yup.object().shape({
  name: yup.string().required("Qualification name is required"),
  description: yup.string().optional(),
});

export const QualificationScreen = ({ open, onClose }: any) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  const addQualificationDialog = useDialog();

  const qualifications = useSelector(selectQualification);
  const [pageValue, setPageValue] = useState(1);
  const [pageLimitValue, setPageLimitValue] = useState<number | string>(10);

  useEffect(() => {
    dispatch(
      getQualificationAction({
        page: pageValue,
        limit: pageLimitValue,
      })
    );
  }, [dispatch, pageValue, pageLimitValue]);

  const submit = (form: any) => {
    dispatch(
      addQualificationAction({
        callback: () => {
          handleAfterSave();
        },
        ...form,
      })
    );
  };

  const handleAfterSave = () => {
    reset();
    addQualificationDialog.hide();
  };

  return (
    <Stack
      component={"div"}
      sx={{
        paddingBottom: "20px",
        border: "1px solid #F1F4F7",
      }}
    >
      <Stack
        component={"div"}
        sx={{
          display: "flex",
          gap: "20px",
        }}
      >
        <Stack
          sx={{ width: "100%", border: "1px solid #F1F4F7", marginTop: "3px" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#FFFFFF",
              paddingLeft: "11px",
            }}
          >
            {/* Search Bar */}
            <Stack sx={{ borderRight: "1px solid #F1F4F7", padding: "7px" }}>
              <TextField
                variant="outlined"
                placeholder="Search Qualifications"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #F1F4F7",
                    width: "100%",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "30px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <Search
                      sx={{
                        marginRight: "5px",
                        color: "black",
                        backgroundColor: "#F1F4F7",
                      }}
                    />
                  ),
                }}
              />
            </Stack>
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                width: "20%",
                borderLeft: "1px solid #F1F4F7",
              }}
            >
              {/* Filter Button */}
              <IconButton>
                <Stack
                  sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <SvgColor src={sourceUrl.icons.filter} />
                  <Typography>Filter</Typography>
                </Stack>
              </IconButton>
            </Stack>
          </Box>
        </Stack>

        <Stack
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            paddingLeft: "20px",
          }}
        >
          <Button
            variant="contained"
            startIcon={<SvgColor src={sourceUrl.icons.addIcon} />}
            onClick={addQualificationDialog.show}
            sx={{
              borderRadius: "5px",
              backgroundColor: "#0177FB",
              fontWeight: "400",
              fontSize: "14px",
            }}
          >
            Add New Qualification
          </Button>
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: "98%",
          margin: "19px",
          boxShadow: "0px 0px 10px 0px #00000026",
        }}
      >
        <>
          <QualificationTable data={qualifications || []} />
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <TablePaginationCustom
            onPageDenseChange={(e) => {
              setPageLimitValue(e.target.value);
            }}
            totalItems={qualifications?.totalItems || 0}
            totalPage={qualifications?.totalPages || 1}
            itemsPerPage={qualifications?.itemsPerPage || pageLimitValue}
            currentPage={qualifications?.currentPage || pageValue}
            showingCustomText={qualifications?.showing}
            onPageChange={(e, currentPage) => {
              setPageValue(currentPage);
            }}
            sx={{
              background: "#fff",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              fontWeight: "400",
              fontSize: "14px",
            }}
          />
        </>
      </Stack>

      {addQualificationDialog.visible && (
        <AddNewQualification open onClose={addQualificationDialog.hide} />
      )}
    </Stack>
  );
};
