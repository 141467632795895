import {
  Box,
  Button,
  CardContent,
  Divider,
  Stack,
  Card,
  Typography,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
} from "@mui/material";
import EmployeeTable from "../../components/table/Table";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "@app/routes/paths";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllEmployee,
  selectAllEmployeeMeta,
} from "../add_employee/selector";
import { useEffect, useState } from "react";
import { getEmployees } from "../add_employee/slice";
import { SvgColor } from "@app/components/svg-color";
import { sourceUrl } from "@app/constants/source_url";
import TablePaginationCustom from "@app/components/table/TablePagination";
import { Search } from "@mui/icons-material";
import { Input, Label } from "@app/components";
import { IDepartment } from "./types";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { addDepartmentAction, getDepartment } from "./slice";
import { selectDepartment } from "./selector";
import { yupResolver } from "@hookform/resolvers/yup";
import DepartmentTable from "./departmentTable/Table";
import { AddNewDepartment } from "./departmentTable/AddNewDepartment";
import { useDialog } from "@app/hooks/use-dialog";

const schema = yup.object().shape({
  name: yup.string().required("department name required"),
  parentDepartmentId: yup.string().optional(),
});
export const DepartmentScreen = ({ open, onClose }: any) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IDepartment>({
    resolver: yupResolver(schema),
    context: { departments: useSelector(selectDepartment) },
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addDepartmentDialog = useDialog();
  const department = useSelector(selectDepartment);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageValue, setPageValue] = useState(1);
  const [pageLimitValue, setPageLimitValue] = useState<number | string>(10);

  useEffect(() => {
    dispatch(
      getDepartment({
        page: pageValue,
        limit: pageLimitValue,
      })
    );
  }, [dispatch, pageValue, pageLimitValue]);

  const submit = (form: IDepartment) => {
    dispatch(
      addDepartmentAction({
        callback: () => {
          handleAfterSave();
        },
        ...form,
      })
    );
  };

  const handleAfterSave = () => {
    reset();
    setIsModalOpen(false);
  };

  return (
    <Stack
      component={"div"}
      sx={{
        paddingBottom: "20px",
        border: "1px solid #F1F4F7",
      }}
    >
      <Stack
        component={"div"}
        sx={{
          display: "flex",
          gap: "20px",
        }}
      >
        <Stack
          sx={{ width: "100%", border: "1px solid #F1F4F7", marginTop: "3px" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#FFFFFF",
              paddingLeft: "11px",
            }}
          >
            {/* Search Bar */}
            <Stack sx={{ borderRight: "1px solid #F1F4F7", padding: "7px" }}>
              <TextField
                variant="outlined"
                placeholder="Search Keywords"
                size="small" // Makes the height smaller
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #F1F4F7",
                    width: "100%",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "30px", // Set height explicitly
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <Search
                      sx={{
                        marginRight: "5px",
                        color: "black",
                        backgroundColor: "#F1F4F7"
                      }}
                    />
                  ),
                }}
              />
            </Stack>
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                width: "20%",
                borderLeft: "1px solid #F1F4F7",
              }}
            >
              {/* Filter Button */}
              <IconButton>
                <Stack
                  sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <SvgColor src={sourceUrl.icons.filter} />
                  <Typography>Filter</Typography>
                </Stack>
              </IconButton>
            </Stack>
          </Box>
        </Stack>

        <Stack
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            paddingLeft: "20px",
          }}
        >
          <Button
            variant="contained"
            startIcon={<SvgColor src={sourceUrl.icons.addIcon} />}
            onClick={() => {
              addDepartmentDialog.show();
            }}
            sx={{ borderRadius: "5px", backgroundColor: "#0177FB", fontWeight: "400", fontSize: "14px" }}
          >
            Add Department
          </Button>
        </Stack>
      </Stack>
      <Stack sx={{
        width: "98%", margin: "19px",
        boxShadow: "0px 0px 10px 0px #00000026"
      }}>
        {/* /   {department && ( */}
        <>
          <DepartmentTable data={department || []} />
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <TablePaginationCustom
            onPageDenseChange={(e) => {
              setPageLimitValue(e.target.value);
            }}
            totalItems={department?.totalItems}
            totalPage={department?.totalPages}
            itemsPerPage={department?.itemsPerPage}
            currentPage={department?.currentPage}
            showingCustomText={department?.showing}
            onPageChange={(e, currentPage) => {
              setPageValue(currentPage);
            }}
            sx={{
              background: "#fff",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              fontWeight: "400",
              fontSize: "14px"
            }}
          />
        </>
        {/* )} */}
      </Stack>
      {addDepartmentDialog.visible && (
        <AddNewDepartment open onClose={addDepartmentDialog.hide} />
      )}
    </Stack>
  );
};
