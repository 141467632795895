import {
  Button,
  Dialog,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";

import { Input } from "@app/components";
import { FormProvider } from "@app/components/hook-form";
import { RHFFilePickerField } from "@app/components/hook-form";
import { useDispatch } from "react-redux";
import { addProofOfIdentity, addProofOfWork } from "../add_employee/slice";
import { yupResolver } from "@hookform/resolvers/yup";
import { uploadAdditionalInformationSchema } from "./validators";
import { useRequestState } from "@app/hooks/use-request-state";
import { selectAddIdentifyRightToSuccess, selectAddProofOfWork } from "../add_employee/selector";
import { LoadingButton } from "@mui/lab";
import { Strings } from "@app/constants/strings";

interface Props {
  open: any;
  onClose: any;
  employeeId: any;
  isProofOfWork: boolean;
}
const UploadAdditionalInformation = ({
  open,
  onClose,
  employeeId,
  isProofOfWork,
}: Props) => {
  const methods = useForm<any>({
    resolver: yupResolver(uploadAdditionalInformationSchema) as any,
  });
  const dispatch = useDispatch();
  const {
    control,
    formState: { errors },
    watch,
  } = methods;
  const theme = useTheme();

  const { loading } = useRequestState({
    stateSelector: isProofOfWork ? selectAddProofOfWork : selectAddIdentifyRightToSuccess,
    successMessageShown: true,
    errorShown: true,
  });

  const onSubmit = (data: any) => {
    const dataFile = watch("file");
    if (isProofOfWork) {
      dispatch(
        addProofOfWork({
          employeeId,
          documentName: data.documentName,
          file: dataFile,
          callback: () => {
            onClose();
          },
        })
      );
    } else {
      dispatch(
        addProofOfIdentity({
          employeeId,
          documentName: data.documentName,
          file: dataFile,
          callback: () => {
            onClose();
          },
        })
      );
    }
  };
  return (
    <Dialog
      open
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          width: "45%",
          maxWidth: "none",
        },
      }}
    >
      <FormProvider methods={methods}>
        {" "}
        <Stack sx={{ width: "100%" }}>
          <Typography sx={{ fontSize: "18px", fontWeight: 450, padding: 1.5 }}>
            Upload Additional Information
          </Typography>
          <Divider sx={{ color: "#F1F4F7" }} />
        </Stack>
        <Divider sx={{ borderColor: "#F1F4F7" }} />
        <Stack sx={{ px: 7 }}>
          <Stack
            sx={{
              padding: 2,
              boxShadow: `0px 4px 10px ${theme.palette.grey[400]}`,
              borderRadius: 1,
              margin: 2,
            }}
          >
            {/* <Typography
              sx={{
                color: theme.palette.grey[600],
                fontSize: "13px",
              }}
            >
              Description
            </Typography>
            <Input
              control={control}
              name="documentName"
              label="select project"
              placeHolder={Strings.field.inputPlaceholder("Description")}
              error={errors.documentName?.message}
              sx={{
                height: "50px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.grey[400],
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.grey[400],
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.grey[400],
                },
              }}
            /> */}
            <Stack sx={{ mt: "20px" }}>
              <Typography
                sx={{
                  color: theme.palette.grey[600],
                  fontSize: "13px",
                }}
              >
                Upload File
              </Typography>
              <Stack>
                <RHFFilePickerField
                  name={"file"}
                  label={"Choose a file"}
                  control={control}
                />
                {errors.file?.message && (
                  <Typography
                    sx={{
                      color: "#E34932",
                      fontSize: "0.75rem",
                      pl: 1.6,
                      pt: 1,
                    }}
                  >
                    {String(errors.file.message)}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </FormProvider>

      <Stack
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          paddingY: "8px",
          paddingX: "12px",
          boxShadow: `10px 0px 0px ${theme.palette.grey[800]}`,
        }}
      >
        <Button
          variant="contained"
          sx={{ backgroundColor: "#FE6B6B", fontWeight: 400, fontSize: "14px" }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          sx={{ backgroundColor: "#0177FB", fontSize: "14px", fontWeight: 400 }}
          onClick={methods.handleSubmit(onSubmit)}
          variant="contained"
          loading={loading}
        >
          Upload File
        </LoadingButton>
      </Stack>
    </Dialog>
  );
};
export { UploadAdditionalInformation };
