import React, { useState, lazy, Suspense } from "react";
import { Box, Card, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import ProjectUsers from "../company-settings/tabs/ProjectUsers";
import ProjectDocuments from "../company-settings/tabs/ProjectDocument";

const ProjectProfile = lazy(() => import("../company-settings/tabs/ProjectProfile"));
// const CompanyProfile = lazy(() => import("./tabs/CompanyProfile"));
// const CompanyUsers = lazy(() => import("./tabs/CompanyUsers"));
// const EmployeeProfileSettings = lazy(
//   () => import("./tabs/EmployeeProfileSettings")
// );
// const EnrollmentReport = lazy(() => import("./tabs/EnrollmentReport"));
// const GeneralLists = lazy(() => import("./tabs/GeneralLists"));
// const GeneralSettings = lazy(() => import("./tabs/GeneralSettings"));
// const OnboardingQuestionnaire = lazy(
//   () => import("./tabs/OnboardingQuestionnaire")
// );
// const ProjectList = lazy(() => import("./tabs/ProjectList"));

const TabPanel = ({
  children,
  value,
  index,
}: {
  children: React.ReactNode;
  value: number;
  index: number;
}) => {
  return (
    <Box role="tabpanel" hidden={value !== index} sx={{ p: 3 }}>
      {value === index && children}
    </Box>
  );
};

export const ProjectInformationScreen = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const project:any = location.state?.project;
  const companyId:any = location.state?.companyId;
  const tabs = [
    { label: "Project Profile", component: ProjectProfile},
    { label: "Project Documents", component: ProjectDocuments},
    { label: "Project Users", component: ProjectUsers},
  ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Stack sx={{ marginTop: "10px", padding: "20px" }}>
      <Card>
        <Stack sx={{ px: 4, py: 2 }}>
          <Typography sx={{ fontSize: "17px", fontWeight: 500 }}>
            {project?.name}
          </Typography>
        </Stack>

        <Box sx={{ borderTop: "1px solid #F1F4F7" }}>
          {/* Tab Navigation */}
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="Company Information Tabs"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#0177FB",
                height: "3px",
              },
              "& .MuiTab-root": {
                textTransform: "none",
                fontWeight: 500,
                color: "gray",
                minWidth: 150,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "12px 16px",
              },
              "& .MuiTab-root:not(:last-of-type)": {
                marginRight: "0px !important",
              },
              "& .Mui-selected": { color: "#0177FB" },
              borderBottom: "1px solid #F1F4F7",
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                sx={{ borderRight: "1px solid #F1F4F7" }}
              />
            ))}
          </Tabs>

          {/* Tab Content */}
          {tabs.map((tab, index) => (
            <TabPanel key={index} value={tabIndex} index={index}>
              <Suspense
                fallback={
                  <Box sx={{ padding: 2, textAlign: "center" }}>Loading...</Box>
                }
              >
                {React.createElement(tab.component, { project, companyId })}  
              </Suspense>
            </TabPanel>
          ))}
        </Box>
      </Card>
    </Stack>
  );
};

export default ProjectInformationScreen;
