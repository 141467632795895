import React, { useState } from "react";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import AppTableHeadCell from "./AppTableHeadCell";
import UpDownIcon from "./UpDownIcon";
import { RowActions } from "./row-action";

interface Props {
  data: any;
}

const ContractTable = ({ data }: Props) => {
  const [selectedRows, setSelectedRows] = useState<readonly string[]>([]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data?.data
        ? data?.data?.length > 0
          ? data.data.map((n: any) => n.id)
          : []
        : [];
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
  };

  const handleSelectSingleContractorCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string | number
  ) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, id.toString()]);
      return;
    }
    setSelectedRows((prev) => prev.filter((item) => item !== id));
  };

  return (
    <TableContainer
      sx={{
        backgroundColor: "#FFFFFF",
        marginTop: "10px",
        borderRadius: "0px",
        borderTopRightRadius: "5px",
        borderTopLeftRadius: "5px",
        minHeight: "500px",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Table sx={{ minWidth: 500 }}>
        <TableHead sx={{ borderBottom: "1px", borderBottomColor: "red" }}>
          <TableRow>
            <TableCell
              width={"20px"}
              padding="checkbox"
              sx={{ backgroundColor: "#fff" }}
            >
              {data.data && (
                <Checkbox
                  size="small"
                  color="primary"
                  checked={
                    data?.data.length > 0 &&
                    selectedRows.length === data.data.length
                  }
                  onChange={handleSelectAllClick}
                />
              )}
            </TableCell>
            <AppTableHeadCell
              cellProps={{
                sx: {
                  minWidth: "70px",
                  padding: "0px 12px",
                  background: "#fff",
                },
              }}
              title="ID"
            />
            <AppTableHeadCell
              cellProps={{
                sx: {
                  minWidth: "300px",
                  padding: "0px 12px",
                  background: "#fff",
                },
              }}
              title="Contractor Name"
            />

            <AppTableHeadCell
              cellProps={{
                sx: {
                  minWidth: "20px",
                  background: "#fff",
                },
                align: "right",
              }}
              title="Actions"
            />
          </TableRow>
        </TableHead>

        <TableBody>
          {data.data && data.data.length > 0 ? (
            data.data.map((item: any) => (
              <TableRow key={item.id}>
                <TableCell width={"20px"} padding="checkbox">
                  <Checkbox
                    checked={selectedRows.includes(item.id)}
                    size="small"
                    sx={{ color: "#616E80" }}
                    inputProps={{ "aria-label": "select all desserts" }}
                    onChange={(e) => handleSelectSingleContractorCheck(e, item.id)}
                  />
                </TableCell>
                <TableCell sx={{ width: "20px" }}>
                  <Typography sx={{ fontSize: "14px", fontWeight: "400", wordWrap: "nowrap" }}>
                    {item.id}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontSize: "14px", fontWeight: "400", wordWrap: "nowrap" }}>
                    {item.name}
                  </Typography>
                </TableCell>
                <TableCell align="center" sx={{ width: "2px" }}>
                  <RowActions data={item} />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                <Typography sx={{ fontSize: "14px", fontWeight: "400", color: "#616E80" }}>
                  No contractors found
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>

      </Table>
    </TableContainer>
  );
};

export default ContractTable;
