/* eslint-disable @typescript-eslint/promise-function-async */
import axios, { AxiosInstance, AxiosError } from "axios";

class HttpService {
  http: AxiosInstance;

  authToken: string | null = null;

  constructor() {
    this.http = axios.create({
      baseURL: process.env.REACT_APP_API_URL ?? "http://localhost:4000/",
      timeout: Number(100000),
    });

    this.interceptRequests();
    this.interceptResponse();
  }

  interceptRequests() {
    /**
     * axios request interceptors for debugging
     * and alter request data
     */
    this.http.interceptors.request.use(
      async (reqConfig) => {
        console.log(`[Http.Request: ${reqConfig.url ?? ""}]`, reqConfig);
        if (this.authToken) {
          reqConfig.headers.Authorization = `Bearer ${this.authToken}`;
        }
        return reqConfig;
      },
      (error) => Promise.reject(error)
    );
  }

  interceptResponse() {
    /**
     * Customize axios success and error
     * data to easily handle them in app
     */
    this.http.interceptors.response.use(
      (response) => {
        console.log(`[Http.Response: ${response.config.url ?? ""}]`, response);
        return response.data;
      },
      (error: AxiosError) => Promise.reject(this.handleApiError(error))
    );
  }

  // Handling error
  // eslint-disable-next-line class-methods-use-this
  handleApiError(error: any) {
    try {
      if (error.response) {
        /*
         Able to connect with server, but something
         went wrong and api returned reason for that
       */
        if (error.response.data.StatusCode === 500) {
          return {
            message: "Server Error",
          };
        }
        return {
          message: error.response.data.message,
        };
      }

      // Not able to connect with server
      return { message: error.message };
    } catch {
      // Can't figure out source of error
      return { message: "unknown error occurred" };
    }
  }

  setToken(token: string | null) {
    this.authToken = token;
  }

  getToken() {
    console.log("this.authToken----", this.authToken);

    return this.authToken;
  }
}

const httpService = new HttpService();

const request = httpService.http;

export { httpService, request };
