import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AppTableHeadCell from "./AppTableHeadCell";

import { Icon } from "@iconify/react";
import { IndicatorCircle } from "@app/components/CircleIndicator";
import UpDownIcon from "../UpDownIcon";
import { RowActions } from "./row-action";

interface Props {
  data: any;
}

const EmployeeTable = ({ data }: Props) => {
  console.log("DataInEmployeeTable______", data);
  const [selectedRows, setSelectedRows] = useState<readonly string[]>([]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data?.data
        ? data?.data?.length > 0
          ? data.data.map((n: any) => n.id)
          : []
        : [];
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
  };

  const handleSelectSingleEmployeeTypeCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string | number
  ) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, id.toString()]);
      return;
    }
    setSelectedRows((prev) => prev.filter((item) => item !== id));
  };

  return (
    <TableContainer
      sx={{
        backgroundColor: "#FFFFFF",
        marginTop: "10px",
        borderRadius: "0px",
        borderTopRightRadius: "5px",
        borderTopLeftRadius: "5px",
        minHeight: "600px",
        "&::-webkit-scrollbar": {
          display: "none", // Chrome, Safari, Edge
        },
      }}
    >
      <Table sx={{ minWidth: 500 }}>
        <TableHead sx={{ borderBottom: "1px", borderBottomColor: "red" }}>
          <TableRow>
            {/* Checkbox Column (30px for 3 parts out of 10) */}
            <TableCell
              width="20px"
              sx={{
                backgroundColor: "#fff",
                padding: "0px",
                textAlign: "center", // Ensures horizontal centering
                verticalAlign: "middle", // Ensures vertical centering
              }}
            >
              {data?.data && (
                <Checkbox
                  color="primary"
                  sx={{
                    color: "#616E80",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  checked={
                    data?.data.length > 0 &&
                    selectedRows.length === data?.data.length
                  }
                  onChange={handleSelectAllClick}
                />
              )}
            </TableCell>

            {/* Type ID Column (90px for 3 parts) */}
            <AppTableHeadCell
              cellProps={{
                sx: {
                  minWidth: "170px",
                  padding: "0px 12px",
                  background: "#fff",
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "#616E80",
                },
              }}
              title="Type ID"
              icon={null}
            />

            {/* Employment Type Column (150px for 5 parts) */}
            <AppTableHeadCell
              cellProps={{
                sx: {
                  minWidth: "100px",
                  padding: "0px 12px",
                  background: "#fff",
                },
              }}
              title="Employment Type"
              icon={null}
            />

            {/* Actions Column (60px for 2 parts) */}
            <AppTableHeadCell
              cellProps={{
                sx: {
                  inWidth: "20px",
                  padding: "0px 12px",
                  background: "#fff",
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "#616E80",
                },
                align: "left",
              }}
              title="Actions"
            />
          </TableRow>
        </TableHead>

        <TableBody>
          {data.data && data.data.length > 0 ? (
            data.data.map((item: any) => (
              <TableRow key={item.id}>
                {/* Checkbox Column (50px) */}
                <TableCell
                  width={50}
                  sx={{ padding: "8px", textAlign: "center" }}
                >
                  <Checkbox
                    checked={selectedRows.includes(item.id)}
                    size="small"
                    sx={{ color: "#616E80" }}
                    inputProps={{ "aria-label": "select all" }}
                    onChange={(e) =>
                      handleSelectSingleEmployeeTypeCheck(e, item.id)
                    }
                  />
                </TableCell>

                {/* Type ID Column (80px) */}
                <TableCell
                  width={80}
                  sx={{ padding: "8px", textAlign: "left" }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item?.id}
                  </Typography>
                </TableCell>

                {/* Employment Type Column */}
                <TableCell
                  sx={{
                    padding: "8px",
                    textAlign: "left",
                    verticalAlign: "middle",
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                    {item?.name}
                  </Typography>
                </TableCell>

                {/* Actions Column (50px) */}
                <TableCell
                  width={50}
                  sx={{ padding: "8px", textAlign: "right" }}
                >
                  <RowActions data={item} />
                </TableCell>
              </TableRow>
            ))
          ) : (
            /* Display message when no employee types are found */
            <TableRow>
              <TableCell
                colSpan={4}
                sx={{
                  textAlign: "center",
                  padding: "16px",
                  fontSize: "14px",
                  color: "#616E80",
                }}
              >
                No Employee Type Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EmployeeTable;
