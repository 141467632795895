import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Card, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { Icon } from "@iconify/react";

import { Button } from "@app/components/button";
import { SvgColor } from "@app/components/svg-color";
import { sourceUrl } from "@app/constants/source_url";
import { Stepper } from "@app/components/Stepper";
import Status from "@app/components/status/Status";
import { PATH_DASHBOARD } from "@app/routes/paths";
import {
  selectSelectedEmployee,
  selectSelectedEmployeeData,
} from "../add_employee/selector";
import EmployeeProject from "./EmployeeProject";
import { EmergencyDetails } from "./EmergencyDetails";
import { IdentifyRightToWork } from "./IdentifyRightToWork";
import { PaymentInformation } from "./PaymentInformtaion";
import { getEmployee } from "../add_employee/slice";
import { TrainingMatrix } from "./TrainingMatrix";
import { DetailOfContract } from "./DetailOfContract";
import { MedicalHistory } from "./MedicalHistory";
// import { NotesComment } from "./NotesComments";

export const steps = [
  {
    stepIndex: 1,
    title: "Invitation Received",
    isComplete: true,
  },
  {
    stepIndex: 2,
    title: "Onboarding Complete",
    isComplete: false,
  },
  {
    stepIndex: 3,
    title: "Induction Complete",
    isComplete: false,
  },
  {
    stepIndex: 4,
    title: "Employment Review",
    isComplete: false,
  },
  {
    stepIndex: 5,
    title: "Approved and Active",
    isComplete: false,
  },
];
export const EmployeeProfileScreen = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const employee = useSelector(selectSelectedEmployee);
  const employeeExtraData = useSelector(selectSelectedEmployeeData);
  const [currentEmployeeId, setCurrentEmployeeId] = useState(state?.employeeId);
  const [isPrevious, setIsPrevious] = useState(false);
  const [isNext, setIsNext] = useState(false);
  useEffect(() => {
    dispatch(getEmployee({ employeeId: currentEmployeeId }));
  }, [dispatch, currentEmployeeId]);

  const employeeDetail = useSelector(selectSelectedEmployee);
  const handlePrevious = () => {
    if (!state?.data?.data || !Array.isArray(state.data.data)) {
      console.log("No data available");
      return;
    }

    const employees = state.data.data;
    const currentIndex = employees.findIndex(
      (emp: any) => emp.id === currentEmployeeId
    );

    if (currentIndex > 0) {
      const previousEmployeeId = employees[currentIndex - 1].id;
      console.log(previousEmployeeId, "Previous Employee ID");
      setCurrentEmployeeId(previousEmployeeId);
      setIsNext(false);
    } else {
      setIsPrevious(true);
      setIsNext(false);
    }
  };

  const handleNext = () => {
    if (!state?.data?.data || !Array.isArray(state.data.data)) {
      return;
    }

    const employees = state.data.data;
    const currentIndex = employees.findIndex(
      (emp: any) => emp.id === currentEmployeeId
    );
    if (currentIndex < employees.length - 1) {
      const nextEmployeeId = employees[currentIndex + 1].id;
      setCurrentEmployeeId(nextEmployeeId);
      setIsPrevious(false);
    } else {
      setIsPrevious(false);
      setIsNext(true);
    }
  };

  const updatedSteps = steps.map((step) =>
    step.stepIndex === 2
      ? {
          ...step,
          isComplete:
            employeeDetail?.employeeOnboardingStatus === "Complete"
              ? true
              : false,
        }
      : step
  );

  const employeeProjectHead = [
    { label: "Projects Inducted", id: "projectInducted" },
    { label: "Emergency Details", id: "emergencyDetails" },
    { label: "Identity & Right To Work", id: "identifyRightWork" },
    { label: "Payment Information", id: "paymentInformation" },
    { label: "Qualifications", id: "qualifications" },
    { label: "Details of Contract", id: "detailsOfContract" },
    { label: "Medical History", id: "medicalHistory" },
    // { label: "Notes & Comments", id: "notesComments" },
  ];

  const [headClicked, setHeadClicked] = useState("projectInducted");

  return (
    <>
      {employee && (
        <Stack component={"div"} sx={{ padding: "25px" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              sx={{ fontSize: "13px", fontWeight: "500" }}
              startIcon={<Icon icon="icon-park-outline:left" />}
              onClick={handlePrevious}
              disabled={isPrevious}
            >
              Previous
            </Button>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                sx={{ fontSize: "13px", fontWeight: "500" }}
                startIcon={<SvgColor src={sourceUrl.icons.revisionLog} />}
              >
                Revision Log
              </Button>
              <Button
                sx={{ fontSize: "13px", fontWeight: "500" }}
                onClick={() => {
                  navigate(PATH_DASHBOARD.edit_employee_profile, {
                    state: { employeeId: employee.id },
                  });
                }}
                startIcon={<SvgColor src={sourceUrl.icons.reviseInfo} />}
              >
                Revise Information
              </Button>
              <Button
                sx={{ fontSize: "13px", fontWeight: "500" }}
                startIcon={<SvgColor src={sourceUrl.icons.exportInfo} />}
              >
                Export
              </Button>
              <Button
                sx={{ fontSize: "13px", fontWeight: "500" }}
                endIcon={<Icon icon="icon-park-outline:right" />}
                disabled={isNext}
                onClick={handleNext}
              >
                Next
              </Button>
            </Box>
          </Box>
          <Card sx={{ padding: "0px", marginTop: "14px" }}>
            <Stack
              sx={{
                height: "53px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                fontWeight={400}
                sx={{ padding: "2px 8px" }}
              >
                Employee # {employee.id}
              </Typography>
            </Stack>
            <Divider
              sx={{
                borderColor: "#F1F4F7",
              }}
            />
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                padding: "10px 20px",
                width: "68%",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              {/* Left Section */}
              <Box>
                {/* Personal Information */}
                <Typography
                  sx={{
                    color: "#616E80",
                    fontStyle: "italic",
                    fontSize: "16px",
                    fontWeight: "600",
                    marginY: "14px",
                    marginX: "10px",
                  }}
                >
                  Personal Information
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "150px auto",
                    gap: "10px 30px",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" fontWeight={500}>
                    Full Name:
                  </Typography>
                  <Typography variant="body1" color="primary" fontWeight={500}>
                    {`${employeeDetail?.firstName} ${employeeDetail?.lastName}`}
                  </Typography>

                  <Typography variant="body1" fontWeight={500}>
                    Date of Birth:
                  </Typography>
                  <Typography variant="body1" color="primary" fontWeight={500}>
                    {employeeDetail?.dateOfBirth
                      ? dayjs(employeeDetail?.dateOfBirth, "DD/MM/YYYY").format(
                          "DD MMMM YYYY"
                        )
                      : "N/A"}
                  </Typography>

                  <Typography variant="body1" fontWeight={500}>
                    Gender:
                  </Typography>
                  <Typography variant="body1" color="primary" fontWeight={500}>
                    {employeeDetail?.gender ?? "N/A"}
                  </Typography>
                </Box>

                {/* Employment Section */}
                <Typography
                  sx={{
                    color: "#616E80",
                    fontStyle: "italic",
                    fontSize: "16px",
                    fontWeight: "600",
                    marginY: "14px",
                    marginX: "10px",
                  }}
                >
                  Employment
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "150px auto",
                    gap: "10px 30px",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" fontWeight={500}>
                    Designation:
                  </Typography>
                  <Typography variant="body1" color="primary" fontWeight={500}>
                    {employeeDetail?.designation
                      ? employeeDetail?.designation.name
                      : "N/A"}
                  </Typography>

                  <Typography variant="body1" fontWeight={500}>
                    Employee Role:
                  </Typography>
                  <Typography variant="body1" color="primary" fontWeight={500}>
                    {employeeDetail?.employeeRole
                      ? employeeDetail?.employeeRole
                      : "N/A"}
                  </Typography>

                  <Typography variant="body1" fontWeight={500}>
                    Employee Type:
                  </Typography>
                  <Typography variant="body1" color="primary" fontWeight={500}>
                    {employeeDetail?.employmentType
                      ? employeeDetail?.employmentType.name
                      : "N/A"}
                  </Typography>

                  <Typography variant="body1" fontWeight={500}>
                    Contractor:
                  </Typography>
                  <Typography variant="body1" color="primary" fontWeight={500}>
                    {employeeDetail?.contractor
                      ? employeeDetail?.contractor.name
                      : "N/A"}
                  </Typography>
                </Box>

                {/* Contact Information */}
                <Typography
                  sx={{
                    color: "#616E80",
                    fontStyle: "italic",
                    fontSize: "16px",
                    fontWeight: "600",
                    marginY: "14px",
                    marginX: "10px",
                  }}
                >
                  Contact Information
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "150px auto",
                    gap: "10px 30px",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" fontWeight={500}>
                    Contact Number:
                  </Typography>
                  <Typography variant="body1" color="primary" fontWeight={500}>
                    {employeeDetail?.contactDetails
                      ? employeeDetail?.contactDetails.mobileNumber
                      : "N/A"}
                  </Typography>

                  <Typography variant="body1" fontWeight={500}>
                    Email:
                  </Typography>
                  <Typography variant="body1" color="primary" fontWeight={500}>
                    {employeeDetail?.contactDetails
                      ? employeeDetail?.contactDetails.emailAddress
                      : "N/A"}
                  </Typography>

                  <Typography variant="body1" fontWeight={500}>
                    Home Address:
                  </Typography>
                  <Typography variant="body1" color="primary" fontWeight={500}>
                    {employeeDetail?.contactDetails
                      ? [
                          employeeDetail.contactDetails.address,
                          employeeDetail.contactDetails.city,
                          employeeDetail.contactDetails.postCode,
                        ]
                          .filter(Boolean)
                          .join(", ")
                      : "N/A"}
                  </Typography>
                </Box>
              </Box>

              {/* Right Section (Unchanged CSS) */}
              <Box>
                {/* Profile Image */}
                <Box
                  component={"img"}
                  sx={{
                    width: "250px",
                    height: "250px",
                    borderRadius: "5px",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  src={
                    employee?.profileUrl ??
                    (employee.gender && employee.gender == "female"
                      ? sourceUrl.icons.female_profile
                      : sourceUrl.icons.male_profile)
                  }
                ></Box>
                {/* Employment Status Details */}{" "}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    paddingTop: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Typography variant="body1" fontWeight={500}>
                      Employment Status:
                    </Typography>
                    <Typography variant="body1" fontWeight={500}>
                      Induction Status:
                    </Typography>
                    <Typography variant="body1" fontWeight={500}>
                      Projects Inducted:
                    </Typography>
                    <Typography variant="body1" fontWeight={500}>
                      Latest Induction:
                    </Typography>
                    <Typography variant="body1" fontWeight={500}>
                      Joined Since:
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Status
                      style={{ paddingLeft: "19px", paddingRight: "19px" }}
                      title={employeeDetail.employmentStatus}
                      status={employeeDetail.employmentStatus}
                    />
                    <Status
                      sx={{ px: "19px" }}
                      title={
                        employeeExtraData &&
                        employeeExtraData.employeeInductionStatus
                      }
                      status={
                        employeeExtraData &&
                        employeeExtraData.employeeInductionStatus
                      }
                      isEmployeeInductionStatus={true}
                    />
                    <Typography
                      variant="body1"
                      color="primary"
                      fontWeight={500}
                    >
                      {employeeExtraData
                        ? employeeExtraData.totalInductedProjects
                        : 0}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="primary"
                      fontWeight={500}
                    >
                      {employeeExtraData
                        ? employeeExtraData.latestInduction
                          ? dayjs(employeeExtraData.latestInduction).format(
                              "DD MMMM YYYY"
                            )
                          : "N/A"
                        : "N/A"}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="primary"
                      fontWeight={500}
                    >
                      {employee.createdAt
                        ? dayjs(employee.createdAt).format("DD MMMM YYYY")
                        : "N/A"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Stack>

            {/* <Box sx={{ width: "100%", marginY: "10px" }}>
              <Stepper steps={updatedSteps} />
            </Box> */}
            <Box
              sx={{
                borderBottom: "1px solid #F1F4F7 ",
                width: "100%",
                display: "flex",
                overflowX: "scroll",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {employeeProjectHead?.map((head, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: ".6px solid #F1F4F7 ",
                      padding: "10px",
                      boxSizing: "border-box",
                      height: "47px",
                      borderBottom:
                        headClicked === head?.id
                          ? `2px solid ${theme.palette.primary.main}`
                          : "none",
                    }}
                    onClick={() => {
                      setHeadClicked(head?.id);
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: theme.palette.grey[600],
                        textWrap: "nowrap",
                        fontWeight: "400",
                        fontSize: "13.34px",
                      }}
                    >
                      {head?.label}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Card>
          {headClicked === "projectInducted" ? (
            <EmployeeProject employee={employeeDetail} />
          ) : headClicked === "emergencyDetails" ? (
            <EmergencyDetails employee={employeeDetail} />
          ) : headClicked === "identifyRightWork" ? (
            <IdentifyRightToWork
              employee={employeeDetail}
              identifyRightWork={employeeDetail.identityAndRightToWork}
            />
          ) : headClicked === "paymentInformation" ? (
            <PaymentInformation employee={employeeDetail} />
          ) : headClicked === "qualifications" ? (
            <TrainingMatrix employee={employeeDetail} />
          ) : headClicked === "detailsOfContract" ? (
            <DetailOfContract employee={employeeDetail} />
          ) : headClicked === "medicalHistory" ? (
            <MedicalHistory
              employee={employeeDetail}
              medicalHistory={employeeDetail.medicalInformation}
            />
          ) : // ) : headClicked === "notesComments" ? (
          // <NotesComment />
          null}
        </Stack>
      )}
    </>
  );
};
