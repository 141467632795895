/*
 * Root Saga
 */

import { accountInformationSaga } from "@app/modules/account-information";
import { subscriptionSaga } from "@app/modules/account-information/plans-billing/saga";
import { employeeSaga } from "@app/modules/add_employee/sagas";
import { authSagas } from "@app/modules/auth/sagas";
import { bootstrapSagas } from "@app/modules/bootstrap";
import { companySagas } from "@app/modules/company/sagas";
import { contractorSaga } from "@app/modules/Contractor/sagas";
import { departmentSagas } from "@app/modules/department/sagas";
import { designationSaga } from "@app/modules/designation/saga";
import { employeeProfileSagas } from "@app/modules/employee-profile/saga";
import { roleSagas } from "@app/modules/employee-roles/sagas";
import { employementTypeSagas } from "@app/modules/EmployeeType/sagas";
import { knowledgeSaga } from "@app/modules/knowledge";
import { projectSaga } from "@app/modules/project/sagas";
import { qualificationSaga } from "@app/modules/qualification/sagas";
import { adminSagas } from "@app/modules/admin/sagas";

const rootSaga = [
  bootstrapSagas,
  authSagas,
  companySagas,
  projectSaga,
  employeeSaga,
  designationSaga,
  departmentSagas,
  employementTypeSagas,
  accountInformationSaga,
  contractorSaga,
  employeeProfileSagas,
  knowledgeSaga,
  roleSagas,
  subscriptionSaga,
  qualificationSaga,
  adminSagas,
];

export { rootSaga };
