import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Input } from "@app/components";
import { addEmployeeContact } from "../add_employee/slice";
import {
  selectAddEmployeeContact,
  selectEmergencyContact,
  selectSelectedEmployee,
} from "../add_employee/selector";
import { addEmergencyContactSchema } from "./validators";
import { useRequestState } from "@app/hooks/use-request-state";
import { LoadingButton } from "@mui/lab";
import { updateEmergencyContactAction } from "./slice";
import { Strings } from "@app/constants/strings";

interface Props {
  open: any;
  onClose: any;
  editData?: any;
}
const AddEmergencyContact = ({ open, onClose, editData }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(addEmergencyContactSchema),
    defaultValues: editData || {
      fullName: "",
      relationship: "",
      mobileNumber: "",
      address: "",
      city: "",
      postCode: "",
    },
  });

  const theme = useTheme();
  const dispatch = useDispatch();
  const employeeDetail = useSelector(selectSelectedEmployee);

  const { loading } = useRequestState({
    stateSelector: !editData
      ? selectAddEmployeeContact
      : selectEmergencyContact,
    errorShown: true,
    successMessageShown: true,
  });

  const onSubmit = (data: any) => {
    !editData
      ? dispatch(
          addEmployeeContact({
            ...data,
            employeeId: employeeDetail?.id,
            callback: () => {
              onClose();
            },
            section: "emergencyContactDetails",
          })
        )
      : dispatch(
          updateEmergencyContactAction({
            id: editData.id,
            ...data,
            callback: () => {
              onClose();
            },
          })
        );
  };
  return (
    <Dialog
      open
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          width: "65%",
          maxWidth: "none",
        },
      }}
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack sx={{ width: "100%" }}>
        <Typography sx={{ fontSize: "18px", fontWeight: 450, padding: 1.5 }}>
          Add Emergency Contact
        </Typography>
        <Divider sx={{ color: "#F1F4F7" }} />
      </Stack>
      <Divider sx={{ borderColor: "#F1F4F7" }} />

      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          padding: 2,
          px: 6,
          gap: 2,
        }}
      >
        <Stack sx={{ flex: 0.5 }}>
          <Stack>
            <Typography
              sx={{
                color: theme.palette.grey[600],
                fontSize: "15px",
              }}
            >
              {"Emergency Contact Full Name"}
            </Typography>
            <Input
              name="fullName"
              control={control}
              label="Company Name"
              placeHolder={Strings.field.inputPlaceholder("Emergency Contact Full Name")}
              error={
                errors?.fullName?.message
                  ? String(errors.fullName.message)
                  : undefined
              }
            />
          </Stack>
          <Stack>
            <Typography
              sx={{
                color: theme.palette.grey[600],
                fontSize: "15px",
              }}
            >
              {"Relationship with your contact"}
            </Typography>
            <Input
              name="relationship"
              control={control}
              label="Relationship with your contact"
              placeHolder={Strings.field.inputPlaceholder("Relationship with your contact")}
              error={
                errors?.relationship?.message
                  ? String(errors.relationship.message)
                  : undefined
              }
            />
          </Stack>
          <Stack>
            <Typography
              sx={{
                color: theme.palette.grey[600],
                fontSize: "15px",
              }}
            >
              {"Mobile Number"}
            </Typography>
            <Input
              name="mobileNumber"
              control={control}
              placeHolder={Strings.field.inputPlaceholder("Emergency contact mobile number")}
              label="Mobile Number"
              error={
                errors?.mobileNumber?.message
                  ? String(errors.mobileNumber.message)
                  : undefined
              }
            />
          </Stack>
        </Stack>
        <Stack sx={{ flex: 0.5 }}>
          <Stack>
            <Typography
              sx={{
                color: theme.palette.grey[600],
                fontSize: "15px",
              }}
            >
              {"Address"}
            </Typography>
            <Input
              name="address"
              control={control}
              label="Address"
              placeHolder={Strings.field.inputPlaceholder("Emergency Contact Address")}
              error={
                errors?.address?.message
                  ? String(errors.address.message)
                  : undefined
              }
            />
          </Stack>
          <Stack>
            <Typography
              sx={{
                color: theme.palette.grey[600],
                fontSize: "15px",
              }}
            >
              {"City"}
            </Typography>
            <Input
              name="city"
              control={control}
              label="City"
              placeHolder={Strings.field.inputPlaceholder("Emergency Contact City")}
              error={
                errors?.city?.message ? String(errors.city.message) : undefined
              }
            />
          </Stack>
          <Stack>
            <Typography
              sx={{
                color: theme.palette.grey[600],
                fontSize: "15px",
              }}
            >
              {"Post Code"}
            </Typography>
            <Input
              name="postCode"
              control={control}
              label="Post Code"
              placeHolder={Strings.field.inputPlaceholder("Emergency Contact Post code")}
              error={
                errors?.postCode?.message
                  ? String(errors.postCode.message)
                  : undefined
              }
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          paddingY: "8px",
          paddingX: "30px",
          boxShadow: `10px 0px 0px ${theme.palette.grey[800]}`,
        }}
      >
        <Button variant="contained" color="error" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton loading={loading} variant="contained" type="submit">
          {editData ? "Edit" : "Add"} Emergency Contact
        </LoadingButton>
      </Stack>
    </Dialog>
  );
};
export { AddEmergencyContact };
