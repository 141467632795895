import { RequestStatus } from "@app/constants/request";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRequestFailed, IRequestState } from "@app/types";
import { EmployeeRoleState } from "./types";

const initialState: EmployeeRoleState = {
    employeeRole: {
        status: RequestStatus.IDLE,
    },
    deleteEmployeeRole: {},
    addEmployeeRole: {},
};

const EmployeeRoleSlice = createSlice({
    initialState,
    name: "employeeRole",
    reducers: {
        
        getEmployeeRole(state, action: PayloadAction<{ page: any; limit: any }>) {
            state.employeeRole.status = RequestStatus.RUNNING;
        },
        getEmployeeRoleSuccess(state, action: PayloadAction<IRequestState>) {
            state.employeeRole.data = action.payload.data;
            state.employeeRole.status = RequestStatus.SUCCESS;
        },
        getEmployeeRoleFailed(state, action: PayloadAction<IRequestFailed>) {
            state.employeeRole.data = action.payload;
            state.employeeRole.status = RequestStatus.ERROR;
        },
        addEmployeeRoleAction(state, action: PayloadAction<any>) {
            state.addEmployeeRole.status = RequestStatus.RUNNING;
        },
        addEmployeeRoleSuccessAction(state, action: PayloadAction<any>) {
            state.addEmployeeRole.status = RequestStatus.SUCCESS;
            state.addEmployeeRole.data = action.payload;
        },
        addEmployeeRoleFailedAction(state, action: PayloadAction<IRequestFailed>) {
            state.addEmployeeRole.error = action.payload;
            state.addEmployeeRole.status = RequestStatus.ERROR;
        },
        deleteEmployeeRole(state, action: PayloadAction<any>) {
            state.deleteEmployeeRole.status = RequestStatus.RUNNING;
        },
        deleteEmployeeRoleSuccess(state, action) {
            state.deleteEmployeeRole.status = RequestStatus.SUCCESS;
            state.deleteEmployeeRole.data = action.payload.data;
        },
        deleteEmployeeRoleFailed(state, action: PayloadAction<IRequestFailed>) {
            state.deleteEmployeeRole.status = RequestStatus.ERROR;
            state.deleteEmployeeRole.error = action.payload;
        },
    },
});

export const employeeRoleReducer = EmployeeRoleSlice.reducer;
export const {
    getEmployeeRole,
    getEmployeeRoleFailed,
    getEmployeeRoleSuccess,
    addEmployeeRoleAction,
    addEmployeeRoleFailedAction,
    addEmployeeRoleSuccessAction,
    deleteEmployeeRole,
    deleteEmployeeRoleSuccess,
    deleteEmployeeRoleFailed,
} = EmployeeRoleSlice.actions;
