import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { get, method } from "lodash";

import { Input, Label } from "@app/components";
import { useRequestState } from "@app/hooks/use-request-state";
import { addDepartmentAction, getDepartment } from "../slice";
import { selectAddDepartment, selectDepartment } from "../selector";
import { ICreateDepartmentPayload } from "../types";
import { LoadingButton } from "@mui/lab";
import { Strings } from "@app/constants/strings";

const schema = yup.object().shape({
  name: yup.string().required("Department name required"),
});

const AddNewDepartment = ({ open, onClose, data }: any) => {
  console.log("Data______", data);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ICreateDepartmentPayload>({
    resolver: yupResolver(schema),
    defaultValues: {
      id: get(data, "id", ""),
      name: get(data, "name", ""),
      parentDepartmentId: get(data?.parentDepartment, "id", ""),
    },
  });

  useEffect(() => {
    console.log("Error______", errors);
  }, [errors]);

  const dispatch = useDispatch();

  const handleSaveDepartment = (data: any) => {
    dispatch(
      addDepartmentAction({ ...data })
    );
  };

  const handleAfterSaveSuccess = useCallback(() => {
    reset();
    onClose();
  }, []);

  const { loading } = useRequestState({
    stateSelector: selectAddDepartment,
    onSuccess: handleAfterSaveSuccess,
    successMessageShown: true,
    errorShown: true,
  });

  const department = useSelector(selectDepartment);
  console.log("Department________", department);

  useEffect(() => {
    dispatch(getDepartment({ page: 1, limit: 1000 }));
  }, [dispatch]);

  return (
    <Dialog open={open}>
      <Stack sx={{ width: "100%" }}>
        <Typography
          sx={{ fontSize: "20px", fontWeight: "400", padding: "5px" , paddingLeft: "20px"}}
        >
          Create Department
        </Typography>
        <Divider sx={{ color: "#F1F4F7" }} />
      </Stack>
      <DialogContent
        sx={{
          width: "600px",
          display: "flex",
          justifyContent: "center",
          padding: "5px",
        }}
      >
        <Card
          sx={{
            width: "500px",
            borderRadius: "8px",
              boxShadow: "0px 0px 10px 0px #00000026"
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Label
              sx={{
                color:" #616E80",
                fontWeight: "400",
                fontSize: "14px"
              }}
              >Department Name</Label>
              <Input name="name" 
              control={control} 
              error={!!errors.name} 
              placeHolder={Strings.field.inputPlaceholder("Department Name")}
              />
              {errors.name && (
                <Typography variant="caption" color="error">
                  {errors.name.message}
                </Typography>
              )}

              <Label
              sx={{
                color:" #616E80",
                fontWeight: "400",
                fontSize: "14px"
              }}>Parent Department</Label>
              <Controller
                name={"parentDepartmentId"}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    name="parentDepartmentId"
                    sx={{ width: "100%" }}
                  >
                    {department?.data &&
                      department?.data.length > 0 &&
                      department?.data?.map((item: any) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))}
                  </Select>
                )}
              />
            </Box>

            <Stack component={"form"} sx={{ width: "100%" }}>
              <Box
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></Box>
            </Stack>
          </CardContent>
        </Card>
      </DialogContent>
      <Divider sx={{ borderColor: "#F1F4F7" }} />
      <Stack
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          paddingY: "8px",
          paddingX: "12px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            color:" #ffffff",
            fontWeight: "400",
            fontSize: "14px",
            backgroundColor: " #FE6B6B"
          }}
          color="error"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          sx={{
            color:" #ffffff",
            fontWeight: "400",
            fontSize: "14px",
            backgroundColor:"#0177FB"
          }}
          onClick={handleSubmit(handleSaveDepartment)}
        >
          Save
        </LoadingButton>
      </Stack>
    </Dialog>
  );
};

export { AddNewDepartment };
