import {
  Avatar,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AppTableHeadCell from "./AppTableHeadCell";
import UpDownIcon from "./UpDownIcon";
import { IndicatorCircle } from "../CircleIndicator";
import { Icon } from "@iconify/react";
import TablePaginationCustom from "./TablePagination";
import { useSelector } from "react-redux";
import { selectAllEmployee } from "@app/modules/add_employee/selector";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "@app/routes/paths";
import { RowActions } from ".";

interface Props {
  data: any;
  pageLimit: any;
  pageValue: any;
  onBoardingReview?: any;
}

const EmployeeTable = ({
  data,
  pageLimit,
  pageValue,
  onBoardingReview,
}: Props) => {
  const [selectedRows, setSelectedRows] = useState<readonly string[]>([]);

  const [open, setOpen] = useState(false);
  const [qrUrl, setQrUrl] = useState(null);

  const handleClickOpen = (url: any) => {
    setQrUrl(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setQrUrl(null);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data?.data
        ? data?.data?.length > 0
          ? data.data.map((n: any) => n.id)
          : []
        : [];
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
  };

  const handleSelectSingleEmployeeCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string | number
  ) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, id.toString()]);
      return;
    }
    setSelectedRows((prev) => prev.filter((item) => item !== id));
  };
  const navigate = useNavigate();
  return onBoardingReview ? (
    <>
      <TableContainer
        sx={{
          backgroundColor: "#FFFFFF",
          marginTop: "10px",
          borderRadius: "0px",
          borderTopRightRadius: "5px",
          borderTopLeftRadius: "5px",
          minHeight: "600px",
          boxShadow: "0px 4px 10px #00000040",
          "&::-webkit-scrollbar": {
            height: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1",
          },
        }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead
            sx={{
              borderBottom: "1px",
              borderBottomColor: "red",
              height: "33pxx",
            }}
          >
            <TableRow>
              <TableCell
                width={"20px"}
                padding="checkbox"
                sx={{ backgroundColor: "#fff", padding: "5px" }}
              >
                <Checkbox
                  color="primary"
                  checked={
                    data.data.length > 0 &&
                    selectedRows.length === data.data.length
                  }
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "230px",
                    padding: "0px 12px",
                    background: "#fff",
                  },
                }}
                title="Line Number"
                icon={null}
              />

              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "230px",
                    padding: "0px 12px",
                    background: "#fff",
                  },
                }}
                title="QR Code"
              />

              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "520px",
                    padding: "0px 12px",
                    background: "#fff",
                  },
                }}
                title="Candidate Name"
                icon={null}
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "260px",
                    padding: "0px 12px",
                    background: "#fff",
                  },
                }}
                title="Onboarding Date"
                icon={null}
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "230px",
                    padding: "0px 12px",
                    background: "#fff",
                  },
                }}
                title="Onboarding Status"
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "230px",
                    padding: "0px 12px",
                    background: "#fff",
                  },
                }}
                title="Status"
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "100px",
                    padding: "0px 12px",
                    background: "#fff",
                  },
                  align: "right",
                }}
                title="Action"
              />
            </TableRow>
          </TableHead>

          <TableBody>
            {data.data.map((item: any, key: number) => (
              <TableRow
                component={"tr"}
                key={item.id}
                sx={{
                  cursor: "pointer",
                  height: "45px",
                  "& td": { padding: "5px" },
                }}
              >
                <TableCell width={"20px"} padding="checkbox">
                  <Checkbox
                    checked={selectedRows.includes(item.id)}
                    size="small"
                    sx={{
                      color: "#616E80",
                    }}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                    onChange={(e) => {
                      handleSelectSingleEmployeeCheck(e, item.id);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      textWrap: "nowrap",
                      ml: 1,
                    }}
                  >
                    {key + 1}
                  </Typography>
                </TableCell>

                <TableCell
                >
                  <IconButton onClick={() => handleClickOpen(item.qrCodeUrl)}>
                    <Avatar src={item.qrCodeUrl ?? null} />
                  </IconButton>
                </TableCell>

                <TableCell
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                    ml: 1,
                  }}
                  onClick={() => {
                    !onBoardingReview &&
                      navigate(PATH_DASHBOARD.employee_profile, {
                        state: { employeeId: item.id, data: data },
                      });
                  }}
                >
                  <Avatar src={item.profileUrl ?? null} />
                  <Box>
                    <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>{`${item.firstName || ""
                      } ${item.lastName || ""}`}</Typography>
                    {/* <Typography sx={{ fontSize: '12px', color: "#616E80" }}>{item.jobTitle}</Typography> */}
                  </Box>
                </TableCell>
                <TableCell
                  onClick={() => {
                    !onBoardingReview &&
                      navigate(PATH_DASHBOARD.employee_profile, {
                        state: { employeeId: item.id },
                      });
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                      ml: 3,
                    }}
                  >
                    {new Date(item?.updatedAt).toLocaleDateString()}
                  </Typography>
                </TableCell>
                <TableCell
                  onClick={() => {
                    !onBoardingReview &&
                      navigate(PATH_DASHBOARD.employee_profile, {
                        state: { employeeId: item.id },
                      });
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                      ml: 1,
                    }}
                  >
                    <IndicatorCircle status="success" />
                    {item?.employeeOnboardingStatus == "Complete"
                      ? "Complete"
                      : item?.employeeOnboardingStatus}
                    {/* {item?.employeeOnboardingStatus} */}
                  </Typography>
                </TableCell>
                <TableCell
                  onClick={() => {
                    !onBoardingReview &&
                      navigate(PATH_DASHBOARD.employee_profile, {
                        state: { employeeId: item.id },
                      });
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                      ml: 1,
                    }}
                  >
                    <IndicatorCircle status="warning" />
                    {item?.employeeOnboardingStatus === "Complete"
                      ? item?.employmentStatus == "active"
                        ? "Active"
                        : item?.employmentStatus / item?.employeeInductionStatus
                      : item?.employeeOnboardingStatus === "Form Complete"
                        ? "Pending Review"
                        : "Pending Form Completion"}
                  </Typography>
                </TableCell>
                <TableCell align="left" width={"20px"}>
                  <RowActions
                    data={item}
                    pageLimit={pageLimit}
                    pageValue={pageValue}
                    onBoardingReview={onBoardingReview}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          {qrUrl && <img src={qrUrl} alt="QR Code" style={{ width: "100%", height: "auto" }} />}
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <>
      <TableContainer
        sx={{
          backgroundColor: "#FFFFFF",
          marginTop: "10px",
          borderRadius: "0px",
          borderTopRightRadius: "5px",
          borderTopLeftRadius: "5px",
          minHeight: "600px",
          boxShadow: "0px 4px 10px #00000040",
          "&::-webkit-scrollbar": {
            height: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1",
          },
        }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead
            sx={{
              borderBottom: "1px",
              borderBottomColor: "red",
              height: "33pxx",
            }}
          >
            <TableRow>
              <TableCell
                width={"20px"}
                padding="checkbox"
                sx={{ backgroundColor: "#fff", padding: "5px" }}
              >
                <Checkbox
                  color="primary"
                  checked={
                    data.data.length > 0 &&
                    selectedRows.length === data.data.length
                  }
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "230px",
                    padding: "0px 12px",
                    background: "#fff",
                  },
                }}
                title="Employee Id"
                icon={null}
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "260px",
                    padding: "0px 12px",
                    background: "#fff",
                  },
                }}
                title="Employee Name"
                icon={null}
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "180px",
                    padding: "0px 12px",
                    background: "#fff",
                  },
                }}
                title="Employee Role"
                icon={null}
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "180px",
                    padding: "0px 12px",
                    background: "#fff",
                  },
                }}
                title="Employee Type"
                icon={null}
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "160px",
                    padding: "0px 12px",
                    background: "#fff",
                  },
                }}
                title="Contact Number"
                icon={null}
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "230px",
                    padding: "0px 12px",
                    background: "#fff",
                  },
                }}
                title="Email"
                icon={null}
              />

              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "230px",
                    padding: "0px 12px",
                    background: "#fff",
                  },
                }}
                title="Status"
              />
              <AppTableHeadCell
                cellProps={{
                  sx: {
                    minWidth: "100px",
                    padding: "0px 12px",
                    background: "#fff",
                  },
                  align: "right",
                }}
                title="Action"
              />
            </TableRow>
          </TableHead>

          <TableBody>
            {data.data.map((item: any) => (
              <TableRow
                component={"tr"}
                key={item.id}
                sx={{
                  cursor: "pointer",
                  height: "45px",
                  "& td": { padding: "5px" },
                }}
              >
                <TableCell width={"20px"} padding="checkbox">
                  <Checkbox
                    checked={selectedRows.includes(item.id)}
                    size="small"
                    sx={{
                      color: "#616E80",
                    }}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                    onChange={(e) => {
                      handleSelectSingleEmployeeCheck(e, item.id);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      textWrap: "nowrap",
                      ml: 1,
                    }}
                  >
                    {item.id}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "7px",
                    ml: 1,
                  }}
                  onClick={() => {
                    !onBoardingReview &&
                      navigate(PATH_DASHBOARD.employee_profile, {
                        state: { employeeId: item.id, data: data },
                      });
                  }}
                >
                  <Avatar src={item.profileUrl ?? null} />
                  <Box
                    sx={{
                      gap: "0px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>{`${item.firstName || ""
                      } ${item.lastName || ""}`}</Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#616E80",
                      }}
                    >
                      {item?.designation?.name || "N/A"}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell
                  onClick={() => {
                    !onBoardingReview &&
                      navigate(PATH_DASHBOARD.employee_profile, {
                        state: { employeeId: item.id },
                      });
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "400", ml: 1 }}>
                    {item?.employeeRole || "N/A"}
                  </Typography>
                </TableCell>
                <TableCell
                  onClick={() => {
                    !onBoardingReview &&
                      navigate(PATH_DASHBOARD.employee_profile, {
                        state: { employeeId: item.id },
                      });
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "400", ml: 1 }}>
                    {item?.employmentType && item?.employmentType?.name
                      ? item?.employmentType?.name
                      : "N/A"}
                  </Typography>
                </TableCell>
                <TableCell
                  onClick={() => {
                    !onBoardingReview &&
                      navigate(PATH_DASHBOARD.employee_profile, {
                        state: { employeeId: item.id },
                      });
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "400", ml: 1 }}>
                    {item?.contactDetails
                      ? item?.contactDetails?.mobileNumber
                      : "N/A"}
                  </Typography>
                </TableCell>
                <TableCell
                  onClick={() => {
                    !onBoardingReview &&
                      navigate(PATH_DASHBOARD.employee_profile, {
                        state: { employeeId: item.id },
                      });
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "400", ml: 1 }}>
                    {item?.contactDetails
                      ? item?.contactDetails?.emailAddress
                      : "N/A"}
                  </Typography>
                </TableCell>

                <TableCell
                  onClick={() => {
                    !onBoardingReview &&
                      navigate(PATH_DASHBOARD.employee_profile, {
                        state: { employeeId: item.id },
                      });
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                      ml: 1,
                    }}
                  >
                    <IndicatorCircle status="warning" />
                    {item?.employmentStatus == "active"
                      ? "Active"
                      : item?.employmentStatus}
                    /{item?.employeeInductionStatus}
                  </Typography>
                </TableCell>
                <TableCell align="left" width={"20px"}>
                  <RowActions
                    data={item}
                    pageLimit={pageLimit}
                    pageValue={pageValue}
                    onBoardingReview={onBoardingReview}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          {qrUrl && <img src={qrUrl} alt="QR Code" style={{ width: "100%", height: "auto" }} />}
        </DialogContent>
      </Dialog>

    </>
  );
};

export default EmployeeTable;
