import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { get } from "lodash";

import { Input } from "@app/components";
import { useRequestState } from "@app/hooks/use-request-state";
import { IContractPayload } from "../types";
import { selectAddContractor } from "../selector";
import { addContractor } from "../slice";
import { Strings } from "@app/constants/strings";

const schema = yup.object().shape({
  name: yup.string().required("Contractor name required"),
});

const AddNewContract = ({ open, onClose, data }: any) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IContractPayload>({
    resolver: yupResolver(schema),
    defaultValues: {
      id: get(data, "id", ""),
      name: get(data, "name", ""),
    },
  });

  const dispatch = useDispatch();

  const onSubmit = (form: any) => {
    dispatch(addContractor({ ...form, contractorId: data?.id }));
  };

  const { loading } = useRequestState({
    stateSelector: selectAddContractor,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  return (
    <Dialog open={open}>
      <Stack sx={{ width: "100%" }}>
        <DialogContent
          sx={{
            width: "600px",
            display: "flex",
            padding: "5px",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "400",
              ml: 1,
              mt: 1,
              color: "#000000",
            }}
          >
            Add Contractor
          </Typography>
        </DialogContent>
        <Divider sx={{ color: "#F1F4F7" }} />
      </Stack>
      <Stack
        sx={{ paddingY: "5px", paddingX: "13%", justifyContent: "center" }}
      >
        <Card sx={{
          mb: 2,
          mt: 2,
        }}>
          <CardContent
            sx={{
              padding: "0px",
              ":last-child": {
                paddingBottom: "0px",
              },
            }}
            component={"form"}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "space-between",
                paddingX: "15px",
                paddingY: "15px",
              }}
            >
              <Box sx={{ width: "100%", minWidth: "400px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#616E80",
                    mb: 1,
                  }}
                >
                  Contractor Name
                </Typography>
                <Input
                  name="name"
                  control={control}
                  placeHolder={Strings.field.inputPlaceholder(
                    "Contractor Name"
                  )}
                  error={errors.name?.message}
                />
              </Box>
            </Box>
            <Divider sx={{ borderColor: "#F1F4F7" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingX: "22px",
                paddingY: "10px",
              }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  onClose();
                }}
                sx={{
                  ml: 1,
                  backgroundColor: "#FE6B6B",
                  fontSize: "14px",
                  fontWeight: "400",
                  width: "66px",
                }}
              >
                Cancel
              </Button>
              <Box sx={{ display: "flex", gap: 2 }}>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: "#0177FB",
                    fontSize: "14px",
                    fontWeight: "400",
                    width: "66px",
                  }}
                >
                  Save
                </LoadingButton>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Stack>
    </Dialog>
  );
};

export { AddNewContract };
