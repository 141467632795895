import * as Yup from "yup";
import { Strings } from "../../constants/strings";

export const section8Schema = Yup.object()
  .shape({
    nationalInsurance: Yup.string().required(
      Strings.validation.fieldRequired("National Insurance")
    ),
    uniqueTaxReferenceNumber: Yup.string().required(
      Strings.validation.fieldRequired("Unique Tax Reference Number")
    ),
    bankName: Yup.string().required(
      Strings.validation.fieldRequired("Bank Name")
    ),
    accountNumber: Yup.string().required(
      Strings.validation.fieldRequired("Account Number")
    ),
    sortCode: Yup.string().required(
      Strings.validation.fieldRequired("Sort Code")
    ),
    employmentType: Yup.string().required(
      Strings.validation.selectRequired("Employment Type")
    ),
    department: Yup.string().required(
      Strings.validation.selectRequired("Department")
    ),
  })
  .required();

export const section3Schema = Yup.object()
  .shape({
    nationalInsurance: Yup.string().required(
      Strings.validation.fieldRequired("National Insurance")
    ),
    uniqueTaxReferenceNumber: Yup.string().required(
      Strings.validation.fieldRequired("Unique Tax Reference Number")
    ),
    bankName: Yup.string().required(
      Strings.validation.fieldRequired("Bank Name")
    ),
    accountNumber: Yup.string().required(
      Strings.validation.fieldRequired("Account Number")
    ),
    branchAddress: Yup.string().required(
      Strings.validation.fieldRequired("Branch Address")
    ),
    sortCode: Yup.string().required(
      Strings.validation.fieldRequired("Sort Code")
    ),
    employmentType: Yup.mixed().required(
      Strings.validation.selectRequired("Employee Type")
    ),
    department: Yup.mixed().required(
      Strings.validation.selectRequired("Department Id")
    ),
  })
  .required();

export const section9Schema = Yup.object()
  .shape({
    outOfWork: Yup.mixed().required(
      Strings.validation.selectFieldRequired(
        "Have you been out of work before starting this job?"
      )
    ),
    beenInHospital: Yup.mixed().required(
      Strings.validation.selectFieldRequired(
        "Have you been in the hospital in the last 3 months?"
      )
    ),
    hasHealthCondition: Yup.mixed().required(
      Strings.validation.selectFieldRequired(
        "Do you suffer from any health condition or take any medication that could affect you at work"
      )
    ),
    understandsAlcoholDrugTesting: Yup.mixed().required(
      Strings.validation.selectFieldRequired(
        "Do you understand that we carry out random alcohol and drug testing"
      )
    ),
    outOfWorkDuration: Yup.string().required(
      Strings.validation.inputRequired("how long you have been out of work.")
    ),
    hospitalReason: Yup.string().required(
      Strings.validation.inputRequired(
        "why you have been in the hospital in the last 3 months."
      )
    ),
    healthConditionDetails: Yup.string().required(
      Strings.validation.inputRequired(
        "more details of your medical condition."
      )
    ),
  })
  .required();

export const section10Schema = Yup.object()
  .shape({
    termsAndConditions: Yup.mixed().required(
      Strings.validation.selectFieldRequired(
        "Have you read and agree with our terms and conditions to work with us?"
      )
    ),
    healthAndSafetyPolicy: Yup.mixed().required(
      Strings.validation.selectFieldRequired(
        "Have you read and agree with our Health & Safety Policy to work with us?"
      )
    ),
    informationAccuracy: Yup.mixed().required(
      Strings.validation.selectFieldRequired(
        "Do you agree that all information you have provided to us are all accurate to the best of your knowledge?"
      )
    ),
    printName: Yup.string().required(
      Strings.validation.fieldRequired("Print Name")
    ),
    date: Yup.mixed().required(
      Strings.validation.selectRequired("Date field is Required.")
    ),
  })
  .required();

export const personalDetailsSchema = Yup.object()
  .shape({
    firstName: Yup.string().required(
      Strings.validation.fieldRequired("First Name")
    ),
    lastName: Yup.mixed().required(
      Strings.validation.selectRequired("Last Name")
    ),

    dateOfBirth: Yup.mixed().required(
      Strings.validation.selectRequired("Date of Birth")
    ),
    gender: Yup.mixed().required(Strings.validation.selectRequired("Gender")),
  })
  .required();

export const contactDetailsSchema = Yup.object()
  .shape({
    address: Yup.string().required(Strings.validation.fieldRequired("Address")),
    city: Yup.string().required(Strings.validation.fieldRequired("City")),
    postCode: Yup.string().required(
      Strings.validation.fieldRequired("Post Code")
    ),
    // country: Yup.string().required(
    //   Strings.validation.selectRequired("Country")
    // ),
    emailAddress: Yup.string()
      .email(Strings.validation.invalidEmail)
      .required(Strings.validation.fieldRequired("Email Address")),
    mobileNumber: Yup.string().required(
      Strings.validation.fieldRequired("Mobile Number")
    ),
  })
  .required();

export const emergencyContactDetailsSchema = Yup.object()
  .shape({
    address: Yup.string().required(Strings.validation.fieldRequired("Address")),
    city: Yup.string().required(Strings.validation.fieldRequired("City")),
    postCode: Yup.string().required(
      Strings.validation.fieldRequired("Post Code")
    ),
    mobileNumber: Yup.string().required(
      Strings.validation.fieldRequired("Mobile Number")
    ),
    fullName: Yup.string().required(
      Strings.validation.fieldRequired("Full Name")
    ),
    relationship: Yup.string().required(
      Strings.validation.fieldRequired("Relationship")
    ),
  })
  .required();

export const identifyRightToWorkSchema = Yup.object()
  .shape({
    nationality: Yup.string().required(
      Strings.validation.selectRequired("Nationality")
    ),
    hasRightToWork: Yup.string().required(
      Strings.validation.selectRequired("Has Right to Work")
    ),
  })
  .required();

export const haveBeenWorkingSchema = Yup.object()
  .shape({
    tradeWorkTime: Yup.string().required(
      Strings.validation.fieldRequired(
        "How Long Have you been working in this Trade"
      )
    ),
    employeeRole: Yup.string().required(
      Strings.validation.selectRequired("Employee Role/Trade")
    ),
  })
  .required();

export const section11Schema = Yup.object()
  .shape({
    printName: Yup.string().required(
      Strings.validation.fieldRequired("Print Name")
    ),
    date: Yup.mixed().required(
      Strings.validation.selectRequired("Date field is Required.")
    ),
    employeePresentedAllInformation:Yup.string().required(
      Strings.validation.selectRequired("Required")
    ),
    accurateAndValid:Yup.string().required(
      Strings.validation.selectRequired("Required")
    ),
  })
  .required();
