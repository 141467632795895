import React, { useState, useCallback, useRef } from "react";
import {
  Container,
  Typography,
  Box,
  Dialog,
  Stack,
  IconButton,
  Button,
  Divider,
  Grid,
} from "@mui/material";
import { usePDF } from "react-to-pdf";
import { Icon } from "@iconify/react";
import { SvgColor } from "@app/components/svg-color";
import { useDialog } from "@app/hooks/use-dialog";
import FilePreviewDialog from "@app/components/file-preview/FilePreview";

type FileItem = {
  fileUrl: string;
  fileName: string;
};
interface IProps {
  open: boolean;
  onClose: () => void;
  knowledgeData?: {
    heading?: string;
    category?: { name: string };
    revisionNumber?: string;
    date?: string;
    description?: string;
    files?: FileItem[];
  };
}

const ArticlePreview: React.FC<IProps> = ({ open, onClose, knowledgeData }) => {
  const { toPDF, targetRef } = usePDF({ filename: "Article-Details.pdf" });
  const [selectedPreviewFile, setSelectedPreviewFile] = useState<any>();
  const filePreviewDialog = useDialog();
  const handleDownload = useCallback(() => {
    toPDF();
  }, [toPDF]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          width: "55%",
          maxWidth: "none",
        },
      }}
    >
      {/* Header */}
      <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
        <Typography variant="h6" fontWeight="bold">
          Article Details
        </Typography>
        <Stack direction="row" spacing={1}>
          <Button variant="contained" onClick={handleDownload}>
            Download
          </Button>
          <IconButton onClick={onClose}>
            <Icon icon="ic:close" color="black" height={20} width={20} />
          </IconButton>
        </Stack>
      </Stack>
      <Divider />

      {/* Content */}
      <Container maxWidth="md" sx={{ mb: 4 }} ref={targetRef}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {[
            { label: "Article Heading", value: knowledgeData?.heading },
            { label: "Category", value: knowledgeData?.category?.name },
            { label: "Revision Number", value: knowledgeData?.revisionNumber },
            { label: "Created By", value: "My Bildr.com" },
            { label: "Date", value: knowledgeData?.date },
          ].map(({ label, value }) => (
            <React.Fragment key={label}>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color="textSecondary"
                >
                  {label}:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{value || "N/A"}</Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>

        {/* Description */}
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" fontWeight="bold">
            Description:
          </Typography>
          <Box
            className="ql-editor"
            sx={{ mt: 1 }}
            dangerouslySetInnerHTML={{
              __html: knowledgeData?.description || "",
            }}
          />
        </Box>

        {/* Image Preview */}
        {Array.isArray(knowledgeData?.files) &&
          knowledgeData?.files.map((item) => (
            <Stack
              key={item.fileUrl}
              sx={{
                border: `1px solid #A1A1A1`,
                p: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "10px",
                my: 2,
                ":hover": {
                  backgroundColor: "#ECECEC",
                },
                color: " #535455",
                minWidth: "100%",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedPreviewFile({
                  name: item.fileName,
                  url: item.fileUrl,
                });
                filePreviewDialog.show();
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {item.fileUrl &&
                  (item.fileUrl.endsWith(".jpg") ||
                  item.fileUrl.endsWith(".jpeg") ||
                  item.fileUrl.endsWith(".webp") ? (
                    <Icon
                      icon="line-md:image-twotone"
                      height={"25px"}
                      width={"25px"}
                      style={{ marginRight: "10px", color: "blue" }}
                    />
                  ) : item.fileUrl.endsWith(".png") ? (
                    <SvgColor
                      src="/assets/icons/file-png.svg"
                      style={{ marginRight: "10px" }}
                      color="blue"
                    />
                  ) : item.fileUrl.endsWith(".pdf") ? (
                    <SvgColor
                      src="/assets/icons/pdfIcon.svg"
                      style={{ marginRight: "10px" }}
                      color="red"
                    />
                  ) : (
                    <Icon
                      icon="bxs:file"
                      height={"25px"}
                      width={"25px"}
                      style={{ marginRight: "10px", color: "gray" }}
                    />
                  ))}

                <div>
                  {item.fileName?.length > 50
                    ? item.fileName.substring(0, 50) + "..."
                    : item.fileName}
                </div>
              </div>
            </Stack>
          ))}
      </Container>
      {filePreviewDialog.visible && (
        <FilePreviewDialog
          open
          onClose={() => {
            filePreviewDialog.hide();
          }}
          fileUrl={selectedPreviewFile && selectedPreviewFile.url}
          fileName={selectedPreviewFile && selectedPreviewFile.name}
        />
      )}
    </Dialog>
  );
};

export { ArticlePreview };
