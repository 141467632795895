export const employee = "/employee";
export const checkDuplicate = `${employee}/check_duplicate`;
export const bookProject = `${employee}/book_projects`;
export const removeEmployeeProject = `${employee}/remove_assigned_project`;
export const uploadCscs = `${employee}/upload_cscs`;
export const uploadQualification = `${employee}/upload_qualification`;
export const addContactDetails = "/employee/";
export const addIdentifyRightToWork = "/employee/";
export const employeePayment = "/employee-payment";
export const addMedicalInformation = "/employees/";
export const addDeclaration = "/employee/";
export const getContractor = "/contractor/company/";
export const updateProfile = "/employee/update_employee_profile/";
export const getEmployeeProjects = "/employee_projects";
export const getEmergencyContact = "/emergency-contact";
export const updateInductionStatus = "/induction_status";
export const employee_recycle_bin = "/recycle_bin";
export const project_based_employee = "/project/";
export const identity_and_right_to_work = "/identity-and-right-to-work";
export const getOnboardingEmployee = "/project/onboarding-employee/";
export const addInductor = "/employee/";
export const details_of_contract = "/details-of-contract";
export const getCompanyTotalEmployee = "/company-onboarding-employees/";
export const getTotalCompanyEmployee = "/company/";