import { Icon } from "@iconify/react";
import { ExpandLess, ExpandMore, Menu } from "@mui/icons-material";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ICONS } from "./items";
import { PATH_DASHBOARD } from "@app/routes/paths";
import { useDispatch, useSelector } from "react-redux";
import { selectActivePathState, setActivePage } from "@app/modules/common";
import { selectCompanyId } from "@app/modules/company/selectors";
import { getProjects, selectProject } from "@app/modules/project/slice";
import {
  selectProjectData,
  selectSelectedProject,
} from "@app/modules/project/selectors";
import { hover } from "@testing-library/user-event/dist/hover";
import { selectGetUser } from "@app/modules/account-information/selector";

const navListsItem = [
  {
    id: 1,
    title: "All Projects",
    // icon: ICONS.project,
    child: [],
  },
  {
    id: 2,
    title: "My Bildr - ADMIN PANEL",
    icon: ICONS.employees,
    child: [
      {
        title: "Dashboard",
        navigation: PATH_DASHBOARD.adminDashboard,
      },
      {
        title: "Member Lists",
        navigation: PATH_DASHBOARD.adminUsers,
      },
      {
        title: "Invoice Lists",
        navigation: PATH_DASHBOARD.adminSales,
      },
      {
        title: "Admin Settings ",
        navigation: PATH_DASHBOARD.adminSetting,
      },
    ],
  },
  {
    id: 3,
    title: "Employees",
    icon: ICONS.employees,
    child: [
      {
        title: "Employees List",
        navigation: PATH_DASHBOARD.employeeList,
      },
      {
        title: "Onboarding Waiting List",
        navigation: PATH_DASHBOARD.onBoardingEmpList,
      },
    ],
  },

  {
    id: 5,
    heading: "General",
  },
  {
    id: 6,
    title: "Knowledge Base",
    icon: ICONS.knowledge_base,
    navigation: PATH_DASHBOARD.knowledge,
  },
];

const CustomListButton = styled(ListItemButton)(({ theme }) => ({
  color: "#E4ECF6",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "transparent !important",
}));

const NavLists = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const openData = Number(localStorage.getItem("open")) || 0; // Ensure it's a number
  // const [open, setOpen] = useState<number>(0); // Always starts closed
  // const selectActivePage = useSelector(selectActivePathState);
  // const [selectedMenu, setSelectedMenu] = useState<string | null>();

  // const projects = useSelector(selectProjectData);
  // const selectedProject: any = useSelector(selectSelectedProject);

  // const location = useLocation();
  // const loggedInUser = useSelector(selectGetUser);
  // const userType = loggedInUser?.data?.userType;

  const openData = Number(localStorage.getItem("open")) || 0; // Ensure it's a number
  const [open, setOpen] = useState<number>(openData);
  const selectActivePage = useSelector(selectActivePathState);
  const [selectedMenu, setSelectedMenu] = useState<string | null>(
    selectActivePage
  );

  const projects = useSelector(selectProjectData);
  const selectedProject: any = useSelector(selectSelectedProject);

  const location = useLocation();
  const loggedInUser = useSelector(selectGetUser);
  const userType = loggedInUser?.data?.userType;

  // useEffect(() => {
  //   let path = location.pathname;

  //   // Find the parent or child item that matches the current URL
  //   const foundChild = navListsItem
  //   .flatMap((item) => item.child || [])
  //   .find((child) => child.navigation === path);
  //  console.log(foundChild,"fount");

  // if (foundChild) {
  //   setSelectedMenu(foundChild.title);

  //   // Auto-expand the parent of the matching child
  //   // const parent = navListsItem.find((item) => item.child?.includes(foundChild));

  // }
  // }, [location.pathname]); // Depend on pathname so it updates on route change

  // Debug remove after issue resolved

  const handleClick = (item: any) => {
    localStorage.setItem("open", String(item.id));
    if (item.child) {
      setOpen((prev: number) => {
        if (item.id == prev) {
          return 0;
        }
        return item.id;
      });
      setSelectedMenu(item.title);
      return;
    } else {
      setSelectedMenu(item.title);
      dispatch(setActivePage(item?.title));
      item.navigation && navigate(item?.navigation);
    }
  };
  // useEffect(() => {
  //   let path = location.pathname;

  //   // Find the parent or child item that matches the current URL
  //   const foundChild = navListsItem
  //   .flatMap((item) => item.child || [])
  //   .find((child) => child.navigation === path);
  //  console.log(foundChild,"fount");

  // if (foundChild) {
  //   setSelectedMenu(foundChild.title);

  //   // Auto-expand the parent of the matching child
  //   // const parent = navListsItem.find((item) => item.child?.includes(foundChild));

  // }
  // }, [location.pathname]); // Depend on pathname so it updates on route change

  // Debug remove after issue resolved

  // const handleClick = (item: any) => {
  //   localStorage.setItem("open", String(item.id));
  //   if (item.child) {
  //     setOpen((prev: number) => {
  //       if (item.id == prev) {
  //         return 0;
  //       }
  //       return item.id;
  //     });
  //     setSelectedMenu(item.title);
  //     return;
  //   } else {
  //     setSelectedMenu(item.title);
  //     dispatch(setActivePage(item?.title));
  //     item.navigation && navigate(item?.navigation);
  //   }
  // };

  return (
    <List
      component="div"
      sx={{
        paddingTop: "0px",
        paddingBottom: "0px",
        overflowY: "auto",
        maxHeight: "100%",
      }}
    >
      {navListsItem
        .filter(
          (item) =>
            !(item.title === "Admin" && userType?.toLowerCase() !== "admin")
        )
        .map((item, index) => {
          // const isSelected = selectedMenu === item.title;
          const isSelected =
            selectedMenu === item.title ||
            localStorage.getItem("selectedMenuItem") === item.title;

          return (
            <Fragment key={item.id}>
              <CustomListButton
                onClick={() => {
                  handleClick(item);
                }}
                sx={{
                  borderRadius: "0",
                  width: index === 0 ? "249px" : "249px",
                  height: index === 0 ? "46px" : "48px",
                  // backgroundColor: isSelected ? "rgba(255, 255, 255, 0.08)" : "transparent",
                }}
                // sx={{
                //   borderRadius: "0",
                //   width: "249px",
                //   height: "48px",
                //   backgroundColor: isSelected
                //     ? "rgba(255, 255, 255, 0.08)"
                //     : "transparent",
                // }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "11px",
                    color: isSelected
                      ? "#FFFFFF"
                      : item?.heading ||
                        (selectedProject?.name && item.id === 1)
                      ? "#D1D3D6"
                      : "#98A5B5",
                    fontSize: index === 0 ? "12px" : "14px",
                    marginLeft: index === 0 ? "2px" : "0px",
                    lineHeight: "22px",
                  }}
                >
                  {item.icon}
                  {item?.heading
                    ? item?.heading
                    : selectedProject?.name && item.id === 1
                    ? selectedProject?.name
                    : item.title}
                </Typography>

                {!item?.heading && open === item?.id ? (
                  <ExpandMore
                    sx={{ color: isSelected ? "#FFFFFF" : "#98A5B5" }}
                  />
                ) : (
                  !item?.heading && (
                    <ExpandMore
                      sx={{ color: isSelected ? "#FFFFFF" : "#98A5B5" }}
                    />
                  )
                )}
              </CustomListButton>
              {item?.title === "All Projects" ? (
                <Collapse in={open === item.id} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    sx={{
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      width: "249px",
                    }}
                  >
                    <Typography variant="body2" sx={{ color: "#98A5B5" }}>
                      {projects?.length > 0 && (
                        <Fragment>
                          <ListItem
                            item={{ name: "All Projects", id: null }}
                            isProjectData
                            selectedMenu={selectedMenu}
                            setSelectedMenu={setSelectedMenu}
                            sx={{ width: "249px" }}
                          />
                          {projects.map((project: any) => {
                            return (
                              <ListItem
                                key={project.id}
                                item={{ name: project?.name, id: project?.id }}
                                isProjectData
                                selectedMenu={selectedMenu}
                                setSelectedMenu={setSelectedMenu}
                                sx={{
                                  paddingLeft: "48px",
                                  color:
                                    selectedMenu === project?.name
                                      ? "#60A6FD"
                                      : "#98A5B5", // White for selected project
                                  fontWeight:
                                    selectedMenu === project?.name
                                      ? "bold"
                                      : "normal",
                                  backgroundColor:
                                    selectedMenu === project?.name
                                      ? "rgba(255, 255, 255, 0.08)"
                                      : "transparent", // Highlight selection
                                }}
                              />
                            );
                          })}
                        </Fragment>
                      )}
                    </Typography>
                  </List>
                </Collapse>
              ) : item.child ? (
                item.child.map((childItem) => (
                  <Collapse in={open === item.id} timeout="auto" unmountOnExit>
                    <List
                      component="div"
                      sx={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        height: "42px",
                        width: "249px",
                      }}
                    >
                      <ListItem
                        item={childItem}
                        selectedMenu={selectedMenu}
                        setSelectedMenu={setSelectedMenu}
                      />
                    </List>
                  </Collapse>
                ))
              ) : null}
              <Divider
                sx={{
                  borderColor: "#252D37",
                  paddingTop: "3px",
                }}
              />
            </Fragment>
          );
        })}
    </List>
  );
};

const ListItem = (props: any) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const selectedProject: any = useSelector(selectSelectedProject);
  const companyId = useSelector(selectCompanyId);
  const isSelected =
    props.selectedMenu === (props.item.title ?? props.item.name);

  useEffect(() => {
    dispatch(getProjects({ companyId }));
  }, [dispatch]);

  const handleClick = (item: any, isProjectData: any) => {
    if (item.nestedChild) {
      setOpen(!open);
      return;
    }
    if (isProjectData) {
      dispatch(selectProject({ name: item.name, id: item?.id }));
      props.setSelectedMenu(item.name);
    } else if (item?.navigation) {
      dispatch(setActivePage(item?.title));
      props.setSelectedMenu(item.title);
      item.navigation && navigate(item?.navigation);
    }
  };

  const handleChildNavigation = (item: any) => {
    dispatch(setActivePage(item?.title));
    props.setSelectedMenu(item.title);
    item.navigation && navigate(item?.navigation);
  };

  return (
    <>
      <CustomListButton
        onClick={() => {
          handleClick(props.item, props.isProjectData);
        }}
        sx={{
          display: "flex",
          mt: "3px",
          flexDirection: "row",
          paddingLeft: "36px",
          backgroundColor: isSelected
            ? "rgba(255, 255, 255, 0.08)"
            : "transparent",
        }}
      >
        <Stack sx={{ display: "flex", marginLeft: "16px" }}>
          <Typography
            variant="body2"
            sx={{
              color: props.isProjectData
                ? isSelected
                  ? "#60A6FD" // White color for selected project
                  : "#98A5B5" // Default sidebar text color
                : isSelected
                ? "#60A6FD"
                : "#98A5B5",
            }}
          >
            {props.item.title ?? props?.item?.name}
          </Typography>
        </Stack>
      </CustomListButton>
      {props.item.nestedChild && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List
            component="div"
            sx={{ paddingTop: "0px", paddingBottom: "0px" }}
          >
            {props.item?.nestedChild &&
              props?.item?.nestedChild.map((childItem: any) => {
                const isChildSelected = props.selectedMenu === childItem.title;
                return (
                  <CustomListButton
                    onClick={() => {
                      handleChildNavigation(childItem);
                    }}
                    sx={{
                      backgroundColor: isChildSelected
                        ? "rgba(255, 255, 255, 0.08)"
                        : "transparent",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        color: isChildSelected ? "#FFFFFF" : "#98A5B5",
                        marginLeft: "20px",
                      }}
                    >
                      {childItem.title}
                    </Typography>
                  </CustomListButton>
                );
              })}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default NavLists;
