import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISubscriptionState } from "./types";
import { RequestStatus } from "@app/constants/request";
import { IRequestFailed } from "@app/types";

const initialState: ISubscriptionState = {
  subscriptionDetail: {},
  addSubscription: {},
};

const SubscriptionInformationSlice = createSlice({
  name: "subscriptionInformation",
  initialState,
  reducers: {
    getSubscriptionDetail(state) {
      state.subscriptionDetail.status = RequestStatus.RUNNING;
    },
    getSubscriptionDetailSuccess(state, action: PayloadAction<any>) {
      state.subscriptionDetail.status = RequestStatus.SUCCESS;
      state.subscriptionDetail.data = action.payload;
    },
    getSubscriptionDetailFailed(state, action: PayloadAction<IRequestFailed>) {
      state.subscriptionDetail.status = RequestStatus.ERROR;
      state.subscriptionDetail.error = action.payload;
    },
    addSubscription(
      state,
      action: PayloadAction<{ id: number; userId: number }>
    ) {
      state.addSubscription.status = RequestStatus.RUNNING;
    },
    addSubscriptionSuccess(state, action: PayloadAction<any>) {
      state.addSubscription.status = RequestStatus.SUCCESS;
      state.addSubscription.data = action.payload;
    },
    addSubscriptionFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addSubscription.status = RequestStatus.ERROR;
      state.addSubscription.error = action.payload;
    },
  },
});

export const subscriptionInformationReducer =
  SubscriptionInformationSlice.reducer;
export const {
  getSubscriptionDetail,
  getSubscriptionDetailSuccess,
  getSubscriptionDetailFailed,
  addSubscription,
  addSubscriptionSuccess,
  addSubscriptionFailed,
} = SubscriptionInformationSlice.actions;
