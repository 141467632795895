import { request } from "@app/services"

export const deleteEmployeeDocumentOfContractDetails = async (employeeId:string, id:string,documentUrl: string) => {
    try {
        const response = await request.delete(
            `/employee/${employeeId}/employee-declaration/${id}/?fileUrl=${documentUrl}`
        );
         
        return response; 
    } catch (error) {
        console.error("Error deleting document from contract details:", error);
        throw error; 
    }
};