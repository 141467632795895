import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatus } from "@app/constants/request";
import { IRequestFailed, IRequestState } from "@app/types";
import { IProjectFormSchema, IProjectState, ProjectPayload } from "./types";
import { update } from "lodash";

const initialState: IProjectState = {
  project: {
    status: RequestStatus.IDLE,
  },
  selectedProject: null,
  projectId: null,
};

const projectSlice = createSlice({
  initialState,
  name: "company",
  reducers: {
    addProject: (state, action: PayloadAction<ProjectPayload>) => {
      state.project.status = RequestStatus.RUNNING;
    },
    addProjectFailed: (state, action: PayloadAction<IRequestFailed>) => {
      state.project.status = RequestStatus.ERROR;
      state.project.error = action.payload;
    },
    addProjectSuccess(state, action) {
      state.project.status = RequestStatus.SUCCESS;
    },
    getProjects(state, action) {
      state.project.status = RequestStatus.RUNNING;
    },
    getProjectsSuccess(state, action: PayloadAction<IRequestState>) {
      state.project.data = action.payload.data;
      state.project.status = RequestStatus.SUCCESS;
    },
    getProjectsFailed(state, action: PayloadAction<IRequestFailed>) {
      state.project.error = action.payload;
      state.project.status = RequestStatus.ERROR;
    },
    selectProject(state, action) {
      state.selectedProject = action.payload;
    },
    deleteSelectedProject(state, action) {
      console.log("project --- ",state, action);
      state.projectId = action.payload;
    },
    updateProjectData(state, action: PayloadAction<IProjectFormSchema>) {
      state.project.status = RequestStatus.RUNNING;
    },
    updateProjectSuccess(state, action) {
      state.project.status = RequestStatus.SUCCESS;
      
    },
    updateProjectFailed(state, action: PayloadAction<IRequestFailed>) {
      state.project.error = action.payload;
      state.project.status = RequestStatus.ERROR;
    }
  },
});

export const projectReducer = projectSlice.reducer;

export const {
  addProject,
  addProjectFailed,
  addProjectSuccess,
  getProjects,
  getProjectsFailed,
  getProjectsSuccess,
  selectProject,
  deleteSelectedProject,
  updateProjectData,
  updateProjectFailed,
  updateProjectSuccess,
} = projectSlice.actions;
