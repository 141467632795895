import React from "react";
import {
  Box,
  Button,
  CardHeader,
  CardMedia,
  Card as MaterialUiCard,
  Stack,
  Typography,
} from "@mui/material";
// import { MdDeleteForever } from "react-icons/md";
import { Props } from "./types";
import { useDispatch } from "react-redux";
import {
  deleteSelectedCompany,
  getCompanies,
} from "@app/modules/company/slice";
import { Strings } from "@app/constants/strings";
import { enqueueSnackbar } from "notistack";
import { deleteSelectedProject, getProjects } from "@app/modules/project/slice";
import { useParams } from "react-router-dom";
import { DeleteForever } from "@mui/icons-material";
import { useDeleteAlert } from "@app/hooks/useAlertDialogue";

const Card = ({
  cardHeaderProps,
  cardProps,
  cardMediaProps,
  headerTitle,
  headerTitleStyle,
  imageUri,
  imageStyle,
  buttonText,
  buttonStyle,
  buttonVariant = "contained",
  onButtonClick,
  children,
  deleteBtn,
  cardId,
  cardType,
}: Props) => {
  const { showDeleteAlert } = useDeleteAlert();
  const dispatch = useDispatch();
  const { companyId } = useParams();

  const handleDelete = (cardId: any) => {
    showDeleteAlert({
      description: `Are you sure you want to delete 
        ${headerTitle}? This action cannot be undone.`,
      onConfirm: () => {
        let res: any;

        if (cardType === "company") {
          res = dispatch(deleteSelectedCompany(cardId));
        } else if (cardType === "project") {
          res = dispatch(deleteSelectedProject(cardId));
        }

        if (res && !res?.error) {
          enqueueSnackbar(
            `Successfully Deleted ${
              cardType === "company" ? "Company" : "Project"
            }`,
            {
              variant: "success",
            }
          );
        }
      },
    });
  };
  return (
    <MaterialUiCard
      {...cardProps}
      sx={{
        p: "25px",
        width: "457px",
        height: "415px",
        borderRadius: "5px",
        ...cardProps?.sx,
      }}
    >
      {headerTitle && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" sx={{ flexGrow: 1, textAlign: "center" }}>
            {headerTitle}
          </Typography>
          {deleteBtn === "active" && (
            <DeleteForever
              style={{ fontSize: "28px", cursor: "pointer" }}
              onClick={() => handleDelete(cardId)}
            />
          )}
        </div>
      )}

      {imageUri && (
        <Stack sx={{ height: "265px", width: "407px", marginTop: "10px" }}>
          <Box
            onClick={onButtonClick}
            component="img"
            src={imageUri}
            alt="Card image"
            sx={{
              cursor: "pointer",
              width: "100%",
              height: "100%",
              objectFit:
                buttonText === "Select All Projects" ||
                buttonText === "Add new project" ||
                buttonText === "Add new company"
                  ? "contain"
                  : "cover",
              border:
                buttonText === "Select All Projects" ||
                buttonText === "Add new project" ||
                buttonText === "Add new company"
                  ? "none"
                  : "1px solid #71797E",
              stroke: "none",
              ...imageStyle,
            }}
            {...cardMediaProps}
          />
        </Stack>
      )}

      {children}

      {buttonText && (
        <Button
          variant={buttonVariant}
          sx={{
            backgroundColor:
              buttonVariant === "contained" ? "#0177FB" : undefined,
            color: buttonVariant === "contained" ? "#fff" : undefined,
            width: "100%",
            marginTop: "14px",
            ...buttonStyle,
          }}
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      )}
    </MaterialUiCard>
  );
};

export default Card;
