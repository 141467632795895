import { useState } from "react";
import { Button, Divider, Stack } from "@mui/material";

import { SvgColor } from "@app/components/svg-color";
import { sourceUrl } from "@app/constants/source_url";
import ArticleTable from "./articleCategoryTable/articleTable";
import { AddCategory } from "./addCategory";

export const ArticleCategoryScreen = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Stack
      component={"div"}
      sx={{
        paddingTop: "30px",
        paddingBottom: "20px",
        border: "1px solid #F1F4F7",
      }}
    >
      <Stack
        component={"div"}
        sx={{
          display: "flex",
          gap: "20px",
        }}
      >
        <Stack
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: "20px",
            paddingLeft: "20px",
          }}
        >
          <Button
            variant="contained"
            startIcon={<SvgColor src={sourceUrl.icons.addIcon} />}
            sx={{ fontSize: "14px", fontWeight: 400 }}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Add New Category
          </Button>
        </Stack>
      </Stack>
      <Stack sx={{ width: "94%", margin: "20px" }}>
        <>
          <ArticleTable />
          <Divider sx={{ borderColor: "#F1F4F7" }} />
        </>
      </Stack>
      <AddCategory isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </Stack>
  );
};
