import { Button, Divider, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { SvgColor } from "@app/components/svg-color";
import { sourceUrl } from "@app/constants/source_url";

import { useDialog } from "@app/hooks/use-dialog";
import ContractTable from "./Table/Table";
import { AddNewContract } from "./Table";
import { getContractor } from "./slice";
import { useRequestState } from "@app/hooks/use-request-state";
import { selectContractor } from "./selector";
import TablePaginationCustom from "@app/components/table/TablePagination";

export const ContractScreen = () => {
  const dispatch = useDispatch();
  const addContractDialog = useDialog();
  const [pageValue, setPageValue] = useState(1);
  const [pageLimitValue, setPageLimitValue] = useState<number | string>(10);

  useEffect(() => {
    dispatch(
      getContractor({
        page: pageValue,
        limit: pageLimitValue,
      })
    );
  }, [dispatch, pageValue, pageLimitValue]);

  const { data: contract } = useRequestState({
    stateSelector: selectContractor,
  });

  return (
    <Stack
      component={"div"}
      sx={{
        paddingTop: "30px",
        paddingBottom: "20px",
        border: "1px solid #F1F4F7",
      }}
    >
      <Stack
        component={"div"}
        sx={{
          display: "flex",
          gap: "20px",
        }}
      >
        <Stack
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: "20px",
            paddingLeft: "20px",
          }}
        >
          <Button
            style={{
              fontWeight: 400,
              fontSize: "14px",
              borderRadius: "5px",
            }}
            variant="contained"
            startIcon={<SvgColor src={sourceUrl.icons.addIcon} />}
            onClick={() => {
              addContractDialog.show();
            }}
          >
            Add Contractor
          </Button>
        </Stack>
      </Stack>
      <Stack sx={{ width: "98%", margin: "19px" }}>
        {/* {contract && contract.data && ( */}
        <>
          <ContractTable data={contract ? contract : []} />
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <TablePaginationCustom
            onPageDenseChange={(e) => {
              setPageLimitValue(e.target.value);
            }}
            totalItems={contract?.totalItems}
            totalPage={contract?.totalPages}
            itemsPerPage={contract?.itemsPerPage}
            currentPage={contract?.currentPage}
            showingCustomText={contract?.showing}
            onPageChange={(e, currentPage) => {
              setPageValue(currentPage);
            }}
            sx={{
              background: "#fff",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
          />
        </>
        {/* )} */}
      </Stack>
      {addContractDialog.visible && (
        <AddNewContract open onClose={addContractDialog.hide} />
      )}
    </Stack>
  );
};
