import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RequestStatus } from "@app/constants/request";
import { IAddNewCategorySchema, IKnowledgeState } from "./types";
import { IRequestFailed } from "@app/types";

const initialState: IKnowledgeState = {
  addKnowledgeBase: {},
  addNewCategory: {},
  categories: {},
  knowledgeBase: {},
  knowledgeBaseCategory: {},
  deleteCategory: {},
  deleteKnowledgeBase: {},
};

const KnowledgeSlice = createSlice({
  initialState,
  name: "employee",
  reducers: {
    addKnowledgeBase(state, action) {
      state.addKnowledgeBase.status = RequestStatus.RUNNING;
    },
    addKnowledgeBaseFailed(state, action) {
      state.addKnowledgeBase.status = RequestStatus.ERROR;
      state.addKnowledgeBase.error = action.payload;
    },
    addKnowledgeBaseSuccess(state, action) {
      state.addKnowledgeBase.status = RequestStatus.SUCCESS;
      state.addKnowledgeBase.data = action.payload;
    },
    //
    addNewCategory(state, action: PayloadAction<IAddNewCategorySchema>) {
      state.addNewCategory.status = RequestStatus.RUNNING;
    },
    addNewCategorySuccess(state, action: PayloadAction<any>) {
      state.addNewCategory.status = RequestStatus.SUCCESS;
      state.addNewCategory.data = action.payload;
    },
    addNewCategoryFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addNewCategory.status = RequestStatus.ERROR;
      state.addNewCategory.error = action.payload;
    },
    //
    getCategories(
      state,
      action: PayloadAction<{
        page: number;
        limit?: number;
        companyId?: string;
      }>
    ) {
      state.categories.status = RequestStatus.RUNNING;
    },
    getCategoriesSuccess(
      state,
      action: PayloadAction<IKnowledgeState["categories"]["data"]>
    ) {
      state.categories.status = RequestStatus.SUCCESS;
      state.categories.data = action.payload;
    },
    getCategoriesFailed(state, action: PayloadAction<IRequestFailed>) {
      state.categories.status = RequestStatus.ERROR;
      state.categories.error = action.payload;
    },
    //
    getKnowledgeBase(state, action: PayloadAction<{ companyId: number }>) {
      state.knowledgeBase.status = RequestStatus.RUNNING;
    },
    getKnowledgeBaseSuccess(
      state,
      action: PayloadAction<IKnowledgeState["knowledgeBase"]["data"]>
    ) {
      state.knowledgeBase.status = RequestStatus.SUCCESS;
      state.knowledgeBase.data = action.payload;
    },
    getKnowledgeBaseFailed(state, action: PayloadAction<IRequestFailed>) {
      state.knowledgeBase.status = RequestStatus.ERROR;
      state.knowledgeBase.error = action.payload;
    },
    //
    getKnowledgeBaseCategory(
      state,
      action: PayloadAction<{ categoryId: number }>
    ) {
      state.knowledgeBaseCategory.status = RequestStatus.RUNNING;
    },
    getKnowledgeBaseCategorySuccess(
      state,
      action: PayloadAction<IKnowledgeState["knowledgeBaseCategory"]["data"]>
    ) {
      state.knowledgeBaseCategory.status = RequestStatus.SUCCESS;
      state.knowledgeBaseCategory.data = action.payload;
    },
    getKnowledgeBaseCategoryFailed(
      state,
      action: PayloadAction<IRequestFailed>
    ) {
      state.knowledgeBaseCategory.status = RequestStatus.ERROR;
      state.knowledgeBaseCategory.error = action.payload;
    },
    //
    deleteCategory(
      state,
      action: PayloadAction<{ id: number; companyId: string }>
    ) {
      state.deleteCategory.status = RequestStatus.RUNNING;
    },
    deleteCategorySuccess(state, action: PayloadAction<any>) {
      state.deleteCategory.status = RequestStatus.SUCCESS;
      state.deleteCategory.data = action.payload;
    },
    deleteCategoryFailed(state, action: PayloadAction<IRequestFailed>) {
      state.deleteCategory.status = RequestStatus.ERROR;
      state.deleteCategory.error = action.payload;
    },
    //
    deleteKnowledgeBase(
      state,
      action: PayloadAction<{ id: number; companyId: string }>
    ) {
      state.deleteKnowledgeBase.status = RequestStatus.RUNNING;
    },
    deleteKnowledgeBaseSuccess(state, action: PayloadAction<any>) {
      state.deleteKnowledgeBase.status = RequestStatus.SUCCESS;
      state.deleteKnowledgeBase.data = action.payload;
    },
    deleteKnowledgeBaseFailed(state, action: PayloadAction<IRequestFailed>) {
      state.deleteKnowledgeBase.status = RequestStatus.ERROR;
      state.deleteKnowledgeBase.error = action.payload;
    },
    //
  },
});

export const knowledgeReducer = KnowledgeSlice.reducer;
export const {
  addKnowledgeBase,
  addKnowledgeBaseSuccess,
  addKnowledgeBaseFailed,
  addNewCategory,
  addNewCategorySuccess,
  addNewCategoryFailed,
  getCategories,
  getCategoriesSuccess,
  getCategoriesFailed,
  getKnowledgeBase,
  getKnowledgeBaseSuccess,
  getKnowledgeBaseFailed,
  getKnowledgeBaseCategory,
  getKnowledgeBaseCategorySuccess,
  getKnowledgeBaseCategoryFailed,
  deleteCategory,
  deleteCategorySuccess,
  deleteCategoryFailed,
  deleteKnowledgeBase,
  deleteKnowledgeBaseFailed,
  deleteKnowledgeBaseSuccess,
} = KnowledgeSlice.actions;
