import { Box, Divider, Drawer, IconButton, Stack } from "@mui/material";
import React from "react";
import { SvgColor } from "../svg-color";
import { sourceUrl } from "@app/constants/source_url";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "@app/routes/paths";
import { SidebarProps } from "./types";
import { useSelector } from "react-redux";
import { selectSelectedCompany } from "@app/modules/company/selectors";

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/sidebar/${name}.svg`}
    sx={{ width: 24, height: 24, color: "#98A5B5" }}
  />
);

const ICONS = {
  dashboard: icon("dashboard"),
  reports: icon("reports"),
  management: icon("management"),
  attendance: icon("attendance"),
  employees: icon("employees"),
  expenses: icon("expenses"),
  knowledge_base: icon("knowledge_base"),
  messages: icon("messages"),
  payroll: icon("payroll"),
  recruitment: icon("recruitment"),
  tasks: icon("tasks"),
  tickets: icon("tickets"),
};

const sideBarNavigation = [
  {
    title: "Employees",
    icon: ICONS.employees,
    path: PATH_DASHBOARD.employeeList,
  },
  {
    title: "Knowledge Base",
    icon: ICONS.knowledge_base,
    path: PATH_DASHBOARD.knowledge,
  },
];
const SmallSidebar = ({ onHideSideBarButtonClick }: SidebarProps) => {
  const navigate = useNavigate();
  const company = useSelector(selectSelectedCompany);
  const handleClick = (item: any) => {
    item.path && navigate(item?.path);
  };
  return (
    <Drawer
      sx={{
        width: "50px",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: "50px",
          marginTop: "64px",
          boxSizing: "border-box",
          backgroundColor: "#171F29",
          "&::-webkit-scrollbar": {
            width: "8px", // Width of the scrollbar
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#171F22", // Color of the scrollbar thumb
            borderRadius: "4px", // Rounded corners
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555", // Color when hovered
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#171F29", // Background of the scrollbar track
          },
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Divider
        sx={{
          borderColor: "#fff",
        }}
      />
      <Stack
        sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <Box
          sx={{
            width: "50px",
            boxSizing: "border-box",
            height: "64px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "4px",
          }}
        >
          <Box
            component={"img"}
            height={"100%"}
            width={"100%"}
            src={company ? company.companySmallLogo : ""}
            sx={{ objectFit: "contain" }}
          />
        </Box>
        {sideBarNavigation.map((item) => (
          <IconButton
            sx={{
              borderRadius: "4px",
              marginTop: "14px",
              height: "48px",
              width: "48px",
            }}
            onClick={() => {
              handleClick(item);
            }}
          >
            {item.icon}
          </IconButton>
        ))}
        <IconButton
          onClick={() => {
            onHideSideBarButtonClick();
          }}
          color="primary"
          sx={{
            borderRadius: "4px",
            marginTop: "15px",
            color: "#307EF3",
          }}
        >
          <Icon icon="ep:arrow-right-bold" width={30} height={30} />
        </IconButton>
      </Stack>
    </Drawer>
  );
};

export default SmallSidebar;
