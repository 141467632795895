import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { Input } from "@app/components";
import { FormProvider } from "@app/components/hook-form";
import { RHFFilePickerField } from "@app/components/hook-form";
import { Strings } from "@app/constants/strings";

interface Props {
  open: any;
  onClose: any;
  onUploadFile: any;
}
const UploadFiles = ({ open, onClose, onUploadFile }: Props) => {
  const methods = useForm<any>();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = methods;
  const theme = useTheme();
  return (
    <Dialog
      open
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          width: "45%",
          maxWidth: "none",
        },
      }}
    >
      <FormProvider methods={methods}>
        <Stack sx={{ width: "100%" }}>
          <Typography sx={{ fontSize: "20px", fontWeight: 400, padding: 1.5 , lineHeight: "24px"}}>
            Upload Files
          </Typography>
          <Divider sx={{ color: "#F1F4F7" }} />
        </Stack>
        <Divider sx={{ borderColor: "#F1F4F7" }} />
        <Stack sx={{ px: 7 }}>
          <Stack
            sx={{
              padding: 2,
              boxShadow: `0px 4px 10px ${theme.palette.grey[400]}`,
              borderRadius: 1,
              margin: 2,
            }}
          >
            <Typography
              sx={{
                color: "#616E80",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "22px",
              }}
            >
              File Name
            </Typography>
            <Input
              name="fileName"
              control={control}
              placeHolder={Strings.field.inputPlaceholder("File Name")}
              error={errors.email?.message}
            />
            <Stack>
              <Typography
                sx={{
                  color: theme.palette.grey[600],
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "22px",
                }}
              >
                Upload File
              </Typography>
              <RHFFilePickerField
                name={"file"}
                label={"Choose a file"}
                control={control}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            paddingY: "8px",
            paddingX: "12px",
            boxShadow: `10px 0px 0px ${theme.palette.grey[800]}`,
          }}
        >
          <Button variant="contained" onClick={onClose} sx={{backgroundColor:"#FE6B6B", color:"#fff", width:"66px", height:"34px" , fontSize:"14px", fontWeight:"400"}} >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit(onUploadFile)} sx={{width:"92px", height:"34px", fontSize:"14px", fontWeight:"400", textWrap:"nowrap"}}>
            Upload File
          </Button>
        </Stack>
      </FormProvider>
    </Dialog>
  );
};
export { UploadFiles };
