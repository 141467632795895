import { type ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { bootstrapApp } from "../slice";
import { selectIsAppReady } from "../selectors";
import { selectIsAuthenticated } from "@app/modules/common";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH_AUTH } from "@app/routes/paths";

interface Props {
  children: ReactElement;
}

const BootstrapGate = ({ children }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isAppReady = useSelector(selectIsAppReady);
  console.log("location", location);

  useEffect(() => {
    dispatch(bootstrapApp());
  }, [dispatch]);

  useEffect(() => {
    const isAuthPage =
      location.pathname.startsWith(PATH_AUTH.login) ||
      location.pathname.startsWith(PATH_AUTH.register);

    // Don't redirect if already on login/register
    if (!isAuthenticated && !isAuthPage) {
      navigate(PATH_AUTH.login, { state: { from: location }, replace: true });
    }
  }, [isAuthenticated, location, navigate]);

  if (!isAppReady) return null;

  return children;
};

export { BootstrapGate };
