import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { get } from "lodash";

import { addDesignationAction, getDesignationAction } from "../slice";
import { ICreateDesignationPayload } from "./types";
import { selectAddDesignation, selectDesignation } from "../selectors";
import { Input } from "@app/components";
import { useRequestState } from "@app/hooks/use-request-state";
import { LoadingButton } from "@mui/lab";
import { Strings } from "@app/constants/strings";

const schema = yup.object().shape({
  name: yup.string().required("Designation name required"),
});

const AddNewDesignation = ({ open, onClose, data }: any) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ICreateDesignationPayload>({
    resolver: yupResolver(schema),
    defaultValues: {
      id: get(data, "id", ""),
      name: get(data, "name", ""),
      parentDesignationId: get(data?.parentDesignation, "id", ""),
    },
  });

  const dispatch = useDispatch();

  const handleSaveDesignation = (data: any) => {
    dispatch(
      addDesignationAction({ ...data, callback: handleAfterSaveSuccess })
    );
  };

  const handleAfterSaveSuccess = () => {
    reset();
    onClose();
  };

  const { loading } = useRequestState({
    stateSelector: selectAddDesignation,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  const designation = useSelector(selectDesignation);

  useEffect(() => {
    dispatch(getDesignationAction({ page: 1, limit: 1000 }));
  }, [dispatch]);

  return (
    <Dialog open={open}>
      <Stack
        sx={{
          height: "53px",
          width: "100%",
          boxShadow: "0px 4px 4px #F1F4F7",
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: 400, padding: "5px" }}>
          Create Designation
        </Typography>
      </Stack>
      <Divider sx={{ color: "#F1F4F7" }} />
      <Card sx={{ padding: "0px" }}>
        <CardContent
          sx={{
            padding: "0px",
            ":last-child": {
              paddingBottom: "0px",
            },
          }}
          component={"form"}
          onSubmit={handleSubmit(handleSaveDesignation)}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              paddingX: "20px",
              paddingY: "12px",
            }}
          >
            <Box sx={{ width: "100%", minWidth: "400px" }}>
              <Typography>Designation Name</Typography>
              <Input
                name="name"
                control={control}
                placeHolder={Strings.field.inputPlaceholder("Designation Name")}
                error={errors.name?.message}
              />
            </Box>
            <Box sx={{ width: "100%", minWidth: "400px" }}>
              <Typography>Parent Designation</Typography>
              <Controller
                name={"parentDesignationId"}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    name="parentDesignationId"
                    sx={{ width: "100%" }}
                  >
                    {designation &&
                      designation?.data?.length > 0 &&
                      designation?.data?.map((item: any) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))}
                  </Select>
                )}
              />
            </Box>
          </Box>
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "22px",
              paddingY: "10px",
            }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                onClose();
              }}
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                backgroundColor: "#FE6B6B",
              }}
            >
              Cancel
            </Button>
            <Box sx={{ display: "flex", gap: 2 }}>
              <LoadingButton
                loading={loading}
                variant="contained"
                type="submit"
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  backgroundColor: "#0177FB",
                }}
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Dialog>
  );
};

export { AddNewDesignation };
