import { RequestStatus } from "@app/constants/request";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRequestFailed, IRequestState } from "@app/types";
import { IAccountInformationState } from "./types";

const initialState: IAccountInformationState = {
  changePassword: {},
  changeProfile: {},
  user: {},
  changeCompanyProfile: {},
  companyDocuments: {},
  companyUsers: {},
  projectDocuments: {},
  generalSettings: {},
  updateGeneralSettings: {},
  projectUsers: {},
  onboardingQuestionnaire: {},
  generalList: {},
  systemUsers: {},
};

const AccountInformationSlice = createSlice({
  initialState,
  name: "accountInformation",
  reducers: {
    changePassword(
      state,
      action: PayloadAction<{ oldPassword: number; newPassword?: any }>
    ) {
      state.changePassword.status = RequestStatus.RUNNING;
    },
    changePasswordSuccess(state, action: PayloadAction<any>) {
      state.changePassword.status = RequestStatus.SUCCESS;
      state.changePassword.data = action.payload;
    },
    changePasswordFailed(state, action: PayloadAction<IRequestFailed>) {
      state.changePassword.status = RequestStatus.ERROR;
      state.changePassword.error = action.payload;
    },
    changeProfilePicture(state, action: PayloadAction<{ file: any }>) {
      state.changeProfile.status = RequestStatus.RUNNING;
    },
    changeProfilePictureSuccess(state, action: PayloadAction<any>) {
      state.changeProfile.status = RequestStatus.SUCCESS;
      state.changeProfile.data = action.payload;
    },
    changeProfilePictureFailed(state, action: PayloadAction<IRequestFailed>) {
      state.changeProfile.status = RequestStatus.ERROR;
      state.changeProfile.error = action.payload;
    },
    getUser(state) {
      state.user.status = RequestStatus.RUNNING;
    },
    getUserSuccess(state, action: PayloadAction<IRequestState>) {
      state.user.data = action.payload.data;
      state.user.status = RequestStatus.SUCCESS;
    },
    getUserFailed(state, action: PayloadAction<IRequestFailed>) {
      state.user.data = action.payload;
      state.user.status = RequestStatus.ERROR;
    },
    updateProfile(state, action: PayloadAction<any>) {
      state.user.status = RequestStatus.RUNNING;
    },
    updateProfileSuccess(state, action: PayloadAction<any>) {
      state.user.status = RequestStatus.SUCCESS;
    },
    updateProfileFailed(state, action: PayloadAction<any>) {
      state.user.status = RequestStatus.ERROR;
    },
    updateCompanyProfile(
      state,
      action: PayloadAction<{ id: number; data: any }>
    ) {
      state.changeCompanyProfile.status = RequestStatus.RUNNING;
    },
    updateCompanyProfileSuccess(state, action: PayloadAction<any>) {
      state.changeCompanyProfile = action.payload.data;
      state.changeCompanyProfile.status = RequestStatus.SUCCESS;
    },
    updateCompanyProfileFailed(state, action: PayloadAction<any>) {
      state.changeCompanyProfile.data = action.payload;
      state.changeCompanyProfile.status = RequestStatus.ERROR;
    },
    createCompanyDocuments(state, action: PayloadAction<any>) {
      state.companyDocuments.status = RequestStatus.RUNNING;
    },
    createCompanyDocumentsSuccess(state, action: PayloadAction<any>) {
      state.companyDocuments.status = RequestStatus.SUCCESS;
      state.companyDocuments.data = action.payload;
    },
    createCompanyDocumentsFailed(state, action: PayloadAction<any>) {
      state.companyDocuments.status = RequestStatus.ERROR;
    },
    getCompanyDocuments(state) {
      state.companyDocuments.status = RequestStatus.RUNNING;
    },
    getCompanyDocumentsSuccess(state, action: PayloadAction<any>) {
      state.companyDocuments.status = RequestStatus.SUCCESS;
      state.companyDocuments.data = action.payload;
    },
    getCompanyDocumentsFailed(state, action: PayloadAction<any>) {
      state.companyDocuments.status = RequestStatus.ERROR;
    },

    // General Settings Reducers
    updateGeneralSettings: (
      state,
      action: PayloadAction<{ genId: any; data: any }>
    ) => {
      state.updateGeneralSettings.status = RequestStatus.RUNNING;
    },

    updateGeneralSettingsSuccess(state, action: PayloadAction<any>) {
      state.updateGeneralSettings.status = RequestStatus.SUCCESS;
      state.updateGeneralSettings.data = action.payload;
    },
    updateGeneralSettingsFailed(state, action: PayloadAction<IRequestFailed>) {
      state.updateGeneralSettings.status = RequestStatus.ERROR;
      state.updateGeneralSettings.error = action.payload;
    },
    getGeneralSettings(state, action: PayloadAction<{ id: any }>) {
      state.generalSettings.status = RequestStatus.RUNNING;
    },
    getGeneralSettingsSuccess(state, action: PayloadAction<any>) {
      state.generalSettings.status = RequestStatus.SUCCESS;
      state.generalSettings.data = action.payload;
    },
    getGeneralSettingsFailed(state, action: PayloadAction<IRequestFailed>) {
      state.generalSettings.status = RequestStatus.ERROR;
    },
    // createCompanyUsers(state, action: PayloadAction<any>) {
    //   state.companyUsers.status = RequestStatus.RUNNING;
    // },

    // createCompanyUsersSuccess(state, action: PayloadAction<any>) {
    //   state.companyUsers.status = RequestStatus.SUCCESS;
    //   state.companyUsers.data = action.payload;
    // },
    // createCompanyUsersFailed(state, action: PayloadAction<any>) {
    //   state.companyUsers.status = RequestStatus.ERROR;
    // },
    // getCompanyUsers(state, action: PayloadAction<any>) {
    //   state.companyUsers.status = RequestStatus.RUNNING;
    //   state.companyUsers.data = action.payload;
    // },
    // getCompanyUsersSuccess(state, action: PayloadAction<any>) {
    //   state.companyUsers.status = RequestStatus.SUCCESS;
    //   state.companyUsers.data = action.payload;
    // },
    // getCompanyUsersFailed(state, action: PayloadAction<any>) {
    //   state.companyUsers.status = RequestStatus.ERROR;
    // },
    
    createProjectDocuments(state, action: PayloadAction<any>) {
      state.projectDocuments.status = RequestStatus.RUNNING;
    },
    createProjectDocumentsSuccess(state, action: PayloadAction<any>) {
      state.projectDocuments.status = RequestStatus.SUCCESS;
      state.projectDocuments.data = action.payload;
    },
    createProjectDocumentsFailed(state, action: PayloadAction<any>) {
      state.projectDocuments.status = RequestStatus.ERROR;
    },
    getProjectDocuments(state) {
      state.projectDocuments.status = RequestStatus.RUNNING;
    },
    getProjectDocumentsSuccess(state, action: PayloadAction<any>) {
      state.projectDocuments.status = RequestStatus.SUCCESS;
      state.projectDocuments.data = action.payload;
    },
    getProjectDocumentsFailed(state, action: PayloadAction<any>) {
      state.projectDocuments.status = RequestStatus.ERROR;
    },
    getProjectUsers(state, action: PayloadAction<any>) {
      state.projectUsers.status = RequestStatus.RUNNING;
    },
    getProjectUsersSuccess(state, action: PayloadAction<any>) {
      state.projectUsers.status = RequestStatus.SUCCESS;
      state.projectUsers.data = action.payload;
    },
    getProjectUsersFailed(state, action: PayloadAction<any>) {
      state.projectUsers.status = RequestStatus.ERROR;
    },

    // General List Reducers
    getGeneralList(state, action: PayloadAction<{ id: number }>) {
      state.generalList.status = RequestStatus.RUNNING;
    },
    getGeneralListSuccess(state, action: PayloadAction<any>) {
      state.generalList.status = RequestStatus.SUCCESS;
      state.generalList.data = action.payload;
    },
    getGeneralListFailed(state, action: PayloadAction<IRequestFailed>) {
      state.generalList.status = RequestStatus.ERROR;
      state.generalList.error = action.payload;
    },

    postGeneralList(state, action: PayloadAction<any>) {
      state.generalList.status = RequestStatus.RUNNING;
    },
    postGeneralListSuccess(state, action: PayloadAction<any>) {
      state.generalList.status = RequestStatus.SUCCESS;
      state.generalList.data = action.payload;
    },
    postGeneralListFailed(state, action: PayloadAction<IRequestFailed>) {
      state.generalList.status = RequestStatus.ERROR;
      state.generalList.error = action.payload;
    },

    deleteGeneralList(state, action: PayloadAction<{ id: any }>) {
      state.generalList.status = RequestStatus.RUNNING;
    },
    deleteGeneralListSuccess(state, action: PayloadAction<any>) {
      state.generalList.status = RequestStatus.SUCCESS;
      state.generalList.data = action.payload;
    },
    deleteGeneralListFailed(state, action: PayloadAction<IRequestFailed>) {
      state.generalList.status = RequestStatus.ERROR;
      state.generalList.error = action.payload;
    },
    getOnboardingQuestionnaire(state, action: PayloadAction<{ id: number }>) {
      state.onboardingQuestionnaire.status = RequestStatus.RUNNING;
    },
    getOnboardingQuestionnaireSuccess(state, action: PayloadAction<any>) {
      state.onboardingQuestionnaire.status = RequestStatus.SUCCESS;
      state.onboardingQuestionnaire.data = action.payload;
    },
    getOnboardingQuestionnaireFailed(state, action: PayloadAction<any>) {
      state.onboardingQuestionnaire.status = RequestStatus.ERROR;
    },
    updateOnboardingQuestionnaire(state, action: PayloadAction<any>) {
      state.onboardingQuestionnaire.status = RequestStatus.RUNNING;
    },
    updateOnboardingQuestionnaireSuccess(state, action: PayloadAction<any>) {
      state.onboardingQuestionnaire.status = RequestStatus.SUCCESS;
      state.onboardingQuestionnaire.data = action.payload;
    },
    updateOnboardingQuestionnaireFailed(state, action: PayloadAction<any>) {
      state.onboardingQuestionnaire.status = RequestStatus.ERROR;
    },
    getCompanyUsers(state, action: PayloadAction<{ companyId: number }>) {
      state.companyUsers.status = RequestStatus.RUNNING;
    },
    getCompanyUsersSuccess(state, action: PayloadAction<any>) {
      state.companyUsers.status = RequestStatus.SUCCESS;
      state.companyUsers.data = action.payload; // Expecting array from API
    },
    getCompanyUsersFailed(state, action: PayloadAction<IRequestFailed>) {
      state.companyUsers.status = RequestStatus.ERROR;
      state.companyUsers.error = action.payload;
    },

    postCompanyUser(state, action: PayloadAction<any>) {
      state.companyUsers.status = RequestStatus.RUNNING;
    },
    postCompanyUserSuccess(state, action: PayloadAction<any>) {
      state.companyUsers.status = RequestStatus.SUCCESS;
      state.companyUsers.data = Array.isArray(state.companyUsers.data)
        ? [...state.companyUsers.data, action.payload]
        : [action.payload];
    },
    postCompanyUserFailed(state, action: PayloadAction<IRequestFailed>) {
      state.companyUsers.status = RequestStatus.ERROR;
      state.companyUsers.error = action.payload;
    },

    // Add these new actions to your slice reducers
    deleteCompanyUser(state, action: PayloadAction<{ userId: string, companyId: string }>) {
      state.companyUsers.status = RequestStatus.RUNNING;
    },
    deleteCompanyUserSuccess(state, action: PayloadAction<any>) {
      state.companyUsers.status = RequestStatus.SUCCESS;
      state.companyUsers.data = state.companyUsers.data.filter(
        (user: any) => user.id !== action.payload.userId
      );
    },
    deleteCompanyUserFailed(state, action: PayloadAction<IRequestFailed>) {
      state.companyUsers.status = RequestStatus.ERROR;
      state.companyUsers.error = action.payload;
    },

    updateCompanyUser(state, action: PayloadAction<{ userId: string; data: any }>) {
      state.companyUsers.status = RequestStatus.RUNNING;
    },
    updateCompanyUserSuccess(state, action: PayloadAction<any>) {
      state.companyUsers.status = RequestStatus.SUCCESS;
      state.companyUsers.data = state.companyUsers.data.map((user: any) =>
        user.id === action.payload.id ? action.payload : user
      );
    },
    updateCompanyUserFailed(state, action: PayloadAction<IRequestFailed>) {
      state.companyUsers.status = RequestStatus.ERROR;
      state.companyUsers.error = action.payload;
    },
    // system users reducers
    getSystemUsers(state) {
      state.companyUsers.status = RequestStatus.RUNNING;
    },
    getSystemUsersSuccess(state, action: PayloadAction<any>) {
      state.companyUsers.status = RequestStatus.SUCCESS;
      state.companyUsers.data = action.payload;
    },
    getSystemUsersFailed(state, action: PayloadAction<IRequestFailed>) {
      state.companyUsers.status = RequestStatus.ERROR;
      state.companyUsers.error = action.payload;
    },

    deleteSystemUser(state, action: PayloadAction<string>) {
      state.companyUsers.status = RequestStatus.RUNNING;
    },
    deleteSystemUserSuccess(state, action: PayloadAction<string>) {
      state.companyUsers.status = RequestStatus.SUCCESS;
      state.companyUsers.data = state.companyUsers.data.filter(
        (user: any) => user.id !== action.payload
      );
    },
    deleteSystemUserFailed(state, action: PayloadAction<IRequestFailed>) {
      state.companyUsers.status = RequestStatus.ERROR;
      state.companyUsers.error = action.payload;
    },
  },
});

export const accountInformationReducer = AccountInformationSlice.reducer;
export const {
  changePassword,
  changePasswordFailed,
  changePasswordSuccess,
  changeProfilePicture,
  changeProfilePictureSuccess,
  changeProfilePictureFailed,
  getUser,
  getUserSuccess,
  getUserFailed,
  updateProfile,
  updateProfileSuccess,
  updateProfileFailed,
  updateCompanyProfile,
  updateCompanyProfileSuccess,
  updateCompanyProfileFailed,
  createCompanyDocuments,
  getCompanyDocuments,
  getCompanyDocumentsSuccess,
  getCompanyDocumentsFailed,
  createCompanyDocumentsFailed,
  createCompanyDocumentsSuccess,
  // createCompanyUsers,
  // createCompanyUsersSuccess,
  // createCompanyUsersFailed,
  // getCompanyUsers,
  // getCompanyUsersSuccess,
  // getCompanyUsersFailed
  createProjectDocuments,
  createProjectDocumentsSuccess,
  createProjectDocumentsFailed,
  getProjectDocuments,
  getProjectDocumentsSuccess,
  getProjectDocumentsFailed,

  updateGeneralSettings,
  updateGeneralSettingsSuccess,
  updateGeneralSettingsFailed,
  getGeneralSettings,
  getGeneralSettingsSuccess,
  getGeneralSettingsFailed,
  getProjectUsers,
  getProjectUsersSuccess,
  getProjectUsersFailed,
  getGeneralList,
  getGeneralListSuccess,
  getGeneralListFailed,
  postGeneralList,
  postGeneralListSuccess,
  postGeneralListFailed,
  deleteGeneralList,
  deleteGeneralListSuccess,
  deleteGeneralListFailed,
  getOnboardingQuestionnaire,
  getOnboardingQuestionnaireSuccess,
  getOnboardingQuestionnaireFailed,
  updateOnboardingQuestionnaire,
  updateOnboardingQuestionnaireSuccess,
  updateOnboardingQuestionnaireFailed,

  getCompanyUsers,
  getCompanyUsersSuccess,
  getCompanyUsersFailed,
  postCompanyUser,
  postCompanyUserSuccess,
  postCompanyUserFailed,
  deleteCompanyUser,
  deleteCompanyUserSuccess,
  deleteCompanyUserFailed,
  updateCompanyUser,
  updateCompanyUserSuccess,
  updateCompanyUserFailed,
  getSystemUsers,
  getSystemUsersSuccess,
  getSystemUsersFailed,
  deleteSystemUser,
  deleteSystemUserSuccess,
  deleteSystemUserFailed,
} = AccountInformationSlice.actions;
