import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { selectCompanyId } from "../company/selectors";
import {
  getContractor,
  getContractorSuccess,
  getContractorFailed,
  addContractorSuccess,
  addContractorFailed,
  addContractor,
  deleteContractor,
  deleteContractorFailed,
  deleteContractorSuccess,
} from "./slice";
import { request } from "@app/services";
import * as endpoint from "./endpoints";
import { selectContractor } from "./selector";

function* getContractorSaga(action: any) {
  try {
    const companyId: string = yield select(selectCompanyId);

    const { page, limit } = action.payload;
    if (companyId) {
      const { data } = yield call(
        request.get,
        `${endpoint.getContractor}${companyId}?page=${page}&limit=${limit}`
      );
      yield put(getContractorSuccess({ data }));
    }
  } catch (error: any) {
    yield put(getContractorFailed(error));
  }
}
function* addContractorSagas(action: ReturnType<typeof addContractor>): any {
  try {
    const { name, contractorId } = action.payload;

    const companyId: string = yield select(selectCompanyId);
    let result;

    if (contractorId) {
      result = yield call(request.put, endpoint.updateContract + contractorId, {
        name,
      });
    } else {
      result = yield call(request.post, endpoint.addContract, {
        name,
        companyId,
      });
    }

    yield put(addContractorSuccess(result.message));
    yield put(getContractor({ page: 1, limit: 1000 }));
  } catch (err: any) {
    yield put(addContractorFailed(err));
  }
}

function* deleteContractorSaga(
  action: ReturnType<typeof deleteContractor>
): any {
  try {
    const { id } = action.payload;
    const result = yield call(request.delete, endpoint.deleteContractor + id);
    const existingContract = yield select(selectContractor);

    const updatedData = {
      ...existingContract,
      data: existingContract?.data?.data?.filter(
        (contract: any) => contract.id !== id
      ),
    };

    yield put(getContractorSuccess({ data: updatedData }));

    yield put(deleteContractorSuccess(result.message));
  } catch (err: any) {
    yield put(deleteContractorFailed(err));
  }
}

export function* contractorSaga() {
  yield all([
    takeLatest(getContractor, getContractorSaga),
    takeLatest(addContractor, addContractorSagas),
    takeLatest(deleteContractor, deleteContractorSaga),
  ]);
}
