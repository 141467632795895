import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AppTableHeadCell from "./AppTableHeadCell";

import { Icon } from "@iconify/react";
import { IndicatorCircle } from "@app/components/CircleIndicator";
import UpDownIcon from "./UpDownIcon";
import { RowActions } from "./row-action";

interface Props {
  data: any;
}

const DepartmentTable = ({ data }: Props) => {
  const [selectedRows, setSelectedRows] = useState<readonly string[]>([]);
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data?.data
        ? data?.data?.length > 0
          ? data.data.map((n: any) => n.id)
          : []
        : [];
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
  };

  const handleSelectSingleDepartmentCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string | number
  ) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, id.toString()]);
      return;
    }
    setSelectedRows((prev) => prev.filter((item) => item !== id));
  };

  return (
    <TableContainer
      sx={{
        backgroundColor: "#FFFFFF",
        padding: "10px",
        borderRadius: "0px",
        borderTopRightRadius: "5px",
        borderTopLeftRadius: "5px",
        minHeight: "600px",
        "&::-webkit-scrollbar": {
          display: "none", // Chrome, Safari, Edge
        },
      }}
    >
      <Table sx={{ minWidth: 500 }}>
        <TableHead sx={{ borderBottom: "1px", borderBottomColor: "red" }}>
          <TableRow>
            <TableCell
              width="20px"
              sx={{
                backgroundColor: "#fff",
                padding: "0px",
                textAlign: "center", // Ensures horizontal centering
                verticalAlign: "middle", // Ensures vertical centering
              }}
            >
              {data.data && (
                <Checkbox
                  size="small"
                  sx={{
                    color: "#616E80",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  checked={
                    data?.data.length > 0 &&
                    selectedRows.length === data.data.length
                  }
                  onChange={handleSelectAllClick}
                />
              )}
            </TableCell>

            <AppTableHeadCell
              cellProps={{
                sx: {
                  minWidth: "170px",
                  padding: "0px 12px",
                  background: "#fff",
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "#616E80",
                },
              }}
              title="Department ID"
              icon={null}
            />
            <AppTableHeadCell
              cellProps={{
                sx: {
                  minWidth: "100px",
                  padding: "0px 12px",
                  background: "#fff",
                },
              }}
              title="Department Name"
              icon={null}
            />
            <AppTableHeadCell
              cellProps={{
                sx: {
                  minWidth: "290px",
                  padding: "0px 12px",
                  background: "#fff",
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "#616E80",
                },
              }}
              title="Parent Department"
            />
            <AppTableHeadCell
              cellProps={{
                sx: {
                  minWidth: "20px",
                  padding: "0px 12px",
                  background: "#fff",
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "#616E80",
                },
                align: "left",
              }}
              title="Action"
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.data && data.data.length > 0 ? (
            data.data.map((item: any) => (
              <TableRow key={item.id}>
                {/* Checkbox Column */}
                <TableCell
                  width={50}
                  sx={{ padding: "8px", textAlign: "center" }}
                >
                  <Checkbox
                    checked={selectedRows.includes(item.id)}
                    size="small"
                    sx={{ color: "#616E80" }}
                    inputProps={{ "aria-label": "select department" }}
                    onChange={(e) =>
                      handleSelectSingleDepartmentCheck(e, item.id)
                    }
                  />
                </TableCell>

                {/* Department ID Column */}
                <TableCell
                  width={80}
                  sx={{ padding: "8px", textAlign: "left" }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.id}
                  </Typography>
                </TableCell>

                {/* Department Name Column */}
                <TableCell
                  sx={{
                    padding: "8px",
                    textAlign: "left",
                    verticalAlign: "middle",
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                    {item.name}
                  </Typography>
                </TableCell>

                {/* Parent Department Column */}
                <TableCell sx={{ padding: "8px", textAlign: "left" }}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                    {item?.parentDepartment?.name ?? "N/A"}
                  </Typography>
                </TableCell>

                {/* Actions Column */}
                <TableCell
                  width={50}
                  sx={{ padding: "8px", textAlign: "right" }}
                >
                  <RowActions data={item} />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={5}
                sx={{ textAlign: "center", padding: "16px" }}
              >
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 500, color: "#999" }}
                >
                  No Departments Found
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DepartmentTable;
