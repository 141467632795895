import {
  Box,
  Button,
  CardContent,
  Divider,
  Stack,
  Card,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  TextField,
} from "@mui/material";
import EmployeeTable from "../../components/table/Table";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "@app/routes/paths";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllEmployee,
  selectAllEmployeeMeta,
  selectOnboardingAllEmployee,
  selectOnboardingAllEmployeeMeta,
} from "../add_employee/selector";
import { useEffect, useState } from "react";
import {
  getOnboardingEmployees,
  setDuplicateNull,
} from "../add_employee/slice";
import { SvgColor } from "@app/components/svg-color";
import { sourceUrl } from "@app/constants/source_url";
import TablePaginationCustom from "@app/components/table/TablePagination";
import { FilterList, Search } from "@mui/icons-material";
import { selectSelectedProject } from "../project/selectors";
import { Spacing } from "@app/components/spacing";

export const EmployeeOnboardingScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const employees = useSelector(selectOnboardingAllEmployee);
  const employeesGetMeta = useSelector(selectOnboardingAllEmployeeMeta);
  const [pageValue, setPageValue] = useState(1);
  const [pageLimitValue, setPageLimitValue] = useState<number | string>(10);
  const selectedProject: any = useSelector(selectSelectedProject);

  useEffect(() => {
    dispatch(
      getOnboardingEmployees({
        page: pageValue,
        limit: pageLimitValue,
      })
    );
  }, [dispatch, pageValue, pageLimitValue, selectedProject]);

  return (
    <Stack
      component={"div"}
      sx={{
        paddingBottom: "20px",
        paddingTop: "6px",
      }}
    >
      <Stack
        component={"div"}
        sx={{
          display: "flex",
          gap: "20px",
        }}
      >
        <Stack sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "40px",
              alignItems: "center",
              backgroundColor: "#FFFFFF",
            }}
          >
            {/* Employee Filter */}
            <Typography
              sx={{
                color: "#616E80",
                fontSize: "12px",
                fontWeight: "500",
                marginLeft: "10px",
              }}
            >
              {"Employee"}
            </Typography>
            <FormControl sx={{ width: "18%" }}>
              <InputLabel
                sx={{
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "500",
                  mt: 0.9,
                }}
              >
                All Employees
              </InputLabel>
              <Select
                defaultValue=""
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    borderRadius: "0px",
                  },
                  "& .MuiOutlinedInput-root": {},
                  "& .MuiSelect-icon": {
                    color: "black",
                    width: "30px",
                    height: "20px",
                    right: "-3px",
                  },
                  borderRight: "1px solid #F1F4F7",
                  fontSize: "12px",
                  fontWeight: "500",
                  paddingY: "5px",
                }}
              >
                <MenuItem
                  value="employee1"
                  sx={{
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  Employee 1
                </MenuItem>
                <MenuItem
                  value="employee2"
                  sx={{
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  Employee 2
                </MenuItem>
              </Select>
            </FormControl>

            {/* Role Filter */}
            <Typography
              sx={{
                color: "#616E80",
                fontSize: "12px",
                fontWeight: "500",
                marginLeft: "20px",
              }}
            >
              {"Role"}
            </Typography>
            <FormControl sx={{ width: "18%" }}>
              <InputLabel
                sx={{
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "500",
                  mt: 0.9,
                }}
              >
                All Roles
              </InputLabel>
              <Select
                defaultValue=""
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    borderRadius: "0px",
                  },
                  "& .MuiSelect-icon": {
                    color: "black",
                    width: "30px",
                    height: "20px",
                  },
                  "& .MuiOutlinedInput-root": {},
                  borderRight: "1px solid #F1F4F7",
                  fontSize: "12px",
                  fontWeight: "500",
                  paddingY: "5px",
                }}
              >
                <MenuItem
                  value="role1"
                  sx={{
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  Role 1
                </MenuItem>
                <MenuItem
                  value="role2"
                  sx={{
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  Role 2
                </MenuItem>
              </Select>
            </FormControl>

            {/* Designation Filter */}
            <Typography
              sx={{
                color: "#616E80",
                fontSize: "12px",
                fontWeight: "500",
                marginLeft: "10px",
              }}
            >
              {"Designation"}
            </Typography>
            <FormControl sx={{ width: "18%" }}>
              <InputLabel
                sx={{
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "500",
                  mt: 0.9,
                }}
              >
                All Designations
              </InputLabel>
              <Select
                defaultValue=""
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    borderRadius: "0px",
                  },
                  "& .MuiSelect-icon": {
                    color: "black",
                    width: "30px",
                    height: "20px",
                    right: "-5px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "25px",
                  },
                  borderRight: "1px solid #F1F4F7",
                  fontSize: "12px",
                  fontWeight: "500",
                  paddingY: "5px",
                }}
              >
                <MenuItem
                  value="designation1"
                  sx={{
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  Designation 1
                </MenuItem>
                <MenuItem
                  value="designation2"
                  sx={{
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  Designation 2
                </MenuItem>
              </Select>
            </FormControl>
            <Spacing right={-4} />
            {/* Department Filter */}
            <Typography
              sx={{
                color: "#616E80",
                fontSize: "12px",
                fontWeight: "500",
                marginLeft: "20px",
              }}
            >
              {"Department"}
            </Typography>
            <FormControl sx={{ width: "18%" }}>
              <InputLabel
                sx={{
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "500",
                  mt: 0.9,
                }}
              >
                All Departments
              </InputLabel>
              <Select
                defaultValue=""
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    borderRadius: "0px",
                  },
                  "& .MuiSelect-icon": {
                    color: "black",
                    width: "30px",
                    height: "20px",
                    right: "-5px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "45px",
                  },
                  borderRight: "1px solid #F1F4F7",
                  fontSize: "12px",
                  fontWeight: "500",
                  paddingY: "5px",
                }}
              >
                <MenuItem
                  value="department1"
                  sx={{
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  Department 1
                </MenuItem>
                <MenuItem
                  value="department2"
                  sx={{
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  Department 2
                </MenuItem>
              </Select>
            </FormControl>
            <Spacing right={-2} />

            {/* Search Bar */}
            <Stack
              sx={{
                margin: "10px",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Search
                  sx={{
                    color: "black",
                    backgroundColor: "#F1F4F7",
                    height: "24px",
                    width: "33px",
                  }}
                />
                <TextField
                  variant="outlined"
                  placeholder="Search Keywords"
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                      width: "20%",
                    },
                    "& .MuiOutlinedInput-root": {
                      height: "20px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: "12px",
                    },
                    border: "1px solid #F1F4F7",
                  }}
                />
              </Stack>
            </Stack>

            {/* Filter Button */}
            <IconButton
              sx={{
                borderLeft: "1px solid #F1F4F7",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <SvgColor
                  src={sourceUrl.icons.filter}
                  sx={{ width: "33px", height: "24px" }}
                />
                <Typography
                  sx={{
                    color: "#616E80",
                    fontSize: "12px",
                    fontWeight: "400",
                    mt: 0.5,
                  }}
                >
                  Filters
                </Typography>
              </Stack>
            </IconButton>
          </Box>
        </Stack>
        <Stack
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            paddingLeft: "20px",
            paddingRight: "20px",
            gap: "25px",
          }}
        >
          <Card
            sx={{
              padding: "0px",
              height: "90px",
              width: "34%",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
            }}
          >
            <CardContent
              sx={{
                padding: "0px",
                ":last-child": {
                  paddingBottom: "0px",
                },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "16px",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 500, fontSize: "16px", color: "#000000" }}
                  >
                    Total Number Candidates
                  </Typography>

                  <Typography
                    sx={{ color: "#4084EF", fontSize: "16px", fontWeight: 500 }}
                  >
                    {employeesGetMeta ? employeesGetMeta.totalItems : 0}
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SvgColor
                    src={sourceUrl.icons.number}
                    style={{
                      color: "black",
                      width: "64px",
                      height: "50px",
                      padding: "10px",
                    }}
                  />
                </Typography>
              </Typography>
            </CardContent>
          </Card>

          <Card
            sx={{
              padding: "0px",
              height: "90px",
              width: "34%",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
            }}
          >
            <CardContent
              sx={{
                padding: "0px",
                ":last-child": {
                  paddingBottom: "0px",
                },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "16px",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 500, fontSize: "16px", color: "#000000" }}
                  >
                    Pending Form Completion
                  </Typography>
                  <Typography
                    sx={{ color: "#4084EF", fontSize: "16px", fontWeight: 500 }}
                  >
                    {employeesGetMeta
                      ? employeesGetMeta.totalBookedForInduction
                      : 0}
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SvgColor
                    src={sourceUrl.icons.number}
                    style={{
                      color: "black",
                      width: "64px",
                      height: "50px",
                      padding: "10px",
                    }}
                  />
                </Typography>
              </Typography>
            </CardContent>
          </Card>

          <Card
            sx={{
              padding: "0px",
              height: "90px",
              width: "33%",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
            }}
          >
            <CardContent
              sx={{
                padding: "0px",
                ":last-child": {
                  paddingBottom: "0px",
                },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "16px",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 500, fontSize: "16px", color: "#000000" }}
                  >
                    Pending Approval
                  </Typography>
                  <Typography
                    sx={{ color: "#14AE5C", fontWeight: 500, fontSize: "16px" }}
                  >
                    {employeesGetMeta
                      ? employeesGetMeta.totalInductedEmployee
                      : 0}
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SvgColor
                    src={sourceUrl.icons.inducted}
                    style={{
                      color: "black",
                      width: "54px",
                      height: "50px",
                      padding: "10px",
                    }}
                  />
                </Typography>
              </Typography>
            </CardContent>
          </Card>
        </Stack>
        <Stack
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            paddingLeft: "20px",
            paddingRight: "20px",
            gap: "20px",
          }}
        >
          <Button
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              height: "34px",
              width: "172px",
              borderRadius: "5px",
              padding: "12px 10px 12px 12px",
              lineHeight: "24px",
            }}
            variant="contained"
            startIcon={
              <SvgColor
                src={sourceUrl.icons.addIcon}
                sx={{ width: "24px", height: "24px" }}
              />
            }
            onClick={() => {
              dispatch(setDuplicateNull());
              navigate(PATH_DASHBOARD.checkForDuplicates);
            }}
          >
            Add new employee
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "#616E80",
              backgroundColor: "#FFFFFF",
              borderColor: "#616E80",
              fontSize: "14px",
              fontWeight: "400",
              height: "34px",
              width: "183px",
              borderRadius: "5px",
              padding: "12px 10px 12px 12px",
              lineHeight: "24px",
            }}
            startIcon={<SvgColor src={sourceUrl.icons.addIcon} />}
            onClick={() => {
              dispatch(setDuplicateNull());
              navigate(PATH_DASHBOARD.checkForDuplicates);
            }}
          >
            Invite New Employee
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "#616E80",
              backgroundColor: "#FFFFFF",
              borderColor: "#616E80",
              fontSize: "14px",
              fontWeight: "400",
              width: "96px",
              height: "34px",
              lineHeight: "24px",
            }}
            startIcon={<SvgColor src={sourceUrl.icons.addIcon} />}
          >
            Export
          </Button>
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: "100%",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        {employees && employees.data ? (
          <>
            <EmployeeTable
              data={employees}
              pageLimit={pageLimitValue}
              pageValue={pageValue}
              onBoardingReview={true}
            />
            <Divider sx={{ borderColor: "#F1F4F7" }} />
            <TablePaginationCustom
              onPageDenseChange={(e) => {
                setPageLimitValue(e.target.value);
              }}
              totalItems={employeesGetMeta.totalItems}
              totalPage={employeesGetMeta.totalPages}
              itemsPerPage={employeesGetMeta.itemsPerPage}
              currentPage={employeesGetMeta.currentPage}
              showingCustomText={employeesGetMeta.showing}
              onPageChange={(e, currentPage) => {
                setPageValue(currentPage);
              }}
              sx={{
                background: "#fff",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
              }}
            />
          </>
        ) : (
          <Card
            sx={{
              padding: "0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "40dvh",
              width: "33%",
              margin: "2rem auto",
              borderRadius: "5px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
            }}
          >
            <CardContent
              sx={{
                padding: "0px",
                margin: "auto",
                ":last-child": {
                  paddingBottom: "0px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "2rem",
                  fontWeight: "700",
                  color: "#616E80",
                }}
              >
                No employee found
              </Typography>
            </CardContent>
          </Card>
        )}
      </Stack>
    </Stack>
  );
};
