import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Button,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";

// Define props type
interface QrDialogueProps {
  isOpen: boolean;
  onClose: () => void;
  qrUrl?: string;
  qrPhotoUrl?: string;
  onComplete: () => void;
  onCancel: () => void;
  employeeName?: string;
}

const QrDialogue: React.FC<QrDialogueProps> = ({
  isOpen,
  onClose,
  qrUrl,
  qrPhotoUrl,
  onComplete,
  onCancel,
  employeeName,
}) => {
  // Function to copy qrUrl to clipboard
  const handleCopyLink = () => {
    if (qrUrl) {
      navigator.clipboard.writeText(qrUrl);
      enqueueSnackbar("Link copied to clipboard", {
        variant: "success",
      });
    }
  };

  // Function to download QR Photo
  const handleDownloadQR = async () => {
    if (qrPhotoUrl) {
      try {
        const response = await fetch(qrPhotoUrl);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = employeeName
          ? `${employeeName}_qr_code.png`
          : "qr_code.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url); // Clean up memory
      } catch (error) {
        console.error("Failed to download QR code:", error);
      }
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Stack sx={{ width: "100%" }}>
        <Divider sx={{ color: "#F1F4F7" }} />
      </Stack>

      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: 600,
          textAlign: "center",
          paddingY: "10px",
        }}
        variant="h6"
      >
        Invitation Generated Successfully
      </Typography>

      <Stack sx={{ width: "100%" }}>
        <Divider sx={{ color: "#F1F4F7" }} />
      </Stack>

      {/* Check if qrUrl exists before rendering the text */}
      {qrUrl && (
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            textAlign: "center",
            paddingY: "10px",
          }}
          variant="body1"
        >
          Scan the QR code or click the link below to access your invitation.
        </Typography>
      )}

      <DialogContent
        sx={{
          width: "600px",
          display: "flex",
          justifyContent: "center",
          padding: "5px",
        }}
      >
        <Card sx={{ width: "500px", borderRadius: "8px" }}>
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            {/* Display QR Code Image */}
            {qrPhotoUrl && (
              <Box
                sx={{
                  width: "200px",
                  height: "200px",
                  borderRadius: "8px",
                  objectFit: "cover",
                  border: "2px solid black",
                }}
                component="img"
                src={qrPhotoUrl}
                alt="QR Code"
              />
            )}

            {/* Download QR Code Button */}
            <Button variant="contained" onClick={handleDownloadQR}>
              Download QR Code
            </Button>

            {/* Direct Link Input */}
            <Box
              sx={{ width: "100%", display: "flex", flexDirection: "column" }}
            >
              <TextField
                label="Direct Link"
                value={qrUrl || ""}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Box>

            {/* Copy Link Button */}
            <Button variant="contained" onClick={handleCopyLink}>
              Copy Link
            </Button>
          </CardContent>
        </Card>
      </DialogContent>

      <Divider sx={{ borderColor: "#F1F4F7" }} />
      <Stack
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          paddingY: "8px",
          paddingX: "12px",
        }}
      >
        <Button variant="contained" color="error" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3FB34F",
            "&:hover": { backgroundColor: "#349e44" },
          }}
          onClick={onComplete}
        >
          Complete
        </Button>
      </Stack>
    </Dialog>
  );
};

export default QrDialogue;
