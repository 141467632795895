import { takeLatest, put, select, call } from 'redux-saga/effects';

import { httpService } from '@app/services';
import { selectIsAuthenticated, selectAuthToken } from '@app/modules/common';
import { logout } from '@app/modules/auth/slice';
import { bootstrapApp, markAppAsReady } from './slice';

function* bootstrapSaga(): any {
  try {
    const isAuthenticated = yield select(selectIsAuthenticated);
    
    if (isAuthenticated) {
      yield call(prepareUserSessionSaga);
    } else {
      yield put(logout());
    }
  } catch (err) {
    console.log('Error - bootstrapSaga', err);
  } finally {
    yield put(markAppAsReady());
  }
}

function* prepareUserSessionSaga(): any {
  
  const token = yield select(selectAuthToken);
  console.log('token---', token);
  
  httpService.setToken(token);
}

function* bootstrapSagas() {
  yield takeLatest(bootstrapApp, bootstrapSaga);
}

export { bootstrapSagas, prepareUserSessionSaga };
