import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { get } from "lodash";

import { Input } from "@app/components";
import { addEmployementTypeAction, getEmployementType } from "../slice";
import { selectAddEmploymentType } from "../selector";
import { useRequestState } from "@app/hooks/use-request-state";
import { LoadingButton } from "@mui/lab";
import { Strings } from "@app/constants/strings";

const schema = yup.object().shape({
  name: yup.string().required("Employment type name required"),
});

const AddNewEmployeeType = ({ open, onClose, data }: any) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      id: get(data, "id", null),
      name: get(data, "name", ""),
    },
  });

  const dispatch = useDispatch();

  const submit = (form: any) => {
    dispatch(
      addEmployementTypeAction({ callback: () => handleAfterSave(), ...form })
    );
  };

  const { loading } = useRequestState({
    stateSelector: selectAddEmploymentType,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  const handleAfterSave = useCallback(() => {
    reset();
    onClose();
  }, []);

  useEffect(() => {
    dispatch(getEmployementType({ page: 1, limit: 1000 }));
  }, [dispatch]);

  return (
    <Dialog open={open}>
      <Stack sx={{ width: "100%" }}>
        <Typography
          sx={{ fontSize: "24px", fontWeight: "500", padding: "5px" }}
        >
          Create Employment Type
        </Typography>
        <Divider sx={{ color: "#F1F4F7" }} />
      </Stack>
      <Card sx={{ padding: "0px" }}>
        <CardContent
          sx={{
            padding: "0px",
            ":last-child": {
              paddingBottom: "0px",
            },
          }}
          component={"form"}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              paddingX: "20px",
              paddingY: "12px",
            }}
          >
            <Box sx={{ width: "100%", minWidth: "400px" }}>
              <Typography>Employment Type Name</Typography>
              <Input
                name="name"
                control={control}
                error={errors?.name?.message}
                placeHolder={Strings.field.inputPlaceholder("Employement Type Name")}
              />
            </Box>
          </Box>
          <Divider sx={{ borderColor: "#F1F4F7" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "22px",
              paddingY: "10px",
            }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Box sx={{ display: "flex", gap: 2 }}>
              <LoadingButton
                variant="contained"
                type="submit"
                onClick={handleSubmit(submit)}
                loading={loading}
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Dialog>
  );
};

export { AddNewEmployeeType };
